<template>
  <div><div v-if="level=='super_admin'">
    <v-row class="ma-2"
    ><v-col cols="12">
      <v-card class="elevation-1">
        <v-toolbar>
          <v-toolbar-title>List User Terdaftar</v-toolbar-title
          ><v-spacer></v-spacer
          ><v-btn icon color="blue" @click="pemicu = true"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="judul"
          :items="user"
          :items-per-page="10"
          :loading="loading"
          v-model="userTerpilih"
          :search="search"
          dense
        >
          <template v-slot:item.created_at="{ item }">
            <span>{{ item.created_at | formatDate }} </span>
          </template>
          <template v-slot:item.opsi="{ item }">
            <v-btn
              class="mx-2"

              color="pink"
              icon
              x-small
              @click="logout(item)"
              :disabled="loadingHapus"
              ><v-icon> mdi-power </v-icon></v-btn
            >
            <v-btn
              class="mx-2"

              color="blue"
              icon
              x-small
              @click="edit(item)"
              :disabled="loadingHapus"
              ><v-icon> mdi-pencil </v-icon></v-btn
            ><v-btn
              class="mx-2"
              icon
              color="red"
              x-small
              @click="hapus(item)"
              :disabled="loadingHapus"
              ><v-icon> mdi-trash-can </v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <r-new
      :idUser="0"
      :pemicu="pemicu"
      @tutup="pemicu = false"
      @lanjutkan="lanjutkan"
    ></r-new>
    <r-edit
      :idUser="idUser"
      :pemicu="pemicuEdit"
      @tutup="pemicuEdit = false"
      @lanjutkan="lanjutkan"
    ></r-edit>
  </v-row>
  </div><div v-else>"kamu tidak bisa mengakses halaman ini"</div></div>
  
</template>

<script>
import rNew from "./register.vue";
import rEdit from "./edit.vue";
import _ from "lodash";
import axios from "axios";
export default {
  components: { rNew ,rEdit},
  computed: {
    lastPage() {
      return Math.ceil(this.harga.length / 12);
    },
  },
  data() {
    return {
      search: "",
      user: [],
      loading: false,
      userTerpilih: [],
      loadingHapus: false,
      judul: [
        {
          text: "Nama ",
          align: "start",
          filterable: true,
          value: "name",
        },
        {
          text: "email",
          value: "email",
        },
        { text: "Tanggal Registrasi", value: "created_at" },
        { text: "Level User", value: "user_level" },
        {
          text: "Opsi",
          value: "opsi",
        },
      ],
      pemicu: false,
      pemicuEdit:false,
      level:"super_admin",
      idUser:0,
    };
  },
  mounted() {
    this.inisialisasi();
      axios
        .get("/user/aktif")
        .then((response) => {
          this.level = response.data.user_level
        })
        .catch((err) => {
          console.log(err);
        });
  },
  watch: {},
  methods: {
    inisialisasi() {
      this.loading = true;
      let url = "/user";
      axios.get(url).then((response) => {
        this.user = _.values(response.data);
        console.log(this.user);
        this.loading = false;
      });
    },
    hapus(item) {
      this.loadingHapus = true;
      let uri = "/user/" + item.id;
      axios.delete(uri).then((response) => {
        this.$noty.success(response.data);
        this.loadingHapus = false;
        this.inisialisasi();
      });
      console.log(item);
    },
    edit(item){
      this.idUser=item.id;
      this.pemicuEdit=true;
    },
    logout(item){
      let uri = `/user/logout/${item.id}`;
      axios.get(uri).then(response=>{
        console.log(response.data);
        this.$noty.success(response.data);
      })
    },
    tutup() {
      this.pemicu = false;
    },
    lanjutkan() {
      this.inisialisasi();
    },
    baru() {},
  },
};
</script>
