<template>
  <v-dialog transition="dialog-bottom-transition" v-model="dialog" width="450">
    <v-card class="elevation-1">
      <v-toolbar dense color="red darken-2" dark>
        <v-btn icon @click="baru"><v-icon>mdi-plus</v-icon></v-btn>
        <v-toolbar-title>Grup Pabrik</v-toolbar-title><v-spacer></v-spacer>
        <v-btn icon @click="tutup"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row no-gutters>
          <v-col>
            <v-form
              @submit.prevent="kirim()"
              color="grey"
              ref="form"
              v-model="valid"
            >
              <v-row no>
                <v-col cols="9">
                  <v-text-field
                    label="Nama Pabrik"
                    required
                    v-model="data.pabrik"
                    ref="pabrik"
                    :rules="[(v) => !!v || 'Harus diisi']"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    color="blue"
                    dark
                    type="submit"
                    :disabled="loadingSimpan"
                    class="mt-2"
                    >Simpan</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-3">
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-2"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-data-table
          :headers="judul"
          :items="grup"
          :loading="loading"
          :items-per-page="8"
          hide-default-header
          @page-count="pageCount = $event"
          :page.sync="page"
          v-model="pabrikTerpilih"
          :search="search"
          hide-default-footer
          dense
        >
          <template v-slot:item="{ item }">
            <tr
              :class="
                pabrikTerpilih.indexOf(item.id) > -1
                  ? 'primary  white--text font-weight-bold'
                  : ''
              "
              @click="rowClicked(item)"
            >
              <td>{{ item.pabrik }}</td>
              <td>
                <div class="d-inline-flex flex-row-reverse">
                  <v-btn
                    class="mx-2"
                    icon
                    color="red"
                    x-small
                    @click="hapus(item)"
                    :disabled="loadingHapus"
                    ><v-icon> mdi-trash-can </v-icon></v-btn
                  >
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import rizki from "./../../../mixins/rizki.js";
export default {
  mixins: [rizki],
  props: ["pemicu"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      search: "",
      pabrikTerpilih: [],
      loading: false,
      page: 1,
      pageCount: 0,
      judul: [
        {
          text: "Nama Pabrik",
          align: "start",
          filterable: true,
          value: "pabrik",
        },
        {
          text: "aksi",
          align: "right",
          value: "opsi",
        },
      ],
      data: {
        id: 0,
        pabrik: "",
      },
      loadingSimpan: false,
      loadingHapus: false,
      grup: [],
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid && this.loadingSimpan == false) {
        this.loadingSimpan = true;
        console.log(this.data);
        if (this.data.id > 0) {
          let uri = `/pabrikgrup/${this.data.id}`;
          let d = this.data;
          let data = { pabrik: d.pabrik };
          console.log("di eksekusi");
          axios.put(uri, data).then((response) => {
            if (response.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.tutup();
              this.$emit("lanjutkan", response.data.data);
              console.log(response.data.data);
              this.loadingSimpan = false;
              this.inisialisasi();
              this.baru();
            } else {
              this.$noty.success(response.data.data[0]);
            }
          });
        } else {
          console.log(this.data);
          let uri = "/pabrikgrup";
          axios.post(uri, this.data).then((response) => {
            if (response.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.tutup();
              this.$emit("lanjutkan", response.data.data);
              console.log(response.data.data);
              this.loadingSimpan = false;
              this.inisialisasi();
              this.baru();
            } else {
              this.$noty.success(response.data.data[0]);
            }
          });
        }
      }
    },
    inisialisasi() {
      axios.get("/pabrikgrup").then((response) => {
        this.grup = response.data;
      });
    },
    rowClicked(item) {
      this.pabrikTerpilih = [item.id];
      this.data.pabrik = item.pabrik;
      this.data.id = item.id;
    },
    baru() {
      this.data.pabrik = "";
      this.data.id = 0;
      this.$refs.pabrik.focus();
      this.pabrikTerpilih = [0];
    },
    hapus(item) {
      this.loadingHapus = true;
      let uri = "/pabrikgrup/" + item.id;
      axios.delete(uri).then((response) => {
        this.$noty.success(response.data.pesan);
        this.loadingHapus = false;
        this.tutup();
      });
    },
  },
};
</script>
