<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    persistent
    width="550"
  >
    <v-card :loading="loading">
      <v-toolbar dense color="blue" dark>
        <v-toolbar-title>Tonase Edit</v-toolbar-title>
        <v-spacer></v-spacer>
        <span v-if="tonase">
          <span v-if="tonase.transaksi"
            >{{ tonase.transaksi ? tonase.transaksi.akun.nama : "" }} /
            {{
              tonase.transaksi ? tonase.transaksi.tanggal : "" | formatDate
            }}</span
          >
          <span v-else
            >{{ tonase.akuns ? tonase.akuns.nama : "" }} /
            {{ tonase.tanggal | formatDate }}</span
          ></span
        >
      </v-toolbar>
      <v-card-text>
        <v-form
          @submit.prevent="updateData"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="4">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs"
                      clearable
                      label="Tanggal"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="date = null"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tonase.tanggaldo"
                    @change="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="tonase.toke"
                  :items="toke"
                  item-text="nama"
                  item-value="ID"
                  label="Toke"
                  dense
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="pabriks"
                  label="Pabrik"
                  item-text="pabrik"
                  item-value="id"
                  v-model="pabrik"
                  required
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="deoFilter"
                  label="Do"
                  item-text="do"
                  item-value="id"
                  v-model="tonase.do"
                  required
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Nama Supir"
                  required
                  v-model="tonase.supir"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Plat"
                  required
                  v-model="tonase.plat"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="3">
                <v-text-field
                  label="Tonase"
                  required
                  v-model="tonase.tonase"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3" md="2">
                <v-text-field
                  label="th"
                  required
                  v-model="tonase.th"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="3">
                <v-text-field
                  label="OPS"
                  required
                  v-model="tonase.ops"
                  dense
                ></v-text-field> </v-col></v-row
            ><v-row>
              <v-col cols="12">
                <v-card>
                  <span class="text-h6 pa-3"
                    >{{ tonase.tonase | angka }} X
                    {{ hargaHitung ? hargaHitung.harga : 0 }}
                  </span>
                  <span class="text-h6 pa-3">
                    {{
                      ((hargaHitung ? hargaHitung.harga : 0) * tonase.tonase)
                        | rupiah
                    }}
                  </span>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="updateData"
                  type="submit"
                  :disabled="tombolOff"
                  class="ma-2"
                >
                  Simpan
                </v-btn>
                <v-btn text @click="tutup"> Batal </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
  data() {
    return {
      menu1: false,
      date: "",
      tonase: [],
      harga: [],
      loadingTon: false,
      loadingHarga: false,
      valid: false,
      tombolOff: false,
      loading: false,
      deo: [],
      pabrik: 0,
      pabriks: [],
      toke: [],
      hargaHitung: {},
    };
  },
  props: ["id", "dialogTon"],
  computed: {
    computedDateFormattedMomentjs() {
      return this.tonase
        ? moment(this.tonase.tanggaldo).format("DD/MM/YYYY")
        : "";
    },
    deoFilter() {
      return this.deo.filter((x) => x.pabrik.id == this.pabrik);
    },
    dialog: {
      get() {
        return this.dialogTon;
      },
      set() {
        this.$emit("tutup");
        this.modalModal = [];
      },
    },
  },
  watch: {
    id() {
      this.tonase = {};
      this.inisialisasi();
    },
    pabrik() {
      this.tonase.do = this.deo
        .filter((x) => x.pabrik.id == this.pabrik)
        .sort((a, b) => a.no - b.no)[0].id;
      this.hargaHitung = this.cariHarga();
    },
  },
  mounted() {
    this.inisialisasi();
  },
  methods: {
    tutup() {
      this.$emit("tutup", 0);
    },
    async inisialisasi() {
      if (this.id) {
        this.loading = true;
        axios.get("/tonase/" + this.id).then((response) => {
          this.tonase = response.data;
          this.tonase.supir = this.tonase["nama supir"];
          this.tonase.do = parseInt(this.tonase.do);
          this.tonase.toke = parseInt(this.tonase.toke);
          console.log(this.tonase);
        });
        this.toke = await this.getToke();
        this.deo = await this.getDeo();
        this.harga = await this.getHarga();
        this.hargaHitung = this.cariHarga();
        this.listPabrik();
        this.pabrik = this.deo.find((x) => x.id == this.tonase.do).pabrik.id;
        console.log(this.hargaHitung);
        this.loading = false;
      }
    },
    async getToke() {
      let uri = "/tokeAktif";
      return axios.get(uri).then((res) => {
        return res.data;
      });
    },
    async getDeo() {
      let uri = "/deo";
      return axios.get(uri).then((res) => {
        return res.data;
      });
    },
    async getHarga() {
      if (this.id) {
        return axios
          .get("/harga/60hari/" + this.tonase.tanggaldo)
          .then((response) => {
            return response.data;
          });
      } else {
        return [];
      }
    },
    listPabrik() {
      this.pabriks = this.deo.reduce((simpan, item) => {
        let id = simpan.findIndex((x) => x.id == item.pabrik.id);
        if (id >= 0) {
          return simpan;
        } else {
          simpan.push({ id: item.pabrik.id, pabrik: item.pabrik.pabrik });
          return simpan;
        }
      }, []);
    },
    updateData() {
      if (this.valid) {
        this.tombolOff = true;
        let tabel = this.tonase;
        let toke2 = this.toke;
        tabel.txtoke = toke2.find((x) => x.ID == tabel.toke).nama;
        tabel.pabrik = this.deo.find((x) => x.id == tabel.do).pabrik.pabrik;
        this.tonase.harga = this.cariHarga().harga;
        this.tonase.id_harga = this.cariHarga().id;
        let uri = `/pencairan/tonase/${this.tonase.id}`;
        axios
          .put(uri, this.tonase)
          .then((response) => {
            if (response.data.status) {
              this.$noty.success(response.data.messages);
              this.$emit("update", tabel);
              this.tombolOff = false;
            }
          })
          .catch((error) => {
            this.$noty.error(error.response.data.errors[0]);
            this.tombolOff = false;
          });
      }
    },
    cariHarga() {
      let deo = this.tonase.do;
      let tgl = this.tonase.tanggaldo;
      console.log(`${deo} == ${tgl}`);
      let pabrik = this.deo.find(function (item) {
        return item.id == deo;
      }).pabrik.id;
      let hasil = this.harga.filter(function (item) {
        return moment(tgl).isSameOrAfter(item.tanggal) && pabrik == item.pabrik;
      });
      if (hasil.length > 0) {
        return hasil[hasil.length - 1];
      } else {
        return 0;
      }
    },
    simpan() {},
  },
};
</script>
