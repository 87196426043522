<template>
  <v-dialog transition="dialog-bottom-transition" v-model="dialog" persistent>
    <v-card dense tile :loading="loading">
      <v-card-title>Pilih Angsuran yg dibayar</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data"
          :search="search"
          :items-per-page="8"
          show-select
          :page="lastPage"
          dense
          v-model="terpilih"
          item-key="ID"
          mobile-breakpoint="300"
          v-show="data.length > 0"
        >
          <template v-slot:item.pinjam="{ item }">
            <span>{{ item.pinjam | rupiah }}</span>
          </template>
          <template v-slot:item.tanggal="{ item }">
            <span>{{ item.tanggal | formatDate }}</span>
          </template>
          <template v-slot:item.angsur="{ item }">
            <span>{{ item.angsur | rupiah }}</span>
          </template>
          <template v-slot:item.saldo="{ item }">
            <span>{{ item.saldo | rupiah }}</span>
          </template></v-data-table
        >
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue" dark :disabled="cekList" @click="simpan"
          >Simpan</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="red" dark @click="tutup()">Batal</v-btn></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>
<script>
// import moment from "moment";
import axios from "axios";
import moment from "moment";
export default {
  props: ["pemicu", "id", "nota"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    lastPage() {
      return Math.ceil(this.data.length / 8);
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
    terpilih() {
      this.cekList = !this.data.length > 0;
    },
  },
  mounted() {},
  data() {
    return {
      loading: false,
      jenis: "PS",
      data: [],
      headers: [
        { text: "Tanggal", value: "tanggal" },
        { text: "Uraian", value: "uraian" },
        { text: "Pinjam", value: "pinjam" },
        { text: "Bayar", value: "angsur" },
        { text: "Saldo", value: "saldo" },
      ],
      search: "",
      terpilih: [],
      cekList: true,
    };
  },
  methods: {
    inisialisasi() {
      console.log(this.id);
      this.terpilih = [];
      let data = [];
      this.loading = true;
      let uri = `/toke/${this.id}/pinjaman/${this.jenis}`;
      axios
        .get(uri)
        .then((res) => {
          data = res.data;
          let simp = 0;
          data.map((x) => {
            simp += parseInt(x.pinjam || 0) - parseInt(x.angsur || 0);
            x.saldo = simp;
            return x;
          });
          this.data = data;
          console.log(this.data);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.message);
        });
    },
    tutup() {
      this.$emit("tutup");
    },
    simpan() {
      console.log("proses simpan");

      this.cekList = true;
      let uri = "/pinjaman/insertbanyak";
      let pinjamans = this.terpilih.map((x) => {
        const ang = x.angsur || 0;
        x.angsur = x.pinjam || 0;
        x.pinjam = ang;
        x.hitung = (x.hitung || 0) * -1;
        x.uraian = `${moment(String(x.tanggal)).format("DD/MM/YYYY")} - ${
          x.uraian
        } `;
        x.idtoke = x.toke;
        x.id_cair = this.nota.id_cair; //id cair
        x.id_gabung = this.nota.ID; //id nota
        x.tanggal = this.nota.tanggal; //tanggal
        return x;
      });
      console.log(pinjamans);
      axios
        .post(uri, pinjamans)
        .then((response) => {
          if (response.data) {
            this.$noty.success("Data berhasil disimpan");
            this.cekList = false;
            this.$emit("simpan");
          }
        })
        .catch((error) => {
          this.$noty.error(
            "Terjadi Erorr, entah kenapa bisa error. silahkan coba lagi atau batalkan"
          );
          console.log(error);
          this.cekList = false;
        });
    },
  },
};
</script>
