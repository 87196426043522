<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile :loading="loading">
      <v-card-title
        >Rekening<v-spacer></v-spacer
        ><v-btn @click="tutup" icon color="red" large
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-card-title
      >
      <v-card-text>
        <v-form
          @submit.prevent="simpan()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="8">
              <v-menu
                v-model="pupup"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="ubahTanggal"
                    clearable
                    label="Tanggal"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.tanggal"
                  @change="pupup = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-switch v-model="data.valid" label="valid?" dense></v-switch>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="data.jenis"
                :items="['PINJAMAN', 'PENCAIRAN', 'MODAL']"
                auto-select-first
                label="jenis"
                autofocus
                dense
                ref="toke"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="data.akun"
                :items="toke"
                item-text="nama"
                item-value="ID"
                auto-select-first
                label="akun"
                autofocus
                dense
                ref="toke"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12">
              <v-text-field label="uraian" required v-model="data.uraian" dense>
              </v-text-field>
            </v-col>
            <v-col cols="12"
              ><v-text-field
                label="Masuk"
                required
                v-model="data.masuk"
                type="number"
                dense
              >
              </v-text-field
            ></v-col>
            <v-col cols="12">
              <v-text-field
                label="Keluar"
                v-model="data.keluar"
                type="number"
                dense
              >
              </v-text-field>
            </v-col>

            <v-btn color="blue" dark type="submit" :disabled="tombolOff"
              >Simpan</v-btn
            ><v-spacer></v-spacer
            ><v-btn
              color="green"
              dark
              :disabled="tombolOff"
              @click="simpandanbaru()"
              >Buat lagi</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  props: ["id", "rekening", "tanggal", "pemicu"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    ubahTanggal() {
      return this.data ? moment(this.data.tanggal).format("DD/MM/YYYY") : "";
    },
  },
  watch: {
    pemicu() {
      this.kondisikan();
    },
  },
  data() {
    return {
      valid: false,
      tombolOff: false,
      data: {
        jenis: "PINJAMAN",
        akun: 1,
      },
      loading: false,
      toke: [],
      pupup: false,
      masal: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid) {
        this.tombolOff = true;
        this.isiData();
        if (this.id) {
          let uri = `/rekeningkoran/${this.id}`;
          console.log(this.data);
          axios
            .put(uri, this.data)
            .then((response) => {
              if (response.data.status) {
                this.$noty.success(response.data.messages);
                if (this.masal == false) {
                  this.$emit("update", response.data.awal);
                  this.$emit("tutup");
                  this.tombolOff = false;
                } else {
                  this.nolkan();
                  this.$emit("update", response.data.awal);
                  this.tombolOff = false;
                }
              } else {
                console.log(response.data);
                this.tombolOff = false;
              }
            })
            .catch((error) => {
              this.$noty.error(error.message);
              this.tombolOff = false;
            });
        } else {
          let uri = "/rekeningkoran";
          console.log(uri);
          console.log(this.data);
          axios
            .post(uri, this.data)
            .then((response) => {
              if (response.data.status) {
                if (this.masal == false) {
                  this.$emit("tutup");
                } else {
                  this.nolkan();
                }
                this.tombolOff = false;
                this.$emit("update", response.data.awal);
                console.log(response.data);
                this.$noty.success(response.data);
              } else {
                console.log(response.data);
                this.$noty.error(response.data.pesan);
                this.tombolOff = false;
              }
            })
            .catch((error) => {
              this.$noty.error(error.message);
              this.tombolOff = false;
            });
        }
      }
    },
    kondisikan() {
      this.loading = true;
      if (this.id) {
        let uri = "/rekeningkoran/" + this.id;
        axios.get(uri).then((response) => {
          this.data = response.data;

          console.log(this.data);
          this.getToke();
        });
      } else {
        this.nolkan();
        this.getToke();
      }
    },
    getToke() {
      axios.get("/tokeAktif").then((res) => {
        this.toke = res.data;
        this.loading = false;
      });
    },
    nolkan() {
      axios.get(`/setting/get/tokeStandar`).then((res) => {
        this.data.akun = res.data;
        console.log(res.data);
      });
      this.data.id = 0;
      this.data.rekening = this.rekening;
      this.data.jenis = "PINJAMAN";
      this.data.tanggal = this.tanggal;
      this.data.uraian = "";
      this.data.valid = 0;
      this.data.masuk = 0;
      this.data.keluar = 0;
    },
    simpandanbaru() {
      this.masal = true;
      this.kirim();
    },
    simpan() {
      this.masal = false;
      this.kirim();
    },
    isiData() {},
  },
};
</script>
