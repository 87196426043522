<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    persistent
    width="550"
  >
    <v-card>
      <v-card-title class="headline"> Pisahkan Pinjaman </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Uraian"
                required
                v-model="pinjaman.uraian"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Pinjam"
                required
                v-model="pinjaman.pinjam"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="bayar"
                type="number"
                required
                v-model="pinjaman.angsur"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="updateData"> Simpan </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="tutup"
          :loading="loadingBtn"
          :disabled="loadingBtn"
        >
          Batal
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      pinjaman: [],
      loadingBtn: false,
    };
  },
  props: ["id", "toke", "dialogSplit"],
  computed: {
    dialog: {
      get() {
        return this.dialogSplit;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    dialogSplit() {
      this.inisialisasi();
    },
  },
  methods: {
    tutup() {
      this.$emit("tutup", 0);
    },
    inisialisasi() {
      this.loadingBtn = true;
      if (this.id) {
        axios.get("/pinjaman/" + this.id).then((response) => {
          this.pinjaman = response.data;
          this.pinjaman.toke = parseInt(this.pinjaman.toke);
          this.kondisikan();
          console.log(this.pinjaman);
          this.loadingBtn = false;
        });
      }
    },
    updateData() {
      this.kondisikan();
      // console.log(this.pinjaman);
      // let uri = `/api/pinjaman/${this.pinjaman.ID}/`;
      // axios
      //     .put(uri, this.pinjaman)
      //     .then(response => {
      //         if (response.data.status) {
      //             this.$noty.success(response.data.messages);
      //             this.$emit("update", response.data.awal);
      //         }
      //     })
      //     .catch(error => {
      //         this.$noty.error(error.response.data.errors[0]);
      //     });
    },
    kondisikan() {},
  },
};
</script>
