<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="350"
    persistent
  >
    <v-card>
      <v-card-title class="headline"> Pilih Filter </v-card-title>

      <v-card-text :loading="loading">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="12">
                  <v-autocomplete
                    label="Akun"
                    v-model="akun"
                    :items="akuns"
                    item-value="id"
                    item-text="nama"
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    label="Jenis"
                    v-model="jenis"
                    :items="jeniss"
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    label="Valid"
                    v-model="valid"
                    :items="valids"
                    item-value="id"
                    item-text="nama"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn color="pink" dark @click="reset"> Reset </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="simpan"> Filter </v-btn>
        <v-btn color="warning" text @click="tutup"> Batal </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      jeniss: [],
      akuns: [],
      valids: [],
      dataAwal: [],
      dataUpdate: [],
      jenis: [],
      valid: [],
      akun: [],
    };
  },
  props: ["pemicu", "data"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      console.log("inisialisasi");
      this.dataAwal = this.data;
      this.dataUpdate = this.data;
      this.jeniss = this.jenisFilter();
      this.akuns = this.tabelFilter1("toke", "ID", "nama");
      this.valids = this.validFilter();

      this.jenis = [];
      this.valid = [];
      this.akun = [];
      this.loading = false;
      console.log(this.akuns);
    },
    jenisFilter() {
      if (this.dataUpdate.length > 0) {
        return this.dataUpdate.reduce((simpan, item) => {
          if (
            simpan.find((x) => {
              return x == item.jenis;
            })
          ) {
            return simpan;
          } else {
            simpan.push(item.jenis);
            return simpan;
          }
        }, []);
      }
    },
    validFilter() {
      return [
        { id: 0, nama: "Belum" },
        { id: 1, nama: "Valid" },
      ];
    },
    tabelFilter1(kolom, idnya, nama) {
      if (this.dataUpdate.length > 0) {
        var c = this.dataUpdate.reduce(function (chace, item) {
          const properti = item[kolom][idnya];
          const kolomA = item[kolom][nama];
          if (chace.some((el) => el.id === properti)) {
            return [...chace];
          }
          chace.push({ id: properti, nama: kolomA });
          return [...chace];
        }, []);
        return c;
      }
    },
    filter() {
      if (this.data.length >> 0) {
        this.dataUpdate = this.data;
        let akun = this.akun;
        let jenis = this.jenis;
        let valid = this.valid;
        if (akun.length > 0) {
          console.log("akun difilter");
          console.log(akun);
          this.dataUpdate = this.dataUpdate.filter(function (x) {
            return akun.indexOf(x.toke.ID) !== -1;
          });
        }
        if (jenis.length > 0) {
          console.log("jenis di filter");
          this.dataUpdate = this.dataUpdate.filter(function (x) {
            return jenis.indexOf(x.jenis) !== -1;
          });
        }
        if (valid.length > 0) {
          console.log("valid di filter");
          this.dataUpdate = this.dataUpdate.filter(function (x) {
            return valid.indexOf(x.valid) !== -1;
          });
        }
      }
      console.log(this.dataUpdate);
    },
    simpan() {
      this.filter();
      this.$emit("simpan", this.dataUpdate);
      this.$emit("tutup");
    },
    tutup() {
      this.$emit("tutup");
    },
    reset() {
      this.tokePilihan = [];
      this.pabrikPilihan = [];
      this.akunPilihan = [];
      this.$emit("reset");
      this.$emit("tutup");
    },
  },
};
</script>
