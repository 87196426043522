<template>
  <v-card>
    <v-card-title>
      <v-btn color="green" fab small dark @click="bukaBaru()"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="tabel"
      :items-per-page="100"
      mobile-breakpoint="300"
      :loading="loading"
      hide-default-footer
    >
      <template v-slot:item.tanggal="{ item }">
        <span>{{ item.tanggal | formatDate }}</span>
      </template>
      <template v-slot:item.tanggalawal="{ item }">
        <span
          >{{ item.daritanggal | formatDate }}-{{
            item.sampaitanggal | formatDate
          }}</span
        >
      </template>
      <template v-slot:item.cair="{ item }">
        <span :class="classEror(item)">{{ item.cair | rupiah }}</span>
      </template>
      <template v-slot:item.banding="{ item }">
        <span>{{ item.banding | angka }}</span>
      </template>
      <template v-slot:item.selisih="{ item }">
        <span>{{
          (parseInt(item.cair) - parseInt(item.banding)) | rupiah
        }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn class="mx-2" icon color="blue" x-small @click="buka(item)">
          <v-icon> mdi-pencil </v-icon> </v-btn
        ><v-btn class="mx-2" icon color="blue" x-small @click="bukaSatu(item)">
          <v-icon> mdi-list-status </v-icon>
        </v-btn>
        <v-btn
          v-if="item.banding == false"
          class="mx-2"
          icon
          color="orange"
          x-small
          @click="bukaLink(item)"
        >
          <v-icon> mdi-link </v-icon>
        </v-btn>
        <v-btn
          v-if="item.banding"
          class="mx-2"
          icon
          color="red"
          x-small
          @click="hapusLink(item)"
        >
          <v-icon> mdi-link-off </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <tambah
      :transaksi="transaksi"
      :pemicu="dialogTambah"
      :idcair="idcair"
      @tutup="tutup"
      @insert="insert"
      @update="update"
      :akun="akun"
      :tanggal="tanggal"
    ></tambah>
    <satusatu
      :idTanggal="idcair"
      :pemicu="pemicuSatu"
      @tutup="pemicuSatu = false"
      @update="update"
      :bank="idAkun"
      :akun="akun"
      :tanggal="tanggal"
    >
    </satusatu>
    <rekeningKoran
      :pemicu="pemicuRekening"
      :tanggal="tanggal"
      :akun="idAkun"
      :modeSelect="true"
      :dicari="dicari"
      @tutup="pemicuRekening = false"
      @list="simpan"
    ></rekeningKoran>
  </v-card>
</template>
<script>
import tambah from "./transaksiTambah.vue";
import satusatu from "./satuSatu.vue";
import rekeningKoran from "../../Pencairan/imporModal/modal.vue";
import axios from "axios";
import _ from "lodash";
export default {
  components: { tambah, satusatu, rekeningKoran },
  props: ["idAkun", "akun", "tanggal", "reload"],
  data() {
    return {
      loading: true,
      search: "",
      tabel: [],
      headers: [
        { text: "Rentang", value: "tanggalawal" },
        { text: "Pabrik", value: "pabrik.pabrik" },
        { text: "Cair", value: "cair" },
        { text: "Fakta", value: "banding" },
        { text: "Selisih", value: "selisih" },
        { text: "Actions", value: "actions" },
      ],
      pencairan: [],
      pemicuSatu: false,
      tanggalbank: [],
      dialogNew: false,
      dialogTambah: false,
      idcair: 0,
      transaksi: {},
      nominalNyata: 0,
      max25chars: (v) => v.length <= 25 || "Input too long!",
      pemicuRekening: false,
      dicari: {},
    };
  },
  watch: {
    reload() {
      this.inisialisasi();
      console.log("diganti tanggale");
    },
  },
  computed: {},
  mounted() {
    this.inisialisasi();
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let url = `/akunjurnal/transaksimasuk/${this.idAkun}/${this.tanggal}`;
      axios.get(url).then((response) => {
        this.tabel = _.values(response.data);
        console.log(response.data);
        this.loading = false;
      });
    },
    cekNol(nilai) {
      if (nilai) {
        return isNaN(parseInt(nilai, 10)) ? 0 : parseInt(nilai, 10);
      } else {
        return 0;
      }
    },
    buka(item) {
      this.idcair = item.id;
      this.dialogTambah = true;
      this.transaksi = item;
    },
    bukaSatu(item) {
      this.idcair = item.id;
      this.pemicuSatu = true;
    },
    bukaBaru() {
      this.idcair = 0;
      this.dialogTambah = true;
    },
    tutup() {
      this.dialogNew = false;
      this.dialogTambah = false;
    },
    lanjutkan(item) {
      this.dialogNew = false;
      this.tabel.push(item);
      this.dialogTambah = true;
    },
    insert(item) {
      this.idcair = item;
    },
    update() {
      this.inisialisasi();
      this.$emit("update");
    },
    tagihan() {
      this.pemicuTagihan = true;
    },
    save(item) {
      let uri = `/akunjurnal/transaksimasuk/selisih/${item.id}`;
      let data = {};
      data.kredit = parseInt(this.nominalNyata);
      console.log(uri);
      axios
        .put(uri, data)
        .then((response) => {
          if (response.data.status) {
            this.$noty.success("berhasil");
            this.inisialisasi();
          }
        })
        .catch((error) => {
          this.$noty.error(error.response.data.errors[0]);
          console.log(error.response.data.errors);
        });
      this.nominalNyata = 0;
    },
    bukaLink(item) {
      this.dicari = item.jurnal;
      console.log(this.dicari);
      this.pemicuRekening = true;
    },
    hapusLink(item) {
      const id = item.jurnal.rekening.id;
      console.log(id);
      const uri = `/rekeningkoran/validkan/${id}`;
      console.log(uri);
      axios
        .delete(uri)
        .then((res) => {
          if (res.data.status) {
            this.$noty.success("berhasil di eksekusi");
          }
        })
        .catch((e) => {
          this.$noty.error(e.message);
        })
        .finally(() => {
          this.inisialisasi();
        });
    },
    simpan(item) {
      let data = {};
      data.rekening = item;
      data.jurnal = this.dicari;
      const uri = `/rekeningkoran/validkan`;
      console.log(uri);
      console.log(data);
      axios
        .post(uri, data)
        .then((res) => {
          if (res.data.status) {
            this.$noty.success("berhasil di eksekusi");
          }
        })
        .catch((e) => {
          this.$noty.error(e.message);
        })
        .finally(() => {
          this.pemicuRekening = false;
          this.inisialisasi();
        });
    },
    cancel() {},
    open() {},
    close() {},
    classEror(item) {
      let selisih = parseInt(item.cair) - parseInt(item.saldo);
      if (selisih > 10 || selisih < -10) {
        return "red";
      }
    },
  },
};
</script>
