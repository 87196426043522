<template>
	<v-row dense no-gutters class="elevation-2 rounded-lg mt-5 mb-8">
		<v-col cols="12">
			<v-toolbar dense flat class="elevation-3">
				<v-text-field
					class="mx-6"
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
					dense></v-text-field
				><v-spacer></v-spacer>
				<span>Total: {{ sumCair() | rupiah }}</span>
			</v-toolbar>
		</v-col>
		<v-col cols="12" class="grey lighten-1">
			<v-btn
				color="orange"
				@click.prevent="bukaPengantar"
				x-small
				class="ma-1"
				:disabled="Aloading"
				><v-icon left>mdi-plus</v-icon>Tonase</v-btn
			>
			<v-btn
				color="red"
				@click.prevent="bukaBelum"
				dark
				class="ma-1"
				x-small
				:disabled="Aloading"
				>Ambil Tonase</v-btn
			><v-btn
				color="green"
				class="ma-1"
				x-small
				dark
				@click="bukaGantiDo"
				:disabled="adaYangTerpilih"
				>Ganti DO</v-btn
			>
			<v-btn
				color="green"
				class="ma-1"
				x-small
				dark
				@click="dialogTgl = true"
				:disabled="adaYangTerpilih"
				>Ganti Tanggal</v-btn
			>
			<v-btn
				color="green"
				class="ma-1"
				dark
				x-small
				@click="cairkanBesok"
				:disabled="adaYangTerpilih"
				>Cairkan Besok</v-btn
			>
			<v-btn
				color="green"
				class="ma-1"
				dark
				x-small
				@click="pemicuToke = true"
				:disabled="adaYangTerpilih"
				>Ganti Toke</v-btn
			>

			<v-btn
				color="orange"
				class="ma-1"
				dark
				x-small
				@click="pemicuPindah = true"
				:disabled="adaYangTerpilih"
				>Pindah Nota</v-btn
			>
		</v-col>
		<v-col cols="12">
			<v-data-table
				:headers="headers"
				:items="tonase"
				:search="search"
				:items-per-page="100"
				dense
				show-select
				v-model="terpilih"
				item-key="id"
				mobile-breakpoint="300"
				hide-default-footer
				v-show="tonase.length > 0">
				<template v-slot:item.cair="{ item }">
					<span
						><v-icon
							>mdi-{{ item.cair == "cair" ? "check" : "close" }}</v-icon
						></span
					>
				</template>
				<template v-slot:item.tanggaldo="{ item }">
					{{ item.tanggaldo | formatDate }}
				</template>
				<template v-slot:item.Hitungcair="{ item }">
					<span>{{
						(item.tonase * (parseInt(item.th) + parseInt(item.harga))) | rupiah
					}}</span>
				</template>
				<template v-slot:item.harga="{ item }">
					<span>{{ item.harga | rupiah }}</span></template
				>
				<template v-slot:item.tonase="{ item }">
					<span>{{ item.tonase | angka }}</span>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-btn
						class="mx-2"
						icon
						x-small
						color="green"
						@click.stop="edit(item)">
						<v-icon> mdi-pencil </v-icon>
					</v-btn>
					<v-btn
						class="mx-2"
						fab
						icon
						x-small
						color="red"
						@click.stop="hapus(item)"
						:loading="loadingDelet"
						:disabled="loadingDelet">
						<v-icon> mdi-trash-can </v-icon>
					</v-btn></template
				>
				<template slot="body.append">
					<tr>
						<th>Totals</th>
						<th></th>
						<th>{{ jumlahMbl() }} Mbl</th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th>{{ sumField("tonase") | angka }}</th>
						<th></th>
						<th></th>
						<th>{{ sumCair() | rupiah }}</th>
						<th>{{ sumField("ops") | angka }}</th>
					</tr>
				</template>
			</v-data-table>
		</v-col>
		<tonedit
			:id="id"
			:deo="deo"
			:toke="toke"
			:dialogTon="dialogTon"
			@tutup="tutupDialog"
			@update="reload"></tonedit>
		<gantido
			:deo="deo"
			:pabrik="pabrik"
			:dialogDeo="dialogDeo"
			@tutup="tutupDialog"
			@update="reload"
			:terpilih="terpilih"></gantido>
		<gantitgl
			:dialogTgl="dialogTgl"
			@tutup="tutupDialog"
			@update="reload"
			:terpilih="terpilih"></gantitgl>
		<pengantarTon
			:toke="toke"
			:pabrik="pabrik"
			:deo="deo"
			:pengantarDialog="pengantarDialog"
			:nota="nota"
			@tutup="tutupPinjamD"
			@lanjutkan="inputTonase"></pengantarTon>
		<tonaseD
			:tonaseDialog="tonaseDialog"
			@tutup="tutupPinjamD"
			:toke="toke"
			:nota="nota"
			:pengantar="pengantar"
			@reload="reload"
			:pabrik="pabrik"></tonaseD>
		<dialogBC
			:pemicu="pemicuBC"
			@tutup="tutupPinjamD"
			:nota="nota"
			@update="reload"></dialogBC>
		<gantitoke
			:pemicu="pemicuToke"
			@tutup="pamicuToke = false"
			:toke="toke"
			@aksi="updateToke"></gantitoke>
		<pindah
			:pemicu="pemicuPindah"
			@tutup="pemicuPindah = false"
			@simpan="
				pemicuPindah = false;
				reload();
			"
			:id="nota ? nota.id_cair : 0"
			:paket="terpilih"
			:tonase="true"></pindah>
	</v-row>
</template>
<style>
	tbody tr:nth-of-type(odd) {
		background-color: rgba(0, 0, 0, 0.05);
	}
</style>
<script>
	import axios from "axios";
	import pengantarTon from "./input/pengantarTon.vue";
	import tonaseD from "./input/tonse.vue";
	import dialogBC from "./input/pilihanBC.vue";
	import tonedit from "./input/tonaseEdit.vue";
	import gantido from "./input/gantiDo.vue";
	import gantitgl from "./input/gantiTanggal.vue";
	import gantitoke from "../Tonase/gantiToke.vue";
	import pindah from "./pindah/listNota.vue";
	export default {
		props: ["tonase", "toke", "deo", "pabrik", "nota", "reset", "Aloading"],
		components: {
			tonedit,
			gantido,
			gantitgl,
			pengantarTon,
			dialogBC,
			tonaseD,
			gantitoke,
			pindah,
		},
		watch: {
			reset() {
				this.terpilih = [];
			},
		},
		computed: {
			adaYangTerpilih() {
				if (this.terpilih.length > 0) {
					return false;
				} else {
					return true;
				}
			},
		},
		data() {
			return {
				headers: [
					{ text: "", value: "cair" },
					{
						text: "Pabrik",
						align: "start",
						filterable: true,
						value: "do.pabrik.pabrik",
					},
					{ text: "Tanggal", value: "tanggaldo" },
					{ text: "Toke", value: "toke.nama" },
					{ text: "Supir", value: "nama supir" },
					{ text: "Plat", value: "plat" },
					{ text: "Tonase", value: "tonase" },
					{ text: "Harga", value: "harga" },
					{ text: "TH", value: "th" },
					{ text: "Cair", value: "Hitungcair" },
					{ text: "OPS", value: "ops" },
					{ text: "Actions", value: "actions", sortable: false },
				],
				search: "",
				terpilih: [],
				dialogTon: false,
				id: 0,
				dialogDeo: false,
				dialogTgl: false,
				loadingDelet: false,
				pemicuBC: false,
				pemicuToke: false,
				tonaseDialog: false,
				pengantarDialog: false,
				pengantar: {},
				pemicuPindah: false,
			};
		},
		methods: {
			edit(item) {
				this.id = item.id;
				this.dialogTon = true;
			},
			hapus(item) {
				this.loadingDelet = true;
				let uri = "/tonase/" + item.id;
				axios.delete(uri).then((response) => {
					console.log(response);
					this.$emit("reload");
					this.loadingDelet = false;
				});
			},
			tutupDialog() {
				this.dialogTon = false;
				this.dialogDeo = false;
				this.dialogTgl = false;
			},
			reload() {
				this.$emit("reload");
				this.dialogTon = false;
				this.dialogDeo = false;
				this.dialogTgl = false;
				this.terpilih = [];
			},
			bukaGantiDo() {
				this.dialogDeo = true;
			},
			bukaPengantar() {
				this.pengantarDialog = true;
			},
			bukaBelum() {
				this.pemicuBC = true;
			},
			tutupPinjamD() {
				this.tonaseDialog = false;
				this.pengantarDialog = false;
				this.pemicuBC = false;
			},
			cairkanBesok() {
				const tabel = [];
				this.terpilih.forEach((x) => {
					tabel.push(x.id);
				});
				this.loading = true;
				let uri = "/tonase/gantiKeBC";
				axios
					.post(uri, tabel)
					.then((response) => {
						if (response.data) {
							this.$noty.success("data Berhasil di Update");
							console.log(response.data.data);
							this.loading = false;
							this.$emit("reload");
							this.terpilih = [];
						}
					})
					.catch((error) => {
						this.$noty.error(error.response.data.errors[0]);
					});
			},
			inputTonase(item) {
				this.pengantar = item;
				this.tonaseDialog = true;
			},
			sumField(key) {
				// sum data in give key (property)
				return this.tonase.reduce((a, b) => a + parseFloat(b[key] || 0), 0);
			},
			sumCair() {
				// sum data in give key (property)
				return this.tonase.reduce(
					(a, b) =>
						a +
						parseInt(
							parseInt(b.tonase) * (parseFloat(b.th) + parseFloat(b.harga))
						),
					0
				);
			},
			updateToke(toke) {
				this.loading = true;
				const tabel = [];
				this.terpilih.forEach((x) => {
					tabel.push(x.id);
				});
				let uri = `/tonase/gantitoke/${toke.ID}`;
				axios
					.put(uri, tabel)
					.then((response) => {
						if (response.data.status) {
							this.$noty.success(response.data.messages);
							console.log(response.data.data);
							this.pemicuToke = false;
							this.terpilih = [];
							this.$emit("reload");
							this.loading = false;
						}
					})
					.catch((error) => {
						if (error.length >> 0) {
							this.$noty.error(error.response.data.errors[0]);
							console.log(error.response.data.errors);
						}
					});
			},
			jumlahMbl() {
				return this.tonase.length;
			},
		},
	};
</script>
