<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Detail saldo </v-toolbar-title><v-spacer></v-spacer
          ><v-btn icon dark @click="dialog.value = false" color="red">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form
            class="mt-3"
            @submit.prevent="kirim()"
            color="grey"
            ref="form"
            v-model="valid"
          >
            <v-row
              ><v-col cols="12"
                ><v-text-field
                  label="modal ram"
                  outlined
                  dense
                  v-model="data.modal_ram"
                ></v-text-field></v-col
              ><v-col cols="12"
                ><v-text-field
                  label="angsuran bank"
                  outlined
                  dense
                  v-model="data.angsuran"
                ></v-text-field
              ></v-col>
              <v-col cols="12"
                ><v-text-field
                  label="fee ram"
                  outlined
                  dense
                  v-model="data.fee_ram"
                ></v-text-field></v-col
            ></v-row>
            <v-btn color="blue" dark type="submit">Simpan</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    pemicu: Boolean,
  },

  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    total() {
      return 0;
    },
  },
  data() {
    return {
      loading: false,
      data: {},
      valid: false,
    };
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  methods: {
    inisialisasi() {
      if (localStorage.getItem("settingLaporan")) {
        console.log("ada local storage");
        let data = localStorage.getItem("settingLaporan");
        this.data = JSON.parse(data);
      } else {
        this.data.modal_ram = "1,2";
        this.data.angsuran = "2";
        this.data.fee_ram = "3,4";
        console.log("local storage tidak ditemukan dan akan di sisipkan");
        localStorage.setItem("settingLaporan", JSON.stringify(this.data));
      }

      //   localStorage.setItem('testObject', JSON.stringify(testObject));
      // var retrievedObject = localStorage.getItem('testObject');
      // console.log('retrievedObject: ', JSON.parse(retrievedObject));
    },
    tutup() {},
    kirim() {
      localStorage.setItem("settingLaporan", JSON.stringify(this.data));
      this.dialog = false;
    },
  },
};
</script>
