<template>
  <v-dialog transition="dialog-bottom-transition" v-model="dialog" persistent>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="tonase"
        :search="search"
        :loading="loading"
        :items-per-page="8"
        dense
        show-select
        v-model="terpilih"
        item-key="id"
        mobile-breakpoint="300"
        :footer-props="{
          showFirstLastPage: false,
          firstIcon: 'mdi-page-first',
          lastIcon: 'mdi-page-last',
          prevIcon: 'mdi-arrow-left-drop-circle-outline',
          nextIcon: 'mdi-arrow-right-drop-circle-outline',
        }"
      >
        <template v-slot:item.tanggaldo="{ item }">
          <span>{{ item.tanggaldo | formatDate }}</span>
        </template>
        <template v-slot:item.Hitungcair="{ item }">
          <span>{{
            (item.tonase * (parseInt(item.th) + parseInt(item.harga))) | rupiah
          }}</span>
        </template>
      </v-data-table>
      <v-card-actions
        ><v-btn color="blue" dark @click="simpan">Simpan</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red" dark @click="tutup()">Batal</v-btn></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>
<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
<script>
import axios from "axios";
export default {
  props: ["pemicu", "nota"],
  computed: {
    adaYangTerpilih() {
      if (this.terpilih.length >> 0) {
        return false;
      } else {
        return true;
      }
    },
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Pabrik",
          align: "start",
          filterable: true,
          value: "do.pabrik.pabrik",
        },
        { text: "Tanggal", value: "tanggaldo" },
        { text: "Toke", value: "toke.nama" },
        { text: "Supir", value: "nama supir" },
        { text: "Plat", value: "plat" },
        { text: "Tonase", value: "tonase" },
        { text: "Harga", value: "harga" },
        { text: "TH", value: "th" },
        { text: "Cair", value: "Hitungcair" },
        { text: "OPS", value: "ops" },
      ],
      search: "",
      terpilih: [],
      tonase: [],
      loading: false,
      tabelKirim: {
        id: [],
        idNota: 0,
        idCair: 0,
      },
      arrayTerpilih: [],
    };
  },
  methods: {
    simpan() {
      console.log(this.terpilih);
      this.terpilih.forEach((x) => {
        this.arrayTerpilih.push(x.id);
      });
      this.loading = true;
      this.tabelKirim.id = this.arrayTerpilih;
      this.tabelKirim.idNota = this.nota.ID;
      this.tabelKirim.idCair = this.nota.id_cair;
      console.log(this.tabelKirim);
      let uri = "/tonase/gantiKeSC";
      axios
        .post(uri, this.tabelKirim)
        .then((response) => {
          if (response.data) {
            this.$noty.success("data Berhasil di Update");
            console.log(response.data.data);
            this.loading = false;
            this.arrayTerpilih = [];
            this.terpilih = [];
            this.$emit("update");
            this.$emit("tutup");
          }
        })
        .catch((error) => {
          this.$noty.error(error.response.data.errors[0]);
        });
    },
    inisialisasi() {
      this.arrayTerpilih = [];
      this.terpilih = [];
      this.loading = true;
      axios.get("/tonase/belum").then((response) => {
        this.tonase = response.data;
        this.loading = false;
      });
    },
    reload() {
      this.$emit("reload");
    },
    tutup() {
      this.$emit("tutup");
    },
    bukaGantiDo() {},
  },
};
</script>
