<template>
  <v-card dense>
    <v-card-title>Bayar tagihan</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            dense
            class="mx-5 my-4"
          ></v-text-field>
        </v-col>
        <v-col cols="12" v-if="back">
          <a @click="kembali" class="text-bold"
            ><v-icon>mdi-arrow-left</v-icon> {{ folderTerpilih }}</a
          >
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :items-per-page="100"
            :show-select="seleksi"
            dense
            hide-default-footer
            v-model="terpilih"
            item-key="ID"
            mobile-breakpoint="300"
          >
            <template v-slot:item.nominal="{ item }">
              <span>{{ item.nominal | rupiah }}</span>
            </template>
            <template v-slot:item.uraianA="{ item }">
              <a @click="bukaIsi(item)">{{ item.keterangan }}</a>
            </template>
          </v-data-table>
        </v-col>
        <v-btn color="blue" dark @click="simpan" :disabled="tombolOff || kosong"
          >Bayar</v-btn
        ></v-row
      >
    </v-card-text>
  </v-card>
</template>
<script>
import axios from "axios";
export default {
  props: {
    id: Number,
    modeSelect: Boolean,
  },
  computed: {
    kosong() {
      return !this.terpilih.length;
    },
  },
  watch: {},
  mounted() {
    this.inisialisasi();
  },
  data() {
    return {
      valid: false,
      tombolOff: false,
      data: [],
      dataMentah: [],
      search: "",
      headerF: [
        { text: "Tanggal", value: "tanggal" },
        {
          text: "Uraian",
          align: "start",
          filterable: true,
          value: "uraianA",
        },
        { text: "Bayar", value: "nominal" },
      ],
      headerA: [
        { text: "Tanggal", value: "tanggal" },
        {
          text: "Uraian",
          align: "start",
          filterable: true,
          value: "keterangan",
        },
        { text: "Bayar", value: "nominal" },
      ],
      headers: [],
      terpilih: [],
      seleksi: false,
      back: false,
      folderTerpilih: "",
    };
  },
  methods: {
    inisialisasi() {
      this.terpilih = [];
      let uri = `/transfer/id_cair/${this.id}`;
      console.log(uri);
      this.headers = this.headerF;
      this.seleksi = false;
      this.terpilih = [];
      this.back = false;
      axios
        .get(uri)
        .then((response) => {
          console.log(response.data);
          this.dataMentah = response.data;
          this.grupkan();
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    bukaIsi(item) {
      let id = item.id_gabung;
      this.headers = this.headerA;
      this.folderTerpilih = item.keterangan;
      this.data = this.dataMentah
        .filter((x) => {
          return x.id_gabung == id;
        })
        .map((x) => {
          x.nominal = x.hitung * -1;
          x.keterangan = x.uraian;
          return x;
        });
      this.seleksi = true;
      this.back = true;
    },
    kembali() {
      this.headers = this.headerF;
      this.folderTerpilih = "";
      this.seleksi = false;
      this.back = false;
      this.grupkan();
      this.terpilih = [];
    },
    grupkan() {
      this.data = this.dataMentah.reduce((before, item) => {
        let cur = before.findIndex((x) => {
          return x.id_gabung == item.id_gabung;
        });
        if (cur >= 0) {
          before[cur].nominal += parseFloat(item.hitung) * -1;
        } else {
          item.keterangan = item.nota.keterangan;
          item.nominal = parseFloat(item.hitung) * -1;
          before.push(item);
        }
        return before;
      }, []);
    },
    simpan() {
      this.$emit("simpan", this.terpilih);
    },
  },
};
</script>
