var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_c('v-btn',{attrs:{"color":"green","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.bukaBaru()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tabel,"items-per-page":5,"dense":"","mobile-breakpoint":"300","loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      prevIcon: 'mdi-arrow-left-drop-circle-outline',
      nextIcon: 'mdi-arrow-right-drop-circle-outline',
    }},scopedSlots:_vm._u([{key:"item.tanggal",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.tanggal)))])]}},{key:"item.tanggalawal",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.daritanggal))+"-"+_vm._s(_vm._f("formatDate")(item.sampaitanggal)))])]}},{key:"item.masuk",fn:function({ item }){return [_c('span',{class:_vm.classEror(item)},[_vm._v(_vm._s(_vm._f("rupiah")(item.masuk)))])]}},{key:"item.kredit",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.kredit},on:{"update:returnValue":function($event){return _vm.$set(props.item, "kredit", $event)},"update:return-value":function($event){return _vm.$set(props.item, "kredit", $event)},"save":function($event){return _vm.save(props.item)},"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","rules":[
              (v) => v <= 10000000000 || 'Terlalu besar',
              (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
            ],"type":"number"},model:{value:(_vm.nominalNyata),callback:function ($$v) {_vm.nominalNyata=$$v},expression:"nominalNyata"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("rupiah")(props.item.kredit))+" ")])]}},{key:"item.selisih",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("rupiah")((parseInt(item.masuk) - parseInt(item.kredit)))))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","color":"blue","x-small":""},on:{"click":function($event){return _vm.buka(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","color":"blue","x-small":""},on:{"click":function($event){return _vm.bukaSatu(item)}}},[_c('v-icon',[_vm._v(" mdi-list-status ")])],1)]}}])}),_c('tambah',{attrs:{"transaksi":_vm.transaksi,"pemicu":_vm.dialogTambah,"idcair":_vm.idcair,"akun":_vm.akun,"tanggal":_vm.tanggal},on:{"tutup":_vm.tutup,"insert":_vm.insert,"update":_vm.update}}),_c('satusatu',{attrs:{"idTanggal":_vm.idcair,"pemicu":_vm.pemicuSatu,"bank":_vm.idAkun},on:{"tutup":function($event){_vm.pemicuSatu = false},"update":_vm.update}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }