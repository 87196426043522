<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog.value = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Print</v-toolbar-title><v-spacer></v-spacer
          ><v-btn dark color="pink" @click="print"
            ><v-icon>mdi-printer</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text> </v-card-text>
        <div id="printops1" class="printarea overflow-auto">
          <div>
            <div class="judul">Rekap Pabrik {{ id_pabrik.text }} Mingguan</div>
            <div class="sub-judul">
              Tanggal {{ tanggal[0] | formatDate }} sampai
              {{ tanggal[1] | formatDate }}
            </div>
            <table class="tabelnya">
              <thead class="kepala-tabel">
                <tr>
                  <th>No</th>
                  <th>Tanggal</th>
                  <th>Nama</th>
                  <th>Plat</th>
                  <th>Tonase</th>
                  <th>Id Kasir</th>
                </tr>
              </thead>
              <tbody class="badan-tabel">
                <tr v-for="(item, id) in tabel" :key="item.id">
                  <td>{{ id + 1 }}</td>
                  <td>{{ item.tanggaldo | formatDate }}</td>
                  <td>{{ item["nama supir"] }}</td>
                  <td>{{ item.plat }}</td>
                  <td>{{ item.tonase | angka }}</td>
                  <td>{{ item.id_akun }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>
<style>
@import "@/assets/aplikasi/riz/opspabrik.css";
</style>
<script>
import axios from "axios";
export default {
  components: {},
  props: {
    pemicu: Boolean,
    tanggal: {
      default: function () {
        return {};
      },
      type: Array,
    },
    id_pabrik: {
      default: function () {
        return [];
      },
      type: Object,
    },
  },

  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  data() {
    return {
      tabel: [],
      loading: false,
    };
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let uri = `/tonase/ops/${this.id_pabrik.id_pabrik}/${this.tanggal[0]}/${this.tanggal[1]}`;
      axios.get(uri).then((response) => {
        this.tabel = response.data;
        this.loading = false;
        console.log(this.tabel);
      });
    },
    tutup() {},
    print() {
      this.$htmlToPaper("printops1", {
        styles: [
          "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
          "/aplikasi/riz/app.css",
          "/aplikasi/riz/opspabrik.css",
        ],
      });
    },
  },
};
</script>
