<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="500"
    persistent
  >
    <v-card dense tile :loading="loading">
      <v-card-title
        >Bayar Tagihan ({{ nota.keterangan }} )<span
          ><v-btn icon color="blue" @click="pemicuNama = true"
            ><v-icon>mdi-pencil</v-icon></v-btn
          ></span
        ></v-card-title
      >
      <v-card-text>
        <v-btn color="blue" dark fab small @click="pemicuTransfer = true"
          ><v-icon>mdi-plus</v-icon></v-btn
        >

        <v-row>
          <v-col cols="12"
            ><div><span>Tagihan yang di bayar </span></div>
            <v-data-table
              :headers="headersA"
              :items="dataAcuan"
              :items-per-page="10"
              dense
              item-key="ID"
              mobile-breakpoint="300"
              hide-default-footer
            >
              <template v-slot:item.pinjaman.hitung="{ item }">
                <span>{{
                  (parseFloat(item.pinjaman.hitung) * -1) | rupiah
                }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="mx-2"
                  icon
                  x-small
                  color="red"
                  @click.stop="nolkanTagihan(item)"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
            </v-data-table></v-col
          >
          <v-col cols="12"
            ><div>
              <span>Bayar </span
              ><span
                ><v-btn
                  icon
                  color="primary"
                  @click="
                    idPinjaman = 0;
                    pemicuPinjaman = true;
                  "
                  ><v-icon>mdi-plus</v-icon></v-btn
                ></span
              ><v-btn
                color="orange"
                class="ma-1"
                x-small
                dark
                @click="pemicuPigas = true"
                >Pigas</v-btn
              >
            </div>
            <v-data-table
              :headers="headersB"
              :items="dataPotongan"
              :items-per-page="10"
              :search="search"
              dense
              v-model="terpilih"
              item-key="ID"
              mobile-breakpoint="300"
              hide-default-footer
            >
              <template v-slot:item.hitung="{ item }">
                <span>{{ item.hitung | rupiah }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="mx-2"
                  icon
                  x-small
                  color="green"
                  @click.stop="edit(item)"
                  ><v-icon> mdi-pencil </v-icon></v-btn
                >
                <v-btn
                  class="mx-2"
                  icon
                  x-small
                  color="red"
                  @click.stop="hapusPinjaman(item)"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
              <template slot="body.append">
                <tr>
                  <th>Totals</th>
                  <th></th>
                  <th>{{ totalB | rupiah }}</th>
                  <th></th>
                </tr> </template></v-data-table
          ></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions><v-btn @click="tutup">Tutup</v-btn> </v-card-actions>
    </v-card>
    <transfer
      :pemicu="pemicuTransfer"
      :id="idTransaksi"
      :idNota="id"
      @tutup="pemicuTransfer = false"
      @simpan="refresh"
    ></transfer>
    <pinjamanMini
      :pemicu="pemicuPinjaman"
      :id="idPinjaman"
      :nota="nota"
      @tutup="pemicuPinjaman = false"
      @simpan="refresh"
    ></pinjamanMini>
    <pigas
      :pemicu="pemicuPigas"
      @tutup="pemicuPigas = false"
      :idNota="id"
      @simpan="refresh"
    ></pigas>
    <nama
      :pemicu="pemicuNama"
      :id="nota.ID"
      @tutup="pemicuNama = false"
      @bukaNota="refresh"
    >
    </nama>
  </v-dialog>
</template>
<script>
import axios from "axios";
import transfer from "../transfer.vue";
import pinjamanMini from "./pinjamanMini.vue";
import nama from "./nama.vue";
import pigas from "./bayarPigas.vue";
export default {
  components: { transfer, pinjamanMini, nama, pigas },
  props: ["id", "pemicu"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      if (this.pemicu) {
        this.inisialisasi();
      }
    },
  },
  data() {
    return {
      loading: false,
      dataAcuan: [],
      dataPotongan: [],
      nota: {},
      headersA: [
        { text: "Keterangan", value: "pinjaman.uraian" },
        { text: "Total", value: "pinjaman.hitung" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headersB: [
        { text: "Toke", value: "toke.nama" },
        { text: "Keterangan", value: "uraian" },
        { text: "Total", value: "hitung" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      terpilih: [],
      search: "",
      pemicuTransfer: false,
      idTransaksi: 0,
      pemicuPinjaman: false,
      idPinjaman: 0,
      pemicuNama: false,
      totalB: 0,
      pemicuPigas: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    update() {
      let uri = "/nota/" + this.id;
      axios
        .put(uri, {
          keterangan: this.dataNota.keterangan,
        })
        .then((response) => {
          if (response.data.status) {
            this.$emit("bukaNota", response.data.data);
            this.$noty.success(response.data.pesan);
          } else {
            this.$noty.error(response.data.pesan);
            console.log("error");
          }
          this.tutup();
          this.tombolOff = false;
        })
        .catch((error) => {
          this.$noty.error(error.Error);
          console.log(error);
          this.tombolOff = false;
        });
    },
    inisialisasi() {
      this.loading = true;
      let uri = `/nota/${this.id}/bayar`;
      axios
        .get(uri)
        .then((res) => {
          this.nota = res.data.nota;
          this.dataAcuan = res.data.transfer;
          this.dataPotongan = res.data.pinjaman;
          console.log(res.data);
          this.idTransaksi = this.nota.id_cair;
          this.totalBayar();
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.message);
          this.loading = false;
        });
      console.log(this.nota);
    },
    edit(item) {
      console.log(item);
      this.idPinjaman = item.ID;
      this.pemicuPinjaman = true;
    },
    refresh() {
      this.inisialisasi();
      this.$emit("reload");
    },
    hapusPinjaman(item) {
      if (confirm("apa kamu yakin mau menghapus?")) {
        let uri = `/pinjaman/${item.ID}`;
        console.log(uri);
        axios
          .delete(uri)
          .then((response) => {
            this.refresh();
            console.log(response);
          })
          .catch((e) => {
            this.$noty.error(e.message);
          });
      } else {
        // Do nothing!
        console.log("Thing was not saved to the database.");
      }
    },
    nolkanTagihan(item) {
      if (confirm("apa kamu yakin mau menghapus?")) {
        let uri = `/transfer/nolkan/${item.id}`;
        console.log(uri);
        axios
          .get(uri)
          .then((response) => {
            this.refresh();
            console.log(response);
          })
          .catch((e) => {
            this.$noty.error(e.message);
          });
      } else {
        // Do nothing!
        console.log("Thing was not saved to the database.");
      }
    },
    totalBayar() {
      this.totalB = this.dataPotongan.reduce((a, b) => {
        a += parseFloat(b.hitung);
        return a;
      }, 0);
    },
  },
};
</script>
