<template>
  <v-row class="ma-2"
    ><v-col cols="12" md="3">
      <v-card class="elevation-1">
        <v-toolbar dense color="orange darken-2" dark>
          <v-toolbar-title>Jenis Akun</v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :headers="judulJenis"
          :items="jenis"
          hide-default-header
          v-model="jenisTerpilih"
          hide-default-footer
          dense
        >
          <template v-slot:item="{ item }">
            <tr
              :class="
                jenisTerpilih.indexOf(item.id) > -1
                  ? 'primary  white--text font-weight-bold'
                  : ''
              "
              @click="rowClicked(item)"
            >
              <td>{{ item.jenis }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card> </v-col
    ><v-col cols="12" md="9">
      <v-card color="grey lighten-4"
        ><v-toolbar dense color="pink" dark>
          <v-toolbar-title>List Akun</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="baru">
            <v-icon>mdi-plus-thick</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="cariAkun"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <div class="my-1" v-if="terpilih.length > 0">
          <v-btn
            color="green"
            class="ma-1"
            x-small
            dark
            @click="pemicuGanti = true"
            >Ganti Jenis Akun</v-btn
          >
        </div>
        <v-card-text>
          <v-data-table
            :headers="judulAkun"
            :items="akun"
            :items-per-page="8"
            :loading="loadingAkun"
            :search="cariAkun"
            class="elevation-1"
            show-select
            v-model="terpilih"
            dense
          >
            <template v-slot:item.bank="{ item }">
              <span>{{ item.bank.nama }}</span>
            </template>

            <template v-slot:item.opsi="{ item }">
              <v-btn
                class="mx-2"
                icon
                x-small
                @click="edit(item)"
                :disabled="loadingHapus"
                ><v-icon> mdi-pencil </v-icon></v-btn
              >
              <v-btn
                class="mx-2"
                icon
                color="red"
                x-small
                @click="hapus(item)"
                :disabled="loadingHapus"
                ><v-icon> mdi-trash-can </v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="pemicuGanti"
          width="600"
          persistent
        >
          <v-card dense tile>
            <v-card-title>GAnti Jenis Akun</v-card-title>
            <v-card-text>
              <v-form
                @submit.prevent="kirim()"
                color="grey"
                ref="form"
                v-model="valid"
              >
                <v-row dense>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="gantiJenis"
                      auto-select-first
                      :items="[
                        'modal',
                        'admin',
                        'bank',
                        'pph',
                        'ppn',
                        'tambahan',
                        'fee',
                        'lain',
                        'modal',
                        'anggota',
                      ]"
                      label="Jenis"
                      ref="aktif"
                      :rules="[(v) => !!v || 'Harus diisi']"
                    >
                    </v-autocomplete
                  ></v-col>
                  <v-btn
                    color="blue"
                    dark
                    type="submit"
                    :disabled="loadingGanti"
                    >Simpan</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn color="red" dark @click="pemicuGanti = false"
                    >Batal</v-btn
                  >
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions> </v-card-actions>
          </v-card>
        </v-dialog>
        <form-akun
          @tutup="pemicuAkun = false"
          @lanjutkan="lanjutkan"
          :id="id_akun"
          :jenis="teksJenis"
          :pemicu="pemicuAkun"
        >
        </form-akun>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import formAkun from "./baru.vue";
export default {
  components: { formAkun },
  computed: {
    lastPage() {
      return Math.ceil(this.relasi.length / 8);
    },
  },
  data() {
    return {
      cariAkun: "",
      pabrik: [],
      jenisTerpilih: [],
      akun: [],
      id_akun: 0,
      loadingAkun: false,
      loadingHapus: false,
      judulJenis: [
        {
          text: "Nama Pabrik",
          align: "start",
          filterable: false,
          value: "jenis",
        },
      ],
      jenis: [
        { id: 1, jenis: "modal" },
        { id: 2, jenis: "admin" },
        { id: 3, jenis: "bank" },
        { id: 4, jenis: "pph" },
        { id: 5, jenis: "ppn" },
        { id: 6, jenis: "tambahan" },
        { id: 7, jenis: "fee" },
        { id: 8, jenis: "lain" },
        { id: 9, jenis: "modal" },
        { id: 10, jenis: "anggota" },
      ],
      judulAkun: [
        {
          text: "Nama",
          value: "nama",
        },
        {
          text: "Nama Lama",
          value: "nama_lama",
        },
        {
          text: "Aktif",
          value: "aktif",
        },
        {
          text: "Opsi",
          value: "opsi",
          align: "right",
        },
      ],
      pemicu: false,
      idAkun: 0,
      pemicuAkun: false,
      teksJenis: "",
      terpilih: [],
      pemicuGanti: false,
      loadingGanti: false,
      gantiJenis: "",
      valid: false,
    };
  },
  mounted() {},
  watch: {},
  methods: {
    bukaAkun(id) {
      this.loadingAkun = true;
      this.terpilih = [];
      let url = `/akun/full`;
      axios.get(url).then((response) => {
        this.akun = _.values(response.data);
        this.akun = this.akun.filter((x) => {
          return x.jenis == id;
        });
        this.loadingAkun = false;
        console.log(this.akun);
      });
    },
    tampilAkun() {
      this.bukaAkun(this.teksJenis);
    },
    rowClicked(item) {
      this.jenisTerpilih = [item.id];
      this.teksJenis = item.jenis;
      this.tampilAkun();
    },
    refresh() {
      this.tampilAkun();
    },
    hapus(item) {
      this.loadingHapus = true;
      let uri = "/akun/" + item.id;
      axios.delete(uri).then((response) => {
        this.$noty.success(response.data.pesan);
        this.loadingHapus = false;
        console.log(item.id);
        console.log(this.relasi);
        this.tampilAkun();
      });
    },
    lanjutkan() {
      this.tampilAkun();
    },
    tutup() {
      this.pemicuAkun = false;
    },
    baru() {
      if (this.jenisTerpilih[0] >> 0) {
        this.pemicuAkun = true;
        this.id_akun = 0;
      } else {
        this.$noty.error("Pilih jenis akun dulu");
        console.log("harus dipilih");
        console.log(this.jenisTerpilih);
      }
    },
    edit(item) {
      this.id_akun = item.id;
      this.pemicuAkun = true;
    },
    kirim() {
      this.loadingGanti = true;
      console.log(this.terpilih);
      let data = {
        array: this.terpilih.map((x) => {
          return x.id;
        }),
        jenis: this.gantiJenis,
      };
      let uri = `/akun/ganti`;
      axios
        .post(uri, data)
        .then((r) => {
          if (r.data.status) {
            this.$noty.success(r.data.messages);
          } else {
            this.$noty.error("gagal update data");
          }
          this.bukaAkun(this.teksJenis);
        })
        .finally(() => {
          this.loadingGanti = false;
          this.pemicuGanti = false;
        });
    },
  },
};
</script>
