<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar dark color="primary">
          <v-row
            ><v-col cols="1">
              <v-btn icon dark @click="dialog.value = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-col
            ><v-spacer></v-spacer
            ><v-col cols="1"
              ><v-btn dark color="pink" @click="print" class="ma-2"
                ><v-icon>mdi-printer</v-icon></v-btn
              ></v-col
            ><v-col cols="1"
              ><download-excel
                :data="data"
                :fields="fieldExcell"
                worksheet="My Worksheet"
                :name="filename"
              >
                <v-btn dark color="green" class="ma-2"
                  ><v-icon>mdi-table-large</v-icon></v-btn
                >
              </download-excel></v-col
            ></v-row
          >
        </v-toolbar>
        <v-card-text> </v-card-text>
        <div id="printSiji">
          <h4>Kasir :{{ kasir }}</h4>
          <h5>Periode :{{ periode }}</h5>
          <table class="tabel">
            <tbody v-for="items in tabel" :key="items.id">
              <tr class="regional">
                <td colspan="5">
                  <h4 class="text-uppercase">
                    {{ items.namaPrint }}
                  </h4>
                </td>
              </tr>
              <tr class="judul">
                <th>No</th>
                <th>Tanggal</th>
                <th>Nama</th>
                <th>Plat</th>
                <th>Tonase</th>
                <th>PPH</th>
              </tr>
              <tr
                v-for="(item, index) in items.data"
                :key="item.id"
                class="isiTabel"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item.tanggaldo | formatDate }}</td>
                <td>{{ item["nama supir"] }}</td>
                <td>{{ item["plat"] }}</td>
                <td>{{ item.tonase | angka }}</td>
                <td>{{ item.hasilpph | rupiah }}</td>
              </tr>
              <tr class="saldo">
                <td colspan="4"></td>
                <td></td>
                <td></td>
              </tr>
              <tr class="saldo">
                <td colspan="4">Total</td>
                <td>{{ items.totTonase | angka }}</td>
                <td>{{ items.totPPH | rupiah }}</td>
              </tr>
            </tbody>
            <tbody>
              <tr class="saldo">
                <td colspan="4">Grand Total</td>
                <td>{{ gtTonase | angka }}</td>
                <td>{{ gtPPH | rupiah }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>
<style>
@import "@/assets/riz/transaksi.css";
</style>
<script>
import moment from "moment";
export default {
  components: {},
  props: {
    pemicu: Boolean,
    data: Array,
    kasir: String,
    bln: String,
    thn: String,
  },

  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    filename() {
      return `Ton Fee Kasir -${moment().format("DD-MM-YY hh")}`;
    },
    gtTonase() {
      return this.tabel.reduce((a, b) => {
        return (a += parseInt(b.totTonase));
      }, 0);
    },
    gtPPH() {
      return this.tabel.reduce((a, b) => {
        return (a += parseInt(b.totPPH));
      }, 0);
    },
    periode() {
      return moment(`${this.thn}/${this.bln}/01`).format("MMMM YYYY");
    },
  },
  data() {
    return {
      tabel: [],
      fieldExcell: {
        Id: "id",
        namapabrik: "relasi.namaPrint",
        Tanggal: "tanggaldo",
        Toke: "toke.nama",
        Nama: "nama supir",
        Plat: "plat",
        tonase: "tonase",
        pabrik: "do.pabrik.pabrik",
        hasilpph: "hasilpph",
      },
    };
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  methods: {
    inisialisasi() {
      this.tabel = this.data.reduce((chace, item) => {
        const properti = item.relasi ? item.relasi.nama : "kosong";
        if (chace.some((el) => el.pabrik === properti)) {
          var terpilih = chace.find(function (x) {
            return x.pabrik === properti;
          });
          terpilih.totTonase += parseFloat(item.tonase);
          terpilih.totPPH += item.hasilpph;
          terpilih.data.push(item);
          return [...chace];
        }
        chace.push({
          pabrik: properti,
          namaPrint: item.relasi.namaPrint,
          data: [item],
          totTonase: parseFloat(item.tonase),
          totPPH: item.hasilpph,
        });
        return [...chace];
      }, []);
      console.log(this.tabel);
    },

    print() {
      this.$htmlToPaper("printSiji", {
        styles: [
          "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
          "/aplikasi/riz/app.css",
          "/aplikasi/riz/opsbd.css",
        ],
      });
    },
  },

  //penulisan standar
};
</script>
