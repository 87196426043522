<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="900"
    persistent
  >
    <v-card dense tile :loading="loading">
      <v-card-title
        >{{ judul }} {{ `${tanggal} / ${akun}` }}<v-spacer></v-spacer
        ><v-btn @click="tutup" icon color="red" large
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-card-title
      >
      <v-card-text>
        <v-row dense>
          <v-col cols="12" v-if="posisi == 'home'">
            <v-row class="mb-12 mx-1 mx-sm-12">
              <v-col cols="12">
                <v-text-field
                  class="mx-1 mx-sm-6"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                ></v-text-field
              ></v-col>
              <v-col
                v-for="item in dataFilter"
                :key="item.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-card>
                  <v-card
                    class="elevation-8"
                    color="indigo darken-1"
                    @click="buka(item.id)"
                    rounded
                    shaped
                  >
                    <v-card-title class="white--text">
                      {{ item.nama }}
                    </v-card-title>
                    <v-card-subtitle class="white--text"
                      >Saldo: 0
                      <div class="text-caption white--text">
                        <v-icon class="white--text">mdi-calendar</v-icon>:
                      </div></v-card-subtitle
                    ></v-card
                  >
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="posisi == 'pilihan'"
            ><v-row
              ><v-btn
                @click="
                  posisi = 'home';
                  judul = 'Rekening';
                  kondisikan();
                "
                icon
                color="red"
                ><v-icon>mdi-arrow-left</v-icon></v-btn
              ></v-row
            >
            <v-row
              ><v-col cols="12" md="4"> tanggalan </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  class="mx-6"
                  v-model="search2"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                ></v-text-field
                ><v-spacer></v-spacer>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="data"
                  :items-per-page="100"
                  :search="search2"
                  show-select
                  v-model="terpilih"
                  dense
                  item-key="id"
                  mobile-breakpoint="300"
                  hide-default-footer
                  :loading="loading"
                >
                  <template v-slot:item.masuk="{ item }">
                    <span>{{ item.masuk | angka }}</span>
                  </template>
                  <template v-slot:item.keluar="{ item }">
                    <span>{{ item.keluar | angka }}</span>
                  </template>
                  <template v-slot:item.saldo="{ item }">
                    <span>{{ item.saldo | angka }}</span>
                  </template>
                </v-data-table></v-col
              >
              <v-col cols="12"
                ><v-btn color="blue" dark @click="simpan"
                  >Simpan<v-icon>mdi-button-pointer</v-icon></v-btn
                ></v-col
              >
              <v-col cols="12" v-if="dicari" class="text-subtitle-1"
                >yang dicari : {{ `${dicari.toke}/${dicari.uraian}/`
                }}{{ dicari.hitung | rupiah }}</v-col
              ></v-row
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["pemicu", "tanggal", "akun", "modeSelect", "dicari"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    dataFilter() {
      return this.data.filter((item) => {
        let nama = item.nama ? item.nama.toLowerCase() : "";
        return nama.indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },
  watch: {
    pemicu() {
      this.kondisikan();
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      search: "",
      search2: "",
      posisi: "home",
      judul: "Rekening",
      terpilih: [],
      headers: [
        {
          text: "Jenis",
          align: "start",
          filterable: true,
          value: "jenis",
        },
        { text: "Akun", value: "toke.nama" },
        { text: "Uraian", value: "uraian" },
        { text: "Masuk", value: "masuk" },
        { text: "Keluar", value: "keluar" },
      ],
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },

    kondisikan() {
      this.terpilih = [];
      this.judul = "Rekening";
      this.posisi = "home";
      this.loading = true;
      let uri = `/rekening/bank/${this.akun}`;
      axios.get(uri).then((response) => {
        this.data = response.data;
        if (this.data.length == 1) {
          this.buka(this.data[0].id);
        }
        this.loading = false;
      });
    },
    buka(id) {
      this.loading = true;
      let url = "/rekening/" + id;
      console.log(url);
      console.log(this.modeSelect);
      axios.get(url).then((response) => {
        this.judul = response.data.nama;
        let uri = `/rekeningkoran/buka/${id}/${this.tanggal}`;
        axios.get(uri).then((res) => {
          this.data = res.data.data;
          if (this.data.length > 0) {
            if (this.modeSelect != true) {
              this.data = this.data.filter((x) => {
                return x.jenis != "MODAL";
              });
            }
            this.data = this.data.filter((x) => x.valid == 0);
          }

          console.log(this.data);
          this.loading = false;
        });
      });
      this.posisi = "pilihan";
      console.log(this.posisi);
    },

    simpan() {
      if (this.terpilih) {
        console.log("sudah ada yg dipilih");
        if (this.modeSelect) {
          this.$emit("list", this.terpilih[0]);
          console.log("masuk ke mode list");
        } else {
          console.log("ini proses simpan");
          let data = {};
          data.tanggal = this.tanggal;
          data.akun = this.akun;
          data.data = this.terpilih;
          console.log(data);
          let uri = "/rekeningkoran/impormodal";
          axios.post(uri, data).then((res) => {
            if (res.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.$emit("reload");
              this.$emit("tutup");
              console.log(res.data.data);
            }
          });
        }
      }
    },
  },
};
</script>
