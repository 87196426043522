<template>
  <v-container>
    <v-card rounded :loading="loading" class="mx-1 mx-sm-12">
      <v-card-text class="text-h5 mx-3 my-5">Transaksi Pabrik</v-card-text>
      <v-row class="mx-1"
        ><v-col v-for="item in data" :key="item.id" cols="6" md="3"
          ><v-card @click="$router.push('/transaksipabrik/' + item.id)"
            ><v-card-actions>{{ item.pabrik }}</v-card-actions
            ><v-card-text class="text-caption">{{
              item.cair | rupiah
            }}</v-card-text></v-card
          ></v-col
        ></v-row
      >
    </v-card></v-container
  >
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data: () => ({
    data: [],
    loading: false,
  }),
  mounted() {
    this.inisialisasi();
  },
  computed: {},
  methods: {
    inisialisasi() {
      this.loading = true;
      axios.get("/pabrikgrup/tagihan").then((res) => {
        this.data = res.data;
        console.log(this.data);
        this.loading = false;
      });
    },
    baru() {},
  },
};
</script>
