<template>
	<v-row>
		<v-col cols="12"><h3 class="mx-auto">Selamat Datang</h3></v-col>
		<br />
		<v-col cols="12" class="pa-6 overflow-auto">
			<v-btn color="pink" to="/raw/tonase" dark class="ma-1">Tonase</v-btn>
			<v-btn color="red" to="/raw/pinjaman" dark class="ma-1">Pinjaman</v-btn>
			<v-btn color="red" to="/raw/regional" dark class="ma-1">Regional</v-btn>
			<v-btn color="red" to="/raw/golongan" dark class="ma-1">Golongan</v-btn>
		</v-col>
	</v-row>
</template>
<script>
	// import axios from "axios";
	// import _ from "lodash";
	export default {
		components: {},
		mounted() {},
		computed: {},
		data() {
			return {};
		},
		methods: {},
	};
</script>
