<template>
  <v-row>
    <v-col cols="12">
      <v-card :loading="loading">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            dense
          ></v-text-field>
          <v-switch label="tanpa nota" v-model="noNota" class="ml-2"></v-switch>
          <v-switch label="bulan ini" v-model="sebulan" class="ml-4"></v-switch>
          <v-btn
            color="orange"
            class="ml-1"
            x-small
            dark
            @click="pemicuPindah = true"
            :disabled="adaYangTerpilih"
            >Pindah Nota</v-btn
          >
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="pinjaman"
          :search="search"
          :items-per-page="1000"
          show-select
          dense
          v-model="terpilih"
          item-key="ID"
          mobile-breakpoint="300"
          hide-default-footer
        >
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="11" class="blue lighten-4">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].jenis }}
            </th>
          </template>
          <template v-slot:item.pinjam="{ item }">
            <span>{{ item.pinjam | rupiah }}</span>
          </template>
          <template v-slot:item.tanggal="{ item }">
            <span>{{ item.tanggal | formatDate }}</span>
          </template>
          <template v-slot:item.angsur="{ item }">
            <span>{{ item.angsur | rupiah }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mx-2"
              icon
              x-small
              color="green"
              @click.stop="edit(item)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <template slot="body.append">
            <tr>
              <th>Totals</th>
              <th></th>
              <th></th>
              <th></th>
              <th>{{ sum.pinjam | rupiah }}</th>
              <th>{{ sum.angsur | rupiah }}</th>
            </tr>
          </template></v-data-table
        >
        <pinjamanedit
          :dialogPinjaman="dialogPinjaman"
          :id="idNya"
          @tutup="tutup"
          @update="reload"
        ></pinjamanedit>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import pinjamanedit from "./input/pinjamanEdit.vue";
import moment from "moment";
export default {
  components: {
    pinjamanedit,
  },
  computed: {
    adaYangTerpilih() {
      if (this.terpilih.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    totalPinjam() {
      return this.pinjaman.reduce(function (simpanan, item) {
        return (simpanan += parseInt(item.pinjam) - parseInt(item.angsur));
      }, 0);
    },
    bln() {
      let bln = moment(this.tanggal).format("M");
      return bln;
    },
    thn() {
      let bln = moment(this.tanggal).format("yyyy");
      return bln;
    },
  },
  watch: {
    sebulan() {
      if (this.sebulan) {
        this.dataSebulan();
      } else {
        this.inisialisasi();
      }
    },
    noNota() {
      if (this.noNota) {
        this.tanpaNota();
      } else {
        this.inisialisasi();
      }
    },
    nota() {
      console.log("id berubah");
      this.inisialisasi();
    },
  },
  mounted() {
    console.log("pinjaman di loading");
    this.inisialisasi();
    console.log(this.nota);
  },
  props: ["tanggal", "idAkun"],
  data() {
    return {
      headers: [
        {
          text: "Toke",
          align: "start",
          filterable: true,
          value: "toke.nama",
        },
        { text: "Tanggal", value: "tanggal" },
        { text: "Uraian", value: "uraian" },
        { text: "Pinjam", value: "pinjam" },
        { text: "Bayar", value: "angsur" },
        { text: "Kategori", value: "jenis" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      search: "",
      terpilih: [],
      dialogPinjaman: false,
      idNya: 0,
      loadingDelet: false,
      sebulan: false,
      pinjaman: [],
      loading: false,
      noNota: false,
      pemicuPindah: false,
      sum: { pinjam: 0, angsur: 0 },
    };
  },
  methods: {
    inisialisasi() {
      this.terpilih = [];
      this.loading = true;
      this.noNota = false;
      let uri = `/pencairan/pinjaman/${this.idAkun}/${this.tanggal}`;
      console.log(uri);
      axios
        .get(uri)
        .then((res) => {
          this.pinjaman = res.data.filter((x) => x.toke.golongan == 1);
          this.sum = this.pinjaman.reduce(
            (awal, x) => {
              awal.pinjam += parseInt(x.pinjam || 0);
              awal.angsur += parseInt(x.angsur || 0);
              return awal;
            },
            { pinjam: 0, angsur: 0 }
          );
          this.loading = false;
          console.log(this.pinjaman);
        })
        .catch((e) => {
          console.log({ msg: e.message, fungsi: "inisialisasi" });
          this.loading = false;
        });
    },
    tanpaNota() {
      this.loading = true;
      let uri = `/transaksi/${this.id}/tanpaNota`;
      axios
        .get(uri)
        .then((res) => {
          this.pinjaman = res.data.pinjaman;
          console.log(res.data);
          this.loading = false;
        })
        .catch((e) => {
          console.log({ msg: e.message, fungsi: "tanpaNota" });
          this.loading = false;
        });
    },
    dataSebulan() {
      this.loading = true;
      const uri = `/pinjaman/${this.idAkun}/${this.bln}/${this.thn}`;
      console.log(uri);
      axios
        .get(uri)
        .then((res) => {
          this.pinjaman = res.data.filter((x) => x.toke.golongan == 1);
          this.loading = false;
        })
        .catch((e) => {
          console.log({ msg: e.message, fungsi: "data sebulan" });
          this.loading = false;
        });
    },
    edit(item) {
      this.idNya = item.ID;
      this.dialogPinjaman = true;
    },
    tutup() {
      this.dialogPinjaman = false;
    },
    reload() {
      this.dialogPinjaman = false;
      this.inisialisasi();
    },
    hapus(item) {
      this.loadingDelet = true;
      let uri = "/pinjaman/" + item.ID;
      axios.delete(uri).then(() => {
        this.inisialisasi();
        this.loadingDelet = false;
      });
    },
  },
};
</script>
