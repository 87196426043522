<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile>
      <v-card-title>{{ tanggal | formatDate }}</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-chip
                v-for="item in id"
                :key="item.pabrik"
                class="ma-2"
                label
                outlined
                color="pink"
              >
                {{ item.pabriks ? item.pabriks.pabrik : "" }}
              </v-chip>
            </v-col>
            <v-col
              ><span class="text-h5">Harga lama : </span
              ><span class="text-h4">{{ hargalama }}</span
              ><span
                ><v-chip
                  pill
                  class="mt-n5"
                  dark
                  :color="selisih < 0 ? 'red' : 'blue'"
                  v-if="selisih"
                  >{{ selisih }}</v-chip
                ></span
              ></v-col
            >
            <v-col cols="12">
              <v-text-field
                label="Harga Baru"
                required
                autofocus
                :rules="[
                  (v) => v <= 10000000000 || 'Terlalu besar',
                  (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                ]"
                v-model="dataTransaksi.harga"
                type="number"
                ref="harga"
              ></v-text-field
            ></v-col>
            <v-btn color="blue" dark type="submit" :disabled="loadingSimpan"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import axios from "axios";
export default {
  props: ["pemicu", "tanggal", "pabrik", "id"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    selisih() {
      let seli = this.dataTransaksi.harga - this.hargalama;
      if (seli == 0) {
        return "";
      } else if (seli > 0) {
        return "+" + seli;
      } else {
        return seli;
      }
    },
    ubahTanggal() {
      return this.dataTransaksi
        ? moment(this.dataTransaksi.tanggal).format("DD/MM/YYYY")
        : "";
    },
  },
  watch: {
    pemicu() {
      this.kondisikan();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      dataTransaksi: {
        tanggal: "",
        pabrik: 0,
        harga: 0,
      },
      pupup: false,
      loading: false,
      hargalama: 0,
      loadingSimpan: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid && this.loadingSimpan == false) {
        this.loadingSimpan = true;
        this.simpan();
        this.dataTransaksi.harga = parseInt(this.dataTransaksi.harga);
        this.dataTransaksi.tanggal = this.tanggal;
        let uri = "/harga";
        axios.post(uri, this.dataTransaksi).then((response) => {
          if (response.data.status) {
            this.$noty.success("Data berhasil disimpan");
            this.tutup();
            this.$emit("lanjutkan", response.data.data);
            console.log(response.data.data);
            this.loadingSimpan = false;
          }
        });
      }
    },
    inisialisasi() {},
    simpan() {},
    kondisikan() {
      console.log(this.id);
      this.dataTransaksi.tanggal = moment().format("YYYY-MM-DD");
      this.dataTransaksi.pabrik =
        this.id.length > 0 ? this.id.map((x) => x.pabriks.id) : [];
      this.hargalama = this.id.length > 0 ? this.id[0].harga : 0;
      this.dataTransaksi.harga = this.hargalama;
    },
  },
};
</script>
