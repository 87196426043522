<template>
  <v-dialog transition="dialog-bottom-transition" v-model="dialog" width="800">
    <v-card dense tile>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Rekap Tonase</v-toolbar-title><v-spacer></v-spacer
        ><v-btn icon dark @click="tutup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
        ></v-text-field
      ></v-card-title>
      <v-card-text>
        <v-row class="mt-2"
          ><v-col cols="12">
            <v-data-table
              :headers="judul"
              :items="tonase"
              :items-per-page="8"
              :loading="loading"
              class="elevation-1"
              :search="search"
              dense
              :footer-props="{
                itemsPerPageText: 'tampilkan',
              }"
            >
              <template v-slot:item.tanggaldo="{ item }">
                {{ item.tanggaldo | formatDate }}
              </template>
              <template v-slot:item.tonase="{ item }">
                {{ item["tonase"] | angka }}
              </template>
              <template v-slot:item.harga="{ item }">
                {{ (parseInt(item.harga) + parseInt(item.do.fee)) | rupiah }}
              </template>
              <template v-slot:item.dpp="{ item }">
                {{
                  ((parseInt(item.harga) + parseInt(item.do.fee)) *
                    parseInt(item.tonase))
                    | rupiah
                }}
              </template>
              <template v-slot:item.opsi="{ item }">
                <v-btn
                  class="mx-1"
                  icon
                  x-small
                  color="green"
                  @click="bukaTon(item)"
                  ><v-icon> mdi-open-in-new </v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col></v-row
        >
      </v-card-text>
      <v-card-actions> </v-card-actions>
      <tonedit
        :id="id"
        :deo="deo"
        :toke="toke"
        :dialogTon="dialogTon"
        @tutup="dialogTon = false"
        @update="reload"
      ></tonedit>
    </v-card>
  </v-dialog>
</template>
<script>
import tonedit from "../../Transaksi/input/tonaseEdit.vue";
import axios from "axios";
export default {
  props: ["pemicu", "tonase"],
  components: { tonedit },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      loading: false,
      search: "",
      judul: [
        {
          text: "Tanggal",
          value: "tanggaldo",
        },
        {
          text: "Nama",
          value: "nama supir",
        },
        {
          text: "Tonase",
          value: "tonase",
        },
        {
          text: "harga",
          value: "harga",
        },
        {
          text: "DPP",
          value: "dpp",
        },
        {
          text: "Opsi",
          value: "opsi",
        },
      ],
      totalTon: 0,
      totalDPP: 0,
      totalPPN: 0,
      totalPPH: 0,
      id: 0,
      deo: [],
      toke: [],
      dialogTon: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    inisialisasi() {
      this.getToke();
    },
    getToke() {
      let uri = "/tokeAktif";
      axios.get(uri).then((response) => {
        this.toke = response.data;
        this.getDeo();
      });
    },
    getDeo() {
      let uri = "/deo";
      axios.get(uri).then((response) => {
        this.deo = response.data;
      });
    },
    sumary() {},
    bukaTon(item) {
      this.id = item.id;
      this.dialogTon = true;
    },
    reload() {
      this.$emit("lanjutkan");
    },
  },
};
</script>
