<template>
  <div>
    <v-dialog
      v-model="dialogD"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      v-if="idUpdate > 0"
    >
      <v-card dense class="overflow-auto" :loading="loading"
        ><v-toolbar dense elevation="-2">
          <v-btn icon large @click="tutup()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title v-if="obNota" dense class="text-uppercase">
            <v-chip class="ma-2" color="yellow">
              {{ idUpdate }}
            </v-chip>
            {{ obNota.keterangan }}
          </v-toolbar-title>
          <v-spacer></v-spacer
          ><v-btn icon class="ma-2" @click="pemicuPrint = true">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          Total: {{ total | rupiah }}
        </v-toolbar>
        <v-card class="ma-2" outlined>
          <v-card-title> </v-card-title>
          <v-card-subtitle>
            <span class="text-h5 red--text font-weight-bold text-uppercase">{{
              obNota.toke ? obNota.toke.nama : ""
            }}</span>
            <v-row
              ><span class="ml-4 mt-5 text-subtitle-1 blue--text"
                >Metode Bayar :</span
              >
              <v-col
                ><v-chip-group
                  active-class="white--text blue"
                  column
                  v-model="terpilih"
                >
                  <v-chip v-for="tag in tags" :key="tag">
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-col></v-row
            ></v-card-subtitle
          >

          <v-card-actions> </v-card-actions>
        </v-card>
        <v-row no-gutters class="px-md-5">
          <v-col cols="12" class="my-4">
            <excelRamp
              :reloader="reloaderExcel"
              :dataAwal="data"
              :tanggal="tanggal"
              :idNota="idUpdate"
              :idAkun="idAkun"
              @reload="reload"
            ></excelRamp>
          </v-col>
        </v-row>
        <v-row no-gutters class="px-md-5">
          <v-col cols="12" class="my-4">
            <excellPinjaman
              @reload="reload"
              :reloader="reloaderExcel"
              :nota="obNota"
              :pinjaman="pinjaman"
              :toke="obToke"
            ></excellPinjaman>
          </v-col>
        </v-row>
      </v-card>
      <print
        :pemicu="pemicuPrint"
        @tutup="pemicuPrint = false"
        :id="idUpdate"
      ></print>
      <rekeningBank
        :pemicu="pemicuBank"
        @tutup="pemicuBank = false"
        @simpan="
          $emit('reload', id);
          isiData();
        "
        :data="dataBank"
      ></rekeningBank>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="pemicuBaru"
      width="400"
      persistent
    >
      <v-card>
        <v-card-title>Nota</v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="kirim()"
            color="grey"
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="dataNota.toke"
                  :items="tokeA"
                  :rules="[(v) => !!v || 'Harus diisi']"
                  auto-select-first
                  item-text="nama"
                  item-value="ID"
                  dense
                  autofocus
                  label="Akun Ramp"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Keterangan"
                  required
                  v-model="dataNota.keterangan"
                >
                </v-text-field>
              </v-col>
              <v-btn color="blue" dark type="submit" :disabled="tombolOff"
                >Simpan</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="red" dark @click="tutupSubNOta">Batal</v-btn>
            </v-row>
          </v-form>
        </v-card-text></v-card
      >
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import excelRamp from "./excelRamp.vue";
import excellPinjaman from "../Pencairan/input/excellPinjaman.vue";
import print from "../print/notaRamp.vue";
import rekeningBank from "../Pencairan/input/rekeningBank.vue";
export default {
  components: { excelRamp, excellPinjaman, print, rekeningBank },
  props: ["pemicu", "id", "idAkun", "tanggal"],
  data() {
    return {
      loading: false,
      data: [],
      obNota: {},
      pemicuBaru: false,
      dataNota: {},
      valid: false,
      tags: ["Cash", "Transfer", "Besok"],
      idMetode: ["cash", "transfer", "besok"],
      terpilih: 0,
      idUpdate: 0,
      pemicuPrint: false,
      tombolOff: false,
      metodeBayar: "",
      total: 0,
      toke: [],
      tokeA: [],
      reloaderExcel: false,
      pinjaman: [],
      dataBank: {},
      obToke: {},
      pemicuBank: false,
    };
  },
  computed: {
    dialogD: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  mounted() {},
  watch: {
    pemicu() {
      console.log(this.pemicu);
      if (this.pemicu == true) {
        this.idUpdate = this.id;
        this.inisialisasi();
      }
    },
    terpilih() {
      if (this.metodeBayar == this.idMetode[this.terpilih]) {
        console.log("tidak berubah");
        console.log(this.idMetode[this.terpilih]);
      } else {
        console.log("berubah");
        console.log(this.idMetode[this.terpilih]);
        this.ubahMetode(this.idMetode[this.terpilih]);
      }
    },
  },
  methods: {
    inisialisasi() {
      let uri = "/tokeAktif";
      axios
        .get(uri)
        .then((res) => {
          this.toke = res.data;
          this.tokeA = this.toke.filter((x) => x.golongan == 13);
          this.obToke = this.convertList(res.data, "ID", "nama");
        })
        .catch((e) => {
          console.log(e.message);
        });
      if (this.id) {
        this.loading = true;
        this.getData();
      } else {
        console.log("pemicu baru di eksekusi");
        this.pemicuBaru = true;
      }
    },
    getData() {
      let uri = `/ramp/nota/${this.idUpdate}`;
      console.log(uri + "get data");
      axios
        .get(uri)
        .then((res) => {
          if (res.data.status) {
            console.log(res.data);
            this.obNota = res.data.nota;
            this.data = res.data.data;
            this.pinjaman = res.data.pinjaman;
            this.total = res.data.total;
            this.terpilih = this.idMetode.findIndex((x) => {
              return x == this.obNota.caraCair;
            });
            this.metodeBayar = this.obNota.caraCair;
            this.reloaderExcel = !this.reloaderExcel;
          } else {
            this.loading = false;
            this.$noty.error("error");
          }
        })
        .catch((e) => {
          console.log(e.message);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tutup() {
      this.$emit("tutup", 0);
    },
    reload() {
      this.$emit("reload", this.id);
      this.getData();
    },
    kirim() {
      const uri = `/ramp/nota`;
      this.tombolOff = true;
      this.dataNota.idAkun = this.idAkun;
      this.dataNota.tanggal = this.tanggal;
      this.dataNota.ID = this.id;
      axios
        .post(uri, this.dataNota)
        .then((res) => {
          if (res.data.status) {
            console.log("berhasil simpan");
            console.log(res.data);
            this.pemicuBaru = false;
            this.idUpdate = res.data.data ? res.data.data.ID : 0;
            this.reload();
          }
        })
        .finally(() => {
          this.tombolOff = false;
        });
      console.log(uri);
      console.log("simpan");
    },
    tutupSubNOta() {
      if (this.id) {
        this.pemicuBaru = false;
      } else {
        this.pemicuBaru = false;
        this.tutup();
      }
    },
    convertList(data, key, value) {
      let hasil = data.reduce(
        (obj, item) => Object.assign(obj, { [item[key]]: item[value] }),
        {}
      );
      return hasil;
    },
    ubahMetode(item) {
      let data = {};
      data.ID = this.obNota.ID;
      data.caraCair = item;
      data.idAkun = this.idAkun;
      if (data.caraCair == "transfer") {
        this.dataBank = data;
        this.pemicuBank = true;
      } else {
        if (confirm("apa kamu yakin mau ganti metode bayar?")) {
          const uri = `/pencairan/nota/metode`;
          axios.post(uri, data).then((res) => {
            console.log(res);
            this.$emit("reload", this.id);
            this.isiData();
          });
        }
      }
    },
    isiData() {
      this.getData();
    },
  },
};
</script>
