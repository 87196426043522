<template>
  <div>
    <v-card>
      <v-toolbar dense color="blue darken-4" dark elevation="-2">
        <v-toolbar-title> Rekap Pinjaman </v-toolbar-title>
      </v-toolbar>
      <v-row class="ma-3">
        <v-col cols="12"
          ><v-chip-group mandatory active-class="primary--text">
            <v-chip
              v-for="tag in ['Rekap Bulanan', 'Rekap Harian']"
              :key="tag"
              @click="tanggal = tag == 'Rekap Harian'"
            >
              {{ tag }}
            </v-chip>
          </v-chip-group></v-col
        >
        <v-col cols="12" sm="4">
          <v-date-picker
            v-if="tanggal == false"
            class="mt-3"
            v-model="picker"
            type="month"
            @click:month="mouseEnter"
          ></v-date-picker>
          <div v-else>
            <div>
              <v-date-picker v-model="tglRentang" range></v-date-picker>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6 ">
          <v-row class="mx-3">
            <v-col col="12"
              >Jenis Pinjaman :
              <span
                ><v-chip-group mandatory active-class="primary--text">
                  <v-chip
                    v-for="tag in ['PS', 'PT']"
                    :key="tag"
                    @click="jenisP = tag"
                  >
                    {{ tag }}
                  </v-chip>
                </v-chip-group></span
              ></v-col
            >
            <v-col cols="12">
              Regional : <v-switch v-model="selectAll"></v-switch
              ><v-chip-group v-model="reg" column multiple
                ><v-chip v-for="tag in regionals" :key="tag" filter outlined>
                  {{ tag }}
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-col cols="12"
              ><v-btn
                outlined
                @click="simpan"
                color="green"
                large
                :disabled="loading"
                >Simpan</v-btn
              ></v-col
            >
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-btn outlined color="red" @click="reload"
            >Refresh<v-icon>mdi-sync</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12" class="mb-5">
          <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="10"
            hide-default-footer
            mobile-breakpoint="300"
          >
            <template v-slot:item.tanggal_buat="{ item }">{{
              item.tanggal_buat | formatDate
            }}</template>
            <template v-slot:item.jenis_pinjaman="{ item }"
              ><span class="text-h6">{{ item.jenis_pinjaman }}</span></template
            >
            <template v-slot:item.status="{ item }">
              <span
                :class="item.status == 'Selesai' ? 'blue--text' : 'red--text'"
                >{{ item.status }} / {{ item.persen }}</span
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                class="mx-2"
                small
                color="blue"
                dark
                @click="
                  idRekap = item.id;
                  pemicuRekap = true;
                "
              >
                <span class="text-caption">Buka</span> </v-btn
              ><v-btn icon x-small color="red" @click="sinkron(item)"
                ><v-icon>mdi-sync</v-icon></v-btn
              >
              <v-btn
                class="mx-2"
                fab
                icon
                x-small
                color="red"
                :disabled="loading"
                @click="hapus(item)"
              >
                <v-icon> mdi-trash-can </v-icon>
              </v-btn>
            </template></v-data-table
          >
        </v-col>
      </v-row>
      <div>
        <rekap
          :pemicu="pemicuRekap"
          @tutup="pemicuRekap = false"
          :id="idRekap"
        ></rekap>
      </div>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import rekap from "./newRekapToke.vue";
// import numeral from "numeral";
export default {
  components: { rekap },
  data() {
    return {
      loading: false,
      picker: new Date().toISOString().substr(0, 10),
      mouseMonth: null,
      tglRentang: null,
      thn: 0,
      bln: 0,
      jenisP: "PS",
      tanggal: false,
      regional: [],
      regionals: [],
      reg: [1, 2],
      selectAll: true,
      data: [],
      idRekap: 0,
      headers: [
        {
          text: "Jenis Pinjaman",
          align: "start",
          filterable: true,
          value: "jenis_pinjaman",
        },
        { text: "Jenis Rekapan", value: "jenis" },
        { text: "Tanggal buat", value: "tanggal_buat" },
        { text: "Tanggal Rentang", value: "tanggal_r" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pemicuRekap: false,
    };
  },
  computed: {
    jenis() {
      return this.tanggal ? 1 : 0;
    },
    setRegional() {
      return this.reg.map((x) => {
        return this.regional[x].id;
      });
    },
  },
  watch: {
    selectAll() {
      if (this.selectAll) {
        this.reg = this.regional.map((x) => x.uid);
      } else {
        this.reg = [];
      }
    },
  },
  mounted() {
    this.thn = moment().format("YYYY");
    this.bln = moment().format("M");
    const tgl1 = moment().format("YYYY-MM") + "-01";
    this.tglRentang = [tgl1, moment().format("YYYY-MM-DD")];
    this.inisialisasi();
  },
  methods: {
    async inisialisasi() {
      this.loading = true;
      this.regional = await axios.get("/regional").then((res) => res.data);
      this.data = await this.list();
      console.log(this.data);
      this.loading = false;
      this.regionals = [];
      let i = 0;
      this.regional.map((x) => {
        x.uid = i;
        i += 1;
        this.regionals.push(x.regional);
        return x;
      });
      this.reg = this.regional.map((x) => x.uid);
      console.log(this.regionals);
      console.log(this.regional);
    },
    sinkron(item) {
      if (this.loading == false) {
        this.loading = true;
        let uri = `/rekap/pinjaman/sinkron/${item.id}`;
        axios.get(uri).then((res) => {
          console.log(res.data);
          this.$noty.success("Sedang di sinkronkan");
          this.reload();
        });
      }
    },
    hapus(item) {
      if (this.loading == false) {
        this.loading = true;
        let uri = `/rekap/pinjaman/hapus/${item.id}`;
        axios.get(uri).then((res) => {
          console.log(res.data);
          this.$noty.success("Sedang di hapus");
          this.reload();
        });
      }
    },
    async list() {
      let uri = "/rekap/pinjaman/list";
      return axios.get(uri).then((res) => {
        this.loading = false;
        return res.data.map((x) => {
          x.jenis = x.jenis == 0 ? "Bulanan" : "harian";
          x.tanggal_r =
            x.jenis == "Bulanan"
              ? moment(x.tanggal_A).format("MMMM YYYY")
              : moment(x.tanggal_A).format("DD/MM/YYYY") +
                "-" +
                moment(x.tanggal_B).format("DD/MM/YYYY");
          return x;
        });
      });
    },
    async reload() {
      this.data = [];
      this.data = await this.list();
    },
    mouseEnter(month) {
      this.bln = moment(month).format("M");
      this.thn = moment(month).format("YYYY");
      console.log(this.bln + "/" + this.thn);
      this.inisialisasi();
    },
    async simpan() {
      if (this.loading == false) {
        this.loading = true;
        let item = {};
        item.jenis = this.jenisP;
        item.rekapTgl = this.tanggal;
        item.tanggal = this.tglRentang.sort();
        item.bln = this.bln;
        item.thn = this.thn;
        item.regional = this.setRegional;
        item.regionalAll = this.selectAll;
        const uri = `/rekap/pinjaman/allin`;
        console.log(item);
        console.log("disimpan");
        if (
          await axios
            .post(uri, item)
            .then((res) => {
              res.data.status;
              this.reload();
            })
            .catch(() => false)
        ) {
          this.$noty.success("Berhasil di buat");
          this.inisialisasi();
        }
      }
    },
  },
};
</script>
