<template>
  <div>
    <div class="my-1" v-if="terpilih.length > 0">
      <v-btn color="green" class="ma-1" x-small dark @click="pemicuToke = true"
        >Ganti Toke</v-btn
      >
    </div>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
      class="mx-3 mb-3"
    ></v-text-field>
    <v-data-table
      class="mx-2 mx-sm-10"
      :headers="headers"
      :items="pinjaman"
      :search="search"
      :items-per-page="10"
      :page="lastPage"
      show-select
      v-model="terpilih"
      dense
      mobile-breakpoint="300"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        prevIcon: 'mdi-arrow-left-drop-circle-outline',
        nextIcon: 'mdi-arrow-right-drop-circle-outline',
      }"
    >
      <template v-slot:item.tanggal="{ item }">
        <span>{{ item.tanggal | formatDate }}</span>
      </template>
      <template v-slot:item.pinjam="{ item }">
        <span>{{ item.pinjam | rupiah }}</span>
      </template>
      <template v-slot:item.angsur="{ item }">
        <span>{{ item.angsur | rupiah }}</span>
      </template>
      <template v-slot:item.saldo="{ item }">
        <span>{{ item.saldo | rupiah }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn class="mx-1" icon x-small color="green" @click.stop="edit(item)">
          <v-icon> mdi-account-edit </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <pinjamanedit
      :dialogPinjaman="dialogPinjaman"
      :id="idNya"
      :toke="toke"
      @tutup="tutup"
      @update="reload"
    ></pinjamanedit>
    <split
      :dialogSplit="dialogSplit"
      :id="idNya"
      :toke="toke"
      @tutup="tutup"
      @update="reload"
    ></split>
    <ganti-toke
      :pemicu="pemicuToke"
      @tutup="pamicuToke = false"
      :toke="toke"
      @aksi="gantiToke"
    ></ganti-toke>
  </div>
</template>

<script>
import pinjamanedit from "../Transaksi/input/pinjamanEdit.vue";
import gantiToke from "../Tonase/gantiToke.vue";
import split from "./split.vue";
import axios from "axios";
export default {
  props: {
    tabel: Array,
    saldoAwal: String,
    loading: Boolean,
    toke: Array,
    mode: Boolean,
  },
  components: { pinjamanedit, split, gantiToke },
  data() {
    return {
      pinjaman: [],
      headers: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggal",
        },
        { text: "Uraian", value: "uraian" },
        { text: "Pinjam", value: "pinjam" },
        { text: "Bayar", value: "angsur" },
        { text: "Saldo", value: "saldo" },
        { text: "Kasir", value: "akun" },
        { text: "Actions", value: "actions" },
      ],
      search: "",
      dialogPinjaman: false,
      idNya: 0,
      dialogSplit: false,
      terpilih: [],
      pemicuToke: false,
      loadingGantiToke: false,
    };
  },
  watch: {
    tabel() {
      this.inisialisasi();
    },
    mode() {
      this.inisialisasi();
    },
  },
  mounted() {
    this.inisialisasi();
  },
  computed: {
    lastPage() {
      return Math.ceil(this.pinjaman.length / 10);
    },
  },
  methods: {
    formatRupiah(angka, prefix) {
      var number_string = angka ? this.cekNol(angka).toString() : "0";
      var split = number_string.split(",");
      var sisa = split[0].length % 3;
      var rupiah = split[0].substr(0, sisa);
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      // tambahkan titik jika yang di input sudah menjadi angka ribuan
      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
    },

    addSaldo() {
      var saldo = [];
      const items = this.tabel;
      var saldoA = parseInt(this.saldoAwal, 10);
      for (let a = 0; a < items.length; a++) {
        let b = "";
        if (items[a].transaksi) {
          b = items[a].transaksi.akun.nama;
        } else {
          if (items[a].akuns) {
            b = items[a].akuns.nama;
          }
        }
        saldoA =
          saldoA + this.cekNol(items[a].pinjam) - this.cekNol(items[a].angsur);
        saldo.push({
          id: items[a].ID,
          tanggal: items[a].tanggal,
          pinjam: items[a].pinjam,
          uraian: items[a].uraian,
          angsur: items[a].angsur,
          saldo: saldoA,
          akun: b,
        });
      }
      this.pinjaman = saldo;
    },
    inisialisasi() {
      this.terpilih = [];
      this.addSaldo();
    },
    cekNol(nilai) {
      if (nilai) {
        return isNaN(parseInt(nilai, 10)) ? 0 : parseInt(nilai, 10);
      } else {
        return 0;
      }
    },
    edit(item) {
      this.idNya = item.id;
      this.dialogPinjaman = true;
      console.log(this.terpilih);
      console.log(this.adaYangTerpilih);
    },
    splite(item) {
      this.idNya = item.id;
      this.dialogSplit = true;
    },
    tutup() {
      this.dialogPinjaman = false;
      this.dialogSplit = false;
    },
    reload() {
      this.$emit("reload");
      this.dialogPinjaman = false;
    },
    gantiToke(item) {
      this.loadingToke = true;
      this.pemicuToke = false;
      let tabel = this.terpilih.map((item) => {
        return item.id;
      });
      console.log(tabel);
      let uri = `/pinjaman/gantitoke/${item.ID}`;
      console.log(uri);
      axios
        .put(uri, tabel)
        .then((response) => {
          if (response.data.status) {
            this.$noty.success("data Berhasil di Update");
            this.loadingToke = false;
            this.$emit("reload");
            tabel = [];
            this.terpilih = [];
          } else {
            this.$noty.error("terjadi kesalahan");
          }
        })
        .catch((error) => {
          this.$noty.error(error.response.data.errors[0]);
        });
    },
  },
};
</script>
