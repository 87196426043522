<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="500"
    persistent
  >
    <v-card dense tile :loading="loading">
      <v-card-title class="text-uppercase">Pilih akun bank</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="bank"
                :items="toke"
                item-text="nama"
                item-value="ID"
                label="Bank"
                ref="toke"
                :rules="[(v) => !!v || 'Item is required']"
                auto-select-first
                required
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" dark @click="tutup()" class="ma-2">Batal</v-btn
        ><v-spacer></v-spacer
        ><v-btn color="blue" dark @click="kirim()" :disabled="loading"
          >Simpan</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>
<style></style>
<script>
import axios from "axios";
export default {
  props: ["data", "pemicu"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  components: {},
  watch: {
    dialog() {
      if (this.dialog) {
        this.inisialisasi();
      }
    },
  },
  mounted() {},
  data() {
    return {
      loading: false,
      bank: 0,
      toke: [],
      valid: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    async inisialisasi() {
      console.log("di inisialisasi");
      this.loading = true;
      this.bank = await axios
        .get("/setting/get/transfer")
        .then((res) => res.data);
      this.toke = await axios.get("/toke/golongan/8;9").then((res) => res.data);
      this.loading = false;
    },
    kirim() {
      this.$refs.form.validate();
      if (this.valid) {
        const uri = `/pencairan/nota/metode`;
        let data = {};
        data.ID = this.data.ID;
        data.caraCair = this.data.caraCair;
        data.idAkun = this.bank;
        axios.post(uri, data).then((res) => {
          console.log(res);
          this.$emit("simpan");
          this.tutup();
        });
      }
    },
  },
};
</script>
