<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card :loading="loading">
      <v-card-title class="headline"> Pilih Toke </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                label="Toke"
                v-model="tokeTerpilih"
                :items="toke"
                item-value="ID"
                item-text="nama"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="aksi"> Simpan </v-btn>
        <v-btn color="warning" text @click="tutup"> Batal </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      tokeTerpilih: 0,
      toke: [],
      loading: false,
    };
  },
  props: ["pemicu"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let uri = "/toke";
      axios.get(uri).then((r) => {
        this.toke = r.data;
        this.loading = false;
      });
    },
    aksi() {
      if (this.tokeTerpilih > 0) {
        let data = this.toke.filter((item) => {
          return item.ID == this.tokeTerpilih;
        });
        this.$emit("aksi", data[0]);
      } else {
        alert("Toke Harus Dipilih");
      }
    },
    tutup() {
      this.$emit("tutup");
    },
  },
};
</script>
