var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"800","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"dense":"","tile":""}},[_c('v-card-title',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.jenisDialog.input + " " + _vm.jenisDialog.jenis))]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"color":"grey"},on:{"submit":function($event){$event.preventDefault();return _vm.simpan()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-autocomplete',{ref:"toke",attrs:{"autofocus":"","items":_vm.toke,"item-text":"nama","item-value":"ID","filter":_vm.cariToke,"label":"Toke","rules":[(v) => !!v || 'Item is required'],"auto-select-first":"","required":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.simpan.apply(null, arguments)}},model:{value:(_vm.pinjaman.idtoke),callback:function ($$v) {_vm.$set(_vm.pinjaman, "idtoke", $$v)},expression:"pinjaman.idtoke"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5"}},[_c('v-text-field',{attrs:{"label":"Uraian","rules":[
									(v) =>
										v.length <= 50 || 'Terlalu bayak yang di isi, gak boleh',
								],"required":""},model:{value:(_vm.pinjaman.uraian),callback:function ($$v) {_vm.$set(_vm.pinjaman, "uraian", $$v)},expression:"pinjaman.uraian"}})],1),_c('v-col',{attrs:{"cols":"6","md":"2","sm":"2"}},[_c('v-text-field',{attrs:{"label":"Pinjam","required":"","type":"number","rules":[
									(v) => !isNaN(parseFloat(v)) || 'Item harus angka',
									(v) =>
										(v >= -90000000000 && v <= 90000000000) || 'terlalu besar',
								]},model:{value:(_vm.pinjaman.nominal),callback:function ($$v) {_vm.$set(_vm.pinjaman, "nominal", $$v)},expression:"pinjaman.nominal"}},[_vm._v(_vm._s(_vm._f("rupiah")(_vm.pinjaman.nominal)))])],1),_c('v-col',{attrs:{"cols":"6","sm":"1","md":"1"}},[_c('v-btn',{attrs:{"color":"green","dark":"","type":"submit","small":"","fab":""}},[_c('v-icon',[_vm._v("mdi-content-save-move")])],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"green","dark":"","fab":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.barisBaru()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1),_c('v-card',[_c('div',{attrs:{"id":"dif"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("No")]),_c('th',{staticClass:"text-left"},[_vm._v("Toke")]),_c('th',{staticClass:"text-left"},[_vm._v("Uraian")]),_c('th',{staticClass:"text-left"},[_vm._v("Nominal")])])]),_c('tbody',[_vm._l((_vm.pinjamans),function(item){return _c('tr',{key:item.nou,class:_vm.selectedRows.indexOf(item.nou) > -1
												? 'primary  white--text font-weight-bold'
												: '',on:{"click":function($event){return _vm.rowClicked(item)}}},[_c('td',[_vm._v(_vm._s(item.nou))]),_c('td',[_vm._v(_vm._s(item.namatoke))]),_c('td',[_vm._v(_vm._s(item.uraian))]),_c('td',[_vm._v(_vm._s(_vm._f("rupiah")(item.nominal)))])])}),_c('tr',{class:_vm.selectedRows == _vm.pinjamans.length + 1
												? 'primary  white--text font-weight-bold'
												: ''},[_c('td',[_vm._v("*")]),_c('td'),_c('td',{attrs:{"colspan":"2"}},[_vm._v("Total: "+_vm._s(_vm._f("rupiah")(_vm.total)))])])],2)]},proxy:true}])})],1)])],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.tutup()}}},[_vm._v("Batal")]),_c('v-btn',{attrs:{"color":"blue","dark":"","loading":_vm.loadingBtn,"disabled":_vm.loadingBtn},on:{"click":function($event){return _vm.kirim()}}},[_vm._v("Simpan")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }