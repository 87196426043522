<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="900"
    persistent
  >
    <v-card dense tile :loading="loading">
      <v-card-title>
        {{ `${tanggal} / ${akun}` }}<v-spacer></v-spacer
        ><v-btn @click="tutup" icon color="red" large
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-card-title
      >
      <v-card-actions
        ><v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          dense
          label="Cari"
          class="mx-2"
        ></v-text-field
      ></v-card-actions>
      <v-card-text>
        <rekeningKoran
          :pemicu="pemicuRekening"
          :tanggal="tanggal"
          :akun="akun"
          :modeSelect="true"
          :dicari="dicari"
          @tutup="pemicuRekening = false"
          @list="simpan"
        ></rekeningKoran>
        <v-data-table
          :headers="headers"
          :items="data"
          :search="search"
          :items-per-page="100"
          dense
          item-key="ID"
          mobile-breakpoint="300"
          hide-default-footer
        >
          <template v-slot:item.pinjam="{ item }">
            <span>{{ item.pinjam | rupiah }}</span>
          </template>
          <template v-slot:item.angsur="{ item }">
            <span>{{ item.angsur | rupiah }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mx-2"
              icon
              x-small
              color="green"
              @click.stop="cairkan(item)"
            >
              <v-icon> mdi-chevron-down-circle-outline </v-icon>
            </v-btn>
          </template></v-data-table
        >
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import rekeningKoran from "../../Pencairan/imporModal/modal.vue";
export default {
  props: ["pemicu", "tanggal", "akun"],
  components: {
    rekeningKoran,
  },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.kondisikan();
    },
  },
  data() {
    return {
      loading: false,
      search: "",
      data: [],
      headers: [
        {
          text: "Toke",
          align: "start",
          filterable: true,
          value: "toke",
        },
        { text: "Uraian", value: "uraian" },
        { text: "Masuk", value: "pinjam" },
        { text: "Keluar", value: "angsur" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pemicuRekening: false,
      dicari: {},
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kondisikan() {
      const uri = `/akunjurnal/belumvalid/${this.akun}/${this.tanggal}`;
      console.log(uri);
      axios.get(uri).then((res) => {
        console.log(res.data);
        this.data = res.data.filter((x) => x.rekening === null);
        this.data = this.data.map((x) => {
          if (x.referensi) {
            x.toke = x.referensi.toke.nama;
          } else {
            x.toke = x.toke.nama;
          }
          return x;
        });
      });
      console.log("di loading");
    },
    cairkan(item) {
      console.log(item);
      this.dicari = item;
      this.pemicuRekening = true;
    },
    simpan(item) {
      let data = {};
      data.rekening = item;
      data.jurnal = this.dicari;
      const uri = `/rekeningkoran/validkan`;
      console.log(uri);
      console.log(data);
      axios
        .post(uri, data)
        .then((res) => {
          if (res.data.status) {
            this.$noty.success("berhasil di eksekusi");
          }
        })
        .catch((e) => {
          this.$noty.error(e.message);
        })
        .finally(() => {
          this.pemicuRekening = false;
          this.kondisikan();
        });
    },
  },
};
</script>
