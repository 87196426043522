<template>
  <v-row justify="center">
    <v-date-picker
      v-model="picker"
      type="month"
      @click:month="mouseEnter"
      elevation="2"
    ></v-date-picker>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      picker: new Date().toISOString().substr(0, 10),
      mouseMonth: null,
    };
  },
  mounted() {},
  methods: {
    mouseEnter(month) {
      this.$emit("ubahBulan", month);
    },
  },
};
</script>
