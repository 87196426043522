<template>
  <v-container>
    <div>
      <v-row class="px-1 px-sm-14">
        <v-col cols="12"
          ><v-btn
            dark
            color="red"
            class="ml-3 mb-5 mt-2"
            @click="
              id = 0;
              pemicuToke = true;
            "
            x-large
            ><v-icon>mdi-plus</v-icon>Akun Pinjaman</v-btn
          ></v-col
        >
        <v-col cols="12">
          <v-card :loading="loading" outlined>
            <v-form @submit.prevent="cari()" ref="form" v-model="valid">
              <v-row class="ma-2 ma-sm-10">
                <v-text-field
                  v-model="search"
                  label="Cari"
                  outlined
                  :rules="labelRules"
                  class="mx-1 mx-sm-6"
                  @click="tesMenu = true"
                ></v-text-field>
                <div class="text-center">
                  <v-menu
                    offset-y
                    :value="ada"
                    :position-x="10"
                    :position-y="200"
                    absolute
                  >
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in tokeFilter"
                        :key="index"
                      >
                        <v-list-item-title @click="bukaSatu(item.ID)">{{
                          item.nama
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <v-btn
                  @click="cari"
                  class="mr-1 mr-sm-6"
                  color="blue"
                  dark
                  x-large
                  ><v-icon>mdi-magnify-scan</v-icon></v-btn
                >
              </v-row>
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="visibleTabel">
          <v-row class="mb-12 mx-1 mx-sm-12">
            <v-col v-for="item in tabel" :key="item.name" cols="12" sm="6">
              <v-card>
                <v-card
                  class="elevation-8"
                  color="indigo darken-1"
                  rounded
                  shaped
                >
                  <v-card-title class="white--text">
                    {{ item.nama }}
                    <v-spacer></v-spacer>
                    <v-chip
                      class="ma-2"
                      :color="
                        item.aktif.toLowerCase() == 'aktif' ? 'green' : ''
                      "
                      text-color="white"
                      ><v-avatar left>
                        <v-icon>mdi-checkbox-marked-circle</v-icon>
                      </v-avatar>
                      {{ item.aktif }}
                    </v-chip>
                  </v-card-title>
                  <v-card-subtitle>
                    <div class="white--text">{{ item.regional.regional }}</div>
                  </v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-card
                          color="indigo darken-2"
                          @click="buka(item.ID, 'PS')"
                          ><v-card-text class="white--text"
                            >PS: {{ item.PS | rupiah }}</v-card-text
                          ></v-card
                        ></v-col
                      >
                      <v-col cols="12" md="6">
                        <v-card
                          color="indigo darken-3"
                          @click="buka(item.ID, 'PT')"
                          ><v-card-text class="white--text"
                            >PT: {{ item.PT | rupiah }}</v-card-text
                          ></v-card
                        ></v-col
                      >
                    </v-row>
                  </v-card-text></v-card
                >
                <div class="text-caption white ma-2">
                  <a
                    @click="
                      id = item.ID;
                      pemicuToke = true;
                    "
                    class="mr-6"
                    >Edit</a
                  >
                  <a
                    @click="
                      id = item.ID;
                      pemicuInfo = true;
                    "
                    class="mr-6"
                    >info</a
                  >
                  <a
                    @click="
                      id = item.ID;
                      pemicuNota = true;
                    "
                    >Nota</a
                  >
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <pinjaman
        :jenis="jenis"
        :id="id"
        @tutup="tutup"
        :dialogPinjaman="dialogPinjaman"
      ></pinjaman>
      <form-toke
        :id="id"
        :pemicu="pemicuToke"
        @tutup="pemicuToke = false"
      ></form-toke>
      <infotoke
        :id="id"
        :pemicu="pemicuInfo"
        @tutup="pemicuInfo = false"
      ></infotoke>
      <nota :id="id" :pemicu="pemicuNota" @tutup="pemicuNota = false"></nota>
    </div>
  </v-container>
</template>

<script>
import pinjaman from "./RinciPinjaman";
import FormToke from "./new.vue";
import infotoke from "./infoToke.vue";
import nota from "./nota.vue";
import axios from "axios";
export default {
  components: { pinjaman, FormToke, infotoke, nota },
  data() {
    return {
      idToke: null,
      valid: false,
      search: "",
      visibleTabel: false,
      labelRules: [(v) => !!v || "Harus di isi ya"],
      tabel: [],
      loading: false,
      jenis: "PS",
      dialogPinjaman: false,
      pemicuToke: false,
      id: 1,
      pemicuInfo: false,
      toke: [],
      pemicuNota: false,
      level: "",
    };
  },
  computed: {
    tokeFilter() {
      let hasil = this.toke.filter((item) => {
        let toke = item.nama.toLowerCase();
        let gol = item.gol ? item.gol.ID : 0;
        if (this.level != "super_admin") {
          return toke.indexOf(this.search.toLowerCase()) > -1 && gol < 2;
        }
        return toke.indexOf(this.search.toLowerCase()) > -1;
      });
      return hasil.sort((a, b) => b.ID - a.ID).slice(-5);
    },
    ada() {
      let kosong = this.search != "";
      let jumlah = this.tokeFilter.length > 0;
      return kosong && jumlah;
    },
  },
  mounted() {
    axios.get("/tokeAktif").then((r) => {
      this.toke = r.data;
      console.log(this.toke);
    });
    this.getUser();
  },
  methods: {
    bukaRinci(id) {
      this.idToke = id;
    },
    getUser() {
      axios
        .get("/user/aktif")
        .then((response) => {
          this.level = response.data.user_level;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    cari() {
      this.$refs.form.validate();
      if (this.valid) {
        this.visibleTabel = true;
        this.loading = true;
        let uri = "/toke/cari/saldo/" + this.search;
        axios.get(uri).then((response) => {
          this.tabel = response.data;
          if (this.level != "super_admin") {
            this.tabel = this.tabel.filter((x) => {
              return x.golongan.ID < 2;
            });
          }
          this.loading = false;
          console.log(this.tabel);
        });
        console.log(uri);
      }
    },
    bukaSatu(id) {
      this.visibleTabel = true;
      this.loading = true;
      let uri = `/toke/${id}/saldo`;
      axios.get(uri).then((response) => {
        this.tabel = response.data.toke;
        this.tabel.PS = response.data.ps;
        this.tabel.PT = response.data.pt;
        this.tabel = [this.tabel];
        this.loading = false;
        console.log(this.tabel);
      });
      console.log(uri);
    },
    buka(id, jen) {
      this.id = id;
      this.jenis = jen;
      this.dialogPinjaman = true;
      console.log(this.jenis);
    },
    tutup() {
      this.dialogPinjaman = false;
    },
  },
};
</script>
