<template>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog.value = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Print</v-toolbar-title><v-spacer></v-spacer
          ><v-btn dark color="pink" @click="print"
            ><v-icon>mdi-printer</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text> </v-card-text>
        <div class="landscape areaprint overflow-auto" id="printLoro">
          <div>
            <div class="tengah"></div>
            <div class="kolom">
              <v-row>
                <v-col cols="10">
                  <table>
                    <tbody class="judulNota">
                      <tr>
                        <td>Tanggal</td>
                        <td>: {{ nota.tanggal | formatDate }}</td>
                      </tr>
                      <tr class="garisbawah">
                        <td class="ma-2">Ramp</td>
                        <td class="ma-2">
                          : {{ nota.toke ? nota.toke.nama : "" }}
                        </td>
                      </tr>
                      <tr class="total"></tr>
                    </tbody>
                  </table>
                </v-col>
                <v-spacer></v-spacer><span class="mt-2">{{ nota.ID }}</span>
              </v-row>
              <br />
              <v-row v-if="tonase.length >> 0">
                <v-col cols="12">
                  <table class="lis">
                    <thead>
                      <tr>
                        <th>Tanggal</th>
                        <th>Supir/kapal</th>
                        <th>Bruto</th>
                        <th>Tara</th>
                        <th>TBS</th>
                        <th>Potongan</th>
                        <th>Netto</th>
                        <th>Harga</th>
                        <th>Cair</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in tonase" :key="item.id">
                        <td>
                          <span>{{ item.tanggalDo | formatDate }}</span>
                        </td>
                        <td>
                          {{ item.nama }}
                        </td>
                        <td>
                          {{ item.bruto | angka }}
                        </td>
                        <td>
                          {{ item.tara | angka }}
                        </td>
                        <td>
                          {{ item.tbs | angka }}
                        </td>
                        <td>
                          {{ item.potongan | angka }}
                        </td>
                        <td>
                          {{ item.netto | angka }}
                        </td>
                        <td>
                          {{ item.harga | rupiah }}
                        </td>
                        <td>
                          {{ (item.netto * parseFloat(item.harga)) | rupiah }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr class="total">
                        <td>
                          <b>Total</b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{{ sumTon | angka }}</b>
                        </td>
                        <td></td>
                        <td>
                          <b>{{ sumCair | rupiah }}</b>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td colspan="3">
                          <b class="text-left">Potongan Modal / angsuran</b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr v-for="item in modal" :key="item.ID">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colspan="3">{{ item.uraian }}</td>
                        <td>
                          {{ item.angsur | rupiah }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="pinjam.length >> 0">
                      <tr class="total">
                        <td colspan="3"></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>
                            {{ (sumCair - sumAngsur) | rupiah }}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="pinjam.length >> 0">
                      <tr>
                        <td colspan="3">
                          <b class="text-left">Pinjaman / Tambahan</b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr v-for="item in pinjam" :key="item.ID">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colspan="3">{{ item.uraian }}</td>
                        <td>
                          {{ item.pinjam | rupiah }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr class="total">
                        <td colspan="3"><b>Sisa Cair</b></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>
                            {{ (sumCair + sumPinjam - sumAngsur) | rupiah }}</b
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <br />
                          Tanda Terima<br /><br /><br /><br /><br />
                          ( {{ nota.toke ? nota.toke.nama : "" }} )
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colspan="2">
                          <br />
                          Dibayar <br /><br /><br /><br /><br />
                          (Kasir)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- </template>
          </v-simple-table> -->
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>
<style>
@import "@/assets/aplikasi/riz/transaksi.css";
</style>
<script>
import axios from "axios";
export default {
  components: {},
  props: {
    pemicu: Boolean,
    id: Number,
  },

  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    sumTon() {
      return this.tonase.reduce((a, b) => a + parseInt(b.netto) || 0, 0);
    },
    sumCair() {
      return this.tonase.reduce(
        (a, b) => a + (parseInt(b.netto) || 0) * parseFloat(b.harga),
        0
      );
    },
    sumOps() {
      return this.tonase.reduce((a, b) => a + (parseInt(b.ops) || 0), 0);
    },
    sumPinjam() {
      return this.tbPinjaman.reduce((a, b) => a + (parseInt(b.pinjam) || 0), 0);
    },
    sumAngsur() {
      return this.tbPinjaman.reduce((a, b) => a + (parseInt(b.angsur) || 0), 0);
    },
  },
  data() {
    return {
      nota: [],
      tonase: [],
      tonaseGrup: [],
      tbPinjaman: [],
      pinjam: [],
      modal: [],
      loading: false,
    };
  },
  watch: {
    pemicu() {
      this.loadData();
    },
  },
  methods: {
    loadData() {
      this.tonase = [];
      this.tbPinjaman = [];
      this.pinjam = [];
      this.modal = [];
      let uri = `/ramp/nota/${this.id}`;
      console.log(uri);
      axios.get(uri).then((response) => {
        this.nota = response.data.nota;
        this.tonase = response.data.data;
        this.tbPinjaman = response.data.pinjaman;
        this.pinjam = this.tbPinjaman.filter(function (x) {
          return parseInt(x.pinjam) >> 0 || parseInt(x.pinjam) << 0;
        });
        this.modal = this.tbPinjaman.filter(function (x) {
          return parseInt(x.angsur) >> 0 || parseInt(x.angsur) << 0;
        });
        console.log(this.nota);
        console.log(this.tonase);
      });
    },
    grupkan(array) {
      if (array.length >> 0) {
        var hasil = [];
        let c = array.reduce(function (chace, item) {
          const properti = item.do.pabrik.id;
          if (chace.some((el) => el.idpabrik === properti)) {
            var terpilih = chace.find(function (x) {
              return x.idpabrik === properti;
            });
            terpilih.tonase += parseInt(item.tonase);
            terpilih.cair +=
              parseInt(item.tonase) *
              (parseFloat(item.harga) + parseFloat(item.th));
            terpilih.data.push(item);
            return [...chace];
          }
          chace.push({
            tonase: parseInt(item.tonase),
            cair:
              parseInt(item.tonase) *
              (parseFloat(item.harga) + parseFloat(item.th)),
            pabrik: item.do.pabrik.pabrik,
            idpabrik: item.do.pabrik.id,
            data: [item],
          });
          return [...chace];
        }, []);
        c.forEach(function (item) {
          hasil.push(item);
        });
        hasil.sort(function (a, b) {
          return a.idpabrik - b.idpabrik;
        });
        console.log(hasil);
        return hasil;
      }
      return array;
    },

    print() {
      this.$htmlToPaper("printLoro", {
        styles: [
          "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
          "/aplikasi/riz/app.css",
          "/aplikasi/riz/transaksi.css",
        ],
      });
    },
  },

  //penulisan standar
};
</script>
