var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"900","persistent":""},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Tagihan")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){dialog.value = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.switch1 ? _vm.headersMinimal : _vm.headersFull,"items":_vm.tabel,"items-per-page":10,"dense":"","mobile-breakpoint":"300","loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-arrow-left-drop-circle-outline',
            nextIcon: 'mdi-arrow-right-drop-circle-outline',
          }},scopedSlots:_vm._u([{key:"item.tanggal",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.tanggal)))])]}},{key:"item.tonase",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("angka")(item.tonase)))])]}},{key:"item.harga",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("rupiah")(item.harga)))])]}},{key:"item.cair",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("rupiah")(item.cair)))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","color":"blue","x-small":""},on:{"click":function($event){_vm.idTon = item.id;
                _vm.tonaseDialog = true;}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Totals")]),_c('th'),_c('th',[_vm._v(_vm._s(_vm._f("angka")(_vm.switch1 ? _vm.totTon : 0)))]),_c('th',[_vm._v(_vm._s(_vm._f("rupiah")(_vm.switch1 ? _vm.totCair : 0)))]),_c('th',[_vm._v(_vm._s(_vm._f("angka")(!_vm.switch1 ? _vm.totTon : 0)))]),_c('th'),_c('th',[_vm._v(_vm._s(_vm._f("rupiah")(!_vm.switch1 ? _vm.totCair : 0)))])])])],2),_c('v-switch',{staticClass:"ml-8",attrs:{"dense":"","label":"Rekap"},model:{value:(_vm.switch1),callback:function ($$v) {_vm.switch1=$$v},expression:"switch1"}})],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})
}
var staticRenderFns = []

export { render, staticRenderFns }