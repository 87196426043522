<template>
  <div class="pa-6">
    <v-row>
      <v-col cols="12" md="3"><opsBD @ubahBulan="mouseEnter"></opsBD></v-col>
      <v-col cols="12" md="9"
        ><v-btn color="red" dark @click="bukaBD" class="ma-1"
          >Print ops B doni</v-btn
        >
        <v-btn
          color="red"
          dark
          :to="'tutupbuku/tutupton/' + bulan.replace('-', '/')"
          class="ma-1"
          >Tonase</v-btn
        >
        <download-excel
          class="mx-2"
          :data="pinjamanLain"
          :fields="fieldExcell"
          worksheet="My Worksheet"
          :name="filename"
          ><v-btn color="green" dark class="ma-1"
            >Rekap Pinjaman lain</v-btn
          ></download-excel
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import opsBD from "./opsbd.vue";
import axios from "axios";
import moment from "moment";
export default {
  components: { opsBD },
  data() {
    return {
      bulan: new Date().toISOString().substr(0, 7),
      pinjamanLain: [],
      fieldExcell: {
        ID: "ID",
        Tanggal: "tanggal",
        Toke: "toke.nama",
        Uraian: "uraian",
        Pinjam: "pinjam",
        Angsur: "angsur",
      },
    };
  },
  mounted() {
    this.inisialisasi();
  },
  computed: {
    filename() {
      return `ops Lainya -${moment().format("DD-MM-YY hh")}`;
    },
  },
  methods: {
    inisialisasi() {
      this.lain();
    },
    bukaBD() {
      const { href } = this.$router.resolve({
        path: "/print/opsbd/" + this.bulan.replace("-", "/"),
      });
      window.open(href, "_blank");
    },
    mouseEnter(item) {
      this.bulan = item;
      this.lain();
    },
    lain() {
      let uri = `/pinjaman/lain/${this.bulan.replace("-", "/")}`;
      axios.get(uri).then((response) => {
        this.pinjamanLain = response.data;
        console.log(this.pinjamanLain);
      });
    },
  },
};
</script>
