var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tonase,"search":_vm.search,"loading":_vm.loading,"items-per-page":8,"dense":"","show-select":"","item-key":"id","mobile-breakpoint":"300","footer-props":{
        showFirstLastPage: false,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        prevIcon: 'mdi-arrow-left-drop-circle-outline',
        nextIcon: 'mdi-arrow-right-drop-circle-outline',
      }},scopedSlots:_vm._u([{key:"item.tanggaldo",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.tanggaldo)))])]}},{key:"item.Hitungcair",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("rupiah")((item.tonase * (parseInt(item.th) + parseInt(item.harga))))))])]}}]),model:{value:(_vm.terpilih),callback:function ($$v) {_vm.terpilih=$$v},expression:"terpilih"}}),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue","dark":""},on:{"click":_vm.simpan}},[_vm._v("Simpan")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.tutup()}}},[_vm._v("Batal")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }