<template>
  <v-dialog transition="dialog-bottom-transition" v-model="dialog" persistent>
    <v-card dense tile :loading="loading">
      <v-card-title>Pilih nota tujuan | id cair: {{ id }}</v-card-title>
      <v-card-subtitle>
        <div v-if="path" class="my-2">
          <v-icon @click="inisialisasi">mdi-arrow-left</v-icon> {{ path + "/" }}
        </div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          dense
          label="Cari"
          class="mx-2"
        ></v-text-field>
        <v-btn @click="notaBaru"><v-icon>mdi-plus</v-icon></v-btn>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col
            v-for="item in notaFilter"
            :key="item.id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card
              elevation="3"
              @click="bukaFolder(item)"
              v-if="item.jenis == 1"
            >
              <v-card-subtitle class="orange lighten-4"
                ><v-icon>mdi-folder</v-icon>
                <b class="text-uppercase"> {{ item.keterangan }}</b>
              </v-card-subtitle>
              <v-card-text>
                <b class="blue--text">{{ item.total | rupiah }}</b>
              </v-card-text>
            </v-card>
            <v-card
              elevation="3"
              @click="bukaNota(item)"
              v-if="!(item.jenis == 1)"
            >
              <v-card-subtitle :class="hiLight(item.ID)" v-if="item.toke"
                ><v-icon>mdi-account</v-icon> {{ item.toke.nama }}
              </v-card-subtitle>
              <v-card-text :class="item.toke ? '' : 'blue lighten-4'">
                <b class="text-uppercase">{{ item.keterangan }}</b>
                <br /><b class="blue--text">{{ item.total | rupiah }}</b>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue" dark @click="simpan()" v-if="!(itemTerpilih == 0)"
          >Simpan</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="red" dark @click="tutup()">Batal</v-btn></v-card-actions
      >
    </v-card>
    <notabaru
      :notaDialog="pemicuNota"
      :transaksi="transaksi"
      :isEdit="false"
      :subFolder="subFolder"
      :idFolder="idFolder"
      @tutup="pemicuNota = false"
      @bukaNota="refresh"
    ></notabaru>
  </v-dialog>
</template>
<script>
import axios from "axios";
import notabaru from "../input/notaBaru.vue";
// import moment from "moment";
export default {
  props: ["pemicu", "id", "paket", "tonase"],
  components: {
    notabaru,
  },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },

    notaFilter() {
      return this.data.filter((item) => {
        let toke = item.toke ? item.toke.nama.toLowerCase() : "";
        let keterangan =
          item.keterangan === null ? "" : item.keterangan.toLowerCase();
        return (
          toke.indexOf(this.search.toLowerCase()) > -1 ||
          keterangan.indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  watch: {
    pemicu() {
      if (this.pemicu) {
        this.inisialisasi();
      }
    },
  },
  mounted() {},
  data() {
    return {
      loading: false,
      data: [],
      search: "",
      path: "",
      pemicuNota: false,
      toke: [],
      transaksi: {},
      subFolder: false,
      idFolder: 0,
      folderTerpilih: {},
      itemTerpilih: 0,
      destinasi: "folder",
    };
  },
  methods: {
    inisialisasi() {
      this.path = "";
      this.itemTerpilih = 0;
      (this.destinasi = "folder"), (this.subFolder = false);
      this.idFolder = 0;
      console.log(this.id);
      this.terpilih = [];
      this.data = [];
      this.loading = true;
      let uri = `/transaksi/${this.id}/nota/rinci`;
      axios
        .get(uri)
        .then((res) => {
          this.data = res.data.data;
          console.log(this.data);
          this.getTransaksi();
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.message);
          this.loading = false;
        });
    },
    getTransaksi() {
      let uri = `/transaksi/${this.id}`;
      axios
        .get(uri)
        .then((res) => {
          this.transaksi = res.data[0];
          console.log(this.transaksi);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    bukaNota(item) {
      this.itemTerpilih = item.ID;
      if (item.toke) {
        this.destinasi = "nota";
      } else {
        this.destinasi = "folder";
      }
    },
    simpan() {
      this.loading = true;
      let uri = "";
      console.log("destinasi terpilih: " + this.itemTerpilih); // pinjaman/gantinota/
      console.log(this.paket);
      let jalankan = false;
      if (this.tonase) {
        uri = `/tonase/pindahnota/${this.itemTerpilih}`;
        if (this.destinasi == "folder") {
          jalankan = false;
        } else {
          jalankan = true;
        }
      } else {
        uri = `/pinjaman/gantinota/${this.itemTerpilih}`;
        jalankan = true;
      }
      console.log(uri);
      if (jalankan) {
        axios
          .put(uri, this.paket)
          .then((response) => {
            console.log(response.data);
            this.$noty.success("Data berhasil disimpan");
            this.loading = false;
            this.$emit("simpan");
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      } else {
        this.$noty.success("Tonase tidak bisa disimpan di sini");
      }
    },
    bukaFolder(item) {
      this.loading = true;
      this.itemTerpilih = item.ID;
      this.destinasi = "folder";
      let uri = `/nota/${item.ID}/folder`;
      this.path = item.keterangan;
      this.subFolder = true;
      this.idFolder = item.ID;
      this.folderTerpilih = item;
      axios
        .get(uri)
        .then((response) => {
          this.data = response.data.isi;
          console.log(this.data);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refresh() {
      console.log("di refresh");
      if (this.path == "") {
        this.inisialisasi();
      } else {
        this.bukaFolder(this.folderTerpilih);
      }
    },
    notaBaru() {
      this.pemicuNota = true;
    },
    tutup() {
      this.$emit("tutup");
    },
    hiLight(item) {
      if (item == this.itemTerpilih) {
        return "red lighten-2";
      } else {
        return "blue lighten-2";
      }
    },
  },
};
</script>
