<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
    persistent
    class="mx-5"
    width="500"
  >
    <v-card :loading="loading">
      <v-toolbar dense :loading="loading">
        <v-toolbar-title>Nota Pencairan {{ supir }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row dense class="mt-3">
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="idToke"
              :items="toke"
              item-text="nama"
              item-value="ID"
              label="Toke"
              dense
              required
              auto-select-first
              :rules="[(v) => !!v || 'Harus diisi']"
            ></v-autocomplete
          ></v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row dense class="mt-4">
          <v-col cols="12" sm="6">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="newDate"
                  clearable
                  label="Tanggal"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="date = null"
                  dense
                  :rules="[(v) => !!v || 'Harus diisi']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="tanggal"
                @change="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="idPabrik"
              :items="pabrik"
              item-text="pabrik"
              item-value="id"
              label="Pabrik"
              dense
              required
              auto-select-first
              :rules="[(v) => !!v || 'Harus diisi']"
            ></v-autocomplete
          ></v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Nama Supir"
              required
              v-model="supir"
              autofocus
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Plat"
              required
              v-model="plat"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Tonase"
              required
              v-model="netto"
              dense
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <span class="ml-4 text-subtitle-1"
              >X
              <a @click="pemicuHarga = true" class="ml-4">{{
                (parseFloat(harga) + parseFloat(th)) | rupiah
              }}</a></span
            >
          </v-col>

          <v-col cols="12">
            <p class="text-h6 text-right mr-4">
              {{ ((parseFloat(harga) + parseFloat(th)) * netto) | rupiah }}
            </p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="5"
            ><a><p class="text-right text-subtitle-1">OPS</p></a></v-col
          >
          <v-col cols="6"><p class="text-left text-subtitle-1">0</p></v-col>
          <v-col cols="1"></v-col>
          <v-col cols="5"><p class="text-right text-subtitle-1">PT</p></v-col>
          <v-col cols="6">
            <v-text-field v-model="pt" dense type="number"></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="5"
            ><p class="text-right text-subtitle-1">Modal</p></v-col
          >
          <v-col cols="6">
            <v-text-field v-model="ps" dense type="number"></v-text-field>
          </v-col>
          <v-col cols="1"
            ><v-btn small icon color="blue"
              ><v-icon>mdi-plus</v-icon></v-btn
            ></v-col
          >
        </v-row>
        <v-row class="mt-5">
          <v-btn
            color="primary"
            @click="updateData"
            type="submit"
            :disabled="tombolOff || loading"
          >
            Simpan </v-btn
          ><v-spacer></v-spacer>
          <v-btn color="red" dark @click="tutup"> Batal </v-btn>
        </v-row>
      </v-card-text>
      <editharga
        :pemicu="pemicuHarga"
        :harga="harga"
        @tutup="pemicuHarga = false"
        @update="updateHarga"
      ></editharga>
      <tambahpeng
        :pemicu="pemicuPotongan"
        :edit="editPotongan"
        :isi="isiPotongan"
        :idToke="idPtoke"
        :toke="toke"
        @tutup="pemicuPotongan = false"
        @update="updatePotongan"
      ></tambahpeng>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import axios from "axios";
import editharga from "./editHarga.vue";
import tambahpeng from "./listPotongan.vue";
export default {
  components: {
    editharga,
    tambahpeng,
  },
  data() {
    return {
      menu1: false,
      date: "",
      tonase: {},
      loadingTon: false,
      loadingHarga: false,
      valid: false,
      tombolOff: false,
      loading: false,
      pemicuHarga: false,
      pemicuPotongan: false,
      editPotongan: false,
      deo: [],
      toke: [],
      pabrik: [],
      hargaHitung: {},
      data: {},
      nota: {},
      pinjaman: [],
      supir: "",
      plat: "",
      idPabrik: 0,
      hasil: 0,
      harga: 0,
      tanggal: "",
      ps: 0,
      pt: 0,
      ops: 0,
      lainya: 0,
      listLainya: [],
      isiPotongan: {},
      netto: 0,
      th: 0,
      idToke: 0,
      idPtoke: 0,
      keterangan: "",
      idOps: 5,
      idPsupir: 6,
      idPtransfer: 7,
      idSupir: 0,
      idTransfer: 0,
      idPS: 0,
      idPT: 0,
      ididOps: 0,
      jumLainya: 0,
      jenisEdit: 0,
      jenisOps: "OPS Supir",
      e1: 1,
    };
  },
  props: ["id", "pemicu", "transaksi", "idFolder"],
  computed: {
    newDate() {
      return moment(this.tanggal).format("DD/MM/YYYY");
    },
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    id() {
      this.inisialisasi();
    },
    pemicu() {
      if (this.pemicu) {
        this.inisialisasi();
      }
    },
    idPabrik() {
      this.cek();
    },
    tanggal() {
      this.cek();
    },
  },
  mounted() {
    // this.inisialisasi();
  },
  methods: {
    tutup() {
      this.$emit("tutup", 0);
    },
    inisialisasi() {
      this.loading = true;
      this.nolkan();
      this.getToke();
      if (this.id) {
        console.log(`ini fungsi edit,id nya: ${this.id}`);
        this.loading = true;
        axios.get(`/notaPencairan/${this.id}`).then((response) => {
          let tonase = response.data.tonase[0];
          let nota = response.data.nota[0];
          let pinjaman = response.data.pinjaman;
          this.idPabrik = tonase.do.pabrik.id;
          this.tanggal = tonase.tanggaldo;
          this.keterangan = nota.keterangan;
          this.netto = tonase.tonase;
          this.idToke = tonase.toke.ID;
          this.ps = 0;
          this.pt = 0;
          this.ops = 0;
          this.lainya = 0;
          this.th = parseFloat(tonase.th);
          this.supir = tonase["nama supir"];
          this.plat = tonase.plat;
          this.loading = false;
          this.harga = parseFloat(tonase.harga);
          this.idTonase = tonase.id;
          console.log(pinjaman);
          console.log(tonase);
          console.log(nota);
          this.getPinjaman(pinjaman ? pinjaman : []);
        });
      } else {
        console.log("ini input baru");
      }
    },
    nolkan() {
      this.listLainya = [];
      this.idPabrik = 0;
      this.tanggal = this.transaksi.tanggal;
      this.keterangan = "Pencairan ";
      this.netto = 0;
      this.idToke = 0;
      this.ps = 0;
      this.pt = 0;
      this.ops = 0;
      this.lainya = 0;
      this.th = 0;
      this.supir = "";
      this.plat = "";
      this.harga = 0;
      this.idPs = 0;
      this.idPT = 0;
      this.ididOps = 0;
    },
    getToke() {
      let uri = "/tokeAktif";
      axios.get(uri).then((res) => {
        this.toke = res.data;
        this.getDeo();
      });
    },
    getDeo() {
      let uri = "/deo";
      axios.get(uri).then((res) => {
        this.deo = res.data;
        this.getPabrik();
      });
    },
    getPabrik() {
      let uri = "/pabrik";
      axios.get(uri).then((res) => {
        this.pabrik = res.data;
        this.loading = false;
      });
    },
    updateData() {
      if (this.valid) {
        this.tombolOff = true;
        let data = {};
        data.toke = this.idToke;
        data.pabrik = this.idPabrik;
        data.tanggal = this.tanggal;
        data.keterangan = `${this.keterangan} ${this.supir}/ ${
          this.pabrik.find((x) => {
            return x.id == data.pabrik;
          }).pabrik
        }`;
        data.supir = this.supir;
        data.plat = this.plat;
        data.tonase = parseFloat(this.netto);
        data.harga = this.harga;
        data.lainya = this.lainya;
        data.pinjaman = this.setPinjaman();
        data.th = parseFloat(this.th);
        data.id_cair = this.transaksi.id;
        data.id_akun = this.transaksi.akun.id;
        data.idTonase = this.idTonase;
        data.ops = parseFloat(this.ops) || 0;
        data.idFolder = this.idFolder;
        if (this.id) {
          data.keterangan = this.keterangan;
          console.log("ini update pencairan");
          console.log(data);
          let uri = `/notaPencairan/${this.id}`;
          axios
            .put(uri, data)
            .then((response) => {
              console.log(response.data);
              if (response.data.status) {
                this.tombolOff = false;
                console.log(response.data);
                this.tutup();
                this.$noty.success("berhasil disimpan dong");
                this.$emit("update", data);
              } else {
                this.$noty.error("gagal update");
                this.tombolOff = false;
                this.tutup();
              }
            })
            .catch((error) => {
              this.$noty.error(error.message);
              this.tombolOff = false;
            });
        } else {
          console.log(data);
          let uri = `/notaPencairan`;
          axios
            .post(uri, data)
            .then((response) => {
              if (response.data.status) {
                this.tombolOff = false;
                console.log(response.data);
                this.tutup();
                this.$noty.success(response.data.messages);
                this.$emit("update", data);
              } else {
                this.tutup();
                this.tombolOff = false;
                this.$noty.error("terjadi error, data gagal");
                console.log(response.data);
              }
            })
            .catch((error) => {
              this.$noty.error(error.message);
              this.tombolOff = false;
            });
        }
      }
    },
    setPinjaman() {
      let pinjaman = [];
      if (this.pt) {
        pinjaman.push({
          jenis: "PT",
          angsur: parseInt(this.pt),
          pinjam: 0,
          uraian: "Potongan p tetap",
          toke: 0,
          ID: this.idPT,
          akun: "",
        });
      }
      if (this.listLainya.length > 0) {
        let supir = this.idPsupir;
        let transfer = this.idPtransfer;
        this.listLainya.forEach((e) => {
          let akun = "";
          if (e.toke == supir) {
            akun = "SUPIR";
          }
          if (e.toke == transfer) {
            akun = "TRANSFER";
          }
          pinjaman.push({
            jenis: "PS",
            angsur: parseInt(e.nominal),
            pinjam: 0,
            uraian: e.uraian,
            toke: e.toke,
            ID: e.ID,
            akun: akun,
          });
        });
      }
      if (this.ops) {
        pinjaman.push({
          jenis: "PS",
          angsur: parseInt(this.ops),
          uraian: "Potongan OPS",
          pinjam: 0,
          toke: this.idOps,
          ID: this.ididOps,
          akun: "OPS",
        });
      }
      if (this.ps) {
        pinjaman.push({
          jenis: "PS",
          angsur: parseInt(this.ps),
          uraian: "Potongan Modal Sementara",
          pinjam: 0,
          toke: 0,
          ID: this.idPS,
          akun: "",
        });
      }
      return pinjaman;
    },
    getPinjaman(item) {
      this.listLainya = [];
      let id = 0;
      console.log("mencoba isi list lainya");
      if (item) {
        console.log("item ditemukan");
        console.log(item);
        for (let i = 0; i < item.length; i++) {
          if (item[i].jenis == "PT") {
            console.log("PT ditemukan");
            this.pt = item[i].angsur;
            this.idPT = item[i].ID;
          } else if (item[i].toke == this.idOps) {
            console.log("ops ditemukan");
            this.ops = item[i].angsur;
            this.ididOps = item[i].ID;
          } else {
            let it = item[i];
            id += 1;
            it.Rid = id;
            it.nominal = it.angsur;
            this.listLainya.push(it);
          }
        }
      }
      if (this.listLainya.length == 1) {
        this.ps = this.listLainya[0].angsur;
        this.idPS = this.listLainya[0].ID;
        this.listLainya = [];
      }
      console.log(this.listLainya);
      this.hitungLainya();
    },
    updateHarga(item) {
      this.th = item;
      console.log(this.th);
    },
    updatePotongan(item) {
      if (item.edit) {
        console.log("sedang edit potongan");
        console.log(item);
        let id = this.listLainya.findIndex((obj) => obj.Rid == item.Rid);
        this.listLainya[id].toke = item.toke;
        this.listLainya[id].uraian = item.uraian;
        this.listLainya[id].nominal = item.nominal;
      } else {
        let id = this.listLainya.length + 1;
        item.Rid = id;
        item.ID = 0;
        this.listLainya.push(item);
      }
      console.log(item);
      console.log(this.listLainya);
      this.hitungLainya();
    },
    editPengeluaran(item) {
      this.isiPotongan = item;
      this.editPotongan = true;
      this.pemicuPotongan = true;
    },
    hapusPengeluaran(item) {
      this.listLainya = this.listLainya.filter((x) => {
        return !(x.id == item);
      });
      this.hitungLainya();
    },
    hitungLainya() {
      this.jumLainya = 0;
      if (this.listLainya.length > 0) {
        this.lainya = this.listLainya.reduce((x, item) => {
          x += parseFloat(item.nominal);
          return x;
        }, 0);
        this.jumLainya = this.listLainya.length;
      } else {
        this.lainya = 0;
      }

      console.log(this.lainya);
    },
    async cek() {
      let data = {};
      if (this.idPabrik && this.tanggal) {
        try {
          let uri = `/harga/${this.idPabrik}/${this.tanggal}`;
          console.log(uri);
          data = await axios.get(uri);
          this.harga = data.data.harga;
        } catch (error) {
          this.loading = false;
          console.log(error.message);
        }
      } else {
        this.tonase.harga = 0;
      }
      this.hitung();
    },
    hitung() {
      this.cair1 = this.harga * this.tonase.tonase;
      this.cair2 = this.cair1 - this.data.ps - this.data.pt - this.data.ops;
    },
  },
};
</script>
