<template>
  <div>
    <v-row
      ><v-btn color="pink" dark @click="print"
        ><v-icon>mdi-printer</v-icon></v-btn
      ></v-row
    >
    <v-row class="lebar" id="pinjamanSiji">
      <v-col>
        <v-card :loading="loading">
          <v-card-title>
            <h4>
              Rincian Pinjaman
              {{ idRekap ? idRekap.jenis_pinjaman : "" }} periode
              {{ periode() }}
            </h4>
          </v-card-title>
          <table class="tabel">
            <thead>
              <tr class="judul">
                <th>No</th>
                <th>Toke</th>
                <th>Saldo Awal</th>
                <th>Pinjam</th>
                <th>Angsur</th>
                <th>Saldo</th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in toke" :key="item.toke.id">
              <tr>
                <td class="regional">{{ item.regional.regional }}</td>
              </tr>
              <tr
                v-for="(item, index) in item.toke"
                :key="item.ID"
                class="isiTabel"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item.nama }}</td>
                <td>{{ item.saldo_awal | rupiah }}</td>
                <td>{{ item.pinjam | rupiah }}</td>
                <td>{{ item.angsur | rupiah }}</td>
                <td>{{ item.saldo | rupiah }}</td>
              </tr>
              <tr class="saldo">
                <td colspan="2">Total</td>
                <td>
                  {{ item.sumAwal | rupiah }}
                </td>
                <td>
                  {{ item.sumPinjam | rupiah }}
                </td>
                <td>
                  {{ item.sumAngsur | rupiah }}
                </td>
                <td>
                  {{ item.sumSaldo | rupiah }}
                </td>
              </tr>
              <tr v-if="index == toke.length - 1" class="saldo">
                <td colspan="2"><b>Grand Total</b></td>
                <td>
                  <b>{{ totalAwal | rupiah }}</b>
                </td>
                <td>
                  <b>{{ sumPer(toke, "sumPinjam") | rupiah }}</b>
                </td>
                <td>
                  <b>{{ sumPer(toke, "sumAngsur") | rupiah }}</b>
                </td>
                <td>
                  <b>{{ sumPer(toke, "sumSaldo") | rupiah }}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style type="text/css" media="print">
@import "@/assets/aplikasi/riz/pinjaman.css";
</style>

<script>
import moment from "moment";
import axios from "axios";
// import _ from "lodash";
export default {
  components: {},
  props: ["id"],
  data() {
    return {
      toke: [],
      loading: false,
      idRekap: {},
    };
  },
  computed: {
    totalAwal() {
      return this.sumPer(this.toke, "sumAwal");
    },
    totalPinjam() {
      return 0;
    },
  },
  mounted() {
    this.loadData();
    // console.log(this.toke);
    // this.addSaldo();
    // this.filterTabel();
    // this.grup();
  },
  watch: {},
  methods: {
    loadData() {
      this.loading = true;
      axios.get("/rekap/pinjaman/" + this.id).then((response) => {
        this.toke = response.data.data;
        this.tanggal_rekap = response.data.tanggal_rekap
          ? response.data.tanggal_rekap.tanggal_buat
          : "";
        this.idRekap = response.data.tanggal_rekap;
        console.log(this.idRekap);
        this.filterTabel();
        this.grup();
        this.loading = false;
      });
    },

    filterTabel() {
      let hasil = this.toke.filter(function (item) {
        let a = item.saldo << -10000 && item.saldo >> 10000;
        return a;
      });
      this.toke = hasil;
    },
    grup() {
      this.toke = this.toke.reduce((p, item) => {
        let arr = -1;
        if (p.length > 0) {
          arr = p.findIndex((x) => {
            return x.regional.id == item.toke.reg;
          });
        }
        item.nama = item.toke.nama;
        if (arr >= 0) {
          p[arr].sumAwal += parseFloat(item.saldo_awal);
          p[arr].sumAngsur += parseFloat(item.angsur);
          p[arr].sumPinjam += parseFloat(item.pinjam);
          p[arr].sumSaldo += parseFloat(item.saldo);
          p[arr].toke.push(item);
        } else {
          item.toke.regional.sumAwal = item.saldo_awal;
          item.toke.regional.sumAngsur = item.angsur;
          item.toke.regional.sumPinjam = item.pinjam;
          item.toke.regional.sumSaldo = item.saldo;
          p.push({
            regional: item.toke.regional,
            toke: [item],
            sumAwal: item.saldo_awal,
            sumAngsur: item.angsur,
            sumPinjam: item.pinjam,
            sumSaldo: item.saldo,
          });
        }
        return p;
      }, []);
      console.log(this.toke);
    },
    sumPer(item, prop) {
      return item.reduce(function (a, b) {
        return a + (parseInt(b[prop]) || 0);
      }, 0);
    },
    periode() {
      if (this.idRekap) {
        if (this.idRekap.jenis == 0) {
          return moment(this.idRekap.tanggal_A).format("MMMM YYYY");
        } else {
          return this.idRekap.tanggal_A + " - " + this.idRekap.tanggal_B;
        }
      }
    },
    goPrint() {
      window.print();
    },
    print() {
      this.$htmlToPaper("pinjamanSiji", {
        styles: ["/aplikasi/riz/app.css", "/aplikasi/riz/pinjaman.css"],
      });
    },
  },
};
</script>
