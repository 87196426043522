var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"800","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"dense":"","tile":"","loading":_vm.loading}},[_c('v-card-title',[_vm._v("List nota toke : "+_vm._s(_vm.toke ? _vm.toke.nama : ""))]),_c('v-card-text',[_c('v-text-field',{staticClass:"mx-3 mb-3",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"mx-2 mx-sm-10",attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"items-per-page":10,"page":_vm.lastPage,"dense":"","mobile-breakpoint":"300","loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-arrow-left-drop-circle-outline',
            nextIcon: 'mdi-arrow-right-drop-circle-outline',
          }},scopedSlots:_vm._u([{key:"item.tanggal",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.tanggal)))])]}},{key:"item.cair",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("rupiah")(item.cair)))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","x-small":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.buka(item)}}},[_c('v-icon',[_vm._v(" mdi-account-edit ")])],1)]}}])})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){return _vm.tutup()}}},[_vm._v("tutup")])],1)],1)],1),(_vm.notaAktif)?_c('div',[_c('isinota',{attrs:{"dialog":_vm.pemicuNota,"idNota":_vm.idNota,"idAkun":_vm.idAkun,"toke":_vm.tokes,"deo":_vm.deo,"pabrik":_vm.pabrik,"loading":_vm.loading,"cekReload":_vm.cekReload,"transaksi":_vm.transaksi},on:{"tutup":function($event){_vm.notaAktif = false;
        _vm.pemicuNota = false;},"reload":_vm.inisialisasi}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }