<template>
  <v-row class="ma-2"
    ><v-col cols="12" md="3">
      <v-date-picker v-model="tanggal" elevation="4"></v-date-picker
    ></v-col>
    <v-col cols="12" md="9">
      <v-card color="grey lighten-3" :loading="loading"
        ><v-toolbar color="blue darken-3" dense dark>
          <v-toolbar-title>{{ akun.nama }}</v-toolbar-title>
          <v-spacer></v-spacer
          ><v-btn color="red" icon x-large @click="$router.push('/banklm')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <template v-slot:extension>
            <v-tabs v-model="Tab" align-with-title>
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab v-for="item in tabs" :key="item" @click="idTab = item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-row class="ma-1">
          <v-col cols="12" md="7">
            <table>
              <tbody>
                <tr>
                  <td>
                    <span class="ma-3"> Saldo : </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
          <v-col cols="12" md="5">
            <v-btn
              color="purple"
              small
              dark
              @click="
                bukaRekKoran = false;
                pemicuTgl = true;
              "
              class="ml-2"
              >Rekap Transaksi</v-btn
            >
            <v-btn
              color="pink"
              small
              dark
              @click="
                bukaRekKoran = true;
                pemicuTgl = true;
              "
              class="ml-2"
              >Rekening Koran</v-btn
            >
          </v-col>
          <v-col cols="12">
            <transaksi
              v-if="idTab == 'Transaksi'"
              :idAkun="id"
              :tanggal="tanggal"
              :akun="akun"
              :reload="reloadTransaksi"
              @update="inisialisasi"
            ></transaksi>
            <modalan
              v-if="idTab == 'Modal'"
              :modal="modal"
              :idAkun="id"
              :tanggal="this.tanggal"
              @reload="inisialisasi"
            ></modalan>
            <tagihan
              v-if="idTab == 'Tagihan'"
              :id="id"
              @tutup="pemicuTagihan = false"
            ></tagihan>
          </v-col>
          <rekeningkoran
            :pemicu="pemicuRekening"
            :idAkun="id"
            :tanggal="arrTanggal"
            @tutup="pemicuRekening = false"
          ></rekeningkoran>
          <rekaptransaksi
            :pemicu="pemicuRekap"
            :arrTanggal="arrTanggal"
            :id="id"
            :akun="akun"
            @tutup="pemicuRekap = false"
          ></rekaptransaksi>
          <tgl
            :pemicu="pemicuTgl"
            @tutup="pemicuTgl = false"
            @simpan="bukaRekap"
          ></tgl>
        </v-row>
      </v-card>
      <v-col cols="12"> </v-col>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import modalan from "../../Transaksi/modalan.vue";
import tagihan from "./tagihan.vue";
import rekeningkoran from "./rekKoran.vue";
import rekaptransaksi from "./rekapTransaksi.vue";
import tgl from "../../component/popupTgl.vue";
import _ from "lodash";
import moment from "moment";
import transaksi from "./transaksiMasuk.vue";
export default {
  components: {
    transaksi,
    modalan,
    tagihan,
    rekeningkoran,
    rekaptransaksi,
    tgl,
  },
  props: ["id"],
  computed: {},
  data() {
    return {
      tanggal: moment().format("YYYY-MM-DD"),
      loadingRefresh: false,
      loading: false,
      akun: {},
      tabs: ["Transaksi", "Modal", "Tagihan"],
      idTab: "Transaksi",
      Tab: "Transaksi",
      reloadTransaksi: false,
      modal: [],
      pemicuRekening: false,
      pemicuRekap: false,
      arrTanggal: [],
      pemicuTgl: false,
      bukaRekKoran: false,
    };
  },
  mounted() {
    this.inisialisasi();
  },
  watch: {
    tanggal() {
      this.reloadTransaksi = !this.reloadTransaksi;
      console.log(this.reloadTransaksi);
    },
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let url = "/kasir/" + this.id;
      axios.get(url).then((response) => {
        this.akun = _.values(response.data)[0];
        this.loading = false;
        console.log(this.akun);
      });
    },
    home() {
      this.$router.push("/bank");
    },
    bukaRekap(item) {
      console.log(item);
      this.arrTanggal = item;
      if (this.bukaRekKoran) {
        this.pemicuRekening = true;
      } else {
        this.pemicuRekap = true;
      }
    },
    refresh() {},
  },
};
</script>
