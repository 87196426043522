var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Rekening Koran")]),_c('download-excel',{staticClass:"mx-2",attrs:{"data":_vm.tabel,"fields":_vm.fieldExcell,"worksheet":"My Worksheet","name":_vm.filename}},[_c('v-btn',{staticClass:"mx-4",attrs:{"icon":"","color":"green"}},[_c('v-icon',[_vm._v("mdi-table-large")])],1)],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":_vm.tutup}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tabel,"search":_vm.search,"items-per-page":10,"page":_vm.lastPage,"dense":"","mobile-breakpoint":"300","loading":_vm.loading,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        prevIcon: 'mdi-arrow-left-drop-circle-outline',
        nextIcon: 'mdi-arrow-right-drop-circle-outline',
      }},scopedSlots:_vm._u([{key:"item.tanggal",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.tanggal)))])]}},{key:"item.masuk",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("rupiah")(item.masuk)))])]}},{key:"item.keluar",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("rupiah")(item.keluar)))])]}},{key:"item.saldo",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("rupiah")(item.saldo)))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}])}),_c('edit',{attrs:{"pemicu":_vm.pemicuEdit,"data":_vm.dataEdit},on:{"tutup":function($event){_vm.pemicuEdit = false;
        _vm.inisialisasi;}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }