<template>
  <v-card :loading="loading">
    <!-- <v-card-title>Nota</v-card-title> -->
    <v-card-actions class="elevation-3">
      <v-btn color="pink" dark @click="modalBaru" fab small
        ><v-icon>mdi-plus</v-icon></v-btn
      >
      <v-btn color="blue" dark @click="imporModal" fab small
        ><v-icon>mdi-package-variant-closed-check</v-icon></v-btn
      >
      <v-switch
        v-model="switch1"
        class="mx-2"
        label="Lihat Semua"
        @click="hanyaKasir"
      ></v-switch
      ><v-spacer></v-spacer>
      <v-text-field
        class="mx-6"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
      ></v-text-field
    ></v-card-actions>
    <v-card-text>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="modalEdit"
          :search="search"
          :items-per-page="100"
          show-select
          v-model="terpilih"
          dense
          item-key="id"
          mobile-breakpoint="300"
          hide-default-footer
        >
          <template v-slot:item.tanggal="{ item }">
            {{ item.tanggal | formatDate }}
          </template>
          <template v-slot:item.masuk="{ item }">
            {{ parseInt(item.masuk) | rupiah }}
          </template>
          <template v-slot:item.keluar="{ item }">
            {{ parseInt(item.keluar) | rupiah }}
          </template>
          <template v-slot:item.aksi="{ item }">
            <v-btn
              class="mx-2"
              icon
              small
              color="green"
              @click.stop="edit(item)"
              :loading="loadingEdit"
              :disabled="loadingEdit"
            >
              <v-icon> mdi-account-edit </v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              icon
              small
              color="red"
              @click.stop="hapus(item)"
              :loading="loadingDelet"
              :disabled="loadingDelet"
            >
              <v-icon> mdi-delete-circle-outline </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-card-text>
    <modalbaru
      :dialogModalBaru="dialogModalBaru"
      :idAkun="idAkun"
      @tutup="tutupDialog"
      :tanggal="tanggal"
      @reload="reload"
    ></modalbaru>
    <modalEdit
      :pemicu="formEdit"
      :data="itemEdit"
      @tutup="tutupDialog"
      @reload="reload"
    >
    </modalEdit>
    <modalImport
      :pemicu="pemicuImpor"
      @tutup="pemicuImpor = false"
      :tanggal="tanggal"
      :akun="idAkun"
      @reload="reload"
    ></modalImport>
  </v-card>
</template>
<script>
import modalbaru from "./input/modalBaru.vue";
import modalEdit from "./input/modalEdit.vue";
import modalImport from "./imporModal/modal.vue";
import axios from "axios";
export default {
  props: ["idAkun", "tanggal"],
  components: {
    modalbaru,
    modalEdit,
    modalImport,
  },
  watch: {
    tanggal() {
      this.getModal();
    },
  },
  mounted() {
    this.getModal();
  },
  computed: {
    modalEdit: {
      get() {
        if (this.switch1) {
          return this.modal;
        } else {
          return this.modal.filter((value) => {
            return value.akun.id == this.idAkun;
          });
        }
      },
      set(value) {
        return value;
      },
    },
  },
  data() {
    return {
      dialogModalBaru: false,
      pemicuImpor: false,
      formEdit: false,
      itemEdit: {},
      selectedRows: [0],
      switch1: true,
      loadingDelet: false,
      loadingEdit: false,
      modal: [],
      loading: false,
      search: "",
      terpilih: [],
      headers: [
        { text: "Tanggal", value: "tanggal" },
        {
          text: "Akun",
          value: "akun.nama",
        },
        { text: "Uraian", value: "uraian" },
        { text: "Masuk", value: "masuk" },
        { text: "Keluar", value: "keluar" },
        { text: "Aksi", value: "aksi" },
      ],
    };
  },
  methods: {
    modalBaru() {
      this.dialogModalBaru = true;
    },
    hanyaKasir() {},
    tutupDialog() {
      this.dialogModalBaru = false;
      this.formEdit = false;
    },
    reload() {
      this.$emit("reload");
    },
    edit(item) {
      console.log(item);
      this.itemEdit = item;
      this.formEdit = true;
    },
    hapus(item) {
      this.loadingDelet = true;
      let uri = "/modal/" + item.id;
      axios.delete(uri).then(() => {
        this.loadingDelet = false;
        this.$noty.success("Data berhasil dihapus");
        this.reload();
      });
    },
    getModal() {
      this.modal = [];
      this.loading = true;
      let uri = `/transaksi/modal/${this.tanggal}`;
      axios.get(uri).then((response) => {
        this.modal = response.data;
        this.loading = false;
      });
    },
    imporModal() {
      this.pemicuImpor = true;
    },
  },
};
</script>
