<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="600"
    persistent
  >
    <v-card dense tile>
      <v-toolbar dense color="pink" dark>
        <v-toolbar-title>Rekap Tonase</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="tutup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        Tonase:{{ tonase.tonase | angka }} | Cair:
        {{ tonase.cair | rupiah }}
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["pemicu", "id"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  mounted() {},
  data() {
    return {
      data: [],
      tonase: {},
      loading: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    inisialisasi() {
      let uri = `/deo/${this.id}/tonase`;
      axios.get(uri).then((response) => {
        this.data = response.data;
        this.tonase = this.data.data;
        console.log(this.data);
      });
    },
    simpan() {},
    kondisikan() {},
  },
};
</script>
