<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Edit Harga</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <span class="subtitle-1">Harga Awal :{{ harga | rupiah }}</span>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Tambah Harga"
                required
                v-model="th"
                dense
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12"
              ><span class="subtitle-1"
                >Harga Final:{{
                  (parseFloat(harga) + parseFloat(th)) | rupiah
                }}</span
              ></v-col
            >
            <v-btn color="blue" dark type="submit">Simpan</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["pemicu", "harga"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      th: 0,
    };
  },
  methods: {
    inisialisasi() {},
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      this.$emit("update", this.th);
      this.tutup();
    },
  },
};
</script>
