<template>
	<v-card>
		<v-card-title>Golongan</v-card-title>
		<v-card-subtitle
			><v-row
				><v-text-field
					class="mx-6"
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
					dense></v-text-field
				><v-spacer></v-spacer
				><v-btn fab color="blue" dark @click="baru()"
					><v-icon>mdi-plus</v-icon></v-btn
				></v-row
			></v-card-subtitle
		>
		<v-card-text>
			<v-data-table
				:headers="headers"
				:items="data"
				:search="search"
				:items-per-page="100"
				dense
				mobile-breakpoint="300">
				<template v-slot:item.opsi="{ item }">
					<v-btn
						class="mx-2"
						icon
						x-small
						color="green"
						@click.stop="edit(item)">
						<v-icon> mdi-pencil </v-icon>
					</v-btn>
					<v-btn
						class="mx-2"
						fab
						icon
						x-small
						color="red"
						@click.stop="hapus(item)"
						:disabled="loading">
						<v-icon> mdi-trash-can </v-icon>
					</v-btn></template
				>
			</v-data-table>
		</v-card-text>
		<inputs
			@tutup="pemicu = false"
			:pemicu="pemicu"
			:id="idGolongan"
			@simpan="inisialisasi()"></inputs>
	</v-card>
</template>
<script>
	import axios from "axios";
	import inputs from "./input.vue";
	export default {
		components: { inputs },
		mounted() {
			this.inisialisasi();
		},
		computed: {},
		data() {
			return {
				search: "",
				headers: [
					{ text: "ID", value: "ID" },
					{
						text: "golongan",
						value: "golongan",
					},
					{
						text: "opsi",
						value: "opsi",
					},
				],
				data: [],
				loading: false,
				pemicu: false,
				idGolongan: 0,
			};
		},
		methods: {
			edit(item) {
				this.idGolongan = item.ID;
				this.pemicu = true;
			},
			baru() {
				this.idGolongan = 0;
				this.pemicu = true;
			},
			hapus() {},
			inisialisasi() {
				this.loading = true;
				let uri = "/golongan";
				axios
					.get(uri)
					.then((res) => {
						console.log(res.data);
						this.data = res.data;
					})
					.finally((this.loading = false));
			},
		},
	};
</script>
