var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.terpilih.length > 0)?_c('div',{staticClass:"my-1"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"green","x-small":"","dark":""},on:{"click":function($event){_vm.pemicuToke = true}}},[_vm._v("Ganti Toke")])],1):_vm._e(),_c('v-text-field',{staticClass:"mx-3 mb-3",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"mx-2 mx-sm-10",attrs:{"headers":_vm.headers,"items":_vm.pinjaman,"search":_vm.search,"items-per-page":10,"page":_vm.lastPage,"show-select":"","dense":"","mobile-breakpoint":"300","loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      prevIcon: 'mdi-arrow-left-drop-circle-outline',
      nextIcon: 'mdi-arrow-right-drop-circle-outline',
    }},scopedSlots:_vm._u([{key:"item.tanggal",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.tanggal)))])]}},{key:"item.pinjam",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("rupiah")(item.pinjam)))])]}},{key:"item.angsur",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("rupiah")(item.angsur)))])]}},{key:"item.saldo",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("rupiah")(item.saldo)))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mx-1",attrs:{"icon":"","x-small":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.edit(item)}}},[_c('v-icon',[_vm._v(" mdi-account-edit ")])],1)]}}]),model:{value:(_vm.terpilih),callback:function ($$v) {_vm.terpilih=$$v},expression:"terpilih"}}),_c('pinjamanedit',{attrs:{"dialogPinjaman":_vm.dialogPinjaman,"id":_vm.idNya,"toke":_vm.toke},on:{"tutup":_vm.tutup,"update":_vm.reload}}),_c('split',{attrs:{"dialogSplit":_vm.dialogSplit,"id":_vm.idNya,"toke":_vm.toke},on:{"tutup":_vm.tutup,"update":_vm.reload}}),_c('ganti-toke',{attrs:{"pemicu":_vm.pemicuToke,"toke":_vm.toke},on:{"tutup":function($event){_vm.pamicuToke = false},"aksi":_vm.gantiToke}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }