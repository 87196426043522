<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="transaksiDialog"
    width="400"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Transaksi Baru</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="tanggalHitung"
                    clearable
                    label="Tanggal"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="transaksi.tanggal"
                  @change="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-btn
              color="blue"
              dark
              type="submit"
              :loading="loading"
              :disabled="loading"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import axios from "axios";
export default {
  props: ["transaksiDialog", "kasir", "tanggal"],
  computed: {
    dialog: {
      get() {
        return this.transaksiDialog;
      },
      set() {
        this.$emit("tutup");
      },
    },
    tanggalHitung() {
      return this.transaksi
        ? moment(this.transaksi.tanggal).format("DD/MM/YYYY")
        : "";
    },
  },
  watch: {
    transaksiDialog() {
      this.kondisikan();
    },
  },
  mounted() {
    document.title = "Transaksi - SMJApp";
  },
  data() {
    return {
      transaksi: { tanggal: "", akun: 0, saldo_fakta: 0 },
      menu1: false,
      valid: false,
      loading: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid) {
        this.loading = true;
        this.simpan();
        let uri = "/transaksi/create";
        console.log(this.transaksi);
        axios
          .post(uri, this.transaksi)
          .then((response) => {
            if (response.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.$emit("lanjutkan", response.data.data);
              this.$emit("simpan");
              this.tutup();
              this.loading = false;
            } else {
              console.log(response.data);
              this.$noty.success(response.data.pesan);
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    simpan() {},
    kondisikan() {
      this.transaksi.akun = this.kasir;
      let tgl = moment(this.tanggal.tanggal.toString()).add(1, "d");
      this.transaksi.tanggal = tgl.format("YYYY-MM-DD");
    },
  },
};
</script>
