v-data-table
<template>
  <div>
    <div v-if="notaAktif">
      <isinota
        @tutup="
          pemicuIsiNota = false;
          notaAktif = false;
        "
        :dialog="pemicuIsiNota"
        :idNota="idNota"
        :toke="toke"
        :deo="deo"
        :pabrik="pabrik"
        @reload="refreshData"
        :loading="loading"
        :cekReload="cekReload"
        :transaksi="transaksi"
        @reloadIsi="bukaNotaBaru"
      ></isinota>
    </div>
    <div v-if="posisi == 'home'">
      <v-card dense class="overflow-auto" :loading="loading"
        ><v-toolbar dense color="blue" dark elevation="-2">
          <v-btn icon large @click="tutup()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title dense class="text-uppercase">
            <v-icon>mdi-folder</v-icon> {{ folder.keterangan }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="ml-1" small @click="edit" :disabled="loading">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon class="ml-4" small @click="hapus" :disabled="loading">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-actions>
          <v-row dense no-gutters class="elevation-2 rounded-lg my-3">
            <v-col cols="12">
              <v-toolbar
                dense
                flat
                color="blue darken-2"
                class="elevation-3"
                dark
              >
                <v-toolbar-title>Pinjaman/Nota</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  dense
                  label="Cari"
                  class="mx-2"
                ></v-text-field>
                <v-spacer></v-spacer>
                <span>{{ folder.total | rupiah }}</span>
              </v-toolbar>
            </v-col>
            <v-col cols="12" class="my-auto grey lighten-1">
              <v-btn
                color="blue"
                class="ma-1"
                @click="
                  idNota = 0;
                  pemicuPinjaman = true;
                "
                :disabled="loading"
                x-small
                dark
                ><v-icon>mdi-plus</v-icon>Pinjaman</v-btn
              >
              <v-btn
                color="pink"
                class="ma-1"
                @click="
                  idNota = 0;
                  pemicuPencairan = true;
                "
                :disabled="loading"
                x-small
                dark
                ><v-icon>mdi-plus</v-icon>Pencairan</v-btn
              >
              <v-btn
                color="blue"
                class="ma-1"
                @click="pemicuNota = true"
                :disabled="loading"
                x-small
                dark
              >
                <v-icon>mdi-plus</v-icon> Nota
              </v-btn>
              <v-btn
                color="blue"
                class="ma-1"
                @click="pemicuTagihan = true"
                :disabled="loading"
                dark
                x-small
              >
                <v-icon>mdi-plus</v-icon> Bayar Tagihan
              </v-btn>
              <v-btn
                color="blue"
                class="ma-1"
                @click="
                  idCair = folder.id_cair;

                  pemicuLihat = true;
                "
                :disabled="loading"
                dark
                x-small
              >
                <v-icon>mdi-magnify</v-icon> Tagihan
              </v-btn>
              <v-btn
                color="orange"
                class="ma-1"
                x-small
                dark
                @click="pemicuPigas = true"
                :loading="loadingGantiToke"
                >Pigas</v-btn
              >
              <v-btn
                color="orange"
                class="ma-1"
                x-small
                dark
                @click="pemicuModal = true"
                >Transfer</v-btn
              >
            </v-col></v-row
          >
        </v-card-actions>

        <v-card-text v-show="table === false">
          <v-row>
            <v-col
              v-for="item in notaFilter"
              :key="item.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card
                elevation="3"
                @click="bukaNota(item)"
                v-if="item.jenis == 2"
              >
                <v-card-subtitle class="grey lighten-2" v-if="item.toke"
                  ><v-icon>mdi-account</v-icon> {{ item.toke.nama }}
                </v-card-subtitle>
                <v-card-text>
                  <b class="text-uppercase">{{ item.keterangan }}</b>
                  <br /><b class="blue--text">{{ item.total | rupiah }}</b>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-show="table">
          <v-simple-table dense class="mx-n1 mx-sm-4 mx-md-12 px-md-6">
            <template v-slot:default>
              <thead>
                <tr>
                  <td></td>
                  <td>jenis</td>
                  <td>Toke</td>
                  <td>Keterangan</td>
                  <td>Pinjam</td>
                  <td>Bayar</td>
                  <td>Tool</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in notaFilter"
                  :key="item.ID"
                  :class="classTabel(item)"
                >
                  <td>
                    <v-checkbox
                      v-model="terpilih"
                      dense
                      :value="item.ID"
                      class="mb-n6 mt-n1"
                      @click="diklik(item.ID)"
                    ></v-checkbox>
                  </td>
                  <td v-if="buka[item.jenis].jenis == 'nota'">
                    <v-icon>{{ buka[item.jenis].icon }}</v-icon>
                  </td>
                  <td v-if="buka[item.jenis].jenis == 'pinjaman'">
                    {{ item.jenis }}
                  </td>
                  <td>
                    {{ item.toke ? item.toke.nama : "Tagihan" }}
                  </td>
                  <td>
                    {{ item.keterangan }}
                  </td>
                  <td>
                    {{ item.pinjam | rupiah }}
                  </td>
                  <td>
                    {{ (item.angsur ? item.angsur : 0) | rupiah }}
                  </td>
                  <td>
                    <v-btn
                      class="mx-2"
                      icon
                      x-small
                      color="green"
                      @click.stop="bukaBuka(item)"
                      ><v-icon> mdi-pencil </v-icon></v-btn
                    >
                    <v-btn
                      class="mx-2"
                      icon
                      x-small
                      color="red"
                      @click.stop="hapusNota(item)"
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{ totPinjam | rupiah }}</td>
                  <td>{{ totAngsur | rupiah }}</td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <isiTagihan
          @tutup="pemicuNotaTagihan = false"
          :pemicu="pemicuNotaTagihan"
          :id="idNota"
          @reload="refreshData"
          @reloadIsi="bukaNotaBaru"
        ></isiTagihan>
        <folder
          :pemicu="pemicuFolder"
          :transaksi="transaksi"
          @tutup="pemicuFolder = false"
          @bukaNota="updateNota"
          :isEdit="true"
          :dataNota="dataEdit"
        ></folder>
        <newnota
          :notaDialog="pemicuNota"
          :transaksi="transaksi"
          :isEdit="false"
          :subFolder="true"
          :idFolder="idFolder"
          @tutup="pemicuNota = false"
          @bukaNota="bukaNotaBaru"
        >
        </newnota>
        <newTagihan
          :pemicu="pemicuTagihan"
          :id="0"
          :transaksi="transaksi"
          :idFolder="idFolder"
          @tutup="pemicuTagihan = false"
          @bukaNota="bukaNotaTagihanBaru"
        >
        </newTagihan>
        <pinjamanMini
          :pemicu="pemicuPinjaman"
          :id="idNota"
          :nota="folder"
          @tutup="pemicuPinjaman = false"
          @simpan="refreshData"
        >
        </pinjamanMini>
        <pigas
          :pemicu="pemicuPigas"
          @tutup="pemicuPigas = false"
          :idNota="folder ? folder.ID : 0"
          @simpan="reload"
        ></pigas>
        <transfer
          :pemicu="pemicuLihat"
          :id="idCair"
          :idNota="0"
          :folder="idFolder"
          @tutup="pemicuLihat = false"
          @simpan="refreshData"
        >
        </transfer>
        <pencairan
          :pemicu="pemicuPencairan"
          @tutup="pemicuPencairan = false"
          @update="refreshData"
          :idFolder="idFolder"
          :id="idNota"
          :transaksi="transaksi"
        ></pencairan>
        <modal
          :pemicu="pemicuModal"
          @tutup="pemicuModal = false"
          @reload="refreshData"
          :tanggal="folder.tanggal"
          :akun="folder.id_admin"
          :id_cair="folder.id_cair"
          :id_folder="folder.ID"
        ></modal>
      </v-card>
    </div>
  </div>
</template>
<script>
import folder from "./input/folder.vue";
import newnota from "./input/notaBaru.vue";
import isinota from "./isiNota.vue";
import newTagihan from "./input/bayar/nama.vue";
import isiTagihan from "./input/bayar/bayarTagihan.vue";
import pinjamanMini from "./input/bayar/pinjamanMini.vue";
import transfer from "./input/transfer.vue";
import pigas from "./input/bayar/bayarPigas.vue";
import pencairan from "./input/pencairan.vue";
import modal from "./imporModal/bayarTagihan.vue";
import axios from "axios";
export default {
  components: {
    folder,
    newnota,
    isiTagihan,
    isinota,
    newTagihan,
    pinjamanMini,
    transfer,
    pigas,
    pencairan,
    modal,
  },
  props: {
    dialog: Boolean,
    idFolder: {
      type: Number,
      default: 1,
    },
    cekReload: Boolean,
    transaksi: Object,
  },
  watch: {
    cekReload() {
      console.log("cek reload:");
      console.log(this.cekReload);
      if (this.dialog && this.cekReload) {
        this.inisialisasi();
      }
    },
    dialog() {
      if (this.dialog === true && this.cekReload) {
        this.inisialisasi();
      }
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "", value: "to" },
        { text: "Toke", value: "toke.nama" },
        { text: "Keterangan", value: "keterangan" },
        { text: "Pinjam", value: "pinjam" },
        { text: "Angsur", value: "angsur" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      terpilih: [],
      pemicuFolder: false,
      nota: [],
      pemicuPrint: false,
      dataEdit: {},
      resetNota: false,
      total: 0,
      toke: [],
      idToke: 0,
      pemicuNota: false,
      pemicuIsiNota: false,
      idNota: 0,
      deo: [],
      pabrik: [],
      folder: [],
      search: "",
      table: true,
      pemicuTagihan: false,
      pemicuNotaTagihan: false,
      pemicuModal: false,
      buka: {
        0: {
          fungsi: () => {
            this.bukaNota();
          },
          icon: "mdi-note-outline",
          jenis: "nota",
          uriHapus: `/nota/`,
          warna: "blue lighten-4",
        },
        2: {
          fungsi: () => {
            this.bukaNota();
          },
          icon: "mdi-note-outline",
          jenis: "nota",
          uriHapus: `/nota/`,
          warna: "yellow lighten-4",
        },
        3: {
          fungsi: () => {
            this.bukaPencairan();
          },
          icon: "mdi-note-outline",
          jenis: "nota",
          uriHapus: `/nota/`,
          warna: "blue lighten-4",
        },
        4: {
          fungsi: () => {
            this.bukaNotaTagihan();
          },
          icon: "mdi-note-outline",
          jenis: "nota",
          uriHapus: `/nota/`,
          warna: "blue lighten-4",
        },
        PS: {
          fungsi: () => {
            this.bukaPinjaman();
          },
          icon: "mdi-folder",
          jenis: "pinjaman",
          uriHapus: `/pinjaman/`,
          warna: "",
        },
        PT: {
          fungsi: () => {
            this.bukaPinjaman();
          },
          icon: "mdi-folder",
          jenis: "pinjaman",
          uriHapus: `/pinjaman/`,
          warna: "",
        },
      },
      loadingKolom: false,
      loadingJenis: false,
      loadingGantiToke: false,
      pemicuPinjaman: false,
      pemicuLihat: false,
      idCair: 0,
      totPinjam: 0,
      totAngsur: 0,
      pemicuPigas: false,
      posisi: "home",
      notaAktif: false,
      pemicuPencairan: false,
    };
  },
  computed: {
    dialogD: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("tutup");
      },
    },
    notaFilter() {
      return this.nota.filter((item) => {
        let toke = item.toke ? item.toke.nama.toLowerCase() : "";
        let keterangan =
          item.keterangan === null ? "" : item.keterangan.toLowerCase();
        let total = item.total ? item.total.toString() : "0";
        console.log("proses cari");
        return (
          toke.indexOf(this.search.toLowerCase()) > -1 ||
          keterangan.indexOf(this.search.toLowerCase()) > -1 ||
          total.indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  mounted() {
    this.inisialisasi();
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let uri = `/nota/${this.idFolder}/folder`;
      axios
        .get(uri)
        .then((response) => {
          let nota = response.data.isi.map((x) => {
            if (x.total < 0) {
              x.pinjam = 0;
              x.angsur = parseFloat(x.total) * -1;
            } else {
              x.pinjam = x.total;
              x.angsur = 0;
            }
            return x;
          });
          this.folder = response.data.nota;
          let pinjaman = response.data.pinjaman.map((x) => {
            x.keterangan = x.uraian;
            x.total = x.hitung;
            return x;
          });
          pinjaman.forEach((x) => {
            nota.push(x);
          });
          this.nota = nota;
          this.folder.total = this.nota.reduce((p, i) => {
            p += parseFloat(i.total);
            return p;
          }, 0);
          this.isiCeklis();
          this.totPinjam = this.nota.reduce((p, i) => {
            p += parseFloat(i.pinjam);
            return p;
          }, 0);
          this.totAngsur = this.nota.reduce((p, i) => {
            p += parseFloat(i.angsur);
            return p;
          }, 0);
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
      console.log(this.nota);
    },
    hapus() {
      if (confirm("apa kamu yakin mau menghapus?")) {
        let uri = `/nota/${this.idFolder}`;
        console.log(uri);
        axios
          .delete(uri)
          .then((response) => {
            this.refreshData();
            this.$emit("tutup");
            console.log(response);
            this.$noty.success(response.data);
          })
          .catch((e) => {
            this.$noty.error(e.message);
          });
      } else {
        // Do nothing!
        console.log("Thing was not saved to the database.");
      }
    },
    tutup() {
      this.$emit("tutup", 0);
      this.resetNota = !this.resetNota;
    },

    reload() {
      this.$emit("reload", this.idFolder);
    },
    print() {
      this.pemicuPrint = true;
    },
    updateNota(item) {
      console.log("emit dari nota");
      this.folder.keterangan = item.keterangan;
      this.reload();
    },
    edit() {
      this.dataEdit = {
        ID: this.folder.ID,
        id_admin: this.folder.id_admin,
        id_cair: this.folder.id_cair,
        keterangan: this.folder.keterangan,
      };
      this.pemicuFolder = true;
    },
    bukaNotaBaru(item) {
      this.refreshData(item);
      this.bukaBuka(item);
    },
    bukaNota() {
      this.notaAktif = true;
      this.pemicuIsiNota = true;
    },
    bukaBuka(item) {
      this.idNota = item.ID;
      let jenis = item.jenis;
      this.buka[jenis].fungsi();
    },
    bukaPencairan() {
      this.pemicuPencairan = true;
    },
    notaBaru() {
      this.pemicuNota = true;
    },
    refreshData(item) {
      this.$emit("reload", item);
      this.inisialisasi();
    },
    bukaNotaTagihanBaru(item) {
      this.bukaBuka(item);
      this.refreshData();
    },
    bukaNotaTagihan() {
      this.pemicuNotaTagihan = true;
    },
    bukaPinjaman() {
      this.pemicuPinjaman = true;
    },
    hapusNota(item) {
      console.log(`hapus item ${item.ID}`);
      if (confirm("apa kamu yakin mau menghapus?")) {
        let uri = this.buka[item.jenis].uriHapus + item.ID;
        console.log(uri);
        axios
          .delete(uri)
          .then((response) => {
            this.refreshData();
            console.log(response);
            this.$noty.success(response.data);
          })
          .catch((e) => {
            this.$noty.error(e.message);
          });
      } else {
        // Do nothing!
        console.log("Thing was not saved to the database.");
      }
    },
    isiCeklis() {
      let uri = `/ceklis/${this.folder.ID}/folder`;
      axios.get(uri).then((r) => {
        this.terpilih = JSON.parse(r.data);
      });
    },
    simpanCeklis() {
      console.log("proses simpan ceklis");
      let data = {
        id_akun: this.folder.ID,
        jenis: "folder",
        array: this.terpilih,
      };
      let uri = `/ceklis`;
      axios
        .post(uri, data)
        .then((r) => {
          console.log(r.data);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    diklik() {
      this.simpanCeklis();
      console.log("ceklis di simpan");
    },
    classTabel(item) {
      return this.buka[item.jenis].warna;
    },
    selectAll() {
      console.log("select all");
    },
    select(item) {
      console.log(item);
    },
  },
};
</script>
