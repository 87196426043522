<template>
  <div>
    <label class="ma-3" for="in">{{ label }}</label>
    <input
      class="pa-2"
      type="text"
      id="in"
      @input="$emit('update:modelValue', $event.target.value)"
      :value="modelValue"
    />
  </div>
</template>
<script>
export default {
  props: ["modelValue", "label"],
  emits: ["update:modelValue"],
  mounted() {},
  computed: {},
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    handleInput() {
      this.$emit("input", this.content);
    },
    cek() {
      console.log(this.label);
    },
  },
};
</script>
