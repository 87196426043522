<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Cek harga</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="dataTransaksi.pabrik"
                auto-select-first
                autofocus
                item-text="pabrik"
                item-value="id"
                :items="pabrik"
                label="Pabrik"
                ref="pabrik"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-menu
                v-model="pupup"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="ubahTanggal"
                    clearable
                    label="Tanggal"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dataTransaksi.tanggal"
                  @change="pupup = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12"
              ><v-row align="center" justify="center"
                ><div v-if="harga" class="mx-20 text-h2">
                  {{ harga }}
                </div></v-row
              ></v-col
            >
            <v-btn color="blue" dark type="submit" :disabled="loadingSimpan"
              >Cek Harga</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
          <v-row> </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import axios from "axios";
export default {
  props: ["pemicu", "pabrik"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    ubahTanggal() {
      return this.dataTransaksi
        ? moment(this.dataTransaksi.tanggal).format("DD/MM/YYYY")
        : "";
    },
  },
  watch: {
    pemicu() {
      this.kondisikan();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      dataTransaksi: {
        tanggal: "",
        pabrik: 0,
        harga: 0,
      },
      harga: 0,
      pupup: false,
      loading: false,
      loadingSimpan: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid) {
        let data = {};
        let uri = `/harga/${this.dataTransaksi.pabrik}/${this.dataTransaksi.tanggal}`;
        console.log(uri);
        axios
          .get(uri)
          .then((res) => {
            data = res.data;
            this.harga = data.harga;
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message);
            this.harga = 0;
          });
      }
    },
    inisialisasi() {},
    simpan() {},
    kondisikan() {
      this.dataTransaksi.tanggal = moment().format("YYYY-MM-DD");
      this.harga = 0;
    },
  },
};
</script>
