<template>
  <div>
    <v-card :loading="loading">
      <v-row
        ><v-col cols="1"
          ><v-btn @click="tutup" color="red" dark
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-col
        ><v-spacer></v-spacer
        ><v-col cols="1"
          ><download-excel
            :data="dataExcel"
            :fields="fieldExcell"
            worksheet="My Worksheet"
            :name="filename"
          >
            <v-btn dark color="blue"
              ><v-icon>mdi-table-large</v-icon></v-btn
            ></download-excel
          ></v-col
        >
        <!-- <v-col cols="1" class="mx-2">
        <v-btn @click="print" color="green" dark
          ><v-icon>mdi-printer</v-icon></v-btn
        ></v-col
      > -->
      </v-row>
      <!-- <v-card id="diprint">
      <v-card-title>
        <table>
          <tbody>
            <tr>
              <td>Nama Toke</td>
              <td>: {{ toke.nama }}</td>
            </tr>
            <tr>
              <td>Rentang Tanggal</td>
              <td>
                : {{ tanggalFilter[0] | formatDate }} sampai
                {{ tanggalFilter[1] | formatDate }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="pinjaman"
        dense
        :items-per-page="10000"
        mobile-breakpoint="300"
        :loading="loading"
        hide-default-footer
      >
        <template v-slot:item.tanggal="{ item }">
          <span>{{ item.tanggal | formatDate }}</span>
        </template>
        <template v-slot:item.pinjam="{ item }">
          <span>{{ item.pinjam | rupiah }}</span>
        </template>
        <template v-slot:item.angsur="{ item }">
          <span>{{ item.angsur | rupiah }}</span>
        </template>
        <template v-slot:item.saldo="{ item }">
          <span>{{ item.saldo | rupiah }}</span>
        </template>
      </v-data-table>
    </v-card> -->
    </v-card>
  </div>
</template>

<script>
// import printJS from "print-js";
import axios from "axios";
import moment from "moment";
export default {
  props: ["id", "tanggal"],
  components: {},
  data() {
    return {
      pinjaman: [],
      headers: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggal",
        },
        { text: "Uraian", value: "uraian" },
        { text: "Pinjam", value: "pinjam" },
        { text: "Bayar", value: "angsur" },
        { text: "Saldo", value: "saldo" },
        { text: "Kasir", value: "akun" },
      ],
      dataExcel: [],
      fieldExcell: {
        A: "a",
        B: "b",
        C: "c",
        D: "d",
        E: "e",
        F: "f",
        G: "g",
        H: "h",
        I: "i",
        J: "j",
        K: "k",
        L: "l",
      },
      loading: false,
    };
  },
  watch: {},

  computed: {
    lastPage() {
      return Math.ceil(this.pinjaman.length / 10);
    },
    filename() {
      return `RekapRamp-${this.id} ${moment().format("DD-MM-YY hh")}`;
    },
  },
  mounted() {
    this.inisialisasi();
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let data = [];
      const uri = `/ramp/print/${this.id}/${this.tanggal[0]}/${this.tanggal[1]}`;
      console.log(uri);
      axios
        .get(uri)
        .then((res) => {
          console.log(res.data);
          data.push({ a: "pinjaman", b: 0 });
          data.push({ a: "tonase", b: 0 });
          data.push({ a: "modal", b: 0 });
          if (res.data.nota.length > 0) {
            res.data.nota.forEach((item) => {
              if (item.pinjaman.length > 0) {
                item.pinjaman.forEach((x) => {
                  const row = {};
                  row.a = "pinjaman";
                  row.b = x.ID;
                  row.c = x.toke.nama;
                  row.d = x.tanggal;
                  row.e = x.uraian;
                  row.f = x.pinjam;
                  row.g = x.angsur;
                  data.push(row);
                });
              }
              const uraian = item.keterangan;
              if (item.tonase.length > 0) {
                item.tonase.forEach((y) => {
                  const row = {};
                  row.a = "tonase";
                  row.b = y.id;
                  row.c = y.tanggaldo;
                  row.d = y.do.pabrik.pabrik;
                  row.e = y["nama supir"];
                  row.f = parseInt(y.tonase);
                  row.g = parseInt(y.harga);
                  row.h = parseInt(y.th);
                  row.i = uraian;
                  data.push(row);
                });
              }
              if (item.ramps.length > 0) {
                item.ramps.forEach((z) => {
                  const row = {};
                  row.a = "ramp";
                  row.b = z.id;
                  row.c = z.tanggal;
                  row.d = z.nama;
                  row.e = z.netto;
                  row.f = z.harga;
                  data.push(row);
                });
              }
            });
          }
          if (res.data.modal.length > 0) {
            res.data.modal.forEach((x) => {
              const row = {};
              row.a = "modal";
              row.b = x.ID;
              row.c = x.toke.nama;
              row.d = x.tanggal;
              row.e = x.uraian;
              row.f = x.pinjam;
              row.g = x.angsur;
              data.push(row);
            });
          }
          console.log(data);
          this.dataExcel = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    print() {
      this.$htmlToPaper("diprint", {
        styles: [
          "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
          "/aplikasi/riz/app.css",
        ],
      });
    },
    tutup() {
      this.$emit("tutup");
    },
    reload() {},
  },
};
</script>
