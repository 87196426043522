var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","persistent":"","width":"550"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"dense":"","tile":""}},[_c('v-card-title'),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"color":"grey"},on:{"submit":function($event){$event.preventDefault();return _vm.simpan()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.data.akun)?_c('v-autocomplete',{ref:"toke",attrs:{"auto-select-first":"","items":_vm.akuns,"item-text":"nama","item-value":"id","label":"Akun","rules":[(v) => !!v || 'Item is required'],"required":""},model:{value:(_vm.data.akun.id),callback:function ($$v) {_vm.$set(_vm.data.akun, "id", $$v)},expression:"data.akun.id"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Keterangan","required":""},model:{value:(_vm.data.uraian),callback:function ($$v) {_vm.$set(_vm.data, "uraian", $$v)},expression:"data.uraian"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Masuk","required":"","rules":[
                (v) => v <= 100000000000 || 'Terlalu besar',
                (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
              ],"type":"number"},model:{value:(_vm.data.masuk),callback:function ($$v) {_vm.$set(_vm.data, "masuk", $$v)},expression:"data.masuk"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Keluar","required":"","rules":[
                (v) => v <= 100000000000 || 'Terlalu besar',
                (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
              ],"type":"number"},model:{value:(_vm.data.keluar),callback:function ($$v) {_vm.$set(_vm.data, "keluar", $$v)},expression:"data.keluar"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.tutup()}}},[_vm._v("Batal")]),_c('v-btn',{attrs:{"color":"blue","dark":"","loading":_vm.loadingBtn,"disabled":_vm.loadingBtn},on:{"click":function($event){return _vm.kirim()}}},[_vm._v("Simpan")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }