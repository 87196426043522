<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="300"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Toke</v-card-title>
      <v-card-text>
        <div>
          <table>
            <tbody>
              <tr>
                <td>ID</td>
                <td>{{ data.ID }}</td>
              </tr>
              <tr>
                <td>Nama</td>
                <td>{{ data.nama }}</td>
              </tr>
              <tr>
                <td>Aktif</td>
                <td>{{ data.aktif }}</td>
              </tr>
              <tr>
                <td>Golongan</td>
                <td>
                  {{ data.golongan }} -
                  {{ data.gol ? data.gol.golongan || "" : "" }}
                </td>
              </tr>
              <tr>
                <td>Regional</td>
                <td>
                  {{ data.reg }}-
                  {{ data.regional ? data.regional.regional : "" }}
                </td>
              </tr>
              <tr>
                <td>Jenis modal</td>
                <td>{{ data.jenis_modal }}</td>
              </tr>
              <tr>
                <td>pinjaman macet</td>
                <td>{{ data.pinjaman_macet }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-4">
          <v-btn color="red" dark @click="tutup()">Tutup</v-btn>
        </div>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["pemicu", "id"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      data: {},
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    inisialisasi() {
      if (this.id > 0) {
        let uri = `/toke/${this.id}`;
        axios.get(uri).then((response) => {
          this.data = response.data;
        });
      } else {
        console.log(this.data);
      }
    },
  },
};
</script>
