<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="350"
    persistent
  >
    <v-card>
      <v-tabs>
        <v-tab>
          <v-icon left> mdi-account </v-icon>
          Bulan
        </v-tab>
        <v-tab>
          <v-icon left> mdi-lock </v-icon>
          Tanggal
        </v-tab>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-date-picker v-model="date" type="month" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$emit('tutup')">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="simpanBln"> OK </v-btn>
              </v-date-picker>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-date-picker v-model="dates" range>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$emit('tutup')">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="simpanTgl">
                  OK
                </v-btn></v-date-picker
              >
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card></v-dialog
  >
</template>
<script>
import moment from "moment";
// import axios from "axios";
export default {
  props: ["pemicu"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {},
  },
  mounted() {},
  data() {
    return {
      dataTgl: {
        jenis: 1,
        data: [moment().format("DD"), moment().format("YYYY")],
      },
      date: moment().format("YYYY-MM"),
      dates: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      tertulis: moment().format("MMMM-YYYY"),
    };
  },
  methods: {
    simpanBln() {
      this.dataTgl.jenis = 1;
      let buln = this.date.split("-");
      this.dataTgl.data = [buln[1], buln[0]];
      this.dataTgl.tertulis = moment(this.date).format("MMMM-YYYY");
      this.$emit("update", this.dataTgl);
    },
    simpanTgl() {
      this.dataTgl.jenis = 2;
      this.dataTgl.data = this.dates;
      this.dataTgl.tertulis = `${moment(this.dates[0]).format(
        "DD-MM-YYYY"
      )} - ${moment(this.dates[1]).format("DD-MM-YYYY")}`;
      this.$emit("update", this.dataTgl);
    },
  },
};
</script>
