<template>
  <v-container>
    <v-row :class="$vuetify.breakpoint.smAndUp ? 'ma-2' : ''">
      <v-col cols="12">
        <v-card color="grey lighten-3" :loading="loading"
          ><v-toolbar color="blue darken-3" dense dark>
            <v-toolbar-title>{{ akun.pabrik }} </v-toolbar-title>
            <v-spacer></v-spacer
            ><v-btn
              color="red"
              icon
              x-large
              @click="$router.push('/transaksipabrik')"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-row class="ma-1">
            <v-col cols="12">
              <v-row class="pa-2">
                <v-radio-group v-model="filter" row class="mt-0">
                  <v-radio label="Terbaru" value="terbaru"></v-radio>
                  <v-radio label="Pilih Tanggal" value="rentang"></v-radio>
                </v-radio-group>
                <v-spacer></v-spacer>
              </v-row>
              <v-divider></v-divider>
              <v-row
                class="ma-2"
                v-if="filter == 'rentang'"
                dense
                color="white"
              >
                <v-col cols="12" sm="6">
                  <v-menu
                    v-model="popupA"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="getTanggalA"
                        prepend-icon="mdi-calendar"
                        clearable
                        label="Pilih Rentang"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="date = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="tanggalFilter"
                      @change="popupA = false"
                      range
                    ></v-date-picker> </v-menu
                ></v-col>
                <v-spacer></v-spacer>
                <v-col
                  ><v-btn color="blue" @click="filterRentang()" dark
                    >Tampilkan</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="1000"
            dense
            mobile-breakpoint="300"
            :loading="loading"
            hide-default-footer
          >
            <template v-slot:item.tanggal="{ item }">
              <span>{{ item.tanggal | formatDate }}</span>
            </template>
            <template v-slot:item.tanggalawal="{ item }">
              <span
                >{{ item.daritanggal | formatDate }}-{{
                  item.sampaitanggal | formatDate
                }}</span
              >
            </template>
            <template v-slot:item.cair="{ item }">
              <span :class="classEror(item)">{{ item.cair | rupiah }}</span>
            </template>
            <template v-slot:item.banding="{ item }">
              <span>{{ item.banding | angka }}</span>
            </template>
            <template v-slot:item.selisih="{ item }">
              <span>{{
                (parseInt(item.cair) - parseInt(item.banding)) | rupiah
              }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn class="mx-2" icon color="blue" x-small @click="buka(item)">
                <v-icon> mdi-pencil </v-icon> </v-btn
              ><v-btn
                class="mx-2"
                icon
                color="blue"
                x-small
                @click="bukaSatu(item)"
              >
                <v-icon> mdi-list-status </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-col>
    </v-row></v-container
  >
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  components: {},
  props: ["id"],
  data: () => ({
    data: [],
    loading: false,
    filter: "terbaru",
    akun: {},
    popupA: false,
    tanggalFilter: [
      moment().format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    ],
    headers: [
      { text: "Tanggal", value: "tanggal" },
      { text: "Rentang", value: "tanggalawal" },
      { text: "Pabrik", value: "pabrik.pabrik" },
      { text: "Cair", value: "cair" },
      { text: "Fakta", value: "banding" },
      { text: "Selisih", value: "selisih" },
      { text: "Actions", value: "actions" },
    ],
  }),
  watch: {
    filter() {
      if (this.filter == "terbaru") {
        this.inisialisasi();
      } else {
        console.log("tampilkan berdasarkan tanggal");
      }
    },
  },
  mounted() {
    this.inisialisasi();
  },
  computed: {
    getTanggalA() {
      return this.tanggalFilter.join(" sampai ");
    },
  },
  methods: {
    async inisialisasi() {
      this.loading = true;
      let url = "/pabrik/" + this.id;
      this.akun = await axios.get(url).then((response) => {
        return response.data;
      });
      if (this.filter == "terbaru") {
        url = `/tanggalbank/terbaru/${this.id}`;
      } else {
        url = `/tanggalbank/buka/${this.id}/${this.tanggalFilter[0]}/${this.tanggalFilter[1]}`;
      }
      console.log(url);
      let hasil = await axios.get(url).then((res) => {
        return res.data;
      });
      this.data = hasil;
      console.log(this.data);
      this.loading = false;
    },
    baru() {},
    filterRentang() {
      this.inisialisasi();
    },
    classEror(item) {
      let selisih = parseInt(item.cair) - parseInt(item.saldo);
      if (selisih > 10 || selisih < -10) {
        return "red";
      }
    },
  },
};
</script>
