<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="950"
    persistent
  >
    <v-card class="white">
      <v-card-title class="text-uppercase"
        >{{ pengantar.txPabrik }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12"> </v-col>
          <v-col cols="12">
            <v-form
              @submit.prevent="simpan()"
              color="grey"
              ref="form"
              v-model="valid"
            >
              <v-row>
                <v-col cols="12" sm="2" md="2">
                  <v-menu
                    v-model="pupup"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="ubahTanggal"
                        clearable
                        label="Tanggal"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="date = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="tonase.tanggaldo"
                      @change="pupup = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <v-text-field
                    label="Supir"
                    :rules="validText"
                    v-model="tonase.supir"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <v-text-field
                    label="Plat"
                    :rules="validText"
                    v-model="tonase.plat"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <v-text-field
                    label="Tonase"
                    required
                    :rules="[
                      (v) => v <= 1000000 || 'Terlalu besar',
                      (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                    ]"
                    v-model="tonase.tonase"
                    type="number"
                    >{{ tonase.tonase | rupiah }}</v-text-field
                  >
                </v-col>
                <v-col cols="6" md="1" sm="1">
                  <v-text-field
                    label="Th"
                    required
                    :rules="[
                      (v) => v <= 1000 || 'Terlalu besar',
                      (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                    ]"
                    v-model.number="tonase.th"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="1" sm="1">
                  <v-text-field
                    label="OPS"
                    required
                    :rules="[
                      (v) => v <= 10000000 || 'Terlalu besar',
                      (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                    ]"
                    v-model="tonase.ops"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col sm="1" md="1"
                  ><v-btn color="green" dark type="submit" small fab
                    ><v-icon>mdi-content-save-move</v-icon></v-btn
                  ></v-col
                ><v-col sm="1" md="1">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green"
                    dark
                    fab
                    small
                    @click.prevent="barisBaru()"
                  >
                    <v-icon>mdi-plus</v-icon></v-btn
                  ></v-col
                >
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="12">
                <div id="dif">
                  <v-simple-table dense ref="bodi" id="siji">
                    <template v-slot:default>
                      <!-- <table id="siji"> -->
                      <thead id="kepala">
                        <tr>
                          <th class="text-left">No</th>
                          <th class="text-left">Tanggal</th>
                          <th class="text-left">Supir</th>
                          <th class="text-left">Plat</th>
                          <th class="text-left">Tonase</th>
                          <th class="text-left">Harga</th>
                          <th class="text-left">Cair</th>
                          <th class="text-left">OPS</th>
                        </tr>
                      </thead>
                      <tbody id="badan">
                        <tr
                          v-for="item in tonaseTonase"
                          :key="item.nou"
                          :class="
                            selectedRows.indexOf(item.nou) > -1
                              ? 'primary  white--text font-weight-bold'
                              : ''
                          "
                          @click="rowClicked(item)"
                          :id="'goto' + item.nou"
                        >
                          <td>{{ item.nou }}</td>
                          <td>
                            {{ item.tanggaldo | formatDate }}
                          </td>
                          <td>{{ item.supir }}</td>
                          <td>{{ item.plat }}</td>
                          <td>{{ item.tonase }}</td>
                          <td>
                            {{
                              (parseFloat(item.harga) + parseFloat(item.th))
                                | angkaKoma
                            }}
                          </td>
                          <td>
                            {{
                              (item.tonase *
                                (parseFloat(item.harga) + parseFloat(item.th)))
                                | rupiah
                            }}
                          </td>
                          <td>{{ item.ops | rupiah }}</td>
                        </tr>
                        <tr
                          :class="
                            selectedRows == tonaseTonase.length + 1
                              ? 'primary  white--text font-weight-bold'
                              : ''
                          "
                        >
                          <td>*</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{{ totalTon | angka }}</td>
                          <td></td>
                          <td>{{ totalCair | rupiah }}</td>
                          <td></td>
                        </tr>
                      </tbody>
                      <!-- </table> -->
                    </template>
                  </v-simple-table>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5">
            <v-btn
              color="blue"
              dark
              @click="kirim()"
              :loading="loadingBtn"
              :disabled="loadingBtn"
              class="ma-1"
              >Simpan</v-btn
            >
            <v-btn color="red" dark @click="tutup()" class="ma-1"
              >Batal</v-btn
            > </v-col
          ><v-spacer></v-spacer
          ><v-col cols="4"
            ><v-btn x-small @click="sisipkan" class="ma-1"
              >Sisipkan Tonase</v-btn
            ><v-btn x-small @click="sisipkan2" class="ma-1"
              >Sisipkan Nama</v-btn
            ></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style >
#dif {
  height: 330px;
  overflow: scroll;
}
/* #badan td {
  font-size: 0.8rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin: 0px;
} */
</style>
<script>
import axios from "axios";
import moment from "moment";
export default {
  props: ["tonaseDialog", "nota", "pengantar"],
  computed: {
    dialog: {
      get() {
        return this.tonaseDialog;
      },
      set() {
        this.$emit("tutup");
        this.tonaseTonase = [];
      },
    },
    ubahTanggal() {
      return this.tonase
        ? moment(this.tonase.tanggaldo).format("DD/MM/YYYY")
        : "";
    },
  },
  watch: {
    tonaseDialog() {
      //   this.kosongkan();
      this.getToke();
    },
  },
  data() {
    return {
      tonase: {
        id: 0,
        tanggal: "",
        do: 0,
        toke: 0,
        supir: "",
        tonase: 0,
        th: 0.0,
        harga: 0.0,
        ops: 0,
        tanggaldo: "",
        tanggalbank: null,
        tanggalbankbareng: 0,
        id_gabung: 0,
        id_akun: 0,
        id_cair: 0,
        tglcairbesok: 0,
        cair: "cair",
        tandai: 0,
        tandaicb: 0,
        id_gabungcair: 0,
        id_harga: 0,
        ok: 0,
        plat: "",
        invoice: 0,
        id_inv: 0,
        cara: "normal",
        cek_nota: 0,
        relasi: 0,
        nou: 1,
      },
      pupup: false,
      search: "",
      tonaseTonase: [],
      selectedRows: [],
      valid: true,
      validNomer: [
        (v) => !isNaN(parseFloat(v)) || "Harus pakai angka",
        (v) => v <= 100000000000 || "gak boleh lebih dari 12 karakter",
      ],
      validText: [(v) => v.length <= 20 || "Gak boleh lebih dari 20 karakter"],
      loadingBtn: false,
      itemHeight: 35,
      totalTon: 0,
      totalCair: 0,
      loading: false,
      toke: [],
    };
  },
  methods: {
    getToke() {
      this.loading = true;
      let uri = "/tokeAktif";
      axios.get(uri).then((r) => {
        this.toke = r.data;
        this.kosongkan();
        this.tonase.nou = 1;
        this.loading = false;
      });
    },
    tutup() {
      this.$emit("tutup");
      this.tonaseTonase = [];
    },
    scrollToBottom() {
      let dip = document.getElementById("dif");
      let a = 1000 + parseInt(dip.scrollHeight);
      dip.scrollTop = a;
    },
    scrollToItem() {
      let dip = document.getElementById("dif");
      let a =
        (parseInt(this.selectedRows - 2) * parseInt(dip.scrollHeight)) /
        parseInt(this.tonaseTonase.length);
      dip.scrollTop = a;
    },
    simpan() {
      this.$refs.form.validate();
      if (this.valid) {
        this.kondisikanTonase();
        let copyan = Object.assign({}, this.tonase);
        const index = this.tonaseTonase.findIndex((e) => e.nou === copyan.nou);
        if (index === -1) {
          this.tonaseTonase.push(copyan);
          this.tonase.nou += 1;
          this.selectedRows = [this.tonase.nou];
          this.kosongkan();
          this.scrollToBottom();
        } else {
          this.tonaseTonase[index] = copyan;
          this.tonase.nou += 1;
          this.selectedRows = [this.tonase.nou];
          if (this.tonase.nou > this.tonaseTonase.length) {
            console.log("nou sekarang:" + this.tonase.nou);
            console.log("selected sekarang:" + this.selectedRows);
            console.log("dikosongkan karena baris baru");
            this.kosongkan();
          } else {
            this.isiObtonase(this.tonase.nou);
          }
          this.scrollToItem();
        }
        this.hitungTotal();
      }
    },
    hitungTotal() {
      this.totalTon = this.tonaseTonase.reduce((simpan, item) => {
        return (simpan += parseInt(item.tonase));
      }, 0);
      this.totalCair = this.tonaseTonase.reduce((simpan, item) => {
        return (simpan +=
          item.tonase * (parseFloat(item.harga) + parseFloat(item.th)));
      }, 0);
    },
    isiObtonase(nou) {
      //ini untuk mengisi form dengan nilai yg sudah ada
      let hasil = this.tonaseTonase.filter(function (item) {
        return item.nou == nou;
      });
      if (hasil.length >> 0) {
        this.tonase = Object.assign({}, hasil[0]);
        console.log(this.tonase);
      } else {
        this.kosongkan();
        console.log("dikosongkan");
      }
    },
    harga(tanggal) {
      let hasil = this.pengantar.harga.filter(function (item) {
        return moment(tanggal).isSameOrAfter(item.tanggal);
      });
      if (hasil.length >> 0) {
        return hasil[hasil.length - 1];
      } else {
        return 0;
      }
    },
    kondisikanTonase() {
      this.tonase.tonase = parseFloat(this.tonase.tonase);
      this.tonase.th = parseFloat(this.tonase.th);
      this.tonase.ops = parseInt(this.tonase.ops);
      this.tonase.tanggal = this.nota.tanggal;
      this.tonase.do = this.pengantar.do;
      this.tonase.toke = this.pengantar.toke;
      this.tonase.harga = this.harga(this.tonase.tanggaldo).harga;
      this.tonase.id_gabung = this.nota.ID;
      this.tonase.id_akun = this.nota.id_admin;
      this.tonase.id_cair = this.nota.id_cair;
      this.tonase.tglcairbesok = this.nota.id_cair;
      this.tonase.id_harga = this.harga(this.tonase.tanggaldo).id;
    },
    strToke(id) {
      const hasil = this.toke.findIndex((e) => e.ID === id);
      return this.toke[hasil].nama;
    },
    kosongkan() {
      this.tonase.tonase = 0;
      this.tonase.tanggaldo = this.nota.tanggal;
      this.tonase.supir = "";
      this.tonase.plat = "";
      this.tonase.th = 0.0;
      this.tonase.ops = 0;
    },
    barisBaru() {
      this.kosongkan();
      this.tonase.nou = this.tonaseTonase.length + 1;
      this.selectedRows = [this.tonase.nou];
      //   this.$refs.toke.focus();
      this.scrollToBottom();
    },
    rowClicked(item) {
      this.selectedRows = [item.nou];
      console.log("posisi selectedrow:" + this.selectedRows);
      this.isiObtonase(item.nou);
    },
    kirim() {
      this.loadingBtn = true;
      let uri = "/tonase/banyak";
      let tonaseTonase = this.tonaseTonase;
      axios
        .post(uri, tonaseTonase)
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            this.$noty.success("Data berhasil disimpan");
            this.tonaseTonase = [];
            this.dialog = false;
            this.$emit("reload");
            this.loadingBtn = false;
          }
        })
        .catch((error) => {
          this.$noty.error(
            "Terjadi Erorr, entah kenapa bisa error. silahkan coba lagi atau batalkan"
          );
          console.log(error);
          this.loadingBtn = false;
        });
    },
    sisip(kolom) {
      let arr = JSON.parse(JSON.stringify(this.tonaseTonase));
      for (let i = 1; i < arr.length; i++)
        if (i > this.selectedRows - 1) {
          this.tonaseTonase[i][kolom] = arr[i - 1][kolom] || 0;
        }
      this.$noty.success(
        "berhasil di sisipkan di kolom no: " + this.selectedRows
      );
    },
    sisipkan() {
      this.sisip("tonase");
    },
    sisipkan2() {
      this.sisip("supir");
    },
  },
};
</script>
