<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="300"
    persistent
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar dark color="primary">
          <v-spacer></v-spacer
          ><v-btn icon dark @click="dialog.value = false" color="red">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Akun</th>
                  <th>Print</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in tabel" :key="item.id">
                  <td>{{ item.Akun }}</td>
                  <td>
                    <v-btn x-small color="green" icon @click="print(item)"
                      ><v-icon>mdi-printer</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template></v-simple-table
          >
          <isiprint
            :data="tabelPrint"
            @tutup="pemicuPrint = false"
            :pemicu="pemicuPrint"
            :kasir="namaKasir"
            :bln="bln"
            :thn="thn"
          ></isiprint>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import isiprint from "./isiPrint.vue";
export default {
  components: { isiprint },
  props: {
    pemicu: Boolean,
    data: Array,
    bln: String,
    thn: String,
  },

  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  data() {
    return {
      tabel: [],
      tabelPrint: [],
      pemicuPrint: false,
      namaKasir: "",
    };
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  methods: {
    inisialisasi() {
      this.tabel = this.data.filter((x) => {
        return x.idAkun > 0;
      });
    },
    urutkan(kolom) {
      this.tabel = this.tabel.sort(function (a, b) {
        if (a[kolom] < b[kolom]) {
          return -1;
        }
        if (a[kolom] > b[kolom]) {
          return 1;
        }
        return 0;
      });
    },
    print(item) {
      this.tabelPrint = this.tabel.find((x) => {
        return x.idAkun == item.idAkun;
      }).data;
      this.namaKasir = item.Akun;
      this.pemicuPrint = true;
      console.log(this.tabelPrint);
    },
  },
};
</script>
