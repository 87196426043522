<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>Step I</v-card-title>
          <v-card-text
            >Rentang Tanggal dipilih: {{ tgl[0] | formatDate }}-{{
              tgl[1] | formatDate
            }}</v-card-text
          >
          <v-card-actions
            ><v-btn @click="pemicuTgl = true"
              >Pilih Tanggal</v-btn
            ></v-card-actions
          >
        </v-card></v-col
      >
      <v-col cols="6">
        <v-card :loading="loadingData">
          <v-card-title>Step II</v-card-title>
          <v-card-text>Pilih mau download apa</v-card-text>
          <v-card-actions
            ><download-excel
              class="mx-2"
              :data="tonase"
              :fields="judulTonase"
              worksheet="My Worksheet"
              :name="namaTonase"
            >
              <v-btn color="blue" :disabled="loading" dark
                ><v-icon>mdi-table-large</v-icon>Tonase</v-btn
              >
            </download-excel>
            <download-excel
              class="mx-2"
              :data="pinjaman"
              :fields="judulPinjaman"
              worksheet="My Worksheet"
              :name="namaPinjaman"
            >
              <v-btn color="red" :disabled="loading" dark
                ><v-icon>mdi-table-large</v-icon>Kantor</v-btn
              >
            </download-excel>
            <download-excel
              class="mx-2"
              :data="pinj"
              :fields="judulPinj"
              worksheet="My Worksheet"
              :name="namaPinj"
            >
              <v-btn color="purple" :disabled="loading" dark
                ><v-icon>mdi-table-large</v-icon>Pinjaman</v-btn
              >
            </download-excel></v-card-actions
          >
        </v-card>
      </v-col>
      <rentang
        :pemicu="pemicuTgl"
        @tutup="pemicuTgl = false"
        @simpan="simpan"
      ></rentang
    ></v-row>
  </div>
</template>
<script>
import axios from "axios";
import rentang from "../../component/popupTgl.vue";
import numeral from "numeral";
export default {
  components: { rentang },
  mounted() {
    this.inisialisasi();
  },
  watch: {},
  computed: {
    namaTonase() {
      return `Rekap Tonase ${this.tgl[0]}-${this.tgl[1]}`;
    },
    namaPinjaman() {
      return `Rekap Kantor ${this.tgl[0]}-${this.tgl[1]}`;
    },
    namaPinj() {
      return `Rekap Pinjaman ${this.tgl[0]}-${this.tgl[1]}`;
    },
  },
  data() {
    return {
      pemicuTgl: false,
      tonase: [],
      pinjaman: [],
      pinj: [],
      judulTonase: {
        ID: "id",
        Pabrik: "do.pabrik.pabrik",
        Tanggal: "tanggaldo",
        Toke: "toke.nama",
        Supir: "nama supir",
        Plat: "plat",
        Tonase: {
          field: "tonase",
          callback: (value) => {
            return numeral(parseFloat(value) || 0).format("0,0.0");
          },
        },
        Harga: "harga",
        HargaP: "hargaP",
        TH: {
          field: "th",
          callback: (value) => {
            return numeral(parseFloat(value) || 0).format("0,0.0");
          },
        },
        tanggalCair: "transaksi.tanggal",
        tanggalBank: "tanggalbank.tanggal",
        id_tgl: "tanggalbank.id",
        buku: "do.buku",
        kasir: "transaksi.akun.nama",
      },
      judulPinjaman: {
        ID: "ID",
        tanggal: "tanggal",
        toke: "toke.nama",
        uraian: "uraian",
        pinjam: "pinjam",
        angsur: "angsur",
      },
      judulPinj: {
        ID: "ID",
        tanggal: "tanggal",
        toke: "toke.nama",
        regional: "toke.regional.regional",
        uraian: "uraian",
        pinjam: "pinjam",
        angsur: "angsur",
        jenisPinjaman: "jenis",
      },
      tgl: [],
      loading: true,
      loadingData: false,
    };
  },
  methods: {
    inisialisasi() {},
    simpan(item) {
      console.log(item);
      this.loadingData = true;
      this.tgl = item;
      let uri = `/tonase/perpajakan/${item[0]}/${item[1]}`;
      axios
        .get(uri)
        .then((r) => {
          this.tonase = r.data.map((x) => {
            let fee = !x.do ? 0 : x.do.fee || 0;
            x.hargaP = parseInt(x.harga) + parseInt(fee);
            return x;
          });
          console.log(this.tonase);
          let sr = `/pinjaman/perpajakan/pinjaman/${item[0]}/${item[1]}`;
          axios
            .get(sr)
            .then((r) => {
              this.pinj = r.data;
              let ar = `/pinjaman/perpajakan/${item[0]}/${item[1]}`;
              axios
                .get(ar)
                .then((res) => {
                  this.pinjaman = res.data;
                  this.loading = false;
                  this.loadingData = false;
                  console.log("sedikit update 22/08");
                })
                .catch((e) => {
                  this.$noty.error(e.message);
                });
            })
            .catch((e) => {
              this.$noty.error(e.message);
            });
        })
        .catch((e) => {
          this.$noty.error(e.message);
        });
    },
  },
};
</script>
