<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Tambah Potongan</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Uraian"
                required
                v-model="uraian"
                dense
                autofocus
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Nominal"
                required
                v-model="nominal"
                dense
                type="number"
              ></v-text-field>
            </v-col>
            <v-btn color="blue" dark type="submit">Simpan</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["pemicu", "idToke", "toke", "isi", "edit"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      tokeId: 0,
      uraian: "",
      nominal: 0,
      id: 0,
    };
  },
  methods: {
    inisialisasi() {
      if (this.edit) {
        this.tokeId = this.isi.toke;
        this.uraian = this.isi.uraian;
        this.nominal = this.isi.nominal;
        this.Rid = this.isi.Rid;
      } else {
        this.tokeId = this.idToke;
        this.uraian = "";
        this.nominal = "";
        this.Rid = 0;
      }
      console.log("sedang membuka menu edit potongan");
      console.log(this.isi);
    },
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      let data = {};
      data.toke = this.tokeId;
      data.uraian = this.uraian;
      data.nominal = this.nominal;
      data.edit = this.edit;
      data.Rid = this.Rid;
      this.$emit("update", data);
      this.tutup();
    },
  },
};
</script>
