
export default {
    data(){
    return {
    }},
     methods: {
         r_coba(){
            console.log("ini percobaan dari mixins")
         },
         r_data_update(ob1,ob2){
             let difference = Object.keys(ob1).filter(
            (k) => ob1[k] !== ob2[k]
          );
          let obaru = {};
          for (const key of difference) {
            obaru[key] = ob1[key];
          }
          return obaru
         }
     },
     computed: {}

}
