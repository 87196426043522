<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    persistent
    fullscreen
  >
    <v-card rounded :loading="loading" class="mx-1 mx-sm-12">
      <v-card-title class="mb-10">
        <v-btn class="mx-4" icon large @click="tutup"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        Daftar Kasir <v-spacer></v-spacer>
        <v-text-field
          class="mx-1 mx-sm-6"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          dense
        ></v-text-field
      ></v-card-title>
      <v-row class="mb-12 mx-1 mx-sm-12">
        <v-col
          v-for="item in kasirFilter"
          :key="item.name"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card>
            <v-card
              class="elevation-8"
              color="indigo darken-1"
              @click="buka(item.id)"
              rounded
              shaped
            >
              <v-card-title class="white--text">
                {{ item.nama }}
              </v-card-title>
              <v-card-subtitle class="white--text"
                >Saldo: {{ item.saldo | rupiah }}
                <div class="text-caption white--text">
                  <v-icon class="white--text">mdi-calendar</v-icon>:
                  {{ item.tanggal | formatDate }}
                </div></v-card-subtitle
              ></v-card
            >
            <div class="text-caption white ma-2">
              <a @click="bukaSaldo(item.id)">Lihat Riwayat</a>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
// import autocomplete from "./input/autocomplete.vue";
import axios from "axios";
export default {
  components: {
    // autocomplete,
  },
  props: ["data", "pemicu"],
  data: () => ({
    selectedItem: 1,
    kasir: [],
    idCairMax: "0",
    loading: true,
    search: "",
  }),
  mounted() {
    this.getKasir();
  },
  computed: {
    kasirFilter() {
      return this.kasir.filter((item) => {
        let toke = item.nama.toLowerCase();
        return toke.indexOf(this.search.toLowerCase()) > -1;
      });
    },
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      if (this.pemicu) {
        this.getKasir();
      }
    },
  },
  methods: {
    getKasir() {
      this.loading = true;
      let uri = "/pencairan/kasir/saldo";
      axios.get(uri).then((response) => {
        this.kasir = response.data;
        this.loading = false;
      });
    },
    tutup() {
      this.$emit("tutup");
    },
    buka(id) {
      let uri = `/pencairan/gantiakun/${id}`;
      console.log(uri);
      let data = this.data.map((x) => x.ID);
      axios.post(uri, data).then((res) => {
        console.log(res.data);
        this.$noty.success("berhasil dipindah");
        this.$emit("reload");
        this.tutup();
      });
    },
    bukaSaldo(id) {
      console.log(`buka saldo ${id}`);
    },
  },
};
</script>
