<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="900"
    persistent
  >
    <v-card dense tile :loading="loading">
      <v-card-title
        >Pilih ramp {{ ` ${idNota}` }}<v-spacer></v-spacer
        ><v-btn @click="tutup" icon color="red" large
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-card-title
      >
      <v-card-text>
        <v-row dense>
          <v-col cols="12" v-if="posisi == 'home'">
            <v-row class="mb-12 mx-1 mx-sm-12">
              <v-col cols="12">
                <v-text-field
                  class="mx-1 mx-sm-6"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                ></v-text-field
              ></v-col>
              <v-col
                v-for="item in dataFilter"
                :key="item.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-card>
                  <v-card
                    class="elevation-8"
                    color="indigo darken-1"
                    @click="buka(item.id)"
                    rounded
                    shaped
                  >
                    <v-card-title class="white--text">
                      {{ item.nama }}
                    </v-card-title>
                    <v-card-subtitle class="white--text"
                      >Saldo: 0
                      <div class="text-caption white--text">
                        <v-icon class="white--text">mdi-calendar</v-icon>:
                      </div></v-card-subtitle
                    ></v-card
                  >
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="posisi == 'pilihan'"
            ><v-row
              ><v-btn
                @click="
                  posisi = 'home';
                  judul = 'Rekening';
                  kondisikan();
                "
                icon
                color="red"
                ><v-icon>mdi-arrow-left</v-icon></v-btn
              ></v-row
            >
            <v-row
              ><v-col cols="12">
                <v-text-field
                  class="mx-6"
                  v-model="search2"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                ></v-text-field
                ><v-spacer></v-spacer>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="data"
                  :items-per-page="500"
                  :search="search2"
                  show-select
                  v-model="terpilih"
                  dense
                  item-key="id"
                  mobile-breakpoint="300"
                  hide-default-footer
                  :loading="loading"
                >
                  <template v-slot:item.netto="{ item }">
                    <span>{{ item.netto | angka }}</span>
                  </template>
                  <template v-slot:item.harga="{ item }">
                    <span>{{ item.harga | angka }}</span>
                  </template>
                  <template v-slot:item.cair="{ item }">
                    <span>{{ item.cair | angka }}</span>
                  </template>
                </v-data-table></v-col
              >
              <v-col cols="12"
                ><v-btn color="blue" dark @click="next"
                  >Next <v-icon>mdi-button-pointer</v-icon></v-btn
                ></v-col
              ></v-row
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: ["pemicu", "idNota"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.kondisikan();
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      dataAwal: [],
      dataFilter: [],
      search: "",
      search2: "",
      posisi: "home",
      judul: "Rekening",
      proses: false,
      terpilih: [],
      tab: "pinjaman",
      valid: false,
      headers: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggalDo",
        },
        { text: "Nama", value: "nama" },
        { text: "Bruto", value: "bruto" },
        { text: "Tara", value: "tar" },
        { text: "Tbs", value: "tbs" },
        { text: "Netto", value: "netto" },
        { text: "Harga", value: "harga" },
        { text: "Cair", value: "cair" },
      ],
    };
  },
  methods: {
    tutup() {
      this.tab = "pinjaman";
      this.$emit("tutup");
    },

    kondisikan() {
      this.judul = "Rekening";
      this.posisi = "home";
      this.loading = true;
      this.terpilih = [];
      let uri = "/ramp/pilihan";
      axios.get(uri).then((response) => {
        this.dataAwal = response.data;
        console.log(uri);
        console.log(this.dataAwal);
        if (this.dataAwal.length > 0) {
          this.dataFilter = this.dataAwal.reduce(function (chace, item) {
            const indek = chace.findIndex((x) => {
              return x.id == item.id_ramp;
            });
            if (indek >= 0) {
              return chace;
            }
            chace.push({
              id: item.id_ramp,
              nama: item.toke ? item.toke.nama : "",
            });
            return chace;
          }, []);
        }
        this.loading = false;
      });
    },

    buka(id) {
      //==============================================================================
      this.data = this.dataAwal.filter((x) => x.id_ramp == id);
      this.judul = "Rekening";
      this.posisi = "pilihan";
    },
    next() {
      if (this.terpilih.length > 0) {
        if (this.proses == false) {
          this.proses = true;
          let data = {};
          data.terpilih = this.terpilih.map((x) => x.id);
          data.idNota = this.idNota;
          data.batal = false;
          let uri = `/ramp/pilihan`;
          console.log(uri);
          console.log(data);
          axios
            .post(uri, data)
            .then((res) => {
              console.log(res.data);
              this.$emit("reload");
              this.$emit("tutup");
            })
            .catch((e) => {
              console.log(e.message);
            })
            .finally(() => {
              this.proses = false;
            });
        }
      } else {
        alert("pilih dulu ya");
      }
    },
  },
};
</script>
