<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div>
          <v-card>
            <v-card-title>
              <v-progress-linear
                v-model="progressLoading"
                color="blue"
                v-if="progressLoading < 100"
              ></v-progress-linear>
              <v-row
                ><v-col cols="12" md="7">
                  <span class="text--h3">List Bank dan Kasir</span>
                  <v-btn
                    fab
                    color="red"
                    dark
                    class="ma-3"
                    @click="
                      idAkun = 0;
                      pemicuAkun = true;
                    "
                    v-if="!(user.user_level == 'kasir_limit')"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  ><v-btn
                    fab
                    color="blue"
                    dark
                    class="ma-3"
                    @click="bukaFull"
                    v-if="!(user.user_level == 'kasir_limit')"
                    ><v-icon v-if="full">mdi-layers-off-outline</v-icon
                    ><v-icon v-else>mdi-layers-outline</v-icon></v-btn
                  >
                  <v-btn
                    fab
                    color="green"
                    @click="pemicuBuku = true"
                    class="ma-3"
                    dark
                    ><v-icon>mdi-book-alert-outline</v-icon></v-btn
                  ></v-col
                >
                <v-spacer></v-spacer>
                <v-col cols="4">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    dense
                    v-if="!(user.user_level == 'kasir_limit')"
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row justify="center"
                ><v-col cols="12" md="4" sm="4"
                  ><v-row justify="center">
                    <v-date-picker
                      v-model="tanggal"
                    ></v-date-picker></v-row></v-col
                ><v-col
                  cols="12"
                  md="4"
                  sm="4"
                  v-if="!(user.user_level == 'kasir_limit')"
                >
                  <v-card class="mx-auto" tile>
                    <v-list rounded dense>
                      <v-subheader>Kasir</v-subheader>
                      <v-list-item-group color="primary">
                        <v-list-item v-for="(item, i) in kasirFilter" :key="i">
                          <v-list-item-icon>
                            <v-icon>mdi-cash</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content @click="buka(item)">
                            <v-list-item-title
                              >{{ item.nama
                              }}<v-list-item-subtitle
                                >{{ item.sum | rupiah }}
                              </v-list-item-subtitle></v-list-item-title
                            >
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-icon @click="edit(item)">mdi-pencil</v-icon>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card> </v-col
                ><v-col
                  cols="12"
                  md="4"
                  sm="4"
                  v-if="!(user.user_level == 'kasir_limit')"
                >
                  <v-card class="mx-auto" tile>
                    <v-list rounded dense>
                      <v-subheader>Bank</v-subheader>
                      <v-list-item-group color="primary">
                        <v-list-item v-for="(item, i) in bankFilter" :key="i">
                          <v-list-item-icon>
                            <v-icon>mdi-bank</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content @click="buka(item)">
                            <v-list-item-title
                              >{{ item.nama
                              }}<v-list-item-subtitle
                                >{{ item.sum | rupiah }}
                              </v-list-item-subtitle></v-list-item-title
                            >
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-icon @click="edit(item)">mdi-pencil</v-icon>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col></v-row
              >
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <formAkun
        @tutup="pemicuAkun = false"
        :pemicu="pemicuAkun"
        :id="idAkun"
        @simpan="inisialisasi"
      ></formAkun>
      <buku
        @tutup="pemicuBuku = false"
        :pemicu="pemicuBuku"
        :tanggal="tanggal"
      ></buku>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import formAkun from "./newAkun.vue";
import buku from "./buku.vue";
import axios from "axios";
export default {
  components: { formAkun, buku },
  props: ["user"],
  data() {
    return {
      loading: false,
      search: "",
      kasir: [],
      akun: [],
      bank: [],
      tabel: [],
      tanggal: moment().format("YYYY-MM-DD"),
      pemicuAkun: false,
      idAkun: 0,
      full: false,
      pemicuBuku: false,
      progressLoading: 0,
    };
  },
  watch: {
    tanggal() {
      console.log(this.tanggal);
      this.inisialisasi();
    },
  },
  computed: {
    kasirFilter() {
      return this.kasir.filter((item) => {
        let toke = item.nama.toLowerCase();
        return toke.indexOf(this.search.toLowerCase()) > -1;
      });
    },
    bankFilter() {
      return this.bank.filter((item) => {
        let toke = item.nama.toLowerCase();
        return toke.indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },
  mounted() {
    this.inisialisasi();
  },
  methods: {
    async inisialisasi() {
      let url = "";
      if (this.full) {
        url = `/akunjurnal/kasirFull`;
      } else {
        url = `/akunjurnal/kasir`;
      }
      axios.get(url).then((response) => {
        const data = response.data;
        let completedRequests = 0;
        const totalRequests = data.length;
        const updateProgress = () => {
          const progress = (completedRequests / totalRequests) * 100;
          this.progressLoading = progress.toFixed(2);
        };
        const requests = data.map((item) =>
          axios
            .get(`/akunjurnal/kasir/${item.ID}/${this.tanggal}`)
            .then((response) => {
              completedRequests++;
              updateProgress();
              return response.data;
            })
        );
        Promise.all(requests)
          .then((responses) => {
            // Semua permintaan berhasil
            let data = [];
            console.log(responses);
            responses.forEach((response) => {
              data.push(response);
            });
            this.akun = data;
            this.pisahkan();
          })
          .catch((error) => {
            // Ada permintaan yang gagal
            console.error("Error during API calls:", error);
          });
      });
    },
    async bukaLInk(uri) {
      const data = await axios.get(uri);
      this.akun = this.akun.concat(data.data);
    },
    bukaFull() {
      this.full = !this.full;
      this.inisialisasi();
    },
    edit(item) {
      this.idAkun = item.ID;
      this.pemicuAkun = true;
    },
    buka(item) {
      this.$router.push("/bank/" + item.ID);
    },
    pisahkan() {
      let jenismodal = [7, 10, 11];
      const jenisbank = [8, 9];
      this.kasir = this.akun.filter(function (x) {
        return jenismodal.indexOf(x.golongan) !== -1;
      });
      this.bank = this.akun.filter(function (x) {
        return jenisbank.indexOf(x.golongan) !== -1;
      });
    },
  },
};
</script>
