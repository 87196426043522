<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    persistent
    width="550"
  >
    <v-card dense tile>
      <v-card-title> </v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="simpan()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                v-if="data.akun"
                v-model="data.akun.id"
                auto-select-first
                :items="akuns"
                item-text="nama"
                item-value="id"
                label="Akun"
                ref="toke"
                :rules="[(v) => !!v || 'Item is required']"
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Keterangan"
                required
                v-model="data.uraian"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                label="Masuk"
                required
                :rules="[
                  (v) => v <= 100000000000 || 'Terlalu besar',
                  (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                ]"
                v-model="data.masuk"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                label="Keluar"
                required
                :rules="[
                  (v) => v <= 100000000000 || 'Terlalu besar',
                  (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                ]"
                v-model="data.keluar"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" dark @click="tutup()">Batal</v-btn
        ><v-btn
          color="blue"
          dark
          @click="kirim()"
          :loading="loadingBtn"
          :disabled="loadingBtn"
          >Simpan</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  //   props: ["pemicu", "idAkun", "data"],
  props: {
    pemicu: Boolean,
    data: {
      type: Object,
      default: function () {
        return { akun: { id: 1, nama: "" }, id: 0, id_masukan: 0 };
      },
    },
  },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
        this.modalModal = [];
      },
    },
  },
  watch: {
    pemicu() {
      this.kondisikan();
      this.getAkun();
    },
  },
  data() {
    return {
      valid: true,
      loadingBtn: false,
      akuns: [],
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    simpan() {
      this.$refs.form.validate();
      this.kondisikan();
      if (this.valid) {
        let tabel = {
          akun: this.data.akun.id,
          uraian: this.data.uraian,
          masuk: this.data.masuk,
          keluar: this.data.keluar,
        };
        console.log(tabel);
        let uri = "/modal/" + this.data.id;
        axios
          .put(uri, tabel)
          .then((response) => {
            if (response.data.status == true) {
              this.$noty.success(response.data.pesan);
              this.data.akun.nama = this.akuns.find((x) => {
                return x.id == this.data.akun.id;
              }).nama;
            } else {
              this.$noty.error(response.data.pesan);
            }
            this.tutup();
          })
          .catch((error) => {
            this.$noty.error(error.Error);
            console.log(error);
          });
      }
    },
    kirim() {
      this.simpan();
    },
    kondisikan() {
      console.log(this.data);
      this.data.masuk = parseInt(this.data.masuk) || 0;
      this.data.keluar = parseInt(this.data.keluar) || 0;
    },
    getAkun() {
      this.akuns = [];
      let uri = "/akun";
      axios.get(uri).then((response) => {
        this.akuns = response.data;
      });
    },
  },
};
</script>
