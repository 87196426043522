<template>
  <div>
    <cari v-model="search" label="Cari" class="mx-1 mx-sm-6"></cari>
    {{ search }}
    <v-btn @click="cek">Cek</v-btn>
  </div>
</template>
<script>
import cari from "./isivmodel.vue";
export default {
  components: { cari },
  mounted() {},
  computed: {},
  data() {
    return {
      search: "",
    };
  },
  methods: {
    cek() {
      console.log(this.search);
    },
  },
};
</script>
