<template>
  <v-row :class="$vuetify.breakpoint.smAndUp ? 'ma-2' : ''">
    <v-col cols="12">
      <v-card color="grey lighten-3" :loading="loading"
        ><v-toolbar color="blue darken-3" dense dark>
          <v-toolbar-title
            >{{ akun.nama }}
            <span class="ml-4 text-caption">{{
              saldo | rupiah
            }}</span></v-toolbar-title
          >
          <v-spacer></v-spacer
          ><v-btn color="red" icon x-large @click="$router.push('/rekening')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-row class="ma-1">
          <v-col cols="12">
            <v-row class="pa-2">
              <v-radio-group v-model="filter" row class="mt-0">
                <v-radio label="Terbaru" value="terbaru"></v-radio>
                <v-radio label="Pilih Tanggal" value="rentang"></v-radio>
              </v-radio-group>
              <v-spacer></v-spacer>
              <input
                style="display: none"
                ref="upload"
                type="file"
                @change="kasihNama"
                accept=".xlsx"
              />
              <v-btn @click="$refs.upload.click()" class="mx-2">Upload</v-btn>
              <v-btn outlined color="green" @click="excel = !excel"
                ><v-icon>mdi-swap-horizontal</v-icon></v-btn
              >
            </v-row>
            <v-divider></v-divider>
            <v-row class="ma-2" v-if="filter == 'rentang'" dense color="white">
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="popupA"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="getTanggalA"
                      prepend-icon="mdi-calendar"
                      clearable
                      label="Pilih Rentang"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="date = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tanggalFilter"
                    @change="popupA = false"
                    range
                  ></v-date-picker> </v-menu
              ></v-col>
              <v-spacer></v-spacer>
              <v-col
                ><v-btn color="blue" @click="filterRentang()" dark
                  >Tampilkan</v-btn
                ></v-col
              >
            </v-row>
          </v-col>
          <rekeningExcel
            v-if="excel"
            :pinjaman="data"
            :reloader="reloader"
            @reload="inisialisasi"
            :toke="toke"
            :rekening="id"
          ></rekeningExcel>
          <satusatu
            v-else
            :data="data"
            :toke="toke"
            :id="id"
            @update="inisialisasi"
          ></satusatu>
        </v-row>
      </v-card>
      <v-col cols="12"> </v-col>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import rekeningExcel from "./rekeningBank/excel.vue";
import satusatu from "./rekeningBank/satusatu.vue";
export default {
  components: { rekeningExcel, satusatu },
  props: ["id"],
  computed: {
    getTanggalA() {
      return this.tanggalFilter.join(" sampai ");
    },
  },
  data() {
    return {
      tanggal: moment().format("YYYY-MM-DD"),
      loading: false,
      data: [],
      akun: {},
      pemicuRekkor: false,
      search: "",
      terpilih: [],
      idRekkor: 0,
      pemicuFilter: false,
      pemicuExcel: false,
      excel: true,
      popupA: false,
      popupB: false,
      reloader: false,
      headers: [
        {
          text: "Jenis",
          align: "start",
          filterable: true,
          value: "jenis",
        },
        { text: "Akun", value: "toke.nama" },
        { text: "Uraian", value: "uraian" },
        { text: "Masuk", value: "masuk" },
        { text: "Keluar", value: "keluar" },
        { text: "saldo", value: "saldo" },
        { text: "v", value: "valid" },
        { text: "opsi", value: "opsi" },
      ],
      fieldExcell: {
        ID: "id",
        Jenis: "jenis",
        Tanggal: "tanggal",
        Akun: "toke.nama",
        uraian: "uraian",
        Masuk: "masuk",
        Keluar: "keluar",
        Saldo: "saldo",
      },
      saldo: 0,
      tanggalFilter: [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      tanggalA: moment().format("YYYY-MM-DD"),
      tanggalB: moment().format("YYYY-MM-DD"),
      toke: {},
      filter: "terbaru",
      fileUpload: null,
    };
  },
  mounted() {
    this.inisialisasi();
  },
  watch: {
    tanggal() {
      this.inisialisasi();
    },
    filter() {
      if (this.filter == "terbaru") {
        this.inisialisasi();
      } else {
        console.log("tampilkan berdasarkan tanggal");
      }
    },
  },
  methods: {
    async inisialisasi() {
      this.loading = true;
      let url = "/rekening/" + this.id;
      this.akun = await axios.get(url).then((response) => {
        return response.data;
      });
      if (this.filter == "terbaru") {
        url = `/rekeningkoran/buka/terbaru/${this.id}`;
      } else {
        url = `/rekeningkoran/buka/${this.id}/${this.tanggalFilter[0]}/${this.tanggalFilter[1]}`;
      }
      let hasil = await axios.get(url).then((res) => {
        return res.data;
      });
      this.toke = await axios.get("/tokeAktif").then((res) => {
        return this.convertList(res.data, "ID", "nama");
      });
      this.data = hasil.data.sort(
        (a, b) => new Date(a.tanggal) - new Date(b.tanggal) || a.id - b.id
      );
      let saldoA = hasil.saldo;
      this.data = this.data.map((x) => {
        saldoA = saldoA + parseInt(x.masuk) - parseInt(x.keluar);
        x.saldo = saldoA;
        return x;
      });
      const p = this.data.length;
      if (p > 0) {
        this.saldo = this.data[p - 1].saldo;
      } else {
        this.saldo = saldoA;
      }
      console.log(this.saldo);
      console.log(this.data);
      this.reloader = !this.reloader;
      this.loading = false;
    },
    filterRentang() {
      this.tanggalFilter = this.tanggalFilter.sort(
        (a, b) => new Date(a) - new Date(b)
      );
      this.inisialisasi();
    },
    home() {
      this.$router.push("/rekening");
    },
    updateFilter(item) {
      this.data = item;
    },
    kasihNama(item) {
      this.fileUpload = item.target.files[0];
      if (this.fileUpload) {
        this.uploadNota();
      }
    },
    async uploadNota() {
      const uri = `/rekeningkoran/upload/${this.akun.id}`;
      if (!this.loading) {
        this.loading = true;
        if (!this.fileUpload) {
          return;
        }
        console.log(this.fileUpload);
        const data = new FormData();
        data.append("excel", this.fileUpload, this.fileUpload.name);
        axios
          .post(uri, data)
          .then((res) => {
            this.$noty.success("Berhasil di upload");
            console.log(res.data);
          })
          .finally(() => {
            this.inisialisasi();
            this.loading = false;
          });
      }
    },
    refresh() {},
    convertList(data, key, value) {
      let hasil = data.reduce(
        (obj, item) => Object.assign(obj, { [item[key]]: item[value] }),
        {}
      );
      return hasil;
    },
  },
};
</script>
