<template>
  <v-container>
    <v-row class="ma-2"
      ><v-col cols="12" md="4" sm="6">
        <v-row justify="center" dense>
          <v-date-picker v-model="tanggal" elevation="4"></v-date-picker>
          <!-- <div><a href="/harga/lama">Tampilan lama</a></div>  -->
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" md="8">
        <v-card
          ><v-toolbar dense color="pink" dark>
            <v-toolbar-title>{{ tanggal | formatDate }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="pemicuCek = true">
              <v-icon>mdi-magnify-scan</v-icon>
            </v-btn>
            <v-btn icon @click="edits" v-if="hargaTerpilih.length > 0">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="judulHarga"
            :items="harga"
            :items-per-page="100"
            :loading="loadingHarga"
            :search="search"
            item-key="id"
            v-model="hargaTerpilih"
            mobile-breakpoint="300"
            show-select
            class="elevation-1"
            dense
            hide-default-footer
          >
            <template v-slot:item.harga="{ item }">
              <span class="mr-2">{{ item.harga | angka }}</span
              ><span
                v-if="selisih(item)"
                :class="`'text-caption' ${
                  selisih(item) < 0 ? 'red--text' : 'blue--text'
                }`"
                >{{ selisih(item) }}</span
              >
            </template>
            <template v-slot:item.opsi="{ item }">
              <v-btn
                class="mx-2"
                icon
                color="pink"
                x-small
                @click="edit(item)"
                :disabled="loadingHapus"
                ><v-icon> mdi-pencil </v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
        <r-new
          :tanggal="tanggal"
          :pabrik="pabrik"
          :pemicu="pemicu"
          :id="hargaTerpilih"
          @tutup="tutup"
          @lanjutkan="lanjutkan"
        ></r-new>
        <r-newgrup
          :tanggal="tanggal"
          :pabrik="pabrik"
          :grup="grupPabrik"
          :pemicu="pemicuGrup"
          @tutup="pemicuGrup = false"
          @lanjutkan="lanjutkan"
        ></r-newgrup>
        <cek
          :pabrik="pabrik"
          :pemicu="pemicuCek"
          @tutup="pemicuCek = false"
        ></cek>
        <v-col cols="12"> </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rNew from "./new.vue";
import rNewgrup from "./newGrup.vue";
import cek from "./cek.vue";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
export default {
  components: { rNew, rNewgrup, cek },
  computed: {
    lastPage() {
      return Math.ceil(this.harga.length / 12);
    },
  },
  data() {
    return {
      tanggal: moment().format("YYYY-MM-DD"),
      pabrik: [],
      search: "",
      grupPabrik: [],
      loadingPabrik: false,
      pabrikTerpilih: [],
      harga: [],
      loadingHarga: false,
      hargaTerpilih: [],
      loadingHapus: false,
      judulHarga: [
        {
          text: "Pabrik",
          value: "pabriks.pabrik",
        },
        {
          text: "Harga",
          value: "harga",
        },
        {
          text: "Opsi",
          value: "opsi",
        },
      ],
      pemicu: false,
      pemicuGrup: false,
      pemicuCek: false,
    };
  },
  mounted() {
    this.inisialisasi();
  },
  watch: {
    tanggal() {
      this.tampilHarga();
    },
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let url = "/pabrik";
      axios.get(url).then((response) => {
        this.pabrik = _.values(response.data);
        console.log(this.pabrik);
        this.loadingPabrik = false;
        this.tampilHarga();
      });
    },
    edit($item) {
      console.log($item);
      this.hargaTerpilih = [$item];
      this.pemicu = true;
    },
    edits() {
      this.pemicu = true;
    },
    selisih(item) {
      if (item.tanggal == this.tanggal) {
        let seli = item.harga - item.hargal;
        if (seli == 0) {
          return "";
        } else if (seli > 0) {
          return "+" + seli;
        } else {
          return seli;
        }
      } else {
        return "";
      }
    },
    LoadgrupPabrik() {
      let url = "/pabrikgrup";
      axios.get(url).then((response) => {
        this.grupPabrik = _.values(response.data);
      });
    },
    tampilHarga() {
      this.loadingHarga = true;
      let url = "/harga/" + this.tanggal;
      axios.get(url).then((response) => {
        this.harga = response.data.sort(
          (a, b) => new Date(b.tanggal) - new Date(a.tanggal)
        );
        console.log(this.harga);
        this.hargaTerpilih = [];
        this.loadingHarga = false;
        this.LoadgrupPabrik();
      });
    },
    rowClicked(item) {
      this.pabrikTerpilih = [item];
      this.tampilHarga();
    },
    lanjutkan() {
      this.tampilHarga();
    },
    tutup() {
      this.pemicu = false;
      this.hargaTerpilih = [];
    },
    baru() {
      this.pemicu = true;
    },
  },
};
</script>
