<template>
  <v-card dense tile>
    <v-toolbar>
      <v-toolbar-title>Tagihan</v-toolbar-title>
      <v-spacer></v-spacer
      ><v-switch v-model="switch1" dense label="Rekap" class="mr-3"></v-switch
      ><v-btn icon color="red" @click="pemicuPrint = true"
        ><v-icon>mdi-printer</v-icon></v-btn
      >
      <download-excel
        class="mx-2"
        :data="tabel"
        :fields="fieldExcell"
        worksheet="My Worksheet"
        :name="filename"
      >
        <v-btn icon color="blue"><v-icon>mdi-table-large</v-icon></v-btn>
      </download-excel>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="switch1 ? headersMinimal : headersFull"
        :items="data"
        :items-per-page="10"
        dense
        mobile-breakpoint="300"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-page-first',
          lastIcon: 'mdi-page-last',
          prevIcon: 'mdi-arrow-left-drop-circle-outline',
          nextIcon: 'mdi-arrow-right-drop-circle-outline',
        }"
      >
        <template v-slot:item.tanggal="{ item }">
          <span>{{ item.tanggal | formatDate }}</span>
        </template>
        <template v-slot:item.tonase="{ item }">
          <span>{{ item.tonase | angka }}</span>
        </template>
        <template v-slot:item.harga="{ item }">
          <span>{{ item.harga | rupiah }}</span>
        </template>
        <template v-slot:item.cair="{ item }">
          <span>{{ item.cair | rupiah }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            class="mx-2"
            icon
            color="blue"
            x-small
            @click="
              idTon = item.id;
              tonaseDialog = true;
            "
          >
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <tonaseD
        :id="idTon"
        :deo="deo"
        :toke="toke"
        :dialogTon="tonaseDialog"
        @tutup="tonaseDialog = false"
        @update="inisialisasi"
      ></tonaseD>
      <print
        :id="id"
        :pemicu="pemicuPrint"
        :data="[]"
        @tutup="pemicuPrint = false"
        @update="pemicuPrint = false"
      ></print>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";
import tonaseD from "../../Transaksi/input/tonaseEdit.vue";
import print from "./printTagihan.vue";
import axios from "axios";
export default {
  props: ["id"],
  components: { tonaseD, print },
  computed: {
    filename() {
      return `Tagihan Pabrik -${moment().format("DD-MM-YY hh")}`;
    },
    data: {
      get() {
        if (this.switch1 === false) {
          return this.tabel;
        } else {
          return this.grupkan(this.tabel);
        }
      },
      set(value) {
        return value;
      },
    },
  },
  watch: {
    switch1() {
      console.log(this.data);
    },
  },
  mounted() {
    this.inisialisasi();
    this.kondisikan();
  },
  data() {
    return {
      tabel: [],
      headersFull: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggal",
        },
        { text: "pabrik", value: "idpabrik.pabrik" },
        { text: "nama", value: "nama supir" },
        { text: "plat", value: "plat" },
        { text: "tonase", value: "tonase" },
        { text: "harga", value: "harga" },
        { text: "cair", value: "cair" },
        { text: "Actions", value: "actions" },
      ],
      headersMinimal: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggal",
        },
        { text: "pabrik", value: "pabrik" },
        { text: "tonase", value: "tonase" },
        { text: "cair", value: "cair" },
      ],
      loading: false,
      fieldExcell: {
        Pabrik: "idpabrik.pabrik",
        Tanggal: "tanggal",
        Toke: "toke",
        Supir: "nama supir",
        Plat: "plat",
        Tonase: "tonase",
        Harga: "harga",
        Cair: "cair",
      },
      tonaseDialog: false,
      toke: [],
      deo: [],
      idTon: 0,
      switch1: false,
      pemicuPrint: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    inisialisasi() {
      this.loading = true;
      let url = "/akunjurnal/cairbank/" + this.id;
      console.log(url);
      axios.get(url).then((response) => {
        this.tabel = response.data;
        console.log(this.tabel);
        this.loading = false;
        this.getToke();
        this.getDeo();
      });
    },
    simpan() {},
    kondisikan() {},
    edit() {},
    getToke() {
      axios.get("/tokeAktif").then((response) => {
        this.toke = response.data;
        this.toke.push({
          ID: 0,
          nama: "--Semua toke--",
        });
      });
    },
    getDeo() {
      axios.get("/deo").then((response) => {
        this.deo = response.data;
      });
    },
    grupkan(array) {
      if (array.length >> 0) {
        var result = [];
        var hasil = [];
        array.reduce(function (res, value) {
          if (!res[value.tanggal]) {
            res[value.tanggal] = { tanggal: value.tanggal };
            result.push(res[value.tanggal]);
          }
          return res;
        }, {});
        result.forEach(function (val) {
          var a = array.filter(function (x) {
            return x.tanggal == val.tanggal;
          });
          var c = a.reduce(function (chace, item) {
            const properti = item.idpabrik.id;
            if (chace.some((el) => el.idpabrik === properti)) {
              var terpilih = chace.find(function (x) {
                return x.idpabrik === properti;
              });
              (terpilih.tonase += parseInt(item.tonase)),
                (terpilih.cair += parseInt(item.cair));
              return [...chace];
            }
            chace.push({
              tanggal: item.tanggal,
              tonase: parseInt(item.tonase),
              cair: parseInt(item.cair),
              pabrik: item.idpabrik.pabrik,
              idpabrik: item.idpabrik.id,
            });
            return [...chace];
          }, []);
          c.forEach(function (item) {
            hasil.push(item);
          });
        });
        hasil.sort(function (a, b) {
          return new Date(a.tanggal).getTime() - new Date(b.tanggal).getTime();
        });
        return hasil;
      }
      return array;
    },
  },
};
</script>
