<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="900"
    persistent
  >
    <v-card dense tile :loading="loading">
      <v-card-title
        >{{ judul }} {{ `${tanggal} / ${akun}` }}<v-spacer></v-spacer
        ><v-btn @click="tutup" icon color="red" large
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-card-title
      >
      <v-card-text>
        <v-row dense>
          <v-col cols="12" v-if="posisi == 'home'">
            <v-row class="mb-12 mx-1 mx-sm-12">
              <v-col cols="12">
                <v-text-field
                  class="mx-1 mx-sm-6"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                ></v-text-field
              ></v-col>
              <v-col
                v-for="item in dataFilter"
                :key="item.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <v-card>
                  <v-card
                    class="elevation-8"
                    color="indigo darken-1"
                    @click="buka(item.id)"
                    rounded
                    shaped
                  >
                    <v-card-title class="white--text">
                      {{ item.nama }}
                    </v-card-title>
                    <v-card-subtitle class="white--text"
                      >Saldo: 0
                      <div class="text-caption white--text">
                        <v-icon class="white--text">mdi-calendar</v-icon>:
                      </div></v-card-subtitle
                    ></v-card
                  >
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="posisi == 'pilihan'"
            ><v-row
              ><v-btn
                @click="
                  posisi = 'home';
                  judul = 'Rekening';
                  kondisikan();
                "
                icon
                color="red"
                ><v-icon>mdi-arrow-left</v-icon></v-btn
              ></v-row
            >
            <v-row
              ><v-col cols="12">
                <v-text-field
                  class="mx-6"
                  v-model="search2"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                ></v-text-field
                ><v-spacer></v-spacer>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="data"
                  :items-per-page="100"
                  :search="search2"
                  show-select
                  v-model="terpilih"
                  single-select
                  dense
                  item-key="id"
                  mobile-breakpoint="300"
                  hide-default-footer
                  :loading="loading"
                >
                  <template v-slot:item.masuk="{ item }">
                    <span>{{ item.masuk | angka }}</span>
                  </template>
                  <template v-slot:item.keluar="{ item }">
                    <span>{{ item.keluar | angka }}</span>
                  </template>
                </v-data-table></v-col
              >
              <v-col cols="12"
                ><v-btn color="blue" dark @click="next"
                  >Next <v-icon>mdi-button-pointer</v-icon></v-btn
                ></v-col
              ></v-row
            >
          </v-col>
          <v-col cols="12" v-if="posisi == 'pinjaman'">
            <v-row dense
              ><v-col cols="12">
                <table>
                  <thead>
                    <tr>
                      <td>{{ dataTransaksi.toke.nama }}</td>
                      <td>{{ dataTransaksi.uraian }}</td>
                      <td>
                        {{
                          (dataTransaksi.keluar - dataTransaksi.masuk) | angka
                        }}
                      </td>
                    </tr>
                  </thead>
                </table></v-col
              >
              <v-col cols="12"
                ><v-tabs>
                  <v-tab @click="tab = 'pinjaman'">Pinjaman</v-tab>
                  <v-tab @click="tab = 'tagihan'">Tagihan</v-tab>
                </v-tabs></v-col
              >
              <v-col cols="12" v-if="tab == 'pinjaman'">
                <v-form
                  @submit.prevent="simpan()"
                  color="grey"
                  ref="form"
                  v-model="valid"
                  ><v-row no-gutters
                    ><v-col cols="12">
                      <v-autocomplete
                        v-model="dataPinjaman.jenis"
                        :items="['PS', 'PT']"
                        label="Jenis"
                        ref="pinjam"
                        dense
                        :rules="[(v) => !!v || 'Harus diisi']"
                      >
                      </v-autocomplete
                    ></v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="dataPinjaman.toke"
                        :items="tokes"
                        item-text="nama"
                        item-value="ID"
                        auto-select-first
                        label="akun"
                        autofocus
                        dense
                        ref="toke"
                        :rules="[(v) => !!v || 'Harus diisi']"
                      >
                      </v-autocomplete
                    ></v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Uraian"
                        required
                        v-model="dataPinjaman.uraian"
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12"></v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Pinjam"
                        required
                        v-model="dataPinjaman.pinjam"
                        dense
                        type="number"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="angsur"
                        required
                        v-model="dataPinjaman.angsur"
                        dense
                      >
                      </v-text-field> </v-col></v-row></v-form
              ></v-col>
              <v-col cols="12" v-if="tab == 'tagihan'"
                ><tagihans @simpan="getTagihan" :id="0"></tagihans>
                <v-row
                  ><v-col cols="12">Akan dibayar</v-col>
                  <v-col cols="12">
                    <table v-if="dataTagihan.length > 0">
                      <tbody>
                        <tr v-for="item in dataTagihan" :key="item.id">
                          <td>{{ item.keterangan }}</td>
                          <td>{{ item.hitung }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col></v-row
                >
              </v-col>
              <v-col cols="12"
                ><v-btn color="blue" dark @click="simpan"
                  >Simpan <v-icon>mdi-button-pointer</v-icon></v-btn
                ></v-col
              ></v-row
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import tagihans from "./imporTagihan.vue";
export default {
  props: ["pemicu", "tanggal", "akun"],
  components: { tagihans },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    dataFilter() {
      return this.data.filter((item) => {
        let nama = item.nama ? item.nama.toLowerCase() : "";
        return nama.indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },
  watch: {
    pemicu() {
      this.kondisikan();
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      search: "",
      search2: "",
      posisi: "home",
      judul: "Rekening",
      terpilih: [],
      tab: "pinjaman",
      dataTransaksi: {},
      dataPinjaman: {},
      dataTagihan: [],
      tokes: [],
      valid: false,
      headers: [
        {
          text: "Jenis",
          align: "start",
          filterable: true,
          value: "jenis",
        },
        { text: "Akun", value: "toke.nama" },
        { text: "Uraian", value: "uraian" },
        { text: "Masuk", value: "masuk" },
        { text: "Keluar", value: "keluar" },
      ],
    };
  },
  methods: {
    tutup() {
      this.tab = "pinjaman";
      this.$emit("tutup");
    },

    kondisikan() {
      this.dataTagihan = [];
      this.judul = "Rekening";
      this.posisi = "home";
      this.loading = true;
      let uri = `/rekening/bank/${this.akun}`;
      axios.get(uri).then((response) => {
        this.data = response.data;
        this.loading = false;
        this.getToke();
      });
    },
    getToke() {
      axios.get("/tokeAktif").then((res) => {
        this.tokes = res.data;
      });
    },
    buka(id) {
      this.loading = true;
      let url = "/rekening/" + id;
      axios.get(url).then((response) => {
        this.judul = response.data.nama;
        let uri = `/rekeningkoran/buka/${id}/${this.tanggal}`;
        axios.get(uri).then((res) => {
          this.data = res.data.data;
          if (this.data.length > 0) {
            this.data = this.data.filter((x) => {
              return x.jenis == "MODAL" && x.valid == 0;
            });
          }
          console.log(this.data);
          this.loading = false;
        });
      });
      this.posisi = "pilihan";
      console.log(this.posisi);
    },
    next() {
      if (this.terpilih.length > 0) {
        this.dataTransaksi = this.terpilih[0];
        this.posisi = "pinjaman";
        this.dataPinjaman.jenis = "PS";
        this.dataPinjaman.toke = 0;
        this.dataPinjaman.uraian = this.dataTransaksi.uraian;
        this.dataPinjaman.pinjam = this.dataTransaksi.keluar;
        this.dataPinjaman.angsur = this.dataTransaksi.masuk;
      } else {
        alert("pilih dulu ya");
      }
    },
    simpan() {
      console.log("ini proses simpan");
      if (this.tab == "tagihan") {
        if (this.dataTagihan.length > 0) {
          let data = {};
          data.rekening = [this.dataTransaksi];
          data.tagihan = this.dataTagihan;
          console.log(data);
          let uri = "/rekeningkoran/transfertagihan";
          console.log(uri);
          console.log(data);
          axios.post(uri, data).then((res) => {
            if (res.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.$emit("reload");
              this.$emit("tutup");
              console.log(res.data);
            }
          });
        } else {
          alert("pilih tagihan yang mau dibayar");
        }
      } else {
        this.$refs.form.validate();
        if (this.valid) {
          let data = {};
          data.rekening = [this.dataTransaksi];
          data.pinjaman = this.dataPinjaman;
          let uri = "/rekeningkoran/transferpinjaman";
          console.log(uri);
          console.log(data);
          axios.post(uri, data).then((res) => {
            if (res.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.$emit("reload");
              this.$emit("tutup");
              console.log(res.data);
            }
          });
        }
      }
    },
    getTagihan(item) {
      console.log(item);
      this.dataTagihan = item;
    },
  },
};
</script>
