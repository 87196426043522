<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="500"
    persistent
  >
    <v-card dense tile>
      <v-card-title>
        <h3>Tabel Tonase</h3>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="simpan()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="relasiId"
                :items="relasi"
                item-text="nama"
                item-value="id"
                label="Relasi"
                ref="relasi"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="blue"
          dark
          type="submit"
          @click="simpan"
          :loading="loading"
          :disabled="loading"
          >Simpan</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="red" dark @click="tutup()">Batal</v-btn></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["pemicu", "ditandai"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      loading: false,
      relasi: [],
      relasiId: null,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    inisialisasi() {
      axios.get("/relasi").then((response) => {
        this.relasi = response.data;
      });
    },
    simpan() {
      if (this.valid) {
        if (this.ditandai.length >> 0) {
          const tabel = this.ditandai.map(function (x) {
            return x.id;
          });
          console.log(tabel);
          console.log(this.relasiId);

          this.loading = true;
          let uri = "/tonase/gantirelasi/" + this.relasiId;
          axios
            .put(uri, tabel)
            .then((response) => {
              if (response.data) {
                this.$noty.success("data Berhasil di Update");
                console.log(response.data.data);
                this.loading = false;
                this.$emit("simpan");
                this.$emit("tutup");
              }
            })
            .catch((error) => {
              console.log(error);
              this.$noty.error(error.response.data.errors[0]);
            });
        }
      }
    },
  },
};
</script>
