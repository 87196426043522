<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Bayar Tagihan</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Nama Tagihan"
                required
                v-model="dataNota.keterangan"
                autofocus
              >
              </v-text-field>
            </v-col>
            <v-btn color="blue" dark type="submit" :disabled="tombolOff"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["transaksi", "id", "idFolder", "pemicu"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.kondisikan();
    },
  },
  data() {
    return {
      valid: false,
      tombolOff: false,
      toke: [],
      dataNota: {},
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid) {
        this.tombolOff = true;
        if (this.id) {
          let uri = "/nota/" + this.dataNota.ID;
          axios
            .put(uri, {
              keterangan: this.dataNota.keterangan,
            })
            .then((response) => {
              if (response.data.status) {
                this.$emit("bukaNota", response.data.data);
                this.$emit("simpan", response.data.data);
                this.$noty.success(response.data.pesan);
              } else {
                this.$noty.error(response.data.pesan);
                console.log("error");
              }
              this.tutup();
              this.tombolOff = false;
            })
            .catch((error) => {
              this.$noty.error(error.Error);
              console.log(error);
              this.tombolOff = false;
            });
        } else {
          let uri = "/nota";
          axios
            .post(uri, this.dataNota)
            .then((response) => {
              if (response.status == 200) {
                this.$emit("bukaNota", response.data.data);
                this.$noty.success(response.data.pesan);
              } else {
                this.$noty.error(response.data.pesan);
              }
              this.tutup();
              this.tombolOff = false;
            })
            .catch((error) => {
              this.$noty.error(error.Error);
              console.log(error);
              this.tombolOff = false;
            });
        }
      }
    },
    kondisikan() {
      if (this.id) {
        console.log("ini proses update");
        let uri = `/nota/${this.id}`;
        axios
          .get(uri)
          .then((res) => {
            this.dataNota = res.data;
            console.log(res.data);
          })
          .catch((e) => {
            console.log(e.message);
          });
        console.log(this.dataNota);
      } else {
        if (this.transaksi) {
          this.dataNota.keterangan = "";
          this.dataNota.toke = 0;
          this.dataNota.tanggal = this.transaksi.tanggal;
          this.dataNota.id_admin = this.transaksi.akun.id;
          this.dataNota.id_cair = this.transaksi.id;
          this.dataNota.id_nota = this.idFolder;
          this.dataNota.jenis = 4;
        }
      }
    },
  },
};
</script>
