<template>
  <v-container
    ><v-row>
      <v-col cols="12">
        <v-card :loading="loading">
          <v-row
            ><v-col cols="12">
              <v-row class="ma-2">
                <v-text-field
                  class="mx-6"
                  v-model="search2"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                ></v-text-field>
                <v-btn
                  outlined
                  color="red"
                  class="ma-2"
                  @click="pemicuFilter = true"
                  >Filter</v-btn
                >
                <v-btn
                  outlined
                  color="blue"
                  class="ma-2"
                  @click="pemicuGanti = true"
                  v-if="adaTerpilih"
                  >Ganti Ramp</v-btn
                ><v-spacer></v-spacer
                ><v-btn
                  outlined
                  color="orange"
                  class="ma-2"
                  @click="pemicuBulan = true"
                  >Ganti Bulan</v-btn
                ></v-row
              >
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="data"
                :items-per-page="10"
                :search="search2"
                show-select
                v-model="terpilih"
                dense
                item-key="id"
                mobile-breakpoint="300"
                :loading="loading"
              >
                <template v-slot:item.tanggalDo="{ item }">
                  <span>{{ item.tanggalDo | formatDate }}</span>
                </template>
                <template v-slot:item.netto="{ item }">
                  <span>{{ item.netto | angka }}</span>
                </template>
                <template v-slot:item.harga="{ item }">
                  <span>{{ item.harga | angka }}</span>
                </template>
                <template v-slot:item.cair="{ item }">
                  <span>{{ (item.harga * item.netto) | angka }}</span>
                </template>
              </v-data-table></v-col
            >
          </v-row></v-card
        >
      </v-col>
    </v-row>
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="pemicuBulan"
      width="350"
      persistent
    >
      <v-card flat>
        <v-card-text>
          <v-date-picker v-model="date" type="month" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="pemicuBulan = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="filterBulan"> OK </v-btn>
          </v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="pemicuFilter"
      width="350"
      persistent
    >
      <v-card>
        <v-card-title class="headline"> Pilih Filter </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="12">
                    <v-autocomplete
                      label="Ramp"
                      v-model="dataFilter"
                      :items="ramp"
                      item-value="id"
                      item-text="nama"
                      multiple
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="pink" dark @click="resetFilter"> Reset </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="filterkan"> Filter </v-btn>
          <v-btn color="warning" text @click="pemicuFilter = false">
            Batal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="pemicuGanti"
      width="350"
      persistent
    >
      <v-card>
        <v-card-title class="headline"> Pilih Ramp </v-card-title>
        <v-card-text>
          <v-autocomplete
            label="Ramp"
            v-model="dataGanti"
            :items="listRamp"
            item-value="ID"
            item-text="nama"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="gantikan"> Simpan </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="pemicuGanti = false">
            Batal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  components: {
    // autocomplete,
  },
  data: () => ({
    data: [],
    bln: moment().format("MM"),
    thn: moment().format("YYYY"),
    loading: false,
    search2: "",
    headers: [
      {
        text: "Tanggal",
        align: "start",
        filterable: true,
        value: "tanggalDo",
      },
      { text: "Ramp", value: "toke.nama" },
      { text: "Uraian", value: "nama" },
      { text: "Bruto", value: "bruto" },
      { text: "Tara", value: "tar" },
      { text: "Tbs", value: "tbs" },
      { text: "Netto", value: "netto" },
      { text: "Harga", value: "harga" },
      { text: "Cair", value: "cair" },
    ],
    terpilih: [],
    pemicuBulan: false,
    date: moment().format("YYYY-MM"),
    pemicuFilter: false,
    ramp: [],
    dataFilter: [],
    dataAwal: [],
    pemicuGanti: false,
    proses: false,
    dataGanti: 0,
    listRamp: [],
  }),
  mounted() {
    this.inisialisasi();
  },
  computed: {
    adaTerpilih() {
      return this.terpilih.length > 0;
    },
  },
  methods: {
    async inisialisasi() {
      this.loading = true;
      const uri = `/ramp/tonase/${this.bln}/${this.thn}`;
      this.data = await axios
        .get(uri)
        .then((res) => {
          return res.data;
        })
        .finally(() => {
          this.loading = false;
        });
      this.dataAwal = [...this.data];
      this.getFilter();
      this.getListRamp();
    },
    async getListRamp() {
      let uri = `/toke/golongan/13`;
      this.listRamp = await axios.get(uri).then((res) => {
        return res.data;
      });
    },
    getFilter() {
      if (this.data.length >> 0) {
        var c = this.data.reduce(function (chace, item) {
          const properti = item.toke.ID;
          const kolomA = item.toke.nama;
          if (chace.some((el) => el.id === properti)) {
            return [...chace];
          }
          chace.push({ id: properti, nama: kolomA });
          return [...chace];
        }, []);
        this.ramp = c;
      }
    },
    async filterBulan() {
      let buln = this.date.split("-");
      this.bln = buln[1];
      this.thn = buln[0];
      console.log(`bln - ${this.bln}/ thn-${this.thn}`);
      this.pemicuBulan = false;
      this.terpilih = [];
      this.inisialisasi();
    },
    async filterkan() {
      if (this.data.length >> 0) {
        const toke = this.dataFilter;
        if (toke.length >> 0) {
          console.log("toke difilter");
          this.data = this.data.filter(function (x) {
            return toke.indexOf(x.toke.ID) !== -1;
          });
        }
      }
      this.pemicuFilter = false;
    },
    resetFilter() {
      this.dataFilter = [];
      this.pemicuFilter = false;
      this.data = [...this.dataAwal];
    },
    async gantikan() {
      if (this.proses == false) {
        this.proses = true;
        let uri = `/ramp/tonase/gantiramp/${this.dataGanti}`;
        await axios
          .post(
            uri,
            this.terpilih.map((x) => x.id)
          )
          .then((res) => {
            if (res.status) {
              this.$noty.success("berhasil");
              this.terpilih = [];
              this.inisialisasi();
            }
          })
          .finally(() => {
            this.proses = false;
            this.pemicuGanti = false;
          });
      }
    },
  },
};
</script>
