<template>
  <v-dialog transition="dialog-bottom-transition" v-model="dialog" width="800">
    <v-card dense tile>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Laporan WA</v-toolbar-title><v-spacer></v-spacer
        ><v-btn icon dark class="mx-2"> <v-icon>mdi-share</v-icon> </v-btn
        ><v-btn icon dark @click="tutup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-row class="mt-2"
          ><v-col cols="12">
            <v-textarea
              ref="laporan"
              v-model="wa"
              label="Laporan WA"
              rows="12"
              row-height="30"
            ></v-textarea> </v-col
        ></v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import numeral from "numeral";
import axios from "axios";
export default {
  props: ["pemicu", "data", "tanggal"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  mounted() {},
  data() {
    return {
      wa: "",
      lain: [],
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    inisialisasi() {
      this.getRegional();
    },
    async tulisWa() {
      this.data.kasir = this.data.kasir.filter((x) => {
        let a = x.sum;
        return x.nama != "MODAL LUAR" && (a > 1000 || a < -1000);
      });

      let wa = `LAPORAN : ${moment(this.tanggal).format("DD/MM/YYYY")} \n \n`;
      wa += `*TAGIHAN PABRIK*\n`;
      this.data.tagihan.forEach((x) => {
        wa += `${x.pabrik} : (${numeral(parseInt(x.tonase) || 0).format(
          "0,0"
        )}) ${numeral(parseInt(x.cair) || 0).format("0,0")}\n`;
      });
      wa += `*TOTAL : ${numeral(this.data.totTagihan).format("0,0")}*\n`;
      if (this.data.tagihanRamp) {
        wa += `\n*TAGIHAN RAMP*\n`;
        this.data.tagihanRamp.forEach((x) => {
          wa += `${x.nama} : (${numeral(parseInt(x.netto) || 0).format(
            "0,0"
          )}) ${numeral(parseInt(x.cair) || 0).format("0,0")}\n`;
        });
        wa += `*TOTAL : ${numeral(this.data.totRamp).format("0,0")}*\n`;
      }
      wa += `\n*BANK*\n`;
      this.data.bank.forEach((x) => {
        wa += `${x.nama} : ${numeral(parseInt(x.sum) || 0).format("0,0")}\n`;
      });
      wa += `*TOTAL : ${numeral(this.data.totBank).format("0,0")}*\n`;
      wa += `\n*SALDO LAINYA*\n`;
      this.data.kasir.forEach((x) => {
        wa += `${x.nama} : ${numeral(parseInt(x.sum) || 0).format("0,0")}\n`;
      });
      wa += `*TOTAL : ${numeral(this.data.totKasir).format("0,0")}*\n`;
      wa += `\n*PINJAMAN*\n`;
      this.data.regional.forEach((x) => {
        wa += `${x.regional} : ${numeral(parseInt(x.saldo) || 0).format(
          "0,0"
        )}\n`;
      });
      wa += `TOTAL PINJAMAN TETAP: ${numeral(
        parseInt(this.data.pinjaman[1].saldo) || 0
      ).format("0,0")}\n`;
      wa += `TOTAL PINJAMAN SEMENTARA: ${numeral(
        parseInt(this.data.pinjaman[0].saldo) || 0
      ).format("0,0")}\n`;
      wa += `TOTAL ANGSURAN BLN INI : ${numeral(
        parseInt(this.data.angsuran.angsur) || 0
      ).format("0,0")}\n`;
      wa += `TOTAL PINJAMAN BLN INI : ${numeral(
        parseInt(this.data.angsuran.pinjam) || 0
      ).format("0,0")}\n`;

      wa += `\n*MODAL RAMP*\n`;
      this.data.modalRamp.forEach((x) => {
        wa += `${x.nama} : ${numeral(parseInt(x.sum) || 0).format("0,0")}\n`;
      });

      wa += `\n*PENGELUARAN*\n`;
      let totKnkt = 0;
      // console.log(angsuran);
      let angsurans = this.data.knkt.find((x) => {
        return x.id == 11;
      });
      angsurans = angsurans ? angsurans.sum : 0;
      wa += `ANGSURAN BANK : ${numeral(parseInt(angsurans) || 0).format(
        "0,0"
      )} \n`;

      let kn = parseInt(
        this.data.knkt.find((x) => {
          return x.id == 3;
        }).sum
      );

      wa += `KANTOR : ${numeral(parseInt(kn) || 0).format("0,0")} \n`;
      let th = parseInt(
        this.data.knkt.find((x) => {
          return x.id == 5;
        }).sum
      );
      wa += `T HARGA DAN PIJAK GAS : ${numeral(parseInt(th) || 0).format(
        "0,0"
      )}\n`;
      totKnkt = totKnkt + th + kn;
      wa += `*TOTAL : ${numeral(totKnkt).format("0,0")}*\n`;
      wa += `\n*OPS B DONI*\n`;
      this.data.bd.forEach((x) => {
        wa += `${x.nama} : ${numeral(parseInt(x.sum) || 0).format("0,0")}\n`;
      });
      wa += `TOTAL: ${numeral(parseInt(this.data.totBd) || 0).format("0,0")}\n`;
      wa += `\n*MODAL LAINYA*\n`;
      wa += `FEE TERPAKAI : ${numeral(parseInt(this.data.fee.fee) || 0).format(
        "0,0"
      )}\n`;
      wa += `BELUM CAIR : ${numeral(parseInt(this.data.fee.belum) || 0).format(
        "0,0"
      )}\n`;
      wa += `PPH : ${numeral(parseInt(this.data.fee.pph) || 0).format(
        "0,0"
      )}\n`;
      let modal_luar = this.data.knkt.find((x) => {
        return x.id == 10;
      });
      const modal_l = modal_luar ? parseInt(modal_luar.sum * -1) : 0;
      console.log("modal luar");
      console.log(modal_l);
      wa += `MODAL LUAR : ${numeral(modal_l).format("0,0")}\n`;
      this.data.feeRamp.forEach((x) => {
        wa += `${x.nama} : ${numeral(parseInt(x.sum * -1) || 0).format(
          "0,0"
        )}\n`;
      });
      const modal_utama = await this.getModalTotal();
      let modal_pasif =
        parseInt(this.data.pinjaman[1].saldo) +
        parseInt(totKnkt) +
        parseInt(this.data.totBd);
      let modal_fee =
        modal_l + this.data.totFeeRamp + parseInt(this.data.fee.fee);
      const modal_aktif = modal_utama - modal_pasif + modal_fee;
      wa += `\n MODAL UTAMA: ${numeral(modal_utama).format("0,0")}`;
      wa += `\n MODAL PASIF: ${numeral(modal_pasif).format("0,0")}`;
      this.wa = wa;
      wa += `\n MODAL LUAR DAN FEE: ${numeral(modal_fee).format("0,0")}`;
      this.wa = wa;
      wa += `\n MODAL AKTIF: ${numeral(modal_aktif).format("0,0")}`;
      this.wa = wa;
    },
    getRegional() {
      let uri = `/regional/saldo/${this.tanggal}/PT`;
      axios.get(uri).then((response) => {
        this.data.regional = response.data;
        this.getAngsuran();
      });
    },
    getAngsuran() {
      let uri = `/pinjaman/angsuran/${this.tanggal}`;
      axios.get(uri).then((response) => {
        let dt = response.data;
        this.data.angsuran = dt.find((x) => {
          return x.jenis == "PT";
        });
        this.getLain();
      });
    },
    async getModalTotal() {
      let uri = `/modalu/tanggal/${this.tanggal}`;
      return axios.get(uri).then((res) => {
        console.log(res.data);
        return res.data;
      });
    },
    getLain() {
      let uri = `/golongan/saldoToke/6/${this.tanggal}`;
      axios.get(uri).then((response) => {
        this.lain = response.data;
        this.tulisWa();
        console.log(this.lain);
      });
    },
  },
};
</script>
