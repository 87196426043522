<template>
	<v-dialog
		transition="dialog-bottom-transition"
		v-model="dialog"
		width="600"
		persistent>
		<v-card dense tile>
			<v-card-title>{{ id ? "Edit Golongan" : "Golongan Baru" }}</v-card-title>
			<v-card-text>
				<v-form
					@submit.prevent="kirim()"
					color="grey"
					ref="form"
					v-model="valid">
					<v-row dense>
						<v-col cols="12">
							<v-text-field
								label="Nama golongan"
								required
								v-model="data.golongan"
								dense
								:rules="[(v) => !!v || 'Harus diisi']"></v-text-field
						></v-col>
						<v-btn color="blue" dark type="submit" :disabled="loading"
							>Simpan</v-btn
						>
						<v-spacer></v-spacer>
						<v-btn color="red" dark @click="tutup()">Batal</v-btn>
					</v-row>
				</v-form>
			</v-card-text>
			<v-card-actions> </v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
	import axios from "axios";
	export default {
		props: ["pemicu", "id"],
		components: {},
		computed: {
			dialog: {
				get() {
					return this.pemicu;
				},
				set() {
					this.$emit("tutup");
				},
			},
		},
		watch: {
			pemicu() {
				this.inisialisasi();
			},
		},
		mounted() {},
		data() {
			return {
				valid: false,
				loading: false,
				data: { golongan: "" },
			};
		},
		methods: {
			tutup() {
				this.$emit("tutup");
			},
			kirim() {
				if (this.valid && this.loading == false) {
					this.loading = true;
					if (this.id > 0) {
						let uri = `/golongan/${this.id}`;
						axios
							.put(uri, this.data)
							.then((response) => {
								if (response.data.status) {
									this.$noty.success("Data berhasil disimpan");
									this.tutup();
									this.$emit("simpan");
								} else {
									this.$noty.success(response.data.data[0]);
								}
							})
							.finally((this.loading = false));
					} else {
						console.log(this.data);
						let uri = "/golongan";
						axios
							.post(uri, this.data)
							.then((response) => {
								if (response.data.status) {
									this.$noty.success("Data berhasil disimpan");
									this.tutup();
									this.$emit("simpan");
									this.loadingSimpan = false;
								} else {
									this.$noty.success(response.data.data[0]);
								}
							})
							.finally((this.loading = false));
					}
				}
			},
			inisialisasi() {
				if (this.id) {
					console.log("edit");
					let uri = `/golongan/${this.id}`;
					this.loading = true;
					axios
						.get(uri)
						.then((res) => {
							this.data.golongan = res.data.golongan;
						})
						.finally((this.loading = false));
				} else {
					console.log("baru");
					this.data.golongan = "";
				}
			},
		},
	};
</script>
