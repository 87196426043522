<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    fullscreen
    hide-overlay
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="tutup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Rekap Tonase</v-toolbar-title><v-spacer></v-spacer
        ><download-excel
          :data="tonase"
          :fields="fieldExcell"
          worksheet="My Worksheet"
          :name="filename"
        >
          <v-btn color="pink" dark>Excell</v-btn>
        </download-excel>
      </v-toolbar>
      <v-card-text>
        <v-row
          ><v-col cols="12" md="4" class="mt-4">
            <table>
              <tbody>
                <tr>
                  <td colspan="2">{{ invoice["no inv"] }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    {{ invoice.pabrik ? invoice.pabrik.pabrik : "" }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <span class="ma-3">
                      Total Ton : {{ invoice.tonase | angka }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <span class="ma-3">
                      DPP:
                      {{ invoice.dpp | rupiah }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="ma-3"> PPN : {{ invoice.ppn | rupiah }}</span>
                  </td>
                  <td>
                    <span class="ma-3">
                      PPN Fakta :
                      <a @click="pemicuFakta = true">{{
                        invoice["PPN FAKTA"] | rupiah
                      }}</a></span
                    >
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <span class="ma-3">
                      Selisih:
                      {{
                        (parseInt(invoice.ppn) - parseInt(invoice["PPN FAKTA"]))
                          | rupiah
                      }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <span class="ma-3">
                      PPH:
                      {{ invoice.pph | rupiah }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <v-row>
              <v-col>
                <v-btn color="blue" @click="singkron" dark
                  >Refresh</v-btn
                ></v-col
              ></v-row
            ></v-col
          ><v-col cols="12" md="8" class="mt-4">
            <v-data-table
              :headers="judul"
              :items="tonase"
              :items-per-page="12"
              :loading="loading"
              class="elevation-1"
              dense
              :footer-props="{
                itemsPerPageText: 'tampilkan',
              }"
            >
              <template v-slot:item.tanggaldo="{ item }">
                {{ item.tanggaldo | formatDate }}
              </template>
              <template v-slot:item.tonase="{ item }">
                {{ item["tonase"] | angka }}
              </template>
              <template v-slot:item.harga="{ item }">
                {{ item["harga"] | rupiah }}
              </template>
              <template v-slot:item.dpp="{ item }">
                {{ item["dpp"] | rupiah }}
              </template>
              <template v-slot:item.opsi="{ item }">
                <v-btn
                  class="mx-1"
                  icon
                  x-small
                  color="green"
                  @click="rinciTon(item)"
                  ><v-icon> mdi-open-in-new </v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
      <tonase-rinci
        :pemicu="pemicuTon"
        @tutup="pemicuTon = false"
        :tonase="tonaseTon"
        @lanjutkan="reload"
      ></tonase-rinci>
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="pemicuFakta"
        width="400"
      >
        <v-card>
          <v-card-title>PPN Fakta</v-card-title
          ><v-card-text>
            <v-form
              @submit.prevent="simpan()"
              color="grey"
              ref="form"
              v-model="valid"
              ><v-text-field
                label="PPN Fakta"
                required
                :rules="[
                  (v) => v <= 1000000000000 || 'Terlalu besar',
                  (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                ]"
                v-model="invoice['PPN FAKTA']"
                type="number"
              ></v-text-field
              ><v-btn color="green" dark type="submit" @click="simpan"
                >simpan</v-btn
              >
              <v-btn @click="pemicuFakta = false" color="red" dark
                >Batal</v-btn
              ></v-form
            ></v-card-text
          ></v-card
        >
      </v-dialog>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import tonaseRinci from "./rinciton.vue";
export default {
  props: ["pemicu", "id"],
  components: { tonaseRinci },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    filename() {
      return `Inv-${this.invoice["no inv"]}`;
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      data: [],
      tonase: [],
      loading: false,
      judul: [
        {
          text: "Tanggal",
          value: "tanggaldo",
        },
        {
          text: "Tonase",
          value: "tonase",
        },
        {
          text: "harga",
          value: "harga",
        },
        {
          text: "DPP",
          value: "dpp",
        },
        {
          text: "Opsi",
          value: "opsi",
        },
      ],
      tonaseTon: [],
      pemicuTon: false,
      totalTon: 0,
      totalDPP: 0,
      totalPPN: 0,
      totalPPH: 0,
      invoice: {},
      pemicuFakta: false,
      loadingSimpan: false,
      dataKirim: {},
      fieldExcell: {
        Tanggal: "tanggaldo",
        Tonase: "tonase",
        harga: "harga",
        dpp: "dpp",
      },
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    inisialisasi() {
      if (this.id > 0) {
        let uri = "/invoice/tonase/" + this.id;
        this.loading = true;
        axios.get(uri).then((response) => {
          this.data = response.data.tonase;
          this.invoice = response.data.invoice;
          console.log(this.invoice);
          this.loading = false;
          this.tonase = this.grupkan(this.data);
          console.log(this.tonase);
          this.sumary();
        });
      }
    },
    grupkan(array) {
      if (array.length >> 0) {
        var result = [];
        var hasil = [];
        array.reduce(function (res, value) {
          if (!res[value.tanggaldo]) {
            res[value.tanggaldo] = { tanggal: value.tanggaldo };
            result.push(res[value.tanggaldo]);
          }
          return res;
        }, {});
        console.log(result);
        result.forEach(function (val) {
          var a = array.filter(function (x) {
            return x.tanggaldo == val.tanggal;
          });
          var c = a.reduce(function (chace, item) {
            const properti = parseInt(item.harga) + parseInt(item.do.fee);
            if (chace.some((el) => parseInt(el.harga) == properti)) {
              var terpilih = chace.find(function (x) {
                return parseInt(x.harga) === parseInt(properti);
              });
              terpilih.tonase += parseInt(item.tonase);
              terpilih.dpp +=
                (parseInt(item.harga) + parseInt(item.do.fee)) *
                parseInt(item.tonase);
              return [...chace];
            }
            chace.push({
              tanggaldo: item.tanggaldo,
              tonase: parseInt(item.tonase),
              harga: parseInt(item.harga) + parseInt(item.do.fee),
              dpp:
                (parseInt(item.harga) + parseInt(item.do.fee)) *
                parseInt(item.tonase),
            });
            return [...chace];
          }, []);
          c.forEach(function (item) {
            hasil.push(item);
          });
        });
        hasil.sort(function (a, b) {
          return (
            new Date(a.tanggaldo).getTime() - new Date(b.tanggaldo).getTime()
          );
        });
        return hasil;
      }
      return array;
    },
    sumary() {
      this.totalTon = this.tonase.reduce(function (simpan, item) {
        return (simpan += parseInt(item.tonase));
      }, 0);
      this.totalDPP = this.tonase.reduce(function (simpan, item) {
        return (simpan += parseInt(item.dpp));
      }, 0);
      this.totalPPN = (this.totalDPP * 10) / 100;
      this.totalPPH = (this.totalDPP * 0.25) / 100;
    },
    rinciTon(item) {
      this.tonaseTon = this.data.filter((x) => {
        return (
          x.tanggaldo == item.tanggaldo &&
          parseInt(x.harga) + parseInt(x.do.fee) === parseInt(item.harga)
        );
      });
      this.pemicuTon = true;
    },
    simpan() {
      if (this.valid && this.loadingSimpan == false) {
        this.dataKirim = { "PPN FAKTA": parseInt(this.invoice["PPN FAKTA"]) };
        this.kirim();
      }
    },
    kirim() {
      this.loadingSimpan = true;
      let uri = "/invoice/" + this.id;
      axios
        .put(uri, this.dataKirim)
        .then((response) => {
          if (response.data.status) {
            this.$noty.success("Data berhasil disimpan");
            this.$emit("lanjutkan");
            this.pemicuFakta = false;
            console.log(response.data.data);
            this.loadingSimpan = false;
          } else {
            this.$noty.success("gagal disimpan");
          }
        })
        .catch((ex) => {
          console.log(ex);
        });
    },
    singkron() {
      this.dataKirim = {
        dpp: parseInt(this.totalDPP),
        ppn: parseInt(this.totalPPN),
        pph: parseInt(this.totalPPH),
        tonase: parseInt(this.totalTon),
      };
      console.log(this.dataKirim);
      this.kirim();
    },
    reload() {
      this.inisialisasi();
      this.$emit("lanjutkan");
    },
  },
};
</script>
