<template>
  <v-card rounded :loading="loading" class="mx-1 mx-sm-12">
    <v-card-actions
      ><v-btn fab color="red" dark @click="baru"
        ><v-icon>mdi-plus</v-icon></v-btn
      ></v-card-actions
    >
    <v-card-title class="mb-10">
      Daftar Rekening <v-spacer></v-spacer>
      <v-text-field
        class="mx-1 mx-sm-6"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
      ></v-text-field
    ></v-card-title>

    <v-row class="mb-12 mx-1 mx-sm-12">
      <v-col
        v-for="item in dataFilter"
        :key="item.id"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card>
          <v-card
            class="elevation-8"
            color="orange darken-1"
            @click="buka(item)"
            rounded
            shaped
          >
            <v-card-title class="white--text">
              {{ item.nama }}
            </v-card-title>
            <v-card-subtitle class="white--text"
              >Saldo: {{ item.saldo | rupiah }}
            </v-card-subtitle></v-card
          >
          <div class="text-caption white ma-2">
            <a @click="edit(item.id)">Edit</a>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <rekening
      :pemicu="pemicuRekening"
      @tutup="pemicuRekening = false"
      @update="inisialisasi"
      :id="idRekening"
    ></rekening>
  </v-card>
</template>

<script>
import axios from "axios";
import rekening from "./rekening.vue";
export default {
  components: { rekening },
  data: () => ({
    data: [],
    search: "",
    loading: false,
    pemicuRekening: false,
    idRekening: 0,
  }),
  mounted() {
    this.inisialisasi();
  },
  computed: {
    dataFilter() {
      return this.data.filter((item) => {
        let nama = item.nama.toLowerCase();
        return nama.indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let uri = "/rekening";
      axios.get(uri).then((response) => {
        this.data = response.data;
        console.log(this.data);
        this.loading = false;
      });
    },
    baru() {
      this.idRekening = 0;
      this.pemicuRekening = true;
    },
    buka(id) {
      if (id.bank.golongan == 9) {
        this.$router.push("/rekeningbank/" + id.id);
      } else {
        this.$router.push("/rekeningkoran/" + id.id);
      }
    },
    edit(id) {
      this.idRekening = id;
      this.pemicuRekening = true;
    },
  },
};
</script>
