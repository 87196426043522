<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Harga Baru</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="dataTransaksi.pabrik"
                autofocus
                auto-select-first
                item-text="pabrik"
                item-value="id"
                :items="grup"
                label="Pabrik"
                ref="pabrik"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Harga"
                required
                :rules="[
                  (v) => v <= 10000000000 || 'Terlalu besar',
                  (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                ]"
                v-model="dataTransaksi.harga"
                type="number"
                ref="harga"
              ></v-text-field
            ></v-col>
            <v-btn color="blue" dark type="submit" :disabled="loadingSimpan"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import axios from "axios";
export default {
  props: ["pemicu", "tanggal", "pabrik", "grup"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    ubahTanggal() {
      return this.dataTransaksi
        ? moment(this.dataTransaksi.tanggal).format("DD/MM/YYYY")
        : "";
    },
  },
  watch: {
    pemicu() {
      this.kondisikan();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      dataTransaksi: {
        ids: [],
        tanggal: "",
        pabrik: 0,
        harga: 0,
      },
      pupup: false,
      loading: false,
      loadingSimpan: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid && this.loadingSimpan == false) {
        this.loadingSimpan = true;
        this.simpan();
        console.log(this.dataTransaksi);
        let uri = "/harga/banyak";
        axios.post(uri, this.dataTransaksi).then((response) => {
          if (response.data) {
            this.$noty.success("Data berhasil disimpan");
            this.tutup();
            this.$emit("lanjutkan", response.data.data);
            console.log(response.data.data);
            this.loadingSimpan = false;
          }
        });
      }
    },
    inisialisasi() {},
    simpan() {
      this.dataTransaksi.ids = [];
      let daftar = this.pabrik.filter((x) => {
        return x.grup_pabrik == this.dataTransaksi.pabrik;
      });
      daftar.forEach((x) => {
        this.dataTransaksi.ids.push(x.id);
      });
      this.dataTransaksi.harga = parseInt(this.dataTransaksi.harga);
      this.dataTransaksi.tanggal = this.tanggal;
    },
    kondisikan() {
      this.dataTransaksi.tanggal = moment().format("YYYY-MM-DD");
      this.dataTransaksi.pabrik = this.pabrik;
      this.dataTransaksi.harga = 0;
    },
  },
};
</script>
