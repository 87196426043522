<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    persistent
    fullscreen
  >
    <template v-slot:default="dialog">
      <v-card :loading="loading">
        <v-toolbar>
          <v-btn icon @click="dialog.value = false" class="mr-4">
            <v-icon>mdi-arrow-left</v-icon> </v-btn
          ><v-toolbar-title>Input Tonase</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form
            @submit.prevent="updateData"
            color="grey"
            ref="form"
            v-model="valid"
          >
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedDateFormattedMomentjs"
                        clearable
                        label="Tanggal"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="date = null"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="tanggaldo"
                      @change="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="data.toke"
                    :items="toke"
                    item-text="nama"
                    item-value="ID"
                    :rules="[(v) => !!v || 'Item is required']"
                    label="Toke"
                    dense
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    :items="pabriks"
                    label="Pabrik"
                    item-text="pabrik"
                    item-value="id"
                    v-model="pabrik"
                    :rules="[(v) => !!v || 'Harus di isi']"
                    required
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Nama Supir"
                    required
                    v-model="data.supir"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Plat"
                    required
                    v-model="data.plat"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    label="Tonase"
                    required
                    v-model="data.tonase"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3" md="2">
                  <v-text-field
                    label="th"
                    required
                    v-model="data.th"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    label="OPS"
                    required
                    v-model="data.ops"
                    dense
                  ></v-text-field> </v-col></v-row
              ><v-row>
                <v-col cols="12">
                  <v-card>
                    <span class="text-h6 pa-3"
                      >{{ data.tonase | angka }} X
                      {{ hargaHitung ? hargaHitung.harga : 0 }}
                    </span>
                    <span class="text-h6 pa-3">
                      {{
                        ((hargaHitung ? hargaHitung.harga : 0) * data.tonase)
                          | rupiah
                      }}
                    </span>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    type="submit"
                    :disabled="tombolOff"
                    class="ma-2"
                  >
                    Simpan
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    computedDateFormattedMomentjs() {
      return this.data ? moment(this.tanggaldo).format("DD/MM/YYYY") : "";
    },
  },
  watch: {
    pemicu() {
      if (this.pemicu) this.inisialisasi();
    },
    taggal() {
      this.hargaHitung = this.cariHarga();
    },
    pabrik() {
      this.hargaHitung = this.cariHarga();
    },
  },
  mounted() {},
  props: ["id", "pemicu", "nota", "pabriks", "deo", "toke"],
  data() {
    return {
      data: {},
      valid: false,
      menu1: false,
      tombolOff: false,
      loading: false,
      tanggaldo: "",
      pabrik: 1,
      hargaHitung: {},
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup", 0);
    },
    async inisialisasi() {
      console.log("di inisialisasi");
      this.loading = true;
      this.harga = await this.getHarga();
      if (this.id) {
        this.loading = true;
        axios.get("/tonase/" + this.id).then((response) => {
          this.data = response.data;
          this.data.supir = this.data["nama supir"];
          this.data.do = parseInt(this.data.do);
          this.data.toke = parseInt(this.data.toke);
          this.data.tonase = parseInt(this.data.tonase);
          this.data.th = parseInt(this.data.th);
          this.tanggaldo = this.data.tanggaldo;
          this.pabrik = this.deo.find((x) => x.id == this.data.do).pabrik.id;
          console.log(this.data);
        });
      } else {
        this.kosongkan();
      }
      this.hargaHitung = this.cariHarga();
      this.loading = false;
    },
    kosongkan() {
      this.data.supir = "";
      this.data.tonase = 0;
      this.data.plat = "";
      this.data.harga = 0;
      this.data.toke = this.nota.toke.ID;
      this.data.th = 0;
      this.data.ops = 0;
      this.pabrik = 1;
      this.data.id = 0;
      this.tanggaldo = this.nota.tanggal;
    },

    async getHarga() {
      return axios
        .get("/harga/60hari/" + this.nota.tanggal)
        .then((response) => {
          return response.data;
        });
    },
    updateData() {
      if (this.valid) {
        this.tombolOff = true;
        this.loading = true;
        let tabel = this.data;
        tabel.pabrik = this.pabrik;
        tabel.tanggaldo = this.tanggaldo;
        tabel.nota = this.nota;
        tabel.do = this.deo
          .filter((x) => x.pabrik.id == tabel.pabrik)
          .sort((a, b) => a.no - b.no)[0].id;
        this.data.harga = this.cariHarga().harga;
        this.data.id_harga = this.cariHarga().id;
        console.log(this.data);
        let uri = `/pencairan/tonase`;
        axios
          .post(uri, this.data)
          .then((response) => {
            if (response.data.status) {
              console.log(response.data);
              this.$noty.success(response.data.messages);
              this.$emit("reload");
            }
          })
          .catch((error) => {
            this.$noty.error(error.response.data.errors[0]);
          })
          .finally(() => {
            this.loading = false;
            this.tombolOff = false;
            this.tutup();
          });
      }
    },
    cariHarga() {
      let pabrik = this.pabrik;
      let tgl = this.tanggaldo;
      if (pabrik && tgl) {
        console.log(`${pabrik} == ${tgl}`);
        let hasil = this.harga.filter(function (item) {
          return (
            moment(tgl).isSameOrAfter(item.tanggal) && pabrik == item.pabrik
          );
        });
        if (hasil.length > 0) {
          return hasil[hasil.length - 1];
        } else {
          return 0;
        }
      }
    },
    simpan() {},
  },
};
</script>
