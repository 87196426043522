<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile>
      <v-card-title>User</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Nama"
                required
                v-model="user.name"
                prepend-icon="mdi-account"
                type="text"
                ref="nama"
              ></v-text-field
            ></v-col>
            <v-col cols="12">
              <v-text-field
                label="Email/user"
                prepend-icon="mdi-email"
                required
                v-model="user.email"
                type="text"
                ref="email"
              ></v-text-field
            ></v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="user.user_level"
                dense
                auto-select-first
                :items="[
                  'super_admin',
                  'kasir',
                  'kasir_ram',
                  'admin_ram',
                  'visitor',
                  'kasir_limit',
                ]"
                label="Level User"
                :rules="[(v) => !!v || 'Harus diisi']"
              ></v-autocomplete
            ></v-col>

            <v-btn color="blue" dark type="submit" :disabled="loadingSimpan"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["pemicu", "idUser"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
      this.kondisikan();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      user: {
        name: "",
        email: "",
        user_level: "",
      },
      loading: false,
      loadingSimpan: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid && this.loadingSimpan == false) {
        this.loadingSimpan = true;
        let uri = `/user/${this.idUser}`;
        axios.post(uri, this.user).then((response) => {
          if (response.data.status) {
            this.$noty.success("Data berhasil disimpan");
            this.tutup();
            this.$emit("lanjutkan");
            console.log(response.data.data);
            this.loadingSimpan = false;
          } else {
            this.$noty.success("Data Gagal disimpan");
            this.loadingSimpan = false;
          }
        });
      }
    },
    inisialisasi() {
      let uri = `/user/${this.idUser}`;
      axios
        .get(uri)
        .then((res) => {
          this.user.email = res.data.email;
          this.user.name = res.data.name;
          this.user.user_level = res.data.user_level;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    kondisikan() {},
  },
};
</script>
