<template>
  <v-row class="ma-2"
    ><v-col cols="12" md="3">
      <v-date-picker v-model="tanggal" elevation="4"></v-date-picker
    ></v-col>
    <v-col cols="12" md="9">
      <v-card color="grey lighten-3" :loading="loading"
        ><v-toolbar color="blue darken-3" dense dark>
          <v-toolbar-title
            ><v-btn class="mx-3" fab color="red" dark @click="baru"
              ><v-icon>mdi-plus</v-icon></v-btn
            >{{ akun.nama }}
            <span class="ml-4 text-caption">{{
              saldo | rupiah
            }}</span></v-toolbar-title
          >
          <v-spacer></v-spacer>

          <v-btn color="red" icon x-large @click="$router.push('/rekening')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-row class="ma-1">
          <v-col cols="12">
            <v-row>
              <v-text-field
                class="mx-6"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                dense
              ></v-text-field
              ><v-spacer></v-spacer>
              <v-btn
                class="mx-2"
                color="red"
                dark
                @click="pemicuToke = true"
                v-if="terpilih.length > 0"
                >Ganti Toke</v-btn
              >
              <v-btn class="mx-2" color="pink" dark @click="pemicuFilter = true"
                >Filter</v-btn
              >
              <input
                style="display: none"
                ref="upload"
                type="file"
                @change="kasihNama"
                accept=".xlsx"
              />
              <v-btn @click="$refs.upload.click()" class="mx-2">Upload</v-btn>
              <v-btn dark color="green" @click="pemicuExcel = true"
                ><v-icon>mdi-microsoft-excel</v-icon></v-btn
              >
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="data"
              :items-per-page="100"
              :search="search"
              show-select
              v-model="terpilih"
              dense
              item-key="id"
              mobile-breakpoint="300"
              :loading="loading"
              hide-default-footer
            >
              <template v-slot:item.masuk="{ item }">
                <span>{{ item.masuk | angka }}</span>
              </template>
              <template v-slot:item.keluar="{ item }">
                <span>{{ item.keluar | angka }}</span>
              </template>
              <template v-slot:item.saldo="{ item }">
                <span>{{ item.saldo | angka }}</span>
              </template>
              <template v-slot:item.opsi="{ item }">
                <span
                  ><v-btn icon @click="edit(item.id)" class="mx-1"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  ><v-btn icon @click="hapus(item.id)" class="mx-1"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  ></span
                >
              </template>
              <template v-slot:item.valid="{ item }">
                <v-icon v-if="item.valid == 0">mdi-close</v-icon
                ><v-icon v-else>mdi-check</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <v-col cols="12"> </v-col>
    </v-col>
    <rekkor
      :pemicu="pemicuRekkor"
      @tutup="pemicuRekkor = false"
      @update="inisialisasi"
      :id="idRekkor"
      :rekening="id"
      :tanggal="tanggal"
    ></rekkor>
    <filters
      :pemicu="pemicuFilter"
      @tutup="pemicuFilter = false"
      :data="data"
      @simpan="updateFilter"
      @reset="inisialisasi()"
    ></filters>
    <rekeningExcel
      :pemicu="pemicuExcel"
      @tutup="pemicuExcel = false"
      :tanggal="tanggal"
      :pinjaman="data"
      :reloader="reloader"
      @reload="inisialisasi"
      :toke="toke"
      :rekening="id"
    ></rekeningExcel>
    <tokes
      :pemicu="pemicuToke"
      @aksi="gantiToke"
      @tutup="pemicuToke = false"
    ></tokes>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import rekkor from "./rekkor.vue";
import filters from "./filter.vue";
import rekeningExcel from "./rekeningkoranExcel.vue";
import tokes from "../../Tonase/gantiToke.vue";
export default {
  components: { rekkor, filters, rekeningExcel, tokes },
  props: ["id"],
  computed: {},
  data() {
    return {
      tanggal: moment().format("YYYY-MM-DD"),
      loading: false,
      data: [],
      akun: {},
      pemicuRekkor: false,
      search: "",
      terpilih: [],
      idRekkor: 0,
      pemicuFilter: false,
      pemicuExcel: false,
      reloader: false,
      headers: [
        {
          text: "Jenis",
          align: "start",
          filterable: true,
          value: "jenis",
        },
        { text: "Akun", value: "toke.nama" },
        { text: "Uraian", value: "uraian" },
        { text: "Masuk", value: "masuk" },
        { text: "Keluar", value: "keluar" },
        { text: "saldo", value: "saldo" },
        { text: "v", value: "valid" },
        { text: "opsi", value: "opsi" },
      ],
      fieldExcell: {
        ID: "id",
        Jenis: "jenis",
        Tanggal: "tanggal",
        Akun: "toke.nama",
        uraian: "uraian",
        Masuk: "masuk",
        Keluar: "keluar",
        Saldo: "saldo",
      },
      saldo: 0,
      toke: {},
      fileUpload: null,
      pemicuToke: false,
    };
  },
  mounted() {
    this.inisialisasi();
  },
  watch: {
    tanggal() {
      this.inisialisasi();
    },
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      this.terpilih = [];
      let url = "/rekening/" + this.id;
      axios.get(url).then((response) => {
        this.akun = response.data;
        let uri = `/rekeningkoran/buka/${this.id}/${this.tanggal}`;
        axios.get(uri).then((res) => {
          this.getToke();
          this.data = res.data.data;
          let saldoA = res.data.saldo;
          this.data = this.data.map((x) => {
            saldoA = saldoA + parseInt(x.masuk) - parseInt(x.keluar);
            x.saldo = saldoA;
            return x;
          });
          const p = this.data.length;
          if (p > 0) {
            this.saldo = this.data[p - 1].saldo;
          } else {
            this.saldo = saldoA;
          }
          console.log(this.saldo);
          console.log(this.data);
          this.reloader = !this.reloader;
          this.loading = false;
        });
      });
    },
    getToke() {
      axios.get("/tokeAktif").then((res) => {
        this.toke = this.convertList(res.data, "ID", "nama");
      });
    },
    home() {
      this.$router.push("/rekening");
    },
    baru() {
      this.idRekkor = 0;
      this.pemicuRekkor = true;
    },
    edit(id) {
      this.idRekkor = id;
      this.pemicuRekkor = true;
    },
    gantiToke(item) {
      if (!this.loading) {
        this.loading = true;
        this.pemicuToke = false;
        console.info("ganti toke");
        let data = {};
        data.array = this.terpilih.map((x) => x.id);
        data.id = item.ID;
        console.info(data);
        const uri = "/rekeningkoran/gantitoke";
        axios
          .post(uri, data)
          .then((res) => {
            this.$noty.success("Tugas Berhasil di eksekusi");
            console.log(res.data);
          })
          .finally(() => {
            this.inisialisasi();
            this.loading = false;
          });
      }
    },
    hapus(id) {
      let uri = `/rekeningkoran/${id}`;
      axios.delete(uri).then((res) => {
        console.log(res.data);
        this.inisialisasi();
      });
    },
    updateFilter(item) {
      this.data = item;
    },
    kasihNama(item) {
      this.fileUpload = item.target.files[0];
      if (this.fileUpload) {
        this.uploadNota();
      }
    },
    async uploadNota() {
      const uri = `/rekeningkoran/upload/${this.akun.id}`;
      if (!this.loading) {
        this.loading = true;
        if (!this.fileUpload) {
          return;
        }
        console.log(this.fileUpload);
        const data = new FormData();
        data.append("excel", this.fileUpload, this.fileUpload.name);
        axios
          .post(uri, data)
          .then((res) => {
            this.$noty.success("Berhasil di upload");
            console.log(res.data);
          })
          .finally(() => {
            this.inisialisasi();
            this.loading = false;
          });
      }
    },
    refresh() {},
    convertList(data, key, value) {
      let hasil = data.reduce(
        (obj, item) => Object.assign(obj, { [item[key]]: item[value] }),
        {}
      );
      return hasil;
    },
  },
};
</script>
