var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"400","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"dense":"","tile":""}},[_c('v-card-title',[_vm._v("User")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"color":"grey"},on:{"submit":function($event){$event.preventDefault();return _vm.kirim()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"nama",attrs:{"label":"Nama","required":"","prepend-icon":"mdi-account","type":"text"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"email",attrs:{"label":"Email/user","prepend-icon":"mdi-email","required":"","type":"text"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"dense":"","auto-select-first":"","items":[
                'super_admin',
                'kasir',
                'kasir_ram',
                'admin_ram',
                'visitor',
                'kasir_limit',
              ],"label":"Level User","rules":[(v) => !!v || 'Harus diisi']},model:{value:(_vm.user.user_level),callback:function ($$v) {_vm.$set(_vm.user, "user_level", $$v)},expression:"user.user_level"}})],1),_c('v-btn',{attrs:{"color":"blue","dark":"","type":"submit","disabled":_vm.loadingSimpan}},[_vm._v("Simpan")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.tutup()}}},[_vm._v("Batal")])],1)],1)],1),_c('v-card-actions')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }