<template>
  <v-row>
    <v-col cols="12">
      <v-card :loading="loading">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            dense
          ></v-text-field>
          <v-switch label="tanpa nota" v-model="noNota" class="ml-2"></v-switch>
          <v-switch label="bulan ini" v-model="sebulan" class="ml-4"></v-switch>
          <v-btn
            color="orange"
            class="ml-1"
            x-small
            dark
            @click="pemicuPindah = true"
            :disabled="adaYangTerpilih"
            >Pindah Nota</v-btn
          >
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="pinjaman"
          :search="search"
          :items-per-page="10"
          show-select
          dense
          v-model="terpilih"
          item-key="ID"
          mobile-breakpoint="300"
          group-by="jenis"
          :footer-props="{
            showFirstLastPage: false,
            prevIcon: 'mdi-arrow-left-drop-circle-outline',
            nextIcon: 'mdi-arrow-right-drop-circle-outline',
          }"
        >
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="11" class="blue lighten-4">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].jenis }}
            </th>
          </template>
          <template v-slot:item.pinjam="{ item }">
            <span>{{ item.pinjam | rupiah }}</span>
          </template>
          <template v-slot:item.tanggal="{ item }">
            <span>{{ item.tanggal | formatDate }}</span>
          </template>
          <template v-slot:item.angsur="{ item }">
            <span>{{ item.angsur | rupiah }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mx-2"
              icon
              x-small
              color="green"
              @click.stop="edit(item)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              icon
              x-small
              color="red"
              :nota="nota"
              @click.stop="hapus(item)"
              :loading="loadingDelet"
              :disabled="loadingDelet"
            >
              <v-icon> mdi-delete-circle-outline </v-icon>
            </v-btn>
          </template></v-data-table
        >
        <pinjamanedit
          :dialogPinjaman="dialogPinjaman"
          :id="idNya"
          :toke="toke"
          @tutup="tutup"
          @update="reload"
        ></pinjamanedit>
        <pindah
          :pemicu="pemicuPindah"
          @tutup="pemicuPindah = false"
          @simpan="
            pemicuPindah = false;
            inisialisasi();
          "
          :id="id"
          :paket="terpilih"
          :tonase="false"
        ></pindah>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import pinjamanedit from "./input/pinjamanEdit.vue";
import pindah from "./pindah/listNota.vue";
import moment from "moment";
export default {
  components: {
    pinjamanedit,
    pindah,
  },
  computed: {
    adaYangTerpilih() {
      if (this.terpilih.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    totalPinjam() {
      return this.pinjaman.reduce(function (simpanan, item) {
        return (simpanan += parseInt(item.pinjam) - parseInt(item.angsur));
      }, 0);
    },
    bln() {
      let bln = moment(this.nota.tanggal).format("M");
      return bln;
    },
    thn() {
      let bln = moment(this.nota.tanggal).format("yyyy");
      return bln;
    },
  },
  watch: {
    sebulan() {
      if (this.sebulan) {
        this.dataSebulan();
      } else {
        this.inisialisasi();
      }
    },
    noNota() {
      if (this.noNota) {
        this.tanpaNota();
      } else {
        this.inisialisasi();
      }
    },
    nota() {
      console.log("id berubah");
      this.inisialisasi();
    },
  },
  mounted() {
    console.log("pinjaman di loading");
    this.inisialisasi();
    console.log(this.nota);
  },
  props: ["nota", "toke", "total", "id"],
  data() {
    return {
      headers: [
        {
          text: "Toke",
          align: "start",
          filterable: true,
          value: "toke.nama",
        },
        { text: "Tanggal", value: "tanggal" },
        { text: "Uraian", value: "uraian" },
        { text: "Pinjam", value: "pinjam" },
        { text: "Bayar", value: "angsur" },
        { text: "Kategori", value: "jenis" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      search: "",
      terpilih: [],
      dialogPinjaman: false,
      idNya: 0,
      loadingDelet: false,
      sebulan: false,
      pinjaman: [],
      loading: false,
      noNota: false,
      pemicuPindah: false,
    };
  },
  methods: {
    inisialisasi() {
      this.terpilih = [];
      this.loading = true;
      this.noNota = false;
      let uri = `/transaksi/${this.id}/pinjaman`;
      axios
        .get(uri)
        .then((res) => {
          this.pinjaman = res.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log({ msg: e.message, fungsi: "inisialisasi" });
          this.loading = false;
        });
    },
    tanpaNota() {
      this.loading = true;
      let uri = `/transaksi/${this.id}/tanpaNota`;
      axios
        .get(uri)
        .then((res) => {
          this.pinjaman = res.data.pinjaman;
          console.log(res.data);
          this.loading = false;
        })
        .catch((e) => {
          console.log({ msg: e.message, fungsi: "tanpaNota" });
          this.loading = false;
        });
    },
    dataSebulan() {
      this.loading = true;
      const uri = `/pinjaman/${this.nota.akun.id}/${this.bln}/${this.thn}`;
      console.log(uri);
      axios
        .get(uri)
        .then((res) => {
          this.pinjaman = res.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log({ msg: e.message, fungsi: "data sebulan" });
          this.loading = false;
        });
    },
    edit(item) {
      this.idNya = item.ID;
      this.dialogPinjaman = true;
    },
    tutup() {
      this.dialogPinjaman = false;
    },
    reload() {
      this.dialogPinjaman = false;
      this.inisialisasi();
    },
    hapus(item) {
      this.loadingDelet = true;
      let uri = "/pinjaman/" + item.ID;
      axios.delete(uri).then(() => {
        this.inisialisasi();
        this.loadingDelet = false;
      });
    },
  },
};
</script>
