<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="600"
    persistent
  >
    <v-card dense tile>
      <v-card-title>DO</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                label="Nama DO"
                required
                v-model="dataTransaksi.do"
                ref="deo"
                :rules="[(v) => !!v || 'Harus diisi']"
              ></v-text-field
            ></v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="dataTransaksi.bank"
                auto-select-first
                :items="bank"
                item-text="nama"
                item-value="id"
                label="Banklama"
                ref="bank"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="dataTransaksi.id_bank"
                auto-select-first
                :items="kasir"
                item-text="nama"
                item-value="ID"
                label="Bank"
                ref="bank"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="dataTransaksi.no"
                auto-select-first
                :items="jenisNO"
                label="No urut"
                ref="no"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Fee"
                required
                :rules="[
                  (v) => v <= 10000000000 || 'Terlalu besar',
                  (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                ]"
                v-model="dataTransaksi.fee"
                type="number"
                ref="fee"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="PPN"
                required
                :rules="[
                  (v) => v <= 10000000000 || 'Terlalu besar',
                  (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                ]"
                v-model="dataTransaksi.ppn"
                type="number"
                ref="ppn"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="PPH"
                required
                :rules="[
                  (v) => v <= 10000000000 || 'Terlalu besar',
                  (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                ]"
                v-model="dataTransaksi.pph"
                type="number"
                ref="pph"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="dataTransaksi.pemppn"
                auto-select-first
                :items="jenisPPN"
                item-text="ket"
                item-value="id"
                label="Jenis PPN"
                ref="pemppn"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="dataTransaksi.pempph"
                auto-select-first
                :items="jenisPPH"
                item-text="ket"
                item-value="id"
                label="Jenis PPH"
                ref="pempph"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12">
              Buku:
              <v-chip-group v-model="buku" column>
                <v-chip
                  v-for="item in jenisBuku"
                  :key="item.id"
                  filter
                  outlined
                >
                  {{ item.ket }}
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-btn color="blue" dark type="submit" :disabled="loadingSimpan"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import rizki from "./../../../mixins/rizki.js";
export default {
  mixins: [rizki],
  props: ["pemicu", "pabrik", "id", "kasir"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    buku: {
      get() {
        return this.dataTransaksi.buku - 2;
      },
      set(item) {
        this.dataTransaksi.buku = item + 2;
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
      this.kondisikan();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      dataTransaksi: {
        do: "coba",
        fee: 50,
        pabrik: 0,
        pph: 0.25,
        ppn: 10,
        pemppn: 2,
        pempph: 1,
        bank: 14,
        no: "a1",
        buku: 2,
      },
      jenisPPH: [
        { id: 1, ket: "dipotong langsung" },
        { id: 2, ket: "ditotal per bulan" },
        { id: 3, ket: "dibayar oleh pabrik" },
      ],
      jenisPPN: [
        { id: 1, ket: "dibayar langsung" },
        { id: 2, ket: "ditotal per bulan" },
      ],
      jenisBuku: [
        { id: 2, ket: "SMJ" },
        { id: 3, ket: "AKD" },
        { id: 4, ket: "JSAJ" },
        { id: 5, ket: "NON PT" },
      ],
      jenisNO: [
        "a1",
        "a2",
        "a3",
        "a4",
        "a5",
        "b1",
        "b2",
        "b3",
        "z1",
        "z2",
        "z3",
      ],
      pupup: false,
      loading: false,
      loadingSimpan: false,
      bank: [],
      pembanding: {},
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid && this.loadingSimpan == false) {
        this.loadingSimpan = true;
        this.simpan();
        if (this.id > 0) {
          console.log(this.r_data_update(this.dataTransaksi, this.pembanding));
          let uri = `/deo/${this.id}`;
          axios
            .put(uri, this.r_data_update(this.dataTransaksi, this.pembanding))
            .then((response) => {
              if (response.data.status) {
                this.$noty.success("Data berhasil disimpan");
                this.tutup();
                this.$emit("lanjutkan", response.data.data);
                console.log(response.data.data);
                this.loadingSimpan = false;
              } else {
                this.$noty.success(response.data.data[0]);
              }
            });
        } else {
          console.log(this.dataTransaksi);
          let uri = "/deo";
          axios.post(uri, this.dataTransaksi).then((response) => {
            if (response.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.tutup();
              this.$emit("lanjutkan", response.data.data);
              console.log(response.data.data);
              this.loadingSimpan = false;
            } else {
              this.$noty.success(response.data.data[0]);
            }
          });
        }
      }
    },
    inisialisasi() {
      let uri = "/akun";
      this.r_coba();
      axios.get(uri).then((response) => {
        this.bank = response.data;
        console.log(this.bank);
      });
      if (this.id > 0) {
        let uri = `/deo/${this.id}`;
        axios.get(uri).then((response) => {
          this.dataTransaksi = response.data;
          console.log(this.dataTransaksi);
          this.dataTransaksi.pemppn = parseInt(this.dataTransaksi.pemppn);
          this.dataTransaksi.pempph = parseInt(this.dataTransaksi.pempph);
          this.dataTransaksi.bank = parseInt(this.dataTransaksi.bank);
          this.dataTransaksi.id_bank = parseInt(this.dataTransaksi.id_bank);
          this.pembanding = Object.assign({}, this.dataTransaksi);
        });
      } else {
        this.dataTransaksi.do = "";
        this.dataTransaksi.fee = 50;
        this.dataTransaksi.pabrik = this.pabrik.id;
        this.dataTransaksi.pph = 0.25;
        this.dataTransaksi.ppn = 10;
        this.dataTransaksi.pemppn = 2;
        this.dataTransaksi.pempph = 1;
        this.dataTransaksi.bank = 14;
        this.dataTransaksi.id_bank = 0;
        this.dataTransaksi.no = "a1";
        this.dataTransaksi.buku = 2;
      }
    },
    simpan() {},
    kondisikan() {},
  },
};
</script>
