var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"dark":"","small":"","color":"blue"},on:{"click":_vm.download}},[_c('v-icon',[_vm._v("mdi-table-large")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"orange","small":"","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.print.apply(null, arguments)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-printer ")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"blue","small":"","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.bukatoke.apply(null, arguments)}}},[_vm._v(" Filter"),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-filter ")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"success","small":""},on:{"click":function($event){$event.preventDefault();_vm.dialogF = true}}},[_vm._v(" Pilih Relasi ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"red","small":""},on:{"click":function($event){$event.preventDefault();_vm.tonase = _vm.tabel}}},[_vm._v(" reset ")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-checkbox',{staticClass:"ml-3",attrs:{"dense":"","color":"orange","label":"Relasi"},model:{value:(_vm.cek),callback:function ($$v) {_vm.cek=$$v},expression:"cek"}})],1),_c('v-col',[_c('v-card',{attrs:{"dense":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tonase,"search":_vm.search,"items-per-page":10,"dense":"","show-select":"","item-key":"id","mobile-breakpoint":"300","footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
              prevIcon: 'mdi-arrow-left-drop-circle-outline',
              nextIcon: 'mdi-arrow-right-drop-circle-outline',
            },"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.tanggaldo",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.tanggaldo)))])]}},{key:"item.pph",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("angka")(((item.tonase * item.do.pph * (item.harga + item.do.fee)) / 100))))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","x-small":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}]),model:{value:(_vm.terpilih),callback:function ($$v) {_vm.terpilih=$$v},expression:"terpilih"}})],1)],1)],1)],1),_c('poptoke',{attrs:{"pemicu":_vm.popupToke,"tonase":_vm.tonase},on:{"simpan":_vm.refresh,"tutup":function($event){_vm.popupToke = false}}}),_c('poprelasi',{attrs:{"pemicu":_vm.dialogF,"ditandai":_vm.terpilih},on:{"tutup":function($event){_vm.dialogF = false},"simpan":_vm.refreshTabel}}),_c('printakun',{attrs:{"pemicu":_vm.pemicuPrint,"data":_vm.dataPrint,"bln":_vm.bln,"thn":_vm.thn},on:{"tutup":function($event){_vm.pemicuPrint = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }