<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile :loading="loading">
      <v-card-title
        >Draft<v-spacer></v-spacer
        ><v-btn @click="tutup" icon color="red" large
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-card-title
      >
      <v-card-subtitle>
        <span class="text-h6">#{{ noUrut + 1 }}</span>
      </v-card-subtitle>
      <v-card-text>
        <v-form
          @submit.prevent="simpan()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="uraian"
                required
                v-model="data.keterangan"
                dense
                autofocus
                ref="uraian"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12"
              ><v-text-field
                label="Masuk"
                required
                v-model="data.angsur"
                type="number"
                dense
              >
              </v-text-field
            ></v-col>
            <v-col cols="12">
              <v-text-field
                label="Keluar"
                v-model="data.pinjam"
                type="number"
                dense
              >
              </v-text-field>
            </v-col>
            <v-btn color="blue" dark type="submit" :disabled="tombolOff"
              >Simpan</v-btn
            ><v-spacer></v-spacer
            ><v-btn
              color="green"
              dark
              :disabled="tombolOff"
              @click="simpandanbaru()"
              >Buat lagi</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

// import axios from "axios";
export default {
  props: ["idAkun", "tanggal", "pemicu", "nota"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    noUrut() {
      return this.nota.length;
    },
  },
  watch: {
    pemicu() {
      this.kondisikan();
    },
  },
  data() {
    return {
      valid: false,
      tombolOff: false,
      data: {
        keterangan: "",
        pinjam: 0,
        angsur: 0,
      },
      loading: false,
      masal: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid) {
        if (this.tombolOff == false) {
          this.tombolOff = true;
          let uri = `/pencairan/draft`;
          let data = {};
          data.data = this.data;
          data.tanggal = this.tanggal;
          data.idAkun = this.idAkun;
          axios
            .post(uri, data)
            .then((res) => {
              this.$emit("reload");
              console.log(res.data);
              this.$noty.success("Data berhasil disimpan");
              this.data = {
                keterangan: "",
                pinjam: 0,
                angsur: 0,
              };
              this.$refs.uraian.focus();
              if (this.masal == false) this.tutup();
            })
            .catch((e) => {
              this.$noty.error(e.message);
            })
            .finally(() => (this.tombolOff = false));
        }
      }
    },
    kondisikan() {},
    nolkan() {},
    simpandanbaru() {
      this.masal = true;
      this.kirim();
    },
    simpan() {
      this.masal = false;
      this.kirim();
    },
    isiData() {},
  },
};
</script>
