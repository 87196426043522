<template>
  <v-card rounded :loading="loading" class="mx-1 mx-sm-12">
    <v-card-title class="mb-10">
      Daftar Kasir <v-spacer></v-spacer>
      <v-text-field
        class="mx-1 mx-sm-6"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
      ></v-text-field
    ></v-card-title>
    <v-row class="mb-12 mx-1 mx-sm-12">
      <v-col
        v-for="item in kasirFilter"
        :key="item.name"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card>
          <v-card
            class="elevation-8"
            color="indigo darken-1"
            @click="buka(item.id)"
            rounded
            shaped
          >
            <v-card-title class="white--text">
              {{ item.nama }}
            </v-card-title>
            <v-card-subtitle class="white--text"
              >Saldo: {{ item.saldo | rupiah }}
              <div class="text-caption white--text">
                <v-icon class="white--text">mdi-calendar</v-icon>:
                {{ item.tanggal | formatDate }}
              </div></v-card-subtitle
            ></v-card
          >
          <div class="text-caption white ma-2">
            <a @click="bukaSaldo(item.id)">Lihat Riwayat</a>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// import autocomplete from "./input/autocomplete.vue";
import axios from "axios";
export default {
  components: {
    // autocomplete,
  },
  data: () => ({
    selectedItem: 1,
    kasir: [],
    idCairMax: "0",
    loading: true,
    search: "",
  }),
  mounted() {
    this.getKasir();
  },
  computed: {
    kasirFilter() {
      return this.kasir.filter((item) => {
        let toke = item.nama.toLowerCase();
        return toke.indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },
  methods: {
    getKasir() {
      this.loading = true;
      let uri = "/pencairan/kasir/saldo";
      axios.get(uri).then((response) => {
        this.kasir = response.data;
        this.loading = false;
      });
    },

    buka(id) {
      this.$router.push("/transaksi/" + id);
    },
    bukaSaldo(id) {
      console.log(`buka saldo ${id}`);
    },
  },
};
</script>
