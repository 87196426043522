<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="800"
    persistent
  >
    <v-card dense>
      <v-card-title>Bayar Pigas</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
              class="mx-5 my-4"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="data"
              :search="search"
              :items-per-page="10"
              show-select
              dense
              v-model="terpilih"
              item-key="id"
              mobile-breakpoint="300"
            >
              <template v-slot:item.tonase="{ item }">
                <span>{{ item.tonase | angka }}</span>
              </template>
              <template v-slot:item.tanggaldo="{ item }">
                <span>{{ item.tanggaldo | formatDate }}</span>
              </template>
            </v-data-table>
          </v-col>
          <v-btn
            color="blue"
            dark
            @click="simpan"
            :disabled="tombolOff || kosong"
            >Bayar</v-btn
          ><v-spacer></v-spacer>
          <v-btn color="red" dark @click="tutup()">Batal</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: {
    pemicu: Boolean,
    idNota: Number,
    idAkun: String,
    tanggal: String,
  },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    kosong() {
      return !this.terpilih.length;
    },
  },
  watch: {
    pemicu() {
      if (this.pemicu) {
        this.inisialisasi();
      }
    },
  },
  data() {
    return {
      valid: false,
      tombolOff: false,
      data: [],
      nota: {},
      search: "",
      headers: [
        {
          text: "Pabrik",
          align: "start",
          filterable: true,
          value: "do.pabrik.pabrik",
        },
        { text: "Tanggal", value: "tanggaldo" },
        { text: "Toke", value: "toke.nama" },
        { text: "Supir", value: "nama supir" },
        { text: "Plat", value: "plat" },
        { text: "Tonase", value: "tonase" },
      ],
      terpilih: [],
      antri: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    inisialisasi() {
      this.terpilih = [];
      let uri = `/pencairan/pigas/tonase`;
      let data = {};
      data.idAkun = this.idAkun;
      data.tanggal = this.tanggal;
      console.log("daftar pigas");
      console.log(data);
      axios
        .post(uri, data)
        .then((response) => {
          console.log(response.data);
          this.data = response.data.filter((x) => {
            return !x.pigas;
          });
          console.log(this.data);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    simpan() {
      if (this.antri == false) {
        console.log("data dikirim");
        this.antri = true;
        let data = {};
        let dt = this.terpilih.map((x) => {
          return {
            id: x.id,
            uraian: "pigas " + x["nama supir"] + "/" + x.do.pabrik.pabrik,
          };
        });
        data.data = dt;
        data.idAkun = this.idAkun;
        data.tanggal = this.tanggal;
        data.idNota = this.idNota;
        let uri = `/pencairan/pigas/bayar`;
        console.log(uri);
        console.log(data);
        axios
          .post(uri, data)
          .then((res) => {
            console.log(res);
            this.$emit("simpan");
            this.antri = false;
            this.tutup();
          })
          .catch((e) => {
            console.log(e.message);
            this.antri = false;
          });
        console.log(data);
      }
    },
    kirim() {},
    kondisikan() {},
  },
};
</script>
