<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    persistent
    width="550"
  >
    <v-card :loading="loadingBtn">
      <v-toolbar dense color="blue" dark>
        <v-toolbar-title>Edit Pinjaman</v-toolbar-title>
        <v-spacer></v-spacer>
        <span v-if="pinjaman"> {{ pinjaman.tanggal | formatDate }}</span>
      </v-toolbar>
      <v-card-title class="headline"> Edit Pinjaman </v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="updateData"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="pinjaman.jenis"
                  :items="['PS', 'PT']"
                  label="Jenis"
                  required
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="pinjaman.toke"
                  :items="toke"
                  item-text="nama"
                  item-value="ID"
                  label="Toke"
                  required
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Uraian"
                  required
                  v-model="pinjaman.uraian"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Pinjam"
                  required
                  v-model="pinjaman.pinjam"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="bayar"
                  type="number"
                  required
                  v-model="pinjaman.angsur"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="updateData"
                type="submit"
                :disabled="tombolOff"
                class="ma-2"
              >
                Simpan
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="tutup"
                :loading="loadingBtn"
                :disabled="loadingBtn"
              >
                Batal
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      menu1: false,
      pinjaman: [],
      loadingBtn: false,
      valid: false,
      tombolOff: false,
      toke: [],
    };
  },
  props: ["id", "dialogPinjaman"],
  computed: {
    dialog: {
      get() {
        return this.dialogPinjaman;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    dialogPinjaman() {
      this.inisialisasi();
    },
  },
  methods: {
    tutup() {
      this.$emit("tutup", 0);
    },
    inisialisasi() {
      this.loadingBtn = true;
      if (this.id) {
        axios.get("/pinjaman/" + this.id).then((response) => {
          this.pinjaman = response.data;
          this.pinjaman.toke = parseInt(this.pinjaman.toke);
          this.kondisikan();
          this.getToke();
        });
      }
    },
    getToke() {
      let uri = "/tokeAktif";
      axios.get(uri).then((res) => {
        this.toke = res.data;
        console.log(this.toke);
        this.loadingBtn = false;
      });
    },
    updateData() {
      if (this.valid) {
        this.tombolOff = true;
        this.kondisikan();
        console.log(this.pinjaman);
        let uri = `/pinjaman/${this.pinjaman.ID}`;
        axios
          .put(uri, this.pinjaman)
          .then((response) => {
            if (response.data.status) {
              this.$noty.success(response.data.messages);
              this.$emit("update", response.data.awal);
              this.tombolOff = false;
            }
          })
          .catch((error) => {
            this.$noty.error(error.response.data.errors[0]);
            this.tombolOff = false;
          });
      }
    },
    kondisikan() {
      this.pinjaman.pinjam = parseInt(this.pinjaman.pinjam) || 0;
      this.pinjaman.angsur = parseInt(this.pinjaman.angsur) || 0;
      this.pinjaman.hitung = this.pinjaman.pinjam - this.pinjaman.angsur;
    },
  },
};
</script>
