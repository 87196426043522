<template>
  <v-dialog transition="dialog-bottom-transition" v-model="dialog" persistent>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Rekening Koran</v-toolbar-title>
        <download-excel
          class="mx-2"
          :data="tabel"
          :fields="fieldExcell"
          worksheet="My Worksheet"
          :name="filename"
        >
          <v-btn icon class="mx-4" color="green"
            ><v-icon>mdi-table-large</v-icon></v-btn
          > </download-excel
        ><v-spacer></v-spacer
        ><v-btn icon color="red" @click="tutup"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="tabel"
        :search="search"
        :items-per-page="10"
        :page="lastPage"
        dense
        mobile-breakpoint="300"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-page-first',
          lastIcon: 'mdi-page-last',
          prevIcon: 'mdi-arrow-left-drop-circle-outline',
          nextIcon: 'mdi-arrow-right-drop-circle-outline',
        }"
      >
        <template v-slot:item.tanggal="{ item }">
          <span>{{ item.tanggal | formatDate }}</span>
        </template>
        <template v-slot:item.masuk="{ item }">
          <span>{{ item.masuk | rupiah }}</span>
        </template>
        <template v-slot:item.keluar="{ item }">
          <span>{{ item.keluar | rupiah }}</span>
        </template>
        <template v-slot:item.saldo="{ item }">
          <span>{{ item.saldo | rupiah }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn class="mx-2" icon small @click="edit(item)">
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <edit
        :dialogPinjaman="pemicuEdit"
        :id="idPinjaman"
        @tutup="
          pemicuEdit = false;
          inisialisasi;
        "
      ></edit>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import edit from "../../Transaksi/input/pinjamanEdit.vue";
import _ from "lodash";
import moment from "moment";
export default {
  components: { edit },
  props: {
    idAkun: {
      type: [Number, String],
      default: 1,
    },
    tanggal: [Array],
    pemicu: [Boolean],
  },
  data() {
    return {
      loading: true,
      search: "",
      tabel: [],
      headers: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggal",
        },
        { text: "Uraian", value: "uraian" },
        { text: "Kredit", value: "masuk" },
        { text: "Debet", value: "keluar" },
        { text: "Saldo", value: "saldo" },
        { text: "Actions", value: "actions" },
      ],
      fieldExcell: {
        Tanggal: "tanggal",
        Uraian: "uraian",
        Kredit: "masuk",
        Debet: "keluar",
        Saldo: "saldo",
      },
      switch1: false,
      pemicuEdit: false,
      dataEdit: { akun: { id: 1, nama: "" }, id: 0, id_masukan: 0 },
      akun: {},
      saldoAwal: 0,
      idPinjaman: 0,
    };
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    filename() {
      return `Rekening koran ${this.tanggal[0]} - ${
        this.tanggal[1]
      } -${moment().format("DD-MM-YY hh")}`;
    },
    lastPage() {
      return Math.ceil(this.tabel.length / 10);
    },
  },
  mounted() {
    this.inisialisasi();
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    getAkun() {
      this.loading = true;
      let url = "/akunjurnal/kasir/" + this.idAkun;
      axios.get(url).then((response) => {
        this.akun = _.values(response.data);
        this.loading = false;
        console.log(this.akun);
      });
    },
    inisialisasi() {
      this.loading = true;
      let url = `/akunjurnal/rekeningkoran/${this.idAkun}/${this.tanggal[0]}/${this.tanggal[1]}`;
      axios.get(url).then((response) => {
        this.tabel = _.values(response.data.data);
        this.saldoAwal = response.data.awal;
        this.addSaldo();
        this.loading = false;
        this.getAkun();
      });
    },
    addSaldo() {
      var saldo = [];
      const items = this.tabel.sort(function (a, b) {
        return new Date(a.tanggal).getTime() - new Date(b.tanggal).getTime();
      });
      var saldoA = parseInt(this.saldoAwal);
      for (let a = 0; a < items.length; a++) {
        saldoA =
          saldoA + this.cekNol(items[a].pinjam) - this.cekNol(items[a].angsur);
        saldo.push({
          id: items[a].ID,
          tanggal: items[a].tanggal,
          masuk: this.cekNol(items[a].pinjam),
          uraian: items[a].uraian,
          keluar: this.cekNol(items[a].angsur),
          saldo: saldoA,
        });
      }
      this.tabel = saldo;
    },
    cekNol(nilai) {
      if (nilai) {
        return isNaN(parseInt(nilai, 10)) ? 0 : parseInt(nilai, 10);
      } else {
        return 0;
      }
    },
    hanyaKasir() {},
    edit(item) {
      this.idPinjaman = item.id;
      console.log(this.idPinjaman);
      this.pemicuEdit = true;
    },
  },
};
</script>
