<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="600"
    persistent
  >
    <v-card dense tile>
      <v-toolbar>
        <v-toolbar-title
          >Transaksi tanggal : {{ this.tanggal | formatDate }}</v-toolbar-title
        >
        <v-spacer></v-spacer
        ><v-btn icon color="red" @click="tutup()"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="data"
              :items-per-page="100"
              dense
              mobile-breakpoint="300"
              :loading="loading"
              hide-default-footer
            >
              <template v-slot:item.nominal="{ item }">
                <span>{{ item.nominal | angka }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["pemicu", "tanggal"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  mounted() {},
  data() {
    return {
      loading: false,
      data: [],
      headers: [
        { text: "Sumber Dana", value: "sumber" },
        { text: "Penerima", value: "penerima" },
        { text: "Nominal", value: "nominal" },
      ],
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {},
    inisialisasi() {
      let uri = `/akunjurnal/buku/${this.tanggal}`;
      this.loading = true;
      axios
        .get(uri)
        .then((res) => {
          this.data = res.data.data
            .filter((x) => [7, 8, 9].indexOf(x.referensi.toke.golongan) > -1)
            .map((x) => {
              let a = {};

              if (x.hitung > 0) {
                a.penerima = x.toke.nama;
                a.sumber = x.referensi.toke.nama;
                a.nominal = x.hitung;
              } else {
                a.sumber = x.toke.nama;
                a.penerima = x.referensi.toke.nama;
                a.nominal = x.hitung * -1;
              }
              if (x.toke.ID != x.referensi.toke.ID) {
                a.sortir = a.sumber + a.penerima;
                return a;
              }
              return;
            })
            .filter((x) => x)
            .sort((a, b) => a.sortir.localeCompare(b.sortir));
          console.log(this.data);
          let dt2 = res.data.pencairan
            .map((x) => {
              let a = {};
              a.penerima = x.toke.nama;
              a.sumber = x.cairpabrik.pabrik.pabrik;
              a.nominal = x.hitung;
              a.sortir = a.sumber + a.penerima;
              return a;
            })
            .filter((x) => x);
          console.log(dt2);
          this.data = dt2.concat(this.data);
          this.data = this.data.reduce((a, item) => {
            if (a.some((x) => x.sortir == item.sortir)) {
              a[a.findIndex((x) => x.sortir == item.sortir)].nominal +=
                item.nominal;
            } else {
              a.push(item);
            }
            return a;
          }, []);
        })
        .finally((this.loading = false));
    },
    simpan() {},
    kondisikan() {},
  },
};
</script>
