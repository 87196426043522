<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile :loading="loading">
      <v-toolbar dense color="blue" dark>
        <v-toolbar-title>Ubah jenis nota</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="red" @click="tutup()" v-if="loading == false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-card-text>
        <v-form color="grey" ref="form" v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-btn
                v-for="item in items"
                :key="item.id"
                @click="ubahKe(item.id)"
                color="green"
                class="ma-2"
                dark
                >{{ item.nama }}</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="pemicuToke"
      width="400"
      persistent
    >
      <v-card dense tile :loading="loading">
        <v-toolbar dense color="blue" dark>
          <v-toolbar-title>Pilih Toke</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="pemicuToke = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="tokeTerpilih"
                auto-select-first
                autofocus
                :items="toke"
                item-text="nama"
                item-value="ID"
                label="Toke"
                ref="toke"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete>
              <v-btn
                color="blue"
                dark
                type="submit"
                :disabled="tombolOff"
                @click="
                  setUpdate(1);
                  pemicuToke = false;
                "
                >Simpan</v-btn
              ></v-col
            >
          </v-row>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
// import autocomplete from "./autocomplete.vue";
import axios from "axios";
export default {
  props: ["pemicu", "id"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      if (this.pemicu == true) {
        this.kondisikan();
      }
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      tombolOff: false,
      toke: [],
      ubahan: 0,
      dataNota: {},
      loading: false,
      items: [
        { id: 0, nama: "Nota Pencairan" },
        { id: 1, nama: "Pinjaman" },
        { id: 4, nama: "Tagihan" },
        { id: 5, nama: "Ramp" },
        { id: 100, nama: "DRAFT" },
      ],
      tokeTerpilih: 1,
      pemicuToke: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    lanjutkan() {},
    ubahKe(items) {
      if (items == 1) {
        this.pemicuToke = true;
      } else {
        this.setUpdate(items);
      }
    },
    setUpdate(items) {
      let item = {};
      item.id = this.id;
      item.toke = this.tokeTerpilih;
      item.ubahan = items;
      this.loading = true;
      let uri = `/pencairan/nota/ubahjenis`;
      console.log(uri);
      console.log(item);
      axios
        .post(uri, item)
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            if (item.ubahan == 1) {
              this.$emit("simpan", item);
            } else {
              this.$emit("next", item);
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.$emit("tutup");
        });
    },
    async kondisikan() {
      let uri = "/tokeAktif";
      let uritoke = "/setting/get/tokeStandar";
      this.tokeTerpilih = await axios.get(uritoke).then((res) => res.data);
      axios
        .get(uri)
        .then((res) => {
          this.toke = res.data;
          console.log(this.toke);
        })
        .catch((e) => {
          this.$noty.success(e);
        });
    },
  },
};
</script>
