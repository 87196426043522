<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="dialog"
      width="800"
      persistent
    >
      <v-card dense tile :loading="loading">
        <v-card-title
          >List nota toke : {{ toke ? toke.nama : "" }}</v-card-title
        >
        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mx-3 mb-3"
          ></v-text-field>
          <v-data-table
            class="mx-2 mx-sm-10"
            :headers="headers"
            :items="data"
            :search="search"
            :items-per-page="10"
            :page="lastPage"
            dense
            mobile-breakpoint="300"
            :loading="loading"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
              prevIcon: 'mdi-arrow-left-drop-circle-outline',
              nextIcon: 'mdi-arrow-right-drop-circle-outline',
            }"
          >
            <template v-slot:item.tanggal="{ item }">
              <span>{{ item.tanggal | formatDate }}</span>
            </template>
            <template v-slot:item.cair="{ item }">
              <span>{{ item.cair | rupiah }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                class="mx-1"
                icon
                x-small
                color="green"
                @click.stop="buka(item)"
              >
                <v-icon> mdi-account-edit </v-icon>
              </v-btn>
            </template>
          </v-data-table></v-card-text
        >
        <v-card-actions> <v-btn @click="tutup()">tutup</v-btn></v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="notaAktif">
      <isinota
        @tutup="
          notaAktif = false;
          pemicuNota = false;
        "
        :dialog="pemicuNota"
        :idNota="idNota"
        :idAkun="idAkun"
        :toke="tokes"
        :deo="deo"
        :pabrik="pabrik"
        @reload="inisialisasi"
        :loading="loading"
        :cekReload="cekReload"
        :transaksi="transaksi"
      ></isinota>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import isinota from "../Pencairan/isiNota.vue";
export default {
  props: ["pemicu", "id"],
  components: { isinota },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    lastPage() {
      return Math.ceil(this.data.length / 10);
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  mounted() {},
  data() {
    return {
      data: [],
      toke: {},
      loading: false,
      search: "",
      headers: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggal",
        },
        { text: "Kasir", value: "admin.nama" },
        { text: "Keterangan", value: "keterangan" },
        { text: "Cair", value: "cair" },
        { text: "Actions", value: "actions" },
      ],
      pemicuNota: false,
      idNota: 0,
      idAkun: 0,
      tokes: [],
      deo: [],
      pabrik: [],
      cekReload: false,
      transaksi: {},
      notaAktif: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    async inisialisasi() {
      let uri = `/nota/toke/${this.id}`;
      this.loading = true;
      const data = await axios.get(uri).then((response) => {
        return response.data;
      });
      this.data = data.nota.map((x) => {
        x.cair = (x.jurnal ? x.jurnal.hitung : 0) * -1;
        return x;
      });
      this.toke = data.toke;
      this.loading = false;
      console.log(this.data);
    },
    buka(item) {
      console.log(item);
      this.idNota = item.ID;
      this.idAkun = `${item.admin.ID}`;
      this.transaksi = { id: item.admin.ID, nama: item.admin.nama };
      this.notaAktif = true;
      this.pemicuNota = true;
    },
  },
};
</script>
