var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"600","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"dense":"","tile":""}},[_c('v-card-title',[_vm._v("Toke")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"color":"grey"},on:{"submit":function($event){$event.preventDefault();return _vm.kirim()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"toke",attrs:{"label":"Nama Akun","required":"","dense":"","rules":[(v) => !!v || 'Harus diisi']},model:{value:(_vm.data.nama),callback:function ($$v) {_vm.$set(_vm.data, "nama", $$v)},expression:"data.nama"}})],1),_c('v-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"golongan",attrs:{"auto-select-first":"","dense":"","item-text":"golongan","item-value":"ID","items":[
                { ID: 7, golongan: 'KASIR' },
                { ID: 8, golongan: 'BANK KASIR' },
                { ID: 9, golongan: 'BANK PABRIK' },
                { ID: 10, golongan: 'FEE' },
                { ID: 11, golongan: 'ANGSURAN BANK' },
              ],"label":"Golongan","rules":[(v) => !!v || 'Harus diisi']},model:{value:(_vm.data.golongan),callback:function ($$v) {_vm.$set(_vm.data, "golongan", $$v)},expression:"data.golongan"}})],1),_c('v-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"aktif",attrs:{"auto-select-first":"","dense":"","items":['Aktif', 'Tidak Aktif'],"label":"Aktif","rules":[(v) => !!v || 'Harus diisi']},model:{value:(_vm.data.aktif),callback:function ($$v) {_vm.$set(_vm.data, "aktif", $$v)},expression:"data.aktif"}})],1),_c('v-btn',{attrs:{"color":"blue","dark":"","type":"submit","disabled":_vm.loadingSimpan}},[_vm._v("Simpan")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.tutup()}}},[_vm._v("Batal")])],1)],1)],1),_c('v-card-actions')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }