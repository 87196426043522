<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="600"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Toke</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Toke"
                required
                v-model="data.nama"
                dense
                ref="toke"
                :rules="[(v) => !!v || 'Harus diisi']"
              ></v-text-field
            ></v-col>
            <v-col cols="12" class="mt-1">
              <v-autocomplete
                v-model="data.reg"
                dense
                auto-select-first
                item-text="regional"
                item-value="id"
                :items="regional"
                label="Regional"
                ref="regional"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12" class="mt-1">
              <v-autocomplete
                v-model="data.golongan"
                auto-select-first
                dense
                item-text="golongan"
                item-value="ID"
                :items="golongan"
                label="Golongan"
                ref="golongan"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12" class="mt-1">
              <v-autocomplete
                v-model="data.jenis_modal"
                dense
                auto-select-first
                :items="['TOKE', 'SUPIR']"
                label="Jenis Akun"
                ref="Tok"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12" class="mt-1">
              <v-autocomplete
                v-model="data.aktif"
                auto-select-first
                :items="['Aktif', 'Tidak Aktif']"
                dense
                label="Aktif?"
                ref="jenis"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12" class="mt-1">
              <v-autocomplete
                v-model="data.pinjaman_macet"
                item-text="ket"
                item-value="id"
                dense
                auto-select-first
                :items="[
                  { id: 0, ket: 'Tidak' },
                  { id: 1, ket: 'Ya' },
                ]"
                label="Pinjaman Macet?"
                ref="macet"
              >
              </v-autocomplete
            ></v-col>
            <v-btn color="blue" dark type="submit" :disabled="loadingSimpan"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import rizki from "./../../mixins/rizki";
export default {
  mixins: [rizki],
  props: ["pemicu", "id"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
      this.kondisikan();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      data: {
        nama: "",
        reg: 0,
        golongan: 0,
        jenis_modal: "TOKE",
        aktif: "Aktif",
      },
      loading: false,
      loadingSimpan: false,
      pembanding: {},
      golongan: [],
      regional: [],
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid && this.loadingSimpan == false) {
        this.loadingSimpan = true;
        this.simpan();
        if (this.id > 0) {
          console.log(this.r_data_update(this.data, this.pembanding));
          let uri = `/toke/${this.id}`;
          axios
            .put(uri, this.r_data_update(this.data, this.pembanding))
            .then((response) => {
              if (response.data.status) {
                this.$noty.success("Data berhasil disimpan");
                this.tutup();
                this.$emit("lanjutkan", response.data.data);
                this.$noty.success(response.data.pesan);
                this.loadingSimpan = false;
              } else {
                this.$noty.success(response.data.pesan);
                this.loadingSimpan = false;
              }
            });
        } else {
          console.log(this.data);
          let uri = "/toke";
          axios.post(uri, this.data).then((response) => {
            if (response.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.tutup();
              this.$emit("lanjutkan", response.data.data);
              this.loadingSimpan = false;
            } else {
              this.$noty.success(response.data.data[0]);
            }
          });
        }
      }
    },
    inisialisasi() {
      let uri = "/regional";
      axios.get(uri).then((response) => {
        this.regional = response.data;
        this.step2();
      });
    },
    step2() {
      let uri = "/golongan";
      axios.get(uri).then((response) => {
        this.golongan = response.data;
        console.log(this.golongan);
        this.step3();
      });
    },
    step3() {
      if (this.id > 0) {
        let uri = `/toke/${this.id}`;
        axios.get(uri).then((response) => {
          this.data = response.data;
          console.log(this.data);
          this.data.reg = parseInt(this.data.reg);
          this.data.golongan = parseInt(this.data.golongan);
          this.pembanding = Object.assign({}, this.data);
        });
      } else {
        this.data.nama = "";
        this.data.reg = 0;
        this.data.golongan = 0;
        this.data.jenis_modal = "TOKE";
        this.data.aktif = "Aktif";
        this.data.pinjaman_macet = 0;
        console.log(this.data);
      }
    },
    simpan() {},
    kondisikan() {},
  },
};
</script>
