<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog.value = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Print</v-toolbar-title><v-spacer></v-spacer
          ><v-btn dark color="pink" @click="print"
            ><v-icon>mdi-printer</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text> </v-card-text>
        <div id="printx1" class="printarea overflow-auto">
          <div>
            <table class="rekap">
              <tbody>
                <tr>
                  <td>Nama Kasir</td>
                  <td>: {{ admin.akun.nama }}</td>
                </tr>
                <tr>
                  <td>Tanggal di Print</td>
                  <td>
                    : {{ tanggalPrint[0] | formatDate }} -
                    {{ tanggalPrint[1] | formatDate }}
                  </td>
                </tr>
              </tbody>
            </table>
            <td></td>
          </div>
          <div class="container">
            <div class="kolom" v-if="modalA.length >> 0">
              <table class="lis">
                <thead>
                  <tr>
                    <th class="text-left" colspan="2">Modal Masuk</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in modalA" :key="item.id" class="ma-2">
                    <td>{{ item.tanggal | formatDate }}</td>
                    <td>{{ item.uraian }}</td>
                    <td>{{ item.masuk | rupiah }}</td>
                  </tr>
                  <tr class="total">
                    <td class="text-left">Total</td>
                    <td></td>
                    <td>
                      {{ sModalM | rupiah }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="kolom" v-if="pinjamanB.length >> 0">
              <table class="lis">
                <thead>
                  <tr>
                    <th class="text-left" colspan="2">Modal Dari Angsuran</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in pinjamanB" :key="item.id" class="ma-2">
                    <td>{{ item.tanggal | formatDate }}</td>
                    <td class="text-left">{{ item.uraian2 }}</td>
                    <td>{{ item.angsur | rupiah }}</td>
                  </tr>
                  <tr class="total">
                    <td class="text-left">Total</td>
                    <td></td>
                    <td>
                      {{ sAngsuran | rupiah }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="tMasuk >> 0 || tMasuk << 0" class="subtotal bukankolom">
              Total Modal : {{ tMasuk | rupiah }}
            </div>
          </div>
          <div class="container">
            <div><span>Tonase</span></div>
            <table class="lis">
              <thead>
                <tr>
                  <th class="text-center">Tanggal</th>
                  <th class="text-center">Pabrik</th>
                  <th class="text-center">Nama</th>
                  <th class="text-center">Plat</th>
                  <th class="text-center">Tonase</th>
                  <th class="text-center">Harga</th>
                  <th class="text-center">Cair</th>
                </tr>
              </thead>
              <tbody v-for="items in grupTonase" :key="items.id">
                <tr v-for="item in items.data" :key="item.id">
                  <td>{{ item.tanggaldo | formatDate }}</td>
                  <td>{{ item.do.pabrik.pabrik }}</td>
                  <td>{{ item["nama supir"] }}</td>
                  <td>{{ item.plat }}</td>
                  <td>{{ item.tonase | angka }}</td>
                  <td>
                    {{ (parseInt(item.harga) + parseFloat(item.th)) | rupiah }}
                  </td>
                  <td>
                    {{
                      (item.tonase *
                        (parseInt(item.harga) + parseFloat(item.th)))
                        | rupiah
                    }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4" class="text-left">Total</td>
                  <td>
                    {{ items.tonase | angka }}
                  </td>
                  <td></td>
                  <td>
                    {{ items.cair | rupiah }}
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr class="total">
                  <td colspan="4" class="text-left">Total</td>
                  <td>
                    {{ sTonase | angka }}
                  </td>
                  <td></td>
                  <td>
                    {{ sCair | rupiah }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="container">
            <div class="kolom" v-if="modalB.length >> 0">
              <table class="lis">
                <thead>
                  <tr>
                    <th class="text-left" colspan="2">Modal Keluar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in modalB" :key="item.id" class="ma-2">
                    <td>{{ item.tanggal | formatDate }}</td>
                    <td class="text-left">{{ item.uraian }}</td>
                    <td>{{ item.keluar | rupiah }}</td>
                  </tr>
                  <tr class="total">
                    <td class="text-left">Total</td>
                    <td></td>
                    <td>
                      {{ sModalK | rupiah }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="kolom" v-if="pinjamanA.length >> 0">
              <table class="lis">
                <thead>
                  <tr>
                    <th class="text-left" colspan="2">Pengeluaran Pinjaman</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in pinjamanA" :key="item.id" class="ma-2">
                    <td>{{ item.tanggal | formatDate }}</td>
                    <td class="text-left">{{ item.uraian2 }}</td>
                    <td>{{ item.pinjam | rupiah }}</td>
                  </tr>
                  <tr class="total">
                    <td class="text-left">Total</td>
                    <td></td>
                    <td>
                      {{ sPinjaman | rupiah }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-if="tKeluar >> 0 || tKeluar << 0"
              class="subtotal bukankolom"
            >
              Total Pengeluaran : {{ tKeluar | rupiah }}
            </div>
          </div>
          <div class="container">
            <table class="rekap">
              <tbody>
                <tr>
                  <td>Sisa Sebelumnya</td>
                  <td>: {{ saldoKemarin | rupiah }}</td>
                </tr>
                <tr>
                  <td>Total Modal</td>
                  <td>: {{ tMasuk | rupiah }}</td>
                </tr>
                <tr>
                  <td>Pengeluaran Pencairan</td>
                  <td>: {{ sCair | rupiah }}</td>
                </tr>
                <tr>
                  <td>Pengeluaran Pinjaman dll</td>
                  <td>: {{ tKeluar | rupiah }}</td>
                </tr>
                <tr class="total">
                  <td>Sisa Saldo</td>
                  <td>: {{ tTotal | rupiah }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>
<style>
@import "@/assets/aplikasi/riz/transaksiPortrait.css";
@media print {
  body *:not(#printx1):not(#printx1 *) {
    visibility: hidden;
  }
  #printx1 {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
<script>
import axios from "axios";
export default {
  components: {},
  props: {
    pemicu: Boolean,
    tanggalPrint: {
      default: function () {
        return [];
      },
      type: Array,
    },
    admin: {
      default: function () {
        return { akun: { id: 0, nama: "" } };
      },
      type: Object,
    },
  },

  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  data() {
    return {
      tonase: [],
      saldoKemarin: 0,
      pinjaman: [],
      pinjamanA: [],
      pinjamanB: [],
      modal: [],
      modalA: [],
      modalB: [],
      loading: false,
      sPinjaman: 0,
      sAngsuran: 0,
      sTonase: 0,
      sCair: 0,
      sModalM: 0,
      sModalK: 0,
      tMasuk: 0,
      tKeluar: 0,
      tTotal: 0,
      grupTonase: [],
    };
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  methods: {
    inisialisasi() {
      console.log("dibuka");
      console.log(this.tanggalPrint);
      this.loading = true;
      const uri =
        "/transaksi/print/" +
        this.admin.akun.id +
        "/" +
        this.tanggalPrint[0] +
        "/" +
        this.tanggalPrint[1] +
        "";
      axios.get(uri).then((response) => {
        this.tonase = response.data.tonase;
        this.grupTonase = this.grupkan(this.tonase);
        this.pTonase();
        this.saldoKemarin = response.data.saldoKemarin;
        this.pinjaman = response.data.pinjaman;
        this.modal = response.data.modal;
        this.pModal();
        this.pPinjaman();
        this.loading = false;
      });
    },
    grupkan(array) {
      if (array.length >> 0) {
        var hasil = [];
        let c = array.reduce(function (chace, item) {
          const properti = item.do.pabrik.id;
          if (chace.some((el) => el.idpabrik === properti)) {
            var terpilih = chace.find(function (x) {
              return x.idpabrik === properti;
            });
            terpilih.tonase += parseInt(item.tonase);
            terpilih.cair +=
              parseInt(item.tonase) *
              (parseFloat(item.harga) + parseFloat(item.th));
            terpilih.data.push(item);
            return [...chace];
          }
          chace.push({
            tonase: parseInt(item.tonase),
            cair:
              parseInt(item.tonase) *
              (parseFloat(item.harga) + parseFloat(item.th)),
            pabrik: item.do.pabrik.pabrik,
            idpabrik: item.do.pabrik.id,
            data: [item],
          });
          return [...chace];
        }, []);
        c.forEach(function (item) {
          hasil.push(item);
        });
        hasil.sort(function (a, b) {
          return a.idpabrik - b.idpabrik;
        });
        console.log(hasil);
        return hasil;
      }
      return array;
    },
    tutup() {},
    pTonase() {
      this.tonase = this.tonase.map(function (x) {
        return {
          ...x,
          cair: parseInt(x.tonase) * (parseInt(x.harga) + parseFloat(x.th)),
        };
      });
    },
    pModal() {
      this.modalA = this.modal.filter(function (x) {
        return parseInt(x.masuk) >> 0 || parseInt(x.masuk) << 0;
      });
      this.modalB = this.modal.filter(function (x) {
        return parseInt(x.keluar) >> 0 || parseInt(x.keluar) << 0;
      });
    },
    pPinjaman() {
      this.pinjaman = this.pinjaman.map(function (x) {
        return {
          ...x,
          uraian2:
            x.jenis + " " + x.toke.nama + " " + (x.uraian ? x.uraian : ""),
        };
      });
      this.pinjamanA = this.pinjaman.filter(function (x) {
        return parseInt(x.pinjam) >> 0 || parseInt(x.pinjam) << 0;
      });
      this.pinjamanB = this.pinjaman.filter(function (x) {
        return parseInt(x.angsur) >> 0 || parseInt(x.angsur) << 0;
      });
      this.Saldo();
    },
    Saldo() {
      this.sPinjaman = this.sumPer(this.pinjaman, "pinjam");
      this.sAngsuran = this.sumPer(this.pinjaman, "angsur");
      this.sModalK = this.sumPer(this.modal, "keluar");
      this.sModalM = this.sumPer(this.modal, "masuk");
      this.sTonase = this.sumPer(this.tonase, "tonase");
      this.sCair = this.sumPer(this.tonase, "cair");
      this.tMasuk = this.sModalM + this.sAngsuran;
      this.tKeluar = this.sModalK + this.sPinjaman;
      this.tTotal = this.saldoKemarin + this.tMasuk - this.sCair - this.tKeluar;
    },
    sumPer(item, prop) {
      return item.reduce(function (a, b) {
        return a + (parseInt(b[prop]) || 0);
      }, 0);
    },
    print() {
      this.$htmlToPaper("printx1", {
        styles: [
          "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
          "/aplikasi/riz/app.css",
          "/aplikasi/riz/transaksiPortrait.css",
        ],
      });
    },
  },
};
</script>
