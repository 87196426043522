<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="900"
    persistent
  >
    <v-card dense tile>
      <v-toolbar
        ><v-toolbar-title
          >Rekap Transaksi {{ arrTanggal[0] | formatDate }} -
          {{ arrTanggal[1] | formatDate }}</v-toolbar-title
        ><v-spacer></v-spacer
        ><download-excel
          class="mx-2"
          :data="tabel"
          :fields="fieldExcell"
          worksheet="data"
          :name="filename"
        >
          <v-btn icon color="blue"
            ><v-icon>mdi-table-large</v-icon></v-btn
          > </download-excel
        ><v-btn icon color="red" @click="dialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-toolbar
      >
      <div>
        <v-btn-toggle v-model="toggle_none" dense>
          <v-btn v-for="a in namaPabrik" :key="a.id" @click="filter(a)" x-small>
            {{ a }}
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="tabel"
          :items-per-page="5"
          dense
          mobile-breakpoint="300"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-arrow-left-drop-circle-outline',
            nextIcon: 'mdi-arrow-right-drop-circle-outline',
          }"
        >
          <template v-slot:item.tanggal="{ item }">
            <span>{{ item.tanggal | formatDate }}</span>
          </template>
          <template v-slot:item.tanggalawal="{ item }">
            <span
              >{{ item.daritanggal | formatDate }}-{{
                item.sampaitanggal | formatDate
              }}</span
            >
          </template>
          <template v-slot:item.masuk="{ item }">
            <span :class="classEror(item)">{{ item.masuk | rupiah }}</span>
          </template>
          <template v-slot:item.kredit="props">
            <v-edit-dialog
              :return-value.sync="props.item.kredit"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
            >
              {{ props.item.kredit | rupiah }}
              <template v-slot:input>
                <v-text-field
                  v-model="nominalNyata"
                  label="Edit"
                  single-line
                  :rules="[
                    (v) => v <= 10000000000 || 'Terlalu besar',
                    (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                  ]"
                  type="number"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.selisih="{ item }">
            <span>{{
              (parseInt(item.masuk) - parseInt(item.kredit)) | rupiah
            }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn class="mx-2" icon color="blue" x-small @click="buka(item)">
              <v-icon> mdi-pencil </v-icon> </v-btn
            ><v-btn
              class="mx-2"
              icon
              color="blue"
              x-small
              @click="bukaSatu(item)"
            >
              <v-icon> mdi-list-status </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions> </v-card-actions>
      <tambah
        :transaksi="transaksi"
        :pemicu="dialogTambah"
        :idcair="idcair"
        @tutup="tutup"
        @insert="insert"
        @update="update"
        :akun="akun"
        :tanggal="arrTanggal[0]"
      ></tambah>
      <satusatu
        :idTanggal="idcair"
        :pemicu="pemicuSatu"
        @tutup="pemicuSatu = false"
        @update="update"
      >
      </satusatu>
    </v-card>
  </v-dialog>
</template>
<script>
// import moment from "moment";
import tambah from "./transaksiTambah.vue";
import satusatu from "./satuSatu.vue";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
export default {
  props: ["pemicu", "id", "arrTanggal", "akun"],
  components: { tambah, satusatu },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    filename() {
      return `Rekap Transaksi -${moment().format("DD-MM-YY hh")}`;
    },
  },
  data() {
    return {
      loading: true,
      search: "",
      tabel: [],
      headers: [
        { text: "Tanggal", value: "tanggal" },
        { text: "Rentang", value: "tanggalawal" },
        { text: "Pabrik", value: "pabrik" },
        { text: "Masuk", value: "masuk" },
        { text: "Fakta", value: "kredit" },
        { text: "Selisih", value: "selisih" },
        { text: "Actions", value: "actions" },
      ],
      fieldExcell: {
        ID: "id",
        Tanggal: "tanggal",
        dari: "daritanggal",
        sampai: "sampaitanggal",
        Pabrik: "pabrik",
        Masuk: "masuk",
        Fakta: "kredit",
        Selisih: "selisih",
      },
      pencairan: [],
      pemicuSatu: false,
      tanggalbank: [],
      dialogNew: false,
      dialogTambah: false,
      idcair: 0,
      transaksi: {},
      nominalNyata: 0,
      max25chars: (v) => v.length <= 25 || "Input too long!",
      tabelReset: [],
      namaPabrik: [],
      toggle_none: null,
    };
  },
  watch: {
    pemicu() {
      this.inisialisasi();
      console.log("diganti tanggale");
    },
  },
  mounted() {
    this.inisialisasi();
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let url = `/akun/transaksimasuk/${this.id}/${this.arrTanggal[0]}/${this.arrTanggal[1]}`;
      console.log(url);
      axios.get(url).then((response) => {
        this.tabel = _.values(response.data);
        this.tabelReset = _.values(response.data);
        console.log(this.tabel);
        this.namaPabrikS();

        this.loading = false;
      });
    },
    cekNol(nilai) {
      if (nilai) {
        return isNaN(parseInt(nilai, 10)) ? 0 : parseInt(nilai, 10);
      } else {
        return 0;
      }
    },
    buka(item) {
      this.idcair = item.id;
      this.dialogTambah = true;
      this.transaksi = item;
    },
    bukaSatu(item) {
      this.idcair = item.id;
      this.pemicuSatu = true;
    },
    bukaBaru() {
      this.idcair = 0;
      this.dialogTambah = true;
    },
    tutup() {
      this.dialogNew = false;
      this.dialogTambah = false;
    },
    lanjutkan(item) {
      this.dialogNew = false;
      this.tabel.push(item);
      this.dialogTambah = true;
    },
    insert(item) {
      this.inisialisasi();
      this.idcair = item.id;
    },
    update() {
      this.inisialisasi();
      this.$emit("update");
    },
    tagihan() {
      this.pemicuTagihan = true;
    },
    save(item) {
      let uri = `/transaksi/edit/${item.idTransaksi}/selisih`;
      let data = {};
      data.kredit = parseInt(this.nominalNyata);
      axios
        .put(uri, data)
        .then((response) => {
          if (response.data.status) {
            this.$noty.success(response.data.messages);
            this.inisialisasi();
          }
        })
        .catch((error) => {
          this.$noty.error(error.response.data.errors[0]);
          console.log(error.response.data.errors);
        });
      this.nominalNyata = 0;
    },
    cancel() {},
    open() {},
    close() {},
    classEror(item) {
      let selisih = parseInt(item.masuk) - parseInt(item.saldo);
      if (selisih > 10 || selisih < -10) {
        return "red";
      }
    },
    namaPabrikS() {
      this.namaPabrik = this.tabelReset.reduce(function (chace, item) {
        if (
          !chace.find((x) => {
            return x === item.pabrik;
          })
        ) {
          let a = item.pabrik;
          chace.push(a);
          return chace;
        }
        return chace;
      }, []);
      console.log(this.namaPabrik);
    },
    filter(a) {
      if (this.toggle_none == null) {
        this.tabel = this.tabelReset.filter(function (x) {
          return x.pabrik == a;
        });
      } else {
        this.tabel = this.tabelReset;
      }
    },
  },
};
</script>
