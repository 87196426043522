<template>
  <v-row class="ma-2"
    ><v-col cols="12" md="3">
      <v-date-picker v-model="tanggal" elevation="4"></v-date-picker
    ></v-col>
    <v-col cols="12" md="9">
      <v-card color="grey lighten-3" :loading="loading"
        ><v-toolbar color="blue darken-3" dense dark>
          <v-toolbar-title>{{ akun.nama }}</v-toolbar-title>
          <v-spacer></v-spacer
          ><v-btn color="red" icon x-large @click="$router.push('/bank')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <template v-slot:extension>
            <v-tabs v-model="Tab" align-with-title>
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab v-for="item in tabs" :key="item" @click="idTab = item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-row class="ma-1">
          <v-col cols="12" md="5">
            <table>
              <tbody>
                <tr>
                  <td>
                    <span class="ma-3"> Saldo : {{ akun.sum | rupiah }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
          <v-col cols="12" md="7">
            <v-btn
              color="purple"
              dark
              small
              @click="
                bukaRekKoran = false;
                pemicuTgl = true;
              "
              class="ml-2"
              >Rekap Transaksi</v-btn
            >
            <v-btn
              color="pink"
              small
              dark
              @click="
                bukaRekKoran = true;
                pemicuTgl = true;
              "
              class="ml-2"
              >Rekening Koran</v-btn
            ><v-btn
              color="pink"
              small
              dark
              @click="pemicuImpor = true"
              class="ml-2"
              ><v-icon>mdi-briefcase-download-outline</v-icon></v-btn
            ><v-btn
              color="blue darken-2"
              dark
              icon
              @click="pemicuTransfer = true"
              :disabled="loading"
              class="ma-1"
              ><v-icon>mdi-credit-card-outline</v-icon></v-btn
            >
            <v-btn
              color="green darken-2"
              dark
              icon
              @click="pemicuSamakan = true"
              :disabled="loading"
              class="ma-1"
              ><v-icon>mdi-circle-edit-outline</v-icon></v-btn
            >
          </v-col>
          <v-col cols="12">
            <transaksi
              v-if="idTab == 'Pabrik'"
              :idAkun="id"
              :tanggal="tanggal"
              :akun="akun"
              :reload="reloadTransaksi"
              @update="inisialisasi"
            ></transaksi>
            <nota
              v-if="idTab == 'Transaksi'"
              :nota="notas"
              :loading="loading"
              @reload="inisialisasi"
              :idAkun="id"
              :tanggal="tanggal"
              :bank="true"
              @bukaLink="bukaLink"
              @hapusLink="hapusLink"
            ></nota>
            <tagihan
              v-if="idTab == 'Tagihan'"
              :id="id"
              @tutup="pemicuTagihan = false"
            ></tagihan>
          </v-col>
          <rekeningkoran
            :pemicu="pemicuRekening"
            :idAkun="id"
            :tanggal="arrTanggal"
            @tutup="pemicuRekening = false"
          ></rekeningkoran>
          <rekor2
            :pemicu="pemicuRekor"
            :tanggal="tanggal"
            :akun="id"
            :modeSelect="true"
            :dicari="dicari"
            @tutup="pemicuRekor = false"
            @list="simpan"
          ></rekor2>
          <rekaptransaksi
            :pemicu="pemicuRekap"
            :arrTanggal="arrTanggal"
            :id="id"
            :akun="akun"
            @tutup="pemicuRekap = false"
          ></rekaptransaksi>
          <tgl
            :pemicu="pemicuTgl"
            @tutup="pemicuTgl = false"
            @simpan="bukaRekap"
          ></tgl>
          <modalImport
            :pemicu="pemicuImpor"
            @tutup="pemicuImpor = false"
            :tanggal="tanggal"
            :akun="id"
            @reload="inisialisasi"
          ></modalImport>
          <bayarTransfer
            :pemicu="pemicuTransfer"
            @tutup="pemicuTransfer = false"
            :tanggal="tanggal"
            :akun="id"
            @reload="inisialisasi"
          ></bayarTransfer>
          <samakan
            :pemicu="pemicuSamakan"
            @tutup="pemicuSamakan = false"
            :tanggal="tanggal"
            :akun="id"
            @reload="inisialisasi"
          ></samakan>
        </v-row>
      </v-card>
      <v-col cols="12"> </v-col>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import nota from "../../Pencairan/listNota.vue";
import tagihan from "./tagihan.vue";
import rekeningkoran from "./rekKoran.vue";
import rekaptransaksi from "./rekapTransaksi.vue";
import modalImport from "../../Pencairan/imporModal/modal.vue";
import samakan from "../../Pencairan/imporModal/samakanRekening.vue";
import tgl from "../../component/popupTgl.vue";
import moment from "moment";
import transaksi from "./transaksiMasuk.vue";
import bayarTransfer from "../../Pencairan/imporModal/bayarTagihan.vue";
import rekor2 from "../../Pencairan/imporModal/modal.vue";
export default {
  components: {
    transaksi,
    nota,
    tagihan,
    rekeningkoran,
    rekaptransaksi,
    tgl,
    modalImport,
    bayarTransfer,
    samakan,
    rekor2,
  },
  props: ["id"],
  computed: {},
  data() {
    return {
      tanggal: moment().format("YYYY-MM-DD"),
      loadingRefresh: false,
      loading: false,
      akun: {},
      tabs: ["Transaksi", "Pabrik", "Tagihan"],
      idTab: "Transaksi",
      Tab: "Transaksi",
      reloadTransaksi: false,
      modal: [],
      pemicuRekening: false,
      pemicuRekap: false,
      arrTanggal: [],
      pemicuTgl: false,
      bukaRekKoran: false,
      notas: [],
      pemicuImpor: false,
      pemicuTransfer: false,
      pemicuSamakan: false,
      pemicuRekor: false,
      dicari: {},
    };
  },
  mounted() {
    this.inisialisasi();
  },
  watch: {
    tanggal() {
      this.reloadTransaksi = !this.reloadTransaksi;
      this.inisialisasi();
      console.log(this.reloadTransaksi);
    },
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let url = `/akunjurnal/kasir/${this.id}/${this.tanggal}`;
      axios.get(url).then((response) => {
        this.akun = response.data;
        let uri = `/pencairan/kasir/saldo/${this.id}/${this.tanggal}`;
        axios
          .get(uri)
          .then((response) => {
            this.transaksi = response.data;
            this.notas = response.data.nota;
            this.transfer = response.data.transfer;
            console.log(this.transfer);
            if (this.transfer.length > 0) {
              this.transfer.map((x) => {
                if (x.jurnal.toke.ID != this.id) {
                  x.jurnal.referensi = {
                    ID: x.ID,
                    toke: { nama: x.toke.nama },
                    caraCair: x.caraCair,
                    jenis: x.jenis,
                  };
                  this.notas.push(x.jurnal);
                }
              });
            }
            this.notas = this.notas.map((x) => {
              x.metode = x.referensi ? x.referensi.caraCair : "cash";
              x.masuk = 0;
              x.keluar = 0;
              if (x.rekening) {
                x.masuk = parseInt(x.pinjam) - x.rekening.masuk;
                x.keluar = parseInt(x.angsur) - x.rekening.keluar;
              }
              return x;
            });
            console.log(this.notas);
            this.loading = false;
          })
          .catch((e) => {
            this.$noty.error(e.message);
            this.loading = false;
          });
        console.log(this.akun);
      });
    },
    home() {
      this.$router.push("/bank");
    },
    bukaRekap(item) {
      console.log(item);
      this.arrTanggal = item;
      if (this.bukaRekKoran) {
        this.pemicuRekening = true;
      } else {
        this.pemicuRekap = true;
      }
    },
    bukaLink(item) {
      this.dicari = item;
      this.dicari.toke = this.dicari.toke.nama;
      console.log(this.dicari);
      this.pemicuRekor = true;
    },
    hapusLink(item) {
      const id = item.rekening.id;
      console.log(id);
      const uri = `/rekeningkoran/validkan/${id}`;
      console.log(uri);
      axios
        .delete(uri)
        .then((res) => {
          if (res.data.status) {
            this.$noty.success("berhasil di eksekusi");
          }
        })
        .catch((e) => {
          this.$noty.error(e.message);
        })
        .finally(() => {
          this.inisialisasi();
        });
    },
    simpan(item) {
      let data = {};
      data.rekening = item;
      data.jurnal = this.dicari;
      const uri = `/rekeningkoran/validkan`;
      console.log(uri);
      console.log(data);
      axios
        .post(uri, data)
        .then((res) => {
          if (res.data.status) {
            this.$noty.success("berhasil di eksekusi");
          }
        })
        .catch((e) => {
          this.$noty.error(e.message);
        })
        .finally(() => {
          this.pemicuRekor = false;
          this.inisialisasi();
        });
    },
    refresh() {},
  },
};
</script>
