import Vue from "vue";
import VueRouter from "vue-router";

// const Toke = require("../pages/Toke/TokeContainer.vue").default;
// const RekapPinjam = require("../pages/RekapToke/RekapContainer.vue").default;
const Notfound = require("../pages/NotFound.vue").default;
const Tonase = require("../pages/Tonase/index.vue").default;
const Home = require("../pages/Home.vue").default;
import Dasboard from "../pages/index";
const Transaksilm = require("../pages/Transaksi/index.vue").default;
const TransaksiIdlm = require("../pages/Transaksi/TransaksiId.vue").default;
const test = require("../pages/test/index.vue").default;
const printNota = require("../pages/print/nota.vue").default;
const printPinjaman = require("../pages/print/printPinjaman.vue").default;
const printPlat = require("../pages/print/printPlat.vue").default;
const supirtoke = require("../pages/lainlain/supirToke/index.vue").default;
const indexBank = require("../pages/lainlain/AkunBank/index.vue").default;
const indexBankLm = require("../pages/lainlain/AkunBankCopy/index.vue").default;
const bank = require("../pages/lainlain/AkunBank/bank.vue").default;
const bankLm = require("../pages/lainlain/AkunBankCopy/bank.vue").default;
const harga = require("../pages/lainlain/harga/index.vue").default;
const hargalama = require("../pages/lainlain/harga/indexlama.vue").default;
const pabrik = require("../pages/lainlain/Pabrik/index.vue").default;
const invoice = require("../pages/lainlain/invoice/index.vue").default;
const laporan = require("../pages/lainlain/laporan/index.vue").default;
const relasi = require("../pages/lainlain/relasi/index.vue").default;
const akunkasir = require("../pages/lainlain/akunkasir/index.vue").default;
import tutupbuku from "../pages/tutupbuku/index.vue";
import opsbd from "../pages/print/opsbd";
import tutupton from "../pages/tutupbuku/tonase";
import ops from "../pages/lainlain/ops";
import Login from "../pages/Login.vue";
import Register from "../pages/Register.vue";
import user from "../pages/users/index.vue";
import perpajakan from "../pages/lainlain/laporanPajak/index.vue";
import tokeIndex from "../pages/Toke/index.vue";
import newRekapToke from "../pages/RekapToke/index.vue";
import vmodelEx from "../pages/experiment/vmodel.vue";
import timbangan from "../pages/transaksiRam/timbangan/homeRamp.vue";
import ramHome from "../pages/transaksiRam/index.vue";
import rawHome from "../pages/lainlain/raw/index.vue";
import rawGolongan from "../pages/lainlain/raw/golongan/index.vue";
import rekening from "../pages/lainlain/rekening/index.vue";
import rekeningKoran from "../pages/lainlain/rekening/rekeningKoran.vue";
import Transaksi from "../pages/Pencairan/index.vue";
import TransaksiId from "../pages/Pencairan/TransaksiId.vue";
import TonaseRamp from "../pages/transaksiRam/tonase.vue";
import TransaksiPabriks from "../pages/lainlain/TransaksiPabrik/index.vue";
import TransaksiPabrik from "../pages/lainlain/TransaksiPabrik/pabrik.vue";
import rekeningBank from "../pages/lainlain/rekening/rekeningBank.vue";
import pinjaman from "../pages/pinjaman/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        components: { default: Home, Mainview: Dasboard },
        meta: { requiresAuth: true },
        name: "Homes",
      },
      // {path: "/toke",components:{default:Home,Mainview: Toke}, meta:{requiresAuth:true},name:"Toke/Pinjaman"},
      {
        path: "/toke",
        components: { default: Home, Mainview: tokeIndex },
        meta: { requiresAuth: true },
        name: "Toke/Pinjaman",
      },
      {
        path: "/pinjaman",
        components: { default: Home, Mainview: pinjaman },
        meta: { requiresAuth: true },
        name: "Pinjaman/Pinjaman",
      },
      {
        path: "/rekap",
        components: { default: Home, Mainview: newRekapToke },
        meta: { requiresAuth: true },
        name: "Rekap Pinjaman",
      },
      {
        path: "/tonase",
        components: { default: Home, Mainview: Tonase },
        meta: { requiresAuth: true },
        name: "Validasi DO",
      },
      {
        path: "/transaksilm",
        components: { default: Home, Mainview: Transaksilm },
        meta: { requiresAuth: true },
        name: "Transaksi Homelm",
      },
      {
        path: "/transaksilm/:kasir/:id",
        components: { default: Home, Mainview: TransaksiIdlm },
        props: { Mainview: true },
        name: "Transaksilm",
        meta: { requiresAuth: true },
      },
      {
        path: "/transaksi",
        components: { default: Home, Mainview: Transaksi },
        meta: { requiresAuth: true },
        name: "Transaksi Home",
      },
      {
        path: "/transaksi/:id",
        components: { default: Home, Mainview: TransaksiId },
        props: { Mainview: true },
        name: "Transaksi",
        meta: { requiresAuth: true },
      },
      {
        path: "/tes",
        components: { default: Home, Mainview: test },
        meta: { requiresAuth: true },
        name: "Testing",
      },
      {
        path: "/print/nota/:id",
        components: { default: Home, Mainview: printNota },
        meta: { requiresAuth: true },
        props: { Mainview: true },
        name: "print nota",
      },
      {
        path: "/print/plat/",
        components: { default: Home, Mainview: printPlat },
        meta: { requiresAuth: true },
        name: "print plat",
      },
      {
        path: "/print/pinjaman/:id",
        components: { default: Home, Mainview: printPinjaman },
        meta: { requiresAuth: true },
        props: { Mainview: true },
        name: "print pinjaman",
      },
      {
        path: "/print/opsbd/:thn/:bln",
        components: { default: Home, Mainview: opsbd },
        meta: { requiresAuth: true },
        props: { Mainview: true },
        name: "print OPS Dendang",
      },
      {
        path: "/supirtoke",
        components: { default: Home, Mainview: supirtoke },
        meta: { requiresAuth: true },
        name: "supir dan toke",
      },
      {
        path: "/bank",
        components: { default: Home, Mainview: indexBank },
        meta: { requiresAuth: true },
        name: "Bank",
      },
      {
        path: "/banklm",
        components: { default: Home, Mainview: indexBankLm },
        meta: { requiresAuth: true },
        name: "Banklm",
      },
      {
        path: "/bank/:id",
        components: { default: Home, Mainview: bank },
        meta: { requiresAuth: true },
        props: { Mainview: true },
        name: "Rincian Bank",
      },
      {
        path: "/banklm/:id",
        components: { default: Home, Mainview: bankLm },
        meta: { requiresAuth: true },
        props: { Mainview: true },
        name: "Rincian Banklm",
      },
      {
        path: "/harga/",
        components: { default: Home, Mainview: harga },
        meta: { requiresAuth: true },
        props: true,
        name: "Harga",
      },
      {
        path: "/harga/lama",
        components: { default: Home, Mainview: hargalama },
        meta: { requiresAuth: true },
        props: true,
        name: "Harga old",
      },
      {
        path: "/pabrik/",
        components: { default: Home, Mainview: pabrik },
        meta: { requiresAuth: true },
        name: "pabrik",
      },
      {
        path: "/invoice/",
        components: { default: Home, Mainview: invoice },
        meta: { requiresAuth: true },
        name: "invoice",
      },
      {
        path: "/laporan/",
        components: { default: Home, Mainview: laporan },
        meta: { requiresAuth: true },
        name: "Laporan",
      },
      {
        path: "/relasi/",
        components: { default: Home, Mainview: relasi },
        meta: { requiresAuth: true },
        name: "relasi",
      },
      {
        path: "/akunkasir/",
        components: { default: Home, Mainview: akunkasir },
        meta: { requiresAuth: true },
        name: "akun dan kasir",
      },
      {
        path: "/tutupbuku/tutupton/:thn/:bln",
        components: { default: Home, Mainview: tutupton },
        meta: { requiresAuth: true },
        props: { Mainview: true },
        name: "T buku",
      },
      {
        path: "/tutupbuku/",
        components: { default: Home, Mainview: tutupbuku },
        meta: { requiresAuth: true },
        name: "Tutup buku",
      },
      {
        path: "/ops",
        components: { default: Home, Mainview: ops },
        meta: { requiresAuth: true },
        name: "OPS pabrik",
      },
      {
        path: "/user",
        components: { default: Home, Mainview: user },
        meta: { requiresAuth: true },
        name: "User",
      },
      {
        path: "/perpajakan",
        components: { default: Home, Mainview: perpajakan },
        meta: { requiresAuth: true },
        name: "perpajakan",
      },
      {
        path: "/ram",
        components: { default: Home, Mainview: ramHome },
        meta: { requiresAuth: true },
        name: "indexram",
      },
      {
        path: "/ram/tonase",
        components: { default: Home, Mainview: TonaseRamp },
        meta: { requiresAuth: true },
        name: "tonRamp",
      },
      {
        path: "/ram/:id",
        components: { default: Home, Mainview: timbangan },
        meta: { requiresAuth: true },
        props: { Mainview: true },
        name: "timbangan",
      },
      {
        path: "/raw",
        components: { default: Home, Mainview: rawHome },
        meta: { requiresAuth: true },
        name: "rawHome",
      },
      {
        path: "/raw/golongan",
        components: { default: Home, Mainview: rawGolongan },
        meta: { requiresAuth: true },
        name: "rawHome2",
      },
      {
        path: "/experiment/vmodel",
        components: { default: Home, Mainview: vmodelEx },
        meta: { requiresAuth: true },
        name: "vmodel",
      },
      {
        path: "/rekening",
        components: { default: Home, Mainview: rekening },
        meta: { requiresAuth: true },
        name: "rekening",
      },
      {
        path: "/transaksipabrik",
        components: { default: Home, Mainview: TransaksiPabriks },
        meta: { requiresAuth: true },
        name: "transaksipabriks",
      },
      {
        path: "/transaksipabrik/:id",
        components: { default: Home, Mainview: TransaksiPabrik },
        meta: { requiresAuth: true },
        props: { Mainview: true },
        name: "transaksipabrik",
      },
      {
        path: "/rekeningkoran/:id",
        components: { default: Home, Mainview: rekeningKoran },
        meta: { requiresAuth: true },
        props: { Mainview: true },
        name: "rekeningkoran",
      },
      {
        path: "/rekeningbank/:id",
        components: { default: Home, Mainview: rekeningBank },
        meta: { requiresAuth: true },
        props: { Mainview: true },
        name: "rekeningbank",
      },
    ],
  },
  { path: "/login", component: Login, name: "Login" },
  { path: "/register", component: Register, name: "register" },
  {
    path: "*",
    component: Notfound,
    name: "Loh kok Error",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function isLoggedIn() {
  return localStorage.getItem("token");
  // return true
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
  console.log(from);
});

export default router;
