var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-6",attrs:{"dense":"","tile":"","loading":_vm.loading}},[_c('v-card-title',[_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){return _vm.tutup()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_vm._v("List Nota "),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",on:{"click":function($event){return _vm.download()}}},[_vm._v("Download")]),_c('input',{ref:"upload",staticStyle:{"display":"none"},attrs:{"type":"file","accept":".xlsx"},on:{"change":_vm.upload}}),_c('v-btn',{staticClass:"mx-2",on:{"click":function($event){return _vm.$refs.upload.click()}}},[_vm._v("Upload")])],1),_c('v-card-text',[_c('vue-excel-editor',{ref:"grid",attrs:{"filter-row":"","enterToSouth":"","no-paging":"","new-if-bottom":"","no-mouse-scroll":false,"free-select":"","readonly":!_vm.edit},on:{"update":_vm.update},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}},[_c('vue-excel-column',{attrs:{"field":"jenis","label":"Jenis Nota","type":"map","width":"150px","options":{
          0: 'Pencairan',
          1: 'Pinjaman',
          4: 'Tagihan',
          5: 'Ramp',
          100: 'DRAFT',
        },"readonly":""}}),_c('vue-excel-column',{attrs:{"field":"jenisP","label":"Jenis Pi","type":"select","width":"30px","options":['PS', 'PT']}}),_c('vue-excel-column',{attrs:{"field":"toke","label":"\n          akun","type":"map","options":_vm.tokes,"width":"120px"}}),_c('vue-excel-column',{attrs:{"field":"keterangan","label":"Uraian","type":"string","width":"250px"}}),_c('vue-excel-column',{attrs:{"field":"angsur","label":"masuk","type":"number","width":"150px","summary":"sum","to-text":_vm.toText,"to-value":_vm.toVal}}),_c('vue-excel-column',{attrs:{"field":"pinjam","label":"keluar","type":"number","width":"150px","summary":"sum","to-text":_vm.toText,"to-value":_vm.toVal}})],1)],1),_c('v-card-actions',[(_vm.edit)?_c('v-btn',{staticClass:"mx-3",attrs:{"color":"blue","dark":"","outlined":"","disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v("Simpan")]):_vm._e(),(_vm.edit)?_c('v-btn',{attrs:{"color":"red","outlined":"","dark":""},on:{"click":function($event){_vm.edit = false;
        _vm.getData();}}},[_vm._v("Batal")]):_c('v-btn',{attrs:{"outlined":"","color":"green","dark":""},on:{"click":function($event){_vm.edit = true}}},[_vm._v("Edit")]),_c('v-spacer'),(_vm.edit == false)?_c('v-btn',{attrs:{"dark":"","icon":"","color":"red","outlined":"","disabled":_vm.loading},on:{"click":_vm.hapus}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":"","color":"red"},on:{"click":_vm.tutup}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v("Tutup")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }