<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title
            >Detail saldo <b>{{ data.golongan }}</b></v-toolbar-title
          ><v-spacer></v-spacer
          ><v-btn icon dark @click="dialog.value = false" color="red">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Akun</th>
                  <th>Saldo</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in data" :key="item.id">
                  <td>{{ item.nama }}</td>
                  <td>
                    {{ item.sum | rupiah }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <b>{{ total | rupiah }}</b>
                  </td>
                </tr>
              </tbody>
            </template></v-simple-table
          >
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
// import axios from "axios";
// import _ from "lodash";
export default {
  components: {},
  props: ["data", "pemicu"],

  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    total() {
      return 0;
    },
  },
  data() {
    return {
      tabel: [],
      loading: false,
    };
  },
  watch: {},
  methods: {
    tutup() {},
  },

  //penulisan standar
  //   <pinjaman
  //     @tutup="pemicuX=false"
  //     :pemicu="pemicuX"
  // ></pinjaman>
};
</script>
