<template>
  <v-card dense tile :loading="loading" class="mb-6">
    <v-card-title
      ><v-btn class="ml-4" icon @click="tutup()"
        ><v-icon>mdi-arrow-left</v-icon></v-btn
      >List Nota <v-spacer></v-spacer
      ><v-btn class="ma-2" @click="download()">Download</v-btn
      ><input
        style="display: none"
        ref="upload"
        type="file"
        @change="upload"
        accept=".xlsx"
      />
      <v-btn @click="$refs.upload.click()" class="mx-2"
        >Upload</v-btn
      ></v-card-title
    >
    <v-card-text>
      <vue-excel-editor
        v-model="data"
        filter-row
        ref="grid"
        enterToSouth
        no-paging
        new-if-bottom
        :no-mouse-scroll="false"
        free-select
        :readonly="!edit"
        @update="update"
      >
        <vue-excel-column
          field="jenis"
          label="Jenis Nota"
          type="map"
          width="150px"
          :options="{
            0: 'Pencairan',
            1: 'Pinjaman',
            4: 'Tagihan',
            5: 'Ramp',
            100: 'DRAFT',
          }"
          readonly
        />
        <vue-excel-column
          field="jenisP"
          label="Jenis Pi"
          type="select"
          width="30px"
          :options="['PS', 'PT']"
        />
        <vue-excel-column
          field="toke"
          label="
            akun"
          type="map"
          :options="tokes"
          width="120px"
        />
        <vue-excel-column
          field="keterangan"
          label="Uraian"
          type="string"
          width="250px"
        />
        <vue-excel-column
          field="angsur"
          label="masuk"
          type="number"
          width="150px"
          summary="sum"
          :to-text="toText"
          :to-value="toVal"
        />
        <vue-excel-column
          field="pinjam"
          label="keluar"
          type="number"
          width="150px"
          summary="sum"
          :to-text="toText"
          :to-value="toVal"
        />
      </vue-excel-editor>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="save"
        color="blue"
        v-if="edit"
        dark
        outlined
        class="mx-3"
        :disabled="loading"
        >Simpan</v-btn
      >
      <v-btn
        @click="
          edit = false;
          getData();
        "
        color="red"
        outlined
        v-if="edit"
        dark
        >Batal</v-btn
      ><v-btn @click="edit = true" outlined color="green" v-else dark
        >Edit</v-btn
      >
      <v-spacer></v-spacer
      ><v-btn
        v-if="edit == false"
        dark
        icon
        color="red"
        @click="hapus"
        outlined
        :disabled="loading"
        ><v-icon>mdi-delete</v-icon></v-btn
      ><v-btn @click="tutup" outlined class="mx-2" color="red"
        ><v-icon>mdi-close</v-icon>Tutup</v-btn
      ></v-card-actions
    >
  </v-card>
</template>
<script>
import axios from "axios";
import numeral from "numeral";
import FileDownload from "js-file-download";
// import moment from "moment";

export default {
  props: ["tanggal", "idAkun", "pemicu", "reloader"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  mounted() {
    this.inisialisasi();
  },
  data() {
    return {
      loading: false,
      data: [],
      tokeStd: 1,
      tanggalStd: 0,
      pinjaman: [],
      harga: [],
      edit: false,
      toke: {},
      dataStd: [],
      fileUpload: null,
    };
  },
  methods: {
    inisialisasi() {
      console.log("excell pinjaman di eksekusi");
      this.loading = true;
      let uri = `/setting/get/tokeStandar`;
      axios
        .get(uri)
        .then((res) => {
          this.tokeStd = res.data;
        })
        .catch((e) => {
          this.tokeStd = 1;
          console.log(e.message);
        });
      this.getToke();
    },
    tutup() {
      this.$emit("tutup");
    },
    getData() {
      const uri = `/pencairan/notas/${this.idAkun}/${this.tanggal}`;
      console.log(uri);
      this.dataStd = [];
      axios
        .get(uri)
        .then((res) => {
          this.pinjaman = res.data;
          console.log(this.pinjaman);
          if (this.pinjaman.length > 0) {
            this.data = this.pinjaman.map((x) => {
              let item = {};
              item.jenisP = x.jurnal.jenis;
              item.jenis = x.jenis;
              item.toke = x.toke.ID;
              item.keterangan = x.keterangan;
              item.pinjam = x.jurnal.angsur;
              item.angsur = x.jurnal.pinjam;
              item.id = x.ID;

              return item;
            });
            console.log(this.data);
          } else {
            this.data = [
              {
                jenisP: "PS",
                jenis: 100,
                toke: this.tokeStd,
                keterangan: "",
                pinjam: 0,
                angsur: 0,
                id: 0,
              },
            ];
            this.edit = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getToke() {
      axios.get("/tokeAktif").then((res) => {
        let hasil = res.data;
        this.toke = hasil.reduce(
          (obj, item) => Object.assign(obj, { [item["ID"]]: item["nama"] }),
          {}
        );
        this.getData();
      });
    },
    tokes() {
      return this.toke;
    },
    toVal(val) {
      return parseInt(val.replace(/[^\d-]/g, ""));
    },
    toText(val) {
      return numeral(parseInt(val)).format("0,0");
    },
    update(record) {
      let a = this.data.findIndex((x) => {
        return x.$id == record[0].$id;
      });
      if (!record[0].newVal && !record[0].oldVal) {
        this.data[a].jenisP = "PS";
        this.data[a].jenis = 100;
        this.data[a].toke = this.tokeStd;
        this.data[a].keterangan = "";
        this.data[a].pinjam = 0;
        this.data[a].angsur = 0;
        this.data[a].id = 0;
      }
    },
    cekData() {
      console.log(this.data);
    },
    save() {
      if (this.loading == false) {
        let uri = `/pencairan/notas`;
        this.loading = true;
        console.log(uri);
        this.data = this.data.filter((x) => {
          return !(x.pinjam == 0 && x.angsur == 0);
        });
        let data = {};
        data.data = this.data;
        data.akun = this.idAkun;
        data.tanggal = this.tanggal;
        console.log(data);
        axios
          .post(uri, data)
          .then((res) => {
            console.log(res.data);
            if (res.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.getData();
              this.edit = false;
              this.$emit("reload");
              this.inisialisasi();
              console.log(res.data);
            }
          })
          .catch((e) => {
            this.$noty.error(e.message).finally((this.loading = false));
          });
      }
    },
    hapus() {
      let terpilih = this.$refs.grid.getSelectedRecords();
      if (terpilih.length > 0) {
        if (confirm("apa kamu yakin mau menghapus?")) {
          this.loading = true;
          terpilih = terpilih.map((x) => x.id);
          let uri = "/pencairan/nota/hapus";
          console.log(uri);
          console.log(terpilih);
          console.log(this.loading);
          axios
            .post(uri, terpilih)
            .then((res) => {
              console.log(res.data);
              this.$noty.success("data Berhasil dihapus");
              this.$emit("reload");
              this.inisialisasi();
              terpilih = [];
            })
            .catch((e) => {
              console.log(e.message);
              this.$noty.error(e.message);
            })
            .finally((this.loading = false));
        }
      } else {
        console.log("harus dipilih dulu");
        alert("pilih dulu");
      }
    },
    download() {
      let uri = `/pencairan/nota/download/${this.tanggal}/${this.idAkun}`;
      this.loading = true;
      axios
        .get(uri, { responseType: "blob" })
        .then((response) => {
          FileDownload(response.data, `nota${this.tanggal}${this.idAkun}.xlsx`);
          console.log(response.data);
        })
        .finally(() => (this.loading = false));
    },
    upload(item) {
      this.fileUpload = item.target.files[0];
      if (this.fileUpload) {
        this.jalankanUpload();
      }
    },
    async jalankanUpload() {
      const uri = `/pencairan/nota/upload/${this.tanggal}/${this.idAkun}`;
      if (!this.loading) {
        this.loading = true;
        if (!this.fileUpload) {
          return;
        }
        console.log(this.fileUpload);
        const data = new FormData();
        data.append("excel", this.fileUpload, this.fileUpload.name);
        axios
          .post(uri, data)
          .then((res) => {
            this.$noty.success("Berhasil di upload");
            console.log(res.data);
          })
          .finally(() => {
            this.inisialisasi();
            this.loading = false;
          });
      }
    },
  },
};
</script>
