<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="600"
    persistent
  >
    <v-card dense>
      <v-card-title
        ><v-row
          >Bayar tagihan <v-spacer></v-spacer
          ><v-btn icon color="red" @click="tutup()"
            ><v-icon>mdi-close </v-icon></v-btn
          ></v-row
        ></v-card-title
      ><v-divider></v-divider>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-row class="mx-4 mt-3"
              ><v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                dense
                class="mx-5 my-4"
              ></v-text-field
              ><v-spacer></v-spacer>
              <div>
                <v-switch
                  v-model="kasirAll"
                  :label="`Semua Kasir`"
                  dense
                ></v-switch></div
            ></v-row>
          </v-col>
          <v-col cols="12" v-if="back">
            <a @click="kembali" class="text-bold"
              ><v-icon>mdi-arrow-left</v-icon> {{ folderTerpilih }}</a
            >
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="data"
              :search="search"
              :items-per-page="300"
              :show-select="seleksi"
              v-model="terpilih"
              item-key="ID"
              hide-default-footer
              mobile-breakpoint="300"
            >
              <template v-slot:item.nominal="{ item }">
                <span>{{ item.nominal | rupiah }}</span>
              </template>
              <template v-slot:item.tanggal="{ item }">
                <span>{{ item.tanggal | formatDate }}</span>
              </template>
              <template v-slot:item.keterangan="{ item }">
                <a @click="bukaIsi(item)" v-if="back == false">{{
                  item.keterangan
                }}</a>
                <div v-else>{{ item.keterangan }}</div>
              </template>
            </v-data-table>
          </v-col>
          <v-btn
            color="blue"
            dark
            @click="teruskan"
            :disabled="tombolOff || kosong"
            >Bayar</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="pemicuNama"
      width="500"
      persistent
    >
      <v-card dense tile>
        <v-card-title>Simpan Sebagai</v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="simpan()"
            color="grey"
            ref="form"
            v-model="valid"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nama Tagihan"
                  required
                  v-model="keterangan"
                  autofocus
                >
                </v-text-field>
              </v-col>
              <v-btn color="blue" dark type="submit" :disabled="tombolOff"
                >Simpan</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="red" dark @click="pemicuNama = false">Batal</v-btn>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: {
    pemicu: Boolean,
    id: Number,
    idNota: Number,
    folder: Number,
    tanggal: String,
    idAkun: String,
  },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    kosong() {
      return !this.terpilih.length;
    },
  },
  watch: {
    pemicu() {
      if (this.pemicu) {
        this.inisialisasi();
      }
    },
    kasirAll() {
      this.grupkan();
    },
  },
  data() {
    return {
      valid: false,
      tombolOff: false,
      data: [],
      dataMentah: [],
      search: "",
      headerF: [
        { text: "Tanggal", value: "tanggal" },
        {
          text: "Uraian",
          align: "start",
          filterable: true,
          value: "keterangan",
        },
        { text: "Bayar", value: "nominal" },
      ],
      headerA: [
        { text: "Tanggal", value: "tanggal" },
        {
          text: "Uraian",
          align: "start",
          filterable: true,
          value: "keterangan",
        },
        { text: "Bayar", value: "nominal" },
      ],
      headers: [],
      terpilih: [],
      seleksi: false,
      back: false,
      folderTerpilih: "",
      dataNota: { keterangan: "" },
      transaksi: {},
      pemicuNama: false,
      keterangan: "",
      antri: false,
      kasirAll: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    inisialisasi() {
      this.terpilih = [];
      let uri = `/transfer/id_cair/${this.id}`;
      this.headers = this.headerF;
      this.seleksi = false;
      this.terpilih = [];
      this.back = false;
      axios
        .get(uri)
        .then((response) => {
          console.log(response.data);
          this.dataMentah = response.data;
          this.grupkan();
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    bukaIsi(item) {
      let id = item.id_gabung;
      this.headers = this.headerA;
      this.folderTerpilih = item.keterangan;
      this.data = this.dataMentah
        .filter((x) => {
          return x.id_gabung == id;
        })
        .map((x) => {
          x.nominal = x.hitung * -1;
          x.keterangan = x.uraian;
          return x;
        });
      this.seleksi = true;
      this.back = true;
      console.log(this.data);
    },
    kembali() {
      this.headers = this.headerF;
      this.folderTerpilih = "";
      this.seleksi = false;
      this.back = false;
      this.grupkan();
      this.terpilih = [];
    },
    grupkan() {
      this.data = this.dataMentah.reduce((before, item) => {
        let cur = before.findIndex((x) => {
          return x.id_gabung == item.id_gabung;
        });
        if (cur >= 0) {
          before[cur].nominal += parseFloat(item.hitung) * -1;
        } else {
          item.keterangan = item.nota.keterangan;
          item.nominal = parseFloat(item.hitung) * -1;
          before.push(item);
        }
        return before;
      }, []);
      if (this.kasirAll == false) {
        this.data = this.data.filter((x) => x.id_akun == this.idAkun);
      }
      this.data = this.data.sort(
        (a, b) => new Date(b.tanggal) - new Date(a.tanggal)
      );
      console.log(this.data);
    },

    simpan() {
      console.log("data dikirim");
      if (this.antri == false) {
        this.antri = true;
        let data = {};
        data.keterangan = this.keterangan;
        data.tanggal = this.tanggal;
        data.idAkun = this.idAkun;
        data.idTransfer = this.terpilih.map((x) => {
          return x.tf.id;
        });
        data.data = this.terpilih;
        let uri = `/pencairan/transfer/bayar/${this.idNota}`;
        console.log(uri);
        axios
          .post(uri, data)
          .then((res) => {
            console.log(res.data);
            this.antri = false;
            this.$emit("simpan", res.data.PID);
            this.tutup();
          })
          .catch((e) => {
            console.log(e.message);
            this.antri = false;
          });
        console.log(data);
      }
    },
    tutupNota() {
      this.pemicuNama = false;
    },
    teruskan() {
      console.log(`id dari nota: ${this.idNota}`);
      if (this.idNota) {
        this.simpan();
      } else {
        this.pemicuNama = true;
      }
    },
    kondisikan() {},
  },
};
</script>
