<template>
  <div>
    <v-card v-if="ujicoba"
      ><v-card-text>halaman ini sedang dibangun . . . </v-card-text></v-card
    >
    <v-card
      :loading="loading"
      elevation="3"
      class="mb-3"
      v-if="ujicoba == false"
    >
      <!-- <v-card-title>Nota</v-card-title> -->
      <v-card-actions>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          dense
          label="Cari"
          class="mx-2"
        ></v-text-field>
        <v-spacer></v-spacer
        ><v-btn
          dark
          @click="
            idNota = 0;
            pemicuPencairan = true;
          "
          color="green"
          ><v-icon class="mx-1">mdi-file-plus</v-icon>Pencairan</v-btn
        ><v-btn
          color="orange"
          dark
          @click="pemicuTransfer = true"
          :disabled="loading"
          ><v-icon class="mx-1">mdi-file-table-box-outline</v-icon
          >Pengeluaran</v-btn
        >
      </v-card-actions>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="nota"
          :search="search"
          :items-per-page="100"
          dense
          show-select
          v-model="terpilih"
          item-key="ID"
          mobile-breakpoint="300"
          hide-default-footer
        >
          <template v-slot:item.tanggal="{ item }">
            {{ item.tanggal | formatDate }}
          </template>
          <template v-slot:item.total="{ item }">
            <span>{{ item.total | rupiah }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="mx-2"
              icon
              x-small
              color="green"
              @click.stop="
                idNota = item.ID;
                pemicuPencairan = true;
              "
              ><v-icon> mdi-pencil </v-icon></v-btn
            >
            <v-btn
              class="mx-2"
              icon
              x-small
              color="red"
              @click.stop="hapus(item)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <pencairan
        :pemicu="pemicuPencairan"
        @tutup="pemicuPencairan = false"
        @update="refreshData"
        :id="idNota"
        :transaksi="transaksi"
      ></pencairan>
      <transfer
        :pemicu="pemicuTransfer"
        :id="transaksi.id"
        @tutup="pemicuTransfer = false"
      ></transfer>
    </v-card>
  </div>
</template>

<script>
import pencairan from "./input/pencairan.vue";
import transfer from "./input/transfer.vue";
import axios from "axios";
export default {
  components: { pencairan, transfer },
  props: ["nota", "loading", "transaksi"],
  data() {
    return {
      headers: [
        { text: "Tanggal", value: "tanggal" },
        { text: "Toke", value: "toke.nama" },
        { text: "Keterangan", value: "keterangan" },
        { text: "Total", value: "total" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      selectedItem: 1,
      dialogNota: false,
      idNota: 0,
      tonase: [],
      tonLoad: false,
      pinjaman: [],
      notaDialog: false,
      loadingData: true,
      cekNotaBaru: false,
      search: "",
      pemicuFolder: false,
      pemicuIsiFolder: false,
      terpilih: [],
      pemicuPencairan: false,
      pemicuTransfer: false,
      ujicoba: false,
    };
  },
  computed: {},
  methods: {
    buatNota() {
      this.notaDialog = true;
      // console.log(this.notaFilter);
    },
    bukaNotaBaru(item) {
      this.$emit("reload", item.ID);
      this.bukaNota(item);
    },
    bukaNota(item) {
      this.idNota = item.ID;
      this.dialogNota = true;
    },
    bukaFolderBaru(item) {
      this.$emit("reload", item.ID);
      this.bukaFolder(item);
    },
    bukaFolder(item) {
      this.idNota = item.ID;
      this.pemicuIsiFolder = true;
      console.log("buka folder dg id " + item.ID);
    },
    refreshData(item) {
      this.$emit("reload", item);
    },
    printin() {
      this.$htmlToPaper("printkan");
    },
    hapus(item) {
      console.log(`hapus item ${item.ID}`);
      if (confirm("apa kamu yakin mau menghapus?")) {
        let uri = `/notaPencairan/${item.ID}`;
        console.log(uri);
        axios
          .delete(uri)
          .then((response) => {
            this.$emit("reload");
            this.$emit("tutup");
            console.log(response);
          })
          .catch((e) => {
            this.$noty.error(e.message);
          });
      } else {
        // Do nothing!
        console.log("Thing was not saved to the database.");
      }
    },
  },
};
</script>
