<template>
  <v-card :loading="loading" class="mb-4">
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
      ></v-text-field>
      <v-switch label="tanpa nota" v-model="noNota" class="ml-2"></v-switch>
      <v-btn
        color="orange"
        class="ml-1"
        v-if="!adaYangTerpilih"
        x-small
        dark
        @click="pemicuPindah = true"
        >Pindah Nota</v-btn
      >
      <v-btn
        color="green"
        x-small
        dark
        class="mx-2 my-sm-0 my-2"
        @click="bukaGantiDo"
        v-if="!adaYangTerpilih"
        >Ganti DO</v-btn
      >
      <v-btn
        color="green"
        x-small
        dark
        class="mx-2 my-sm-0 my-2"
        @click="dialogTgl = true"
        v-if="!adaYangTerpilih"
        >Ganti Tanggal</v-btn
      >
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="tonase"
      :search="search"
      :items-per-page="500"
      dense
      show-select
      v-model="terpilih"
      group-by="do.pabrik.pabrik"
      item-key="id"
      mobile-breakpoint="300"
      hide-default-footer
    >
      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th colspan="11" class="blue lighten-4">
          <v-icon @click="toggle"
            >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
          </v-icon>
          {{ items[0].do.pabrik.pabrik }} :
          {{ perPabrik(items[0].do.pabrik.id) }} Mobil
        </th>
      </template>
      <template v-slot:item.tanggaldo="{ item }">
        <span>{{ item.tanggaldo | formatDate }}</span>
      </template>
      <template v-slot:item.tonase="{ item }">
        <span>{{ parseFloat(item.tonase) | formatNumber }}</span>
      </template>
      <template v-slot:item.harga="{ item }">
        <span>{{ parseFloat(item.harga) | formatNumber }}</span>
      </template>
      <template v-slot:item.ops="{ item }">
        <span>{{ parseFloat(item.ops) | formatNumber }}</span>
      </template>
      <template v-slot:item.th="{ item }">
        <span>{{ parseFloat(item.th) | formatNumber }}</span>
      </template>
      <template v-slot:item.c="{ item }">
        <span>{{
          (item.tonase * (parseFloat(item.harga) + parseFloat(item.th || 0)))
            | rupiah
        }}</span>
      </template>
      <template slot="body.append">
        <tr>
          <th>Totals</th>
          <th></th>
          <th>{{ sumData().jumlah }} Mbl</th>
          <th></th>
          <th></th>
          <th>{{ sumData().tonase | angka }}</th>
          <th></th>
          <th></th>
          <th></th>
          <th>{{ sumData().cair | rupiah }}</th>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<style></style>
<script>
import axios from "axios";
export default {
  props: ["idAkun", "tanggal"],
  components: {},
  computed: {
    adaYangTerpilih() {
      if (this.terpilih.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    console.log("di loading");
    this.inisialisasi();
  },
  watch: {
    noNota() {
      if (this.noNota) {
        this.tanpaNota();
      } else {
        this.inisialisasi();
      }
    },
  },
  data() {
    return {
      tonase: [],
      idTon: 0,
      headers: [
        {
          text: "Pabrik",
          align: "start",
          filterable: true,
          value: "do.pabrik.pabrik",
        },
        { text: "Tanggal", value: "tanggaldo" },
        { text: "Toke", value: "toke.nama" },
        { text: "Supir", value: "nama supir" },
        { text: "Plat", value: "plat" },
        { text: "Tonase", value: "tonase" },
        { text: "Harga", value: "harga" },
        { text: "TH", value: "th" },
        { text: "OPS", value: "ops" },
        { text: "Cair", value: "c" },
      ],
      search: "",
      terpilih: [],
      dialogTon: false,
      dialogDeo: false,
      dialogTgl: false,
      loadingDelet: false,
      noNota: false,
      pemicuPindah: false,
      loading: false,
    };
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      this.terpilih = [];
      let uri = `/pencairan/tonase/${this.idAkun}/${this.tanggal}`;
      axios
        .get(uri)
        .then((res) => {
          this.tonase = res.data;
          this.loading = false;
        })
        .catch((e) => {
          this.$noty.error(e.message);
          this.loading = false;
        });
    },

    tanpaNota() {
      this.loading = true;
      let uri = `/transaksi/${this.id}/tanpaNota`;
      axios
        .get(uri)
        .then((res) => {
          this.tonase = res.data.tonase;
          console.log(res.data);
          this.loading = false;
        })
        .catch((e) => {
          console.log({ msg: e.message, fungsi: "tanpaNota" });
          this.loading = false;
        });
    },
    perPabrik(id) {
      return this.tonase.filter((x) => x.do.pabrik.id == id).length;
    },
    edit(item) {
      this.idTon = item.id;
      this.dialogTon = true;
    },
    hapus(item) {
      this.loadingDelet = true;
      let uri = `/pencairan/tonase/${item.id}`;
      axios.delete(uri).then((response) => {
        console.log(response);
        this.$emit("reload");
        this.loadingDelet = false;
      });
    },
    sumData() {
      if (this.tonase.length > 0) {
        return this.tonase.reduce(
          (awal, x) => {
            awal.jumlah += 1;
            awal.tonase += parseInt(x.tonase);
            awal.cair += parseInt(
              parseInt(x.tonase) * (parseInt(x.harga) + parseInt(x.th))
            );
            return awal;
          },
          { jumlah: 0, tonase: 0, cair: 0 }
        );
      } else {
        return { jumlah: 0, tonase: 0, cair: 0 };
      }
    },
    tutupDialog() {
      this.dialogTon = false;
      this.dialogDeo = false;
      this.dialogTgl = false;
    },
    reload() {
      this.inisialisasi();
      this.terpilih = [];
      this.tutupDialog();
    },
    bukaGantiDo() {
      this.dialogDeo = true;
    },
  },
};
</script>
