<template>
  <div>
    <v-row
      ><v-col cols="1"
        ><v-btn @click="tutup" color="red" dark
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-col
      ><v-spacer></v-spacer
      ><v-col cols="1"
        ><download-excel
          :data="pinjaman"
          :fields="fieldExcell"
          worksheet="My Worksheet"
          :name="filename"
        >
          <v-btn dark color="blue"
            ><v-icon>mdi-table-large</v-icon></v-btn
          ></download-excel
        ></v-col
      ><v-col cols="1" class="mx-2">
        <v-btn @click="print" color="green" dark
          ><v-icon>mdi-printer</v-icon></v-btn
        ></v-col
      ></v-row
    >
    <v-card id="diprint">
      <v-card-title>
        <table>
          <tbody>
            <tr>
              <td>Nama Toke</td>
              <td>: {{ toke.nama }}</td>
            </tr>
            <tr>
              <td>Rentang Tanggal</td>
              <td>
                : {{ tanggalFilter[0] | formatDate }} sampai
                {{ tanggalFilter[1] | formatDate }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="pinjaman"
        dense
        :items-per-page="10000"
        mobile-breakpoint="300"
        :loading="loading"
        hide-default-footer
      >
        <template v-slot:item.tanggal="{ item }">
          <span>{{ item.tanggal | formatDate }}</span>
        </template>
        <template v-slot:item.pinjam="{ item }">
          <span>{{ item.pinjam | rupiah }}</span>
        </template>
        <template v-slot:item.angsur="{ item }">
          <span>{{ item.angsur | rupiah }}</span>
        </template>
        <template v-slot:item.saldo="{ item }">
          <span>{{ item.saldo | rupiah }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// import printJS from "print-js";
// import axios from "axios";
import moment from "moment";
export default {
  props: {
    tabel: Array,
    saldoAwal: String,
    loading: Boolean,
    toke: Object,
    tanggalFilter: Array,
    mode: Boolean,
  },
  components: {},
  data() {
    return {
      pinjaman: [],
      headers: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggal",
        },
        { text: "Uraian", value: "uraian" },
        { text: "Pinjam", value: "pinjam" },
        { text: "Bayar", value: "angsur" },
        { text: "Saldo", value: "saldo" },
        { text: "Kasir", value: "akun" },
      ],
      fieldExcell: {
        ID: "ID",
        Tanggal: "tanggal",
        Uraian: "uraian",
        Pinjam: "pinjam",
        Angsur: "angsur",
        Saldo: "saldo",
        Kasir: "akun",
      },
    };
  },
  watch: {
    mode() {
      this.inisialisasi();
    },
  },
  mounted() {
    this.inisialisasi();
  },
  computed: {
    lastPage() {
      return Math.ceil(this.pinjaman.length / 10);
    },
    filename() {
      return `Pinjaman-${this.toke.nama} ${moment().format("DD-MM-YY hh")}`;
    },
  },
  methods: {
    cekNol(nilai) {
      if (nilai) {
        return isNaN(parseInt(nilai, 10)) ? 0 : parseInt(nilai, 10);
      } else {
        return 0;
      }
    },
    addSaldo() {
      console.log(this.tabel);
      var saldo = [];
      const items = this.tabel;
      var saldoA = parseInt(this.saldoAwal, 10);
      for (let a = 0; a < items.length; a++) {
        let b = items[a].transaksi ? items[a].transaksi.akun.nama : "";
        saldoA =
          saldoA + this.cekNol(items[a].pinjam) - this.cekNol(items[a].angsur);
        saldo.push({
          id: items[a].ID,
          tanggal: items[a].tanggal,
          pinjam: items[a].pinjam,
          uraian: items[a].uraian,
          angsur: items[a].angsur,
          saldo: saldoA,
          akun: b,
        });
      }
      this.pinjaman = saldo;
    },
    inisialisasi() {
      this.addSaldo();
      const sortir = this.tanggalFilter;
      const tglA = sortir[0];
      const tglB = sortir[1];
      this.pinjaman = this.pinjaman.filter(function (x) {
        return (
          new Date(x.tanggal) >= new Date(tglA) &&
          new Date(x.tanggal) <= new Date(tglB)
        );
      });
    },
    print() {
      this.$htmlToPaper("diprint", {
        styles: [
          "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
          "/aplikasi/riz/app.css",
        ],
      });
    },
    tutup() {
      this.$emit("tutup");
    },
    reload() {},
  },
};
</script>
