<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="600"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Relasi</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Nama Relasi"
                required
                v-model="dataTransaksi.nama"
                ref="deo"
                :rules="[(v) => !!v || 'Harus diisi']"
              ></v-text-field
            ></v-col>
            <v-col cols="12">
              <v-text-field
                label="Fee"
                required
                :rules="[
                  (v) => v <= 10000000000 || 'Terlalu besar',
                  (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                ]"
                v-model="dataTransaksi.PEE"
                type="number"
                ref="fee"
              ></v-text-field
            ></v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="dataTransaksi.akun"
                auto-select-first
                :items="bank"
                item-text="nama"
                item-value="id"
                label="Bank"
                ref="bank"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12">
              <v-text-field
                label="Nama Print"
                required
                v-model="dataTransaksi.namaPrint"
                ref="namaPrint"
                :rules="[(v) => !!v || 'Harus diisi']"
              ></v-text-field
            ></v-col>
            <v-col cols="12">
              <v-text-field
                label="No urut"
                required
                v-model="dataTransaksi.NOu"
                ref="nou"
                :rules="[(v) => !!v || 'Harus diisi']"
              ></v-text-field
            ></v-col>

            <v-btn color="blue" dark type="submit" :disabled="loadingSimpan"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import rizki from "./../../../mixins/rizki.js";
export default {
  mixins: [rizki],
  props: ["pemicu", "pabrik", "id"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
      this.kondisikan();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      dataTransaksi: {
        nama: "",
        NOu: "",
        PEE: 0,
        pabrik: 0,
        akun: 0,
        namaPrint: "",
      },
      loading: false,
      loadingSimpan: false,
      bank: [],
      pembanding: {},
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid && this.loadingSimpan == false) {
        this.loadingSimpan = true;
        console.log(this.dataTransaksi);
        this.simpan();
        if (this.id > 0) {
          let uri = `/relasi/${this.id}`;
          axios
            .put(uri, this.r_data_update(this.dataTransaksi, this.pembanding))
            .then((response) => {
              if (response.data.status) {
                this.$noty.success("Data berhasil disimpan");
                this.tutup();
                this.$emit("lanjutkan", response.data.data);
                console.log(response.data.data);
                this.loadingSimpan = false;
              } else {
                this.$noty.success(response.data.data[0]);
              }
            });
        } else {
          console.log(this.dataTransaksi);
          let uri = "/relasi";
          axios.post(uri, this.dataTransaksi).then((response) => {
            if (response.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.tutup();
              this.$emit("lanjutkan", response.data.data);
              console.log(response.data.data);
              this.loadingSimpan = false;
            } else {
              this.$noty.success(response.data.data[0]);
            }
          });
        }
      }
    },
    inisialisasi() {
      let uri = "/akun";
      this.r_coba();
      axios.get(uri).then((response) => {
        this.bank = response.data;
      });
      if (this.id > 0) {
        let uri = `/relasi/${this.id}`;
        axios.get(uri).then((response) => {
          this.dataTransaksi = response.data;
          this.dataTransaksi.akun = parseInt(this.dataTransaksi.akun);
          this.pembanding = Object.assign({}, this.dataTransaksi);
        });
      } else {
        this.dataTransaksi.nama = "";
        this.dataTransaksi.NOu = "";
        this.dataTransaksi.PEE = 0;
        this.dataTransaksi.pabrik = this.pabrik;
        this.dataTransaksi.akun = 0;
        this.dataTransaksi.namaPrint = "";
      }
    },
    simpan() {},
    kondisikan() {},
  },
};
</script>
