<template>
  <v-card dense class="overflow-auto" :loading="loading">
    <v-toolbar dense elevation="-2">
      <v-btn icon large @click="tutup()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title v-if="obNota" dense class="text-uppercase">
        <v-chip class="ma-2" color="yellow">
          {{ idNota }}
        </v-chip>
        {{ obNota.keterangan }} |
      </v-toolbar-title>
      <v-spacer></v-spacer>
      Total: {{ total | rupiah }}
    </v-toolbar>
    <v-row no-gutters class="px-md-5" v-if="tonAktif">
      <v-col cols="12">
        <v-card
          ><v-card-title class="mt-3"
            ><v-row
              >Pembelian Ramp <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                dense
                label="Cari"
                class="mx-2"
              ></v-text-field
              ><v-btn
                class="mx-3"
                fab
                color="blue"
                dark
                @click="pemicuPilihan = true"
                ><v-icon>mdi-plus</v-icon></v-btn
              ><v-btn
                v-if="terpilih.length > 0"
                class="mx-3"
                fab
                color="red"
                dark
                @click="hapusPilihan"
                ><v-icon>mdi-delete</v-icon></v-btn
              ></v-row
            ></v-card-title
          ><v-card-text>
            <v-data-table
              :headers="headers"
              :items="ramp"
              mobile-breakpoint="300"
              hide-default-footer
              :items-per-page="500"
              :search="search"
              dense
              show-select
              v-model="terpilih"
              item-key="id"
            >
              <template v-slot:item.tanggal="{ item }">
                <span>{{ item.tanggal | formatDate }}</span>
              </template>
              <template v-slot:item.netto="{ item }">
                <span>{{ item.netto | angka }}</span>
              </template>
              <template v-slot:item.harga="{ item }">
                <span>{{ item.harga | angka }}</span>
              </template>
              <template v-slot:item.cair="{ item }">
                <span>{{ item.totCair | rupiah }}</span>
              </template>
              <template slot="body.append">
                <tr class="text-subtitle-2">
                  <td colspan="3">Total</td>

                  <td>0</td>
                  <td>0</td>
                  <td>{{ totNetto | angka }}</td>
                  <td></td>
                  <td>{{ totCair | rupiah }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text></v-card
        >
      </v-col>
      <v-col cols="12" class="my-4">
        <excellTon
          @reload="reload"
          :nota="obNota"
          :tonase="tonase"
          :pabrik="obPabrik"
          :reloader="reloader"
          :toke="obToke"
          :deo="deo"
        ></excellTon>
      </v-col>
      <v-col cols="12">
        <excellPinjaman
          @reload="reload"
          :reloader="reloader"
          :nota="obNota"
          :pinjaman="pinjaman"
          :pabrik="obPabrik"
          :toke="obToke"
          :deo="deo"
        ></excellPinjaman>
        <pilihan
          :pemicu="pemicuPilihan"
          :idNota="idNota"
          @tutup="pemicuPilihan = false"
          @reload="reload"
        ></pilihan>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import excellTon from "../../Pencairan/input/newInputTonase.vue";
import excellPinjaman from "../../Pencairan/input/excellPinjaman.vue";
import pilihan from "./ambilRamp.vue";
import axios from "axios";
export default {
  components: {
    excellTon,
    excellPinjaman,
    pilihan,
  },
  props: ["tanggal", "idAkun"],
  watch: {},
  data() {
    return {
      selectedItem: 1,
      loading: false,
      notaDialog: false,
      pinjaman: [],
      akunPindahan: 0,
      tonase: [],
      terpilih: [],
      obNota: {
        toke: 0,
      },
      pemicuPrint: false,
      dataEdit: {},
      resetNota: false,
      total: 0,
      idMetode: ["cash", "transfer", "besok"],
      metodeBayar: "",
      toke: [],
      pemicuPilihan: false,
      items: [
        {
          judul: "hapus",
          fungsi: () => {
            this.hapus();
          },
          icon: "mdi-delete",
        },
        {
          judul: "angsur",
          fungsi: () => {
            this.angsur();
          },
          icon: "mdi-handshake",
        },
      ],
      pemicuAngsur: false,
      pemicuPindah: false,
      idToke: 0,
      saldo: {},
      idTab: "Pabrik",
      obPabrik: {},
      obToke: {},
      deo: [],
      tonAktif: false,
      reloader: false,
      tampilan: false,
      proses: false,
      PT: 0,
      PS: 0,
      idNota: 0,
      search: "",
      ramp: [],
      headers: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggalDo",
        },
        { text: "Ramp", value: "toke.nama" },
        { text: "Nama", value: "nama" },
        { text: "Tbs", value: "tbs" },
        { text: "Netto", value: "netto" },
        { text: "Harga", value: "harga" },
        { text: "Cair", value: "cair" },
      ],
    };
  },
  computed: {
    dialogD: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("tutup");
      },
    },
    totPinjam() {
      return this.pinjaman.reduce(
        (a, b) => a + (b.pinjam || 0) - (b.angsur || 0),
        0
      );
    },
    totNetto() {
      return this.ramp.reduce((a, x) => a + (x.netto || 0), 0);
    },
    totCair() {
      return this.ramp.reduce((a, x) => a + (x.totCair || 0), 0);
    },
  },
  mounted() {
    this.inisialisasi();
  },
  methods: {
    async inisialisasi() {
      this.loading = true;
      this.tonAktif = false;
      this.toke = await this.getToke();
      this.obToke = this.convertList(this.toke, "ID", "nama");
      this.obPabrik = this.convertList(await this.getPabrik(), "id", "pabrik");
      this.deo = await this.getDeo();
      this.getData();
    },
    async getData() {
      const dt = await this.dataUtama();
      this.obNota = dt.nota;
      this.obNota.toke = this.toke.find((x) => x.ID == this.obNota.toke);
      this.idNota = this.obNota.ID;
      this.ramp = dt.ramp;
      this.total = dt.total;
      this.tonase = dt.tonase;
      this.pinjaman = dt.pinjaman;
      this.PS = dt.saldoPS;
      this.PT = dt.saldoPT;
      this.metodeBayar = this.obNota.caraCair;
      this.tonAktif = true;
      this.loading = false;
      this.reloader = !this.reloader;
    },
    async getToke() {
      let uri = "/tokeAktif";
      return axios
        .get(uri)
        .then((res) => {
          return res.data;
        })
        .catch((e) => {
          console.log(e.message);
          return [];
        });
    },
    async getPabrik() {
      let uri = "/pabrik";
      return axios
        .get(uri)
        .then((res) => {
          return res.data;
        })
        .catch((e) => {
          console.log(e.message);
          return [];
        });
    },
    async getDeo() {
      let uri = "/deo";
      return axios
        .get(uri)
        .then((res) => {
          return res.data;
        })
        .catch((e) => {
          console.log(e.message);
          return [];
        });
    },
    async dataUtama() {
      let uri = `/ramp/pembayaran/${this.idAkun}/${this.tanggal}`;
      console.log(uri + "get data");
      const dt = axios
        .get(uri)
        .then((res) => {
          if (res.data.status) {
            console.log(res.data);
            return res.data;
          } else {
            this.$noty.error("error");
            return [];
          }
        })
        .catch((e) => {
          console.log(e.message);
          return [];
        });
      return dt;
    },
    hapus() {
      if (confirm("apa kamu yakin mau menghapus?")) {
        let uri = `/nota/${this.idNota}`;
        console.log(uri);
        axios
          .delete(uri)
          .then((response) => {
            this.$emit("reload");
            this.$emit("tutup");
            console.log(response);
          })
          .catch((e) => {
            this.$noty.error(e.message);
          });
      } else {
        // Do nothing!
        console.log("Thing was not saved to the database.");
      }
    },
    hapusPilihan() {
      if (this.terpilih.length > 0) {
        if (this.proses == false) {
          this.proses = true;
          let data = {};
          data.terpilih = this.terpilih.map((x) => x.id);
          data.idNota = this.idNota;
          data.batal = true;
          let uri = `/ramp/pilihan`;
          console.log(uri);
          console.log(data);
          axios
            .post(uri, data)
            .then((res) => {
              console.log(res);
              this.getData();
              this.terpilih = [];
              this.$emit("reload");
              this.$emit("tutup");
            })
            .catch((e) => {
              console.log(e.message);
            })
            .finally(() => {
              this.proses = false;
            });
        }
      } else {
        alert("pilih dulu ya");
      }
    },
    angsur() {
      this.idToke = this.obNota.toke.ID;
      console.log(this.idToke);
      this.pemicuAngsur = true;
      console.log("angsur");
    },
    ubahMetode(item) {
      if (confirm("apa kamu yakin mau ganti metode bayar?")) {
        const uri = `/pencairan/nota/metode`;
        let data = {};
        data.ID = this.obNota.ID;
        data.caraCair = item;
        data.idAkun = this.idAkun;
        console.log(uri);
        console.log(data);
        axios.post(uri, data).then((res) => {
          console.log(res);
          this.$emit("reload", this.idNota);
          this.getData();
        });
      }
    },
    pindahkan_ps() {
      this.idToke = this.obNota.toke.ID;
      this.pemicuPindah = true;
      console.log("pindahkan ps pt");
    },
    pindahkan_ton() {
      console.log("pindahkan tonase");
    },
    tutup() {
      this.$emit("tutup", 0);
      this.resetNota = !this.resetNota;
    },
    reload() {
      this.$emit("reload", this.idNota);
      this.getData();
    },
    print() {
      this.pemicuPrint = true;
    },
    updateNota(item) {
      console.log("emit dari nota");
      this.obNota.toke.ID = parseInt(item.toke);
      this.obNota.toke.nama = this.toke.find((x) => {
        return x.ID == item.toke;
      }).nama;
      this.obNota.keterangan = item.keterangan;
      this.reload();
    },
    edit() {
      this.dataEdit = {
        ID: this.obNota.ID,
        id_admin: this.obNota.id_admin,
        id_cair: this.obNota.id_cair,
        keterangan: this.obNota.keterangan,
        toke: this.obNota.toke.ID,
      };
      this.notaDialog = true;
    },
    convertList(data, key, value) {
      let hasil = data.reduce(
        (obj, item) => Object.assign(obj, { [item[key]]: item[value] }),
        {}
      );
      return hasil;
    },
  },
};
</script>
