<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    dense
    width="700"
  >
    <v-card>
      <v-toolbar dense color="purple lighten-2" dark>
        <v-toolbar-title> Ubah Transaksi</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          small
          icon
          class="mr-6"
          color="red"
          @click="apus"
          :disabled="loadingHapus"
          ><v-icon>mdi-trash-can</v-icon></v-btn
        ><download-excel
          class="mx-2"
          :data="tabelExcell"
          :fields="fieldExcell"
          worksheet="My Worksheet"
          :name="filename"
        >
          <v-btn icon class="mr-4"
            ><v-icon>mdi-table-large</v-icon></v-btn
          > </download-excel
        ><v-btn small icon class="mr-6" @click="masukkanSaldo"
          ><v-icon>mdi-refresh</v-icon></v-btn
        ><v-btn small icon class="mr-4" @click="pemicuPrint = true"
          ><v-icon>mdi-printer</v-icon></v-btn
        >
        <v-icon @click="tutup" class="ml-3">mdi-close</v-icon>
        <template v-slot:extension>
          <v-tabs align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab v-for="item in tabs" :key="item" @click="idtab = item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="elevation-3" v-show="idtab === 'Tagihan'">
            <v-menu
              v-model="pupup"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="ubahTanggal"
                  label="Tanggal"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="date = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dataTransaksi.tanggal"
                @change="pupup = false"
                @click:date="editTanggal"
              ></v-date-picker> </v-menu
            ><v-text-field
              dense
              label="Cari"
              append-icon="mdi-mignify"
              v-model="cariPilihan"
            ></v-text-field>
            <v-btn-toggle v-model="toggle_none" dense>
              <v-btn
                v-for="a in namaPabrik"
                :key="a.id"
                @click="filter(a.id)"
                x-small
              >
                {{ a.pabrik }}
              </v-btn>
            </v-btn-toggle>
            <v-data-table
              :headers="headers"
              :search="cariPilihan"
              :items="pilihan"
              :items-per-page="6"
              show-select
              v-model="dataMasuk"
              dense
              mobile-breakpoint="300"
              :loading="loading"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-arrow-left-drop-circle-outline',
                nextIcon: 'mdi-arrow-right-drop-circle-outline',
              }"
            >
              <template v-slot:item.tanggaldo="{ item }">
                <span>{{ item.tanggaldo | formatDate }}</span>
              </template>
              <template v-slot:item.cair="{ item }">
                <span>{{ item.cair | rupiah }}</span>
              </template>
              <template v-slot:item.tonase="{ item }">
                <span>{{ item.tonase | angka }}</span>
              </template>
              <template v-slot:item.aksi="{ item }">
                <v-btn
                  class="mx-2"
                  icon
                  x-small
                  color="green"
                  @click.stop="edit(item)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn></template
              >
            </v-data-table>
            <v-row class="elevation-1 pa-2 mx-2">
              <span>Total Cair: {{ totalCalon | rupiah }}</span>
              <span class="ml-6"
                >Total Terpilih: {{ calonMasukCeklis | rupiah }}</span
              ><v-spacer></v-spacer>
              <v-btn
                class="ml-2"
                x-small
                dark
                color="purple"
                fab
                @click="tonMasuk"
                ><v-icon>mdi-arrow-right</v-icon></v-btn
              ></v-row
            >
          </v-col>
          <v-col cols="12" v-show="idtab === 'Terpilih'">
            <v-text-field
              dense
              label="Cari"
              append-icon="mdi-mignify"
              v-model="cariTerpilih"
            ></v-text-field>
            <v-data-table
              :headers="headers"
              :search="cariTerpilih"
              :items="terpilih"
              :items-per-page="6"
              show-select
              v-model="dataBuang"
              group-by="terpilih"
              dense
              mobile-breakpoint="300"
              :loading="loading"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-arrow-left-drop-circle-outline',
                nextIcon: 'mdi-arrow-right-drop-circle-outline',
              }"
            >
              <template v-slot:item.tanggaldo="{ item }">
                <span>{{ item.tanggaldo | formatDate }}</span>
              </template>
              <template v-slot:item.cair="{ item }">
                <span>{{ item.cair | rupiah }}</span>
              </template>
              <template v-slot:item.tonase="{ item }">
                <span>{{ item.tonase | angka }}</span>
              </template>
              <template v-slot:item.aksi="{ item }">
                <v-btn
                  class="mx-2"
                  icon
                  x-small
                  color="green"
                  @click.stop="edit(item)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn></template
              >
            </v-data-table>
            <v-row class="elevation-1 mx-2 pa-2">
              <v-btn
                class="ml-2"
                x-small
                dark
                color="purple"
                fab
                @click="tonKeluar"
                ><v-icon>mdi-arrow-left</v-icon></v-btn
              ><v-spacer></v-spacer>
              <span class="ml-4">Total Cair: {{ totalMasuk | rupiah }}</span>
              <span class="ml-6"
                >Total Terpilih: {{ calonBuang | rupiah }}</span
              >
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <print
        :id="1"
        :pemicu="pemicuPrint"
        :data="tabelPrint"
        @tutup="pemicuPrint = false"
        @update="pemicuPrint = false"
      ></print>
      <tonedit
        :id="idTon"
        :deo="deo"
        :toke="toke"
        :dialogTon="dialogTon"
        @tutup="dialogTon = false"
        @update="dialogTon = false"
      ></tonedit>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import axios from "axios";
import _ from "lodash";
import print from "./printTagihan.vue";
import tonedit from "../../Transaksi/input/tonaseEdit.vue";
export default {
  props: ["pemicu", "idTanggal", "bank"],
  components: { print, tonedit },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    ubahTanggal() {
      return this.dataTransaksi
        ? moment(this.dataTransaksi.tanggal).format("DD/MM/YYYY")
        : "";
    },
    filename() {
      return `rekap masuk -${moment().format("DD-MM-YY hh")}`;
    },
    totalMasuk() {
      return this.terpilih.reduce(function (cum, item) {
        return (cum += parseInt(item.cair));
      }, 0);
    },
    totalCalon() {
      return this.pilihan.reduce(function (cum, item) {
        return (cum += parseInt(item.cair));
      }, 0);
    },
    calonMasukCeklis() {
      return this.dataMasuk.reduce(function (cum, item) {
        return (cum += parseInt(item.cair));
      }, 0);
    },
    calonBuang() {
      return this.dataBuang.reduce(function (cum, item) {
        return (cum += parseInt(item.cair));
      }, 0);
    },
  },
  watch: {
    pemicu() {
      if (this.pemicu) {
        this.inisialisasi();
      }
    },
    dataMasuk() {
      console.log(this.dataMasuk);
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      tabelExcell: [],
      tabelPrint: [],
      pemicuPrint: false,
      loading: false,
      pilihan: [],
      terpilih: [],
      dataTransaksi: [],
      dataMasuk: [],
      dataBuang: [],
      pupup: false,
      headers: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggaldo",
        },
        { text: "Pabrik", value: "pabrik" },
        { text: "Nama", value: "nama supir" },
        { text: "Tonase", value: "tonase" },
        { text: "Cair", value: "cair" },
        { text: "Aksi", value: "aksi" },
      ],
      saldoBank: 0,
      loadingHapus: false,
      fieldExcell: {
        Pabrik: "do.pabrik.pabrik",
        Tanggal: "tanggaldo",
        Toke: "toke.nama",
        Supir: "nama supir",
        Plat: "plat",
        Tonase: "tonase",
        Harga: "harga",
        Cair: "cair",
      },
      tabs: ["Tagihan", "Terpilih"],
      idtab: "Tagihan",
      namaPabrik: [],
      pilihanSumber: [],
      toggle_none: null,
      cariPilihan: "",
      cariTerpilih: "",
      idTon: 0,
      dialogTon: false,
      do: [],
      toke: [],
    };
  },
  methods: {
    tutup() {
      console.log("ditutup");
      this.pilihan = [];
      this.terpilih = [];
      this.dataMasuk = [];
      this.dataBuang = [];
      this.$emit("tutup");
    },
    editTransaksi() {
      let uri = "/tanggalbank/" + this.idTanggal;
      axios.put(uri, this.dataTransaksi).then((response) => {
        if (response.data.status) {
          this.masukkanSaldo();
          this.$noty.success("Data berhasil disimpan");
        }
      });
    },
    masukkanSaldo() {
      let uri = "/transaksi/edit/" + this.idTanggal + "/pabrik";
      var data = {
        akun: this.dataTransaksi.bank,
        masuk: this.saldoBank,
        tanggal: this.dataTransaksi.tanggal,
      };
      axios.put(uri, data).then((response) => {
        if (response.data.status) {
          console.log("update berhasil>>");
          console.log(response.data.data);
          this.$noty.success("Data berhasil disimpan");
          this.$emit("update");
          this.inisialisasi();
        }
      });
    },
    inisialisasi() {
      this.dataBuang = [];
      this.dataMasuk = [];
      this.loading = true;
      let uri = `/tanggalbank/${this.idTanggal}`;
      axios.get(uri).then((response) => {
        this.dataTransaksi = response.data;
        this.terpilihTerpilih();
      });
    },
    edit(item) {
      this.idTon = item.id;
      this.dialogTon = true;
    },
    terpilihTerpilih() {
      let url = `/tanggalbank/terpilih/${this.idTanggal}`;
      axios.get(url).then((response) => {
        this.tabelExcell = response.data;
        this.tabelPrint = response.data;
        this.terpilih = _.values(response.data);
        this.terpilih = this.tambahKolom(this.terpilih);
        this.terpilih = this.pisahkan(this.terpilih);
        this.pilihanPilihan();
        this.excell();
        console.log(this.terpilih);
      });
    },
    pilihanPilihan() {
      let url = `/tonase/cairbank/${this.bank}`;
      axios.get(url).then((response) => {
        this.pilihan = _.values(response.data);
        this.pilihan = this.pilihan.map((x) => {
          x.tanggaldo = x.tanggal;
          x.pabrik = x.idpabrik.pabrik;
          return x;
        });
        this.pilihanSumber = this.pilihan;
        this.namaPabrikS();
        this.loading = false;
      });
    },
    pisahkan() {
      let mantap = [];
      let tanggalbank = this.dataTransaksi;
      this.terpilih.forEach((x) => {
        if (
          x.tanggaldo >= tanggalbank.daritanggal &&
          x.tanggaldo <= tanggalbank.sampaitanggal
        ) {
          if (x.do.pabrik == tanggalbank.pabrik) {
            x.terpilih = "sesuai";
          } else {
            x.terpilih = "diluar kategori";
          }
        } else {
          x.terpilih = "diluar kategori";
        }
        mantap.push(x);
      });
      return mantap;
    },
    excell() {
      let hasil = [];
      this.tabelExcell.forEach((x) => {
        let pph = 0;
        let hargaP = parseInt(x.harga) + parseFloat(x.do.fee);
        if (x.do.pempph == 1) {
          pph = (parseInt(x.tonase) * hargaP * parseFloat(x.do.pph)) / 100;
        }
        x.harga = hargaP;
        x.cair = parseInt(parseInt(x.tonase) * hargaP - pph);
        hasil.push(x);
      });
      this.tabelExcell = hasil;
    },
    editTanggal() {
      if (this.idTanggal == 0) {
        console.log("transaksi baru, gak perlu edit tanggal");
      } else {
        this.editTransaksi();
      }
    },
    tambahKolom(arr) {
      let hasil = [];
      arr.forEach((x) => {
        let pph = 0;
        let hargaP = parseInt(x.harga) + parseFloat(x.do.fee);
        if (x.do.pempph == 1) {
          pph = (parseInt(x.tonase) * hargaP * parseFloat(x.do.pph)) / 100;
        }
        x.cair = parseInt(x.tonase) * hargaP - pph;
        x.pabrik = x.do.pabrik.pabrik;
        hasil.push(x);
      });
      return hasil;
    },
    tonMasuk() {
      var data = this.jadiArray(this.dataMasuk);
      let uri = "/tonase/cairbank/" + this.dataTransaksi.id + "/masuk";
      axios.put(uri, data).then((response) => {
        if (response.data.status) {
          console.log("update ton masuk berhasil>>");
          this.saldoBank = this.totalMasuk + this.calonMasukCeklis;
          this.$noty.success("Data berhasil disimpan");
          this.masukkanSaldo();
        }
      });
    },
    tonKeluar() {
      var data = this.jadiArray(this.dataBuang);
      let uri = "/tonase/cairbank/" + this.dataTransaksi.id + "/keluar";
      axios.put(uri, data).then((response) => {
        if (response.data.status) {
          console.log("updATE ton masuk berhasil>>");
          this.saldoBank = this.totalMasuk + this.calonBuang;
          this.$noty.success("Data berhasil disimpan");
          this.masukkanSaldo();
        }
      });
    },
    jadiArray(arr) {
      return arr.reduce(function (awal, step) {
        return awal.concat(step.id);
      }, []);
    },
    apus() {
      this.loadingHapus = true;
      let uri = "/tanggalbank/" + this.idTanggal;
      axios.delete(uri).then((response) => {
        this.$noty.success(response.data.pesan);
        this.loadingHapus = false;
        this.$emit("update");
        this.$emit("tutup");
      });
    },
    namaPabrikS() {
      this.namaPabrik = this.pilihan.reduce(function (chace, item) {
        if (
          !chace.find((x) => {
            return x.id === item.idpabrik.id;
          })
        ) {
          let a = { id: item.idpabrik.id, pabrik: item.idpabrik.pabrik };
          chace.push(a);
          return chace;
        }
        return chace;
      }, []);
      console.log(this.namaPabrik);
    },
    filter(a) {
      if (this.toggle_none == null) {
        this.pilihan = this.pilihan.filter(function (x) {
          return x.idpabrik.id == a;
        });
      } else {
        this.pilihan = this.pilihanSumber;
      }
    },
  },
};
</script>
