<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="350"
    persistent
  >
    <v-card>
      <v-card-title class="headline"> Rekap </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-data-table
              :headers="headers"
              :items="rekap"
              :items-per-page="100"
              dense
              item-key="id"
              mobile-breakpoint="300"
              :loading="loading"
            >
              <template v-slot:item.tonase="{ item }">
                <span>{{ item.tonase | angka }}</span>
              </template>
            </v-data-table>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn color="warning" text @click="tutup"> Tutup </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      pabrikPilihan: [],
      headers: [
        {
          text: "Pabrik",
          align: "start",
          filterable: true,
          value: "nama",
        },
        { text: "Tonase", value: "tonase" },
      ],
      rekap: [],
    };
  },
  props: ["pemicu", "tonase"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  methods: {
    inisialisasi() {
      this.rekap = this.tabelFilter2();
    },

    tabelFilter2() {
      if (this.tonase.length > 0) {
        var c = this.tonase.reduce(function (chace, item) {
          const idPabrik = item["do"]["pabrik"]["id"];
          const pabrik = item["do"]["pabrik"]["pabrik"];
          const indek = chace.findIndex((x) => {
            return x.id == idPabrik;
          });
          if (indek >= 0) {
            chace[indek].tonase += parseInt(item.tonase);
            return chace;
          }
          chace.push({
            id: idPabrik,
            nama: pabrik,
            tonase: parseInt(item.tonase),
          });
          return chace;
        }, []);
        return c;
      }
    },

    tutup() {
      this.$emit("tutup");
    },
  },
};
</script>
