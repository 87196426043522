<template>
  <v-card dense tile :loading="loading" class="mb-6">
    <v-card-title>Pinjaman</v-card-title>
    <v-card-subtitle
      >PS: {{ dataPs.ps | rupiah }} / PT:
      {{ dataPs.pt | rupiah }}</v-card-subtitle
    >
    <v-card-text>
      <vue-excel-editor
        v-model="data"
        filter-row
        ref="grid"
        enterToSouth
        no-paging
        new-if-bottom
        :no-mouse-scroll="false"
        free-select
        :readonly="!edit"
        @update="update"
      >
        <vue-excel-column
          field="jenis"
          label="Jenis"
          type="select"
          width="50px"
          :options="['PS', 'PT']"
        />
        <vue-excel-column
          field="toke"
          label="Toke"
          type="map"
          :options="tokes"
          width="170px"
        />
        <vue-excel-column
          field="uraian"
          label="Uraian"
          type="string"
          width="250px"
        />
        <vue-excel-column
          field="pinjam"
          label="Pinjam"
          type="number"
          width="150px"
          summary="sum"
          :to-text="toText"
          :to-value="toVal"
        />
        <vue-excel-column
          field="angsur"
          label="Angsur"
          type="number"
          width="150px"
          summary="sum"
          :to-text="toText"
          :to-value="toVal"
        />
      </vue-excel-editor>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="save"
        color="blue"
        v-if="edit"
        outlined
        class="mx-3"
        :disabled="loading"
        >Simpan</v-btn
      >
      <v-btn
        @click="
          edit = false;
          getData();
        "
        color="red"
        v-if="edit"
        outlined
        >Batal</v-btn
      ><v-btn @click="edit = true" color="green" v-else outlined>Edit</v-btn>
      <v-spacer></v-spacer
      ><v-btn
        @click="tagihan"
        v-if="edit == false"
        class="mx-4"
        outlined
        color="pink"
        >Tagihan</v-btn
      ><v-btn v-if="edit == false" outlined color="red" @click="hapus"
        ><v-icon>mdi-delete</v-icon></v-btn
      ></v-card-actions
    >
  </v-card>
</template>
<script>
import axios from "axios";
import numeral from "numeral";
// import moment from "moment";

export default {
  props: ["nota", "pabrik", "deo", "toke", "pinjaman", "reloader"],
  components: {},
  computed: {},
  watch: {
    reloader() {
      this.inisialisasi();
    },
  },
  mounted() {
    this.inisialisasi();
  },
  data() {
    return {
      loading: false,
      data: [],
      pabrikStd: 1,
      tokeStd: 0,
      tanggalStd: 0,
      harga: [],
      edit: false,
      dataPs: {},
    };
  },
  methods: {
    async inisialisasi() {
      console.log("excell pinjaman di eksekusi");
      console.log(this.nota);
      this.loading = true;
      this.tokeStd = this.nota.toke ? this.nota.toke.ID : 0;
      this.getData();
      this.dataPs = await this.pspt();
    },
    getData() {
      if (this.pinjaman.length > 0) {
        console.log("excell pinjaman di load");
        this.data = this.pinjaman.map((x) => {
          let item = {};
          item.jenis = x.jenis;
          item.toke = x.toke.ID;
          item.uraian = x.uraian;
          item.pinjam = parseInt(x.pinjam);
          item.angsur = parseInt(x.angsur);
          item.ID = x.ID;
          item.transfer = x.transfer;
          return item;
        });
      } else {
        console.log("excell pinjaman di load isi standar");
        this.data = [
          {
            jenis: "PS",
            toke: this.tokeStd,
            uraian: "",
            pinjam: 0,
            angsur: 0,
            ID: 0,
            transfer: null,
          },
        ];
        this.edit = true;
      }
      console.log(this.data);
      this.loading = false;
    },
    async pspt() {
      let uri = `/toke/${this.nota.toke.ID}/saldo`;
      return axios.get(uri).then((res) => {
        return res.data;
      });
    },
    tokes() {
      return this.toke;
    },
    toVal(val) {
      return parseInt(val.replace(/[^\d-]/g, ""));
    },
    toText(val) {
      return numeral(parseInt(val)).format("0,0");
    },
    update(record) {
      let a = this.data.findIndex((x) => {
        return x.$id == record[0].$id;
      });
      if (!record[0].newVal && !record[0].oldVal) {
        this.data[a].jenis = "PS";
        this.data[a].toke = this.tokeStd;
        this.data[a].uraian = "";
        this.data[a].pinjam = 0;
        this.data[a].angsur = 0;
        this.data[a].ID = 0;
        this.data[a].transfer = null;
      }
    },
    cekData() {
      console.log(this.data);
    },
    save() {
      if (this.loading == false) {
        let uri = `/pencairan/pinjaman/excell`;
        this.loading = true;
        console.log("simpan");
        this.data = this.data.filter((x) => {
          return !(x.pinjam == 0 && x.angsur == 0);
        });
        let data = {};
        data.data = this.data;
        data.pendukung = this.nota;
        console.log(data);
        axios
          .post(uri, data)
          .then((res) => {
            console.log(res.data);
            if (res.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.getData();
              this.edit = false;
              this.$emit("reload");
              console.log(res.data);
            }
          })
          .catch((e) => {
            this.$noty.error(e.message).finally((this.loading = false));
          })
          .finally(() => (this.loading = false));
      } else {
        alert("Sedang diproses, mohon bersabar");
      }
    },
    tagihan() {
      let terpilih = this.$refs.grid.getSelectedRecords();
      if (terpilih.length > 0) {
        let cek = terpilih.map((x) => {
          if (x.transfer) {
            if (x.transfer.id_bayar == 0) {
              x.aksi = "biasa";
            } else {
              x.aksi = "tidak";
            }
          } else {
            x.aksi = "tagihan";
          }
          return { ID: x.ID, aksi: x.aksi };
        });
        let data = { toke: this.nota.toke.ID, data: cek };
        let uri = "/pinjaman/opssupir";
        axios
          .post(uri, data)
          .then((res) => {
            console.log(res.data);
            this.$noty.success("data Berhasil di Pindah");
            this.$emit("reload");
            terpilih = [];
          })
          .catch((e) => {
            console.log(e.message);
            this.$noty.error(e.message);
          });
      } else {
        console.log("harus dipilih dulu");
      }
    },
    hapus() {
      let terpilih = this.$refs.grid.getSelectedRecords();
      if (terpilih.length > 0) {
        if (confirm("apa kamu yakin mau menghapus?")) {
          if (this.loading == false) {
            this.loading = true;
            let uri = "/pencairan/pinjaman/hapus";
            axios
              .post(uri, terpilih)
              .then((res) => {
                console.log(res.data);
                this.$noty.success("data Berhasil dihapus");
                this.$emit("reload");
                terpilih = [];
              })
              .catch((e) => {
                console.log(e.message);
                this.$noty.error(e.message);
              })
              .finally(() => (this.loading = false));
          }
        }
      } else {
        alert("harap dipilih dulu baru ditekan tomboh hapus");
      }
    },
  },
};
</script>
