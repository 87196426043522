<template>
  <v-row class="ma-2"
    ><v-col cols="12" md="4">
      <v-date-picker v-model="tanggal"></v-date-picker> </v-col
    ><v-col cols="12" md="8">
      <v-card :loading="loading"
        ><v-toolbar dense color="yellow">
          <v-toolbar-title>Laporan Modal</v-toolbar-title>
          <v-spacer></v-spacer
          ><span>{{ tanggalTerpilih[0] | formatDate }}</span>
          <v-btn @click="bukaWa" class="mx-2"
            ><v-icon>mdi-whatsapp</v-icon></v-btn
          >
          <v-btn @click="pemicuSetting = true" class="mx-2"
            ><v-icon>mdi-cog-outline</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row
            ><v-col cols="12">
              <span>Tagihan</span>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Pabrik</th>
                      <th class="text-left">Tonase</th>
                      <th class="text-left">Cair</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in tagihan" :key="item.id">
                      <td>{{ item.pabrik }}</td>
                      <td>
                        {{ item.tonase | angka }}
                      </td>
                      <td>
                        <a
                          @click="
                            dataTon = item.data;
                            pemicuT = true;
                          "
                          >{{ item.cair | rupiah }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <b>{{ totTagihan | rupiah }}</b>
                      </td>
                    </tr>
                  </tbody>
                </template></v-simple-table
              >
            </v-col>
            ><v-col cols="12">
              <span>Tagihan Ramp</span>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Ramp</th>
                      <th class="text-left">Netto</th>
                      <th class="text-left">Cair</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in tagihanRamp" :key="item.id">
                      <td>{{ item.nama }}</td>
                      <td>
                        {{ item.netto | angka }}
                      </td>
                      <td>
                        <a
                          @click="
                            dataTon = item.data;
                            pemicuT = true;
                          "
                          >{{ item.cair | rupiah }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <b>{{ totRamp | rupiah }}</b>
                      </td>
                    </tr>
                  </tbody>
                </template></v-simple-table
              >
            </v-col>
            <v-col cols="12">
              <span>Saldo Bank</span>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Bank</th>
                      <th class="text-left">Saldo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in bank" :key="item.id">
                      <td>{{ item.nama }}</td>
                      <td>
                        {{ item.sum | rupiah }}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <b>{{ totBank | rupiah }}</b>
                      </td>
                    </tr>
                  </tbody>
                </template></v-simple-table
              >
            </v-col>
            <v-col cols="12">
              <span>Pinjaman</span>
              <v-simple-table dense>
                <template v-slot:default v-if="pinjaman.length >> 0">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>PS</td>
                      <td>
                        {{ pinjaman[0].saldo | rupiah }}
                      </td>
                    </tr>
                    <tr>
                      <td>PT</td>
                      <td>
                        {{ pinjaman[1].saldo | rupiah }}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <b>{{ totPinjaman | rupiah }}</b>
                      </td>
                    </tr>
                  </tbody>
                </template></v-simple-table
              >
            </v-col>
            <v-col cols="12">
              <span>OPS b doni</span>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Akun</th>
                      <th>Saldo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in bd" :key="item.id">
                      <td>{{ item.nama }}</td>
                      <td>{{ item.sum | rupiah }}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <b>{{ totBd | rupiah }}</b>
                      </td>
                    </tr>
                  </tbody>
                  pinjaman macet: {{ macet }}
                </template></v-simple-table
              >
            </v-col>
            <v-col cols="12">
              <span>KNKT</span>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Akun</th>
                      <th>Saldo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in knkt" :key="item.id">
                      <td>{{ item.nama }}</td>
                      <td>
                        <a @click="rinciKnkt(item)">{{ item.sum | rupiah }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <b>{{ totKnkt | rupiah }}</b>
                      </td>
                    </tr>
                  </tbody>
                </template></v-simple-table
              >
            </v-col>
            <v-col cols="12">
              <span>Saldo Kasir</span>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Akun</th>
                      <th>Saldo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in kasir" :key="item.id">
                      <td>{{ item.nama }}</td>
                      <td>
                        {{ item.sum | rupiah }}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <b>{{ totKasir | rupiah }}</b>
                      </td>
                    </tr>
                  </tbody>
                </template></v-simple-table
              >
            </v-col>
            <v-col cols="12">
              <span>Saldo Ramp</span>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Akun</th>
                      <th>Saldo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in modalRamp" :key="item.id">
                      <td>{{ item.nama }}</td>
                      <td>
                        {{ item.sum | rupiah }}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <b>{{ totModalRamp | rupiah }}</b>
                      </td>
                    </tr>
                  </tbody>
                </template></v-simple-table
              >
            </v-col>
            <v-col cols="12">
              <b> Total uang yang ada: {{ totalModal | rupiah }}</b>
            </v-col>
            <v-col cols="12">
              <span>Yang harus dikeluarkan</span>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Jenis</th>
                      <th>Nominal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Fee</td>
                      <td>
                        {{ fee.fee | rupiah }}
                      </td>
                    </tr>
                    <tr>
                      <td>PPH</td>
                      <td>
                        {{ fee.pph | rupiah }}
                      </td>
                    </tr>
                    <tr>
                      <td>Belum Cair</td>
                      <td>
                        {{ fee.belum | rupiah }}
                      </td>
                    </tr>
                    <tr v-for="item in feeRamp" :key="item.ID">
                      <td>{{ item.nama }}</td>
                      <td>{{ (item.sum * -1) | rupiah }}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <b>{{ totalHarus | rupiah }}</b>
                      </td>
                    </tr>
                  </tbody>
                </template></v-simple-table
              > </v-col
            ><v-col cols="12">
              <b> Total uang yang ada: {{ grandTotal | rupiah }}</b>
            </v-col>
            <v-col cols="12">
              <b> Total Modal harusnya: {{ modalu | rupiah }}</b>
            </v-col>
          </v-row>
        </v-card-text>
        <d-knkt
          @tutup="listKnkt = false"
          :pemicu="listKnkt"
          :data="dataKnKt"
        ></d-knkt>
        <wa
          @tutup="pemicuWa = false"
          :pemicu="pemicuWa"
          :data="dataWa"
          :tanggal="tanggal"
        ></wa>
        <tagihan
          @tutup="pemicuT = false"
          :pemicu="pemicuT"
          :data="dataTon"
        ></tagihan>
        <setting
          @tutup="pemicuSetting = false"
          :pemicu="pemicuSetting"
        ></setting>
      </v-card>

      <v-col cols="12"> </v-col>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import _ from "lodash";
import DKnkt from "./knkt.vue";
import wa from "./wa.vue";
import tagihan from "./tagihan.vue";
import setting from "./setting.vue";
// import moment from "moment";
export default {
  components: { DKnkt, wa, tagihan, setting },
  props: [],
  watch: {
    tanggal() {
      if (this.tanggal) {
        this.totTagihan = 0;
        this.totBank = 0;
        this.totKasir = 0;
        this.totPinjaman = 0;
        this.totKnkt = 0;
        this.totModalRamp = 0;
        this.totFeeRamp = 0;
        this.totRamp = 0;
        this.inisialisasi();
      }
    },
  },
  data() {
    return {
      loading: false,
      tanggalTransaksi: [],
      tanggalTerpilih: [],
      tanggal: "",
      search: "",
      bank: [],
      kasir: [],
      pinjaman: [],
      tagihan: [],
      tagihanRamp: [],
      totRamp: 0,
      bd: [],
      knkt: [],
      belumCair: 0,
      fee: 0,
      pph: 0,
      totTagihan: 0,
      totBank: 0,
      totKasir: 0,
      totPinjaman: 0,
      totBd: 0,
      totKnkt: 0,
      totDana: 0,
      listKnkt: false,
      toKnkt: {},
      pemicuWa: false,
      dataWa: {},
      tanggalWa: "",
      macet: 0,
      pemicuT: false,
      dataTon: [],
      pemicuSetting: false,
      data: {},
      dataKnKt: 0,
      modalRamp: [],
      feeRamp: [],
      totModalRamp: 0,
      totFeeRamp: 0,
      modalu: 0,
    };
  },
  computed: {
    totalModal() {
      return (
        this.totTagihan +
        this.totBank +
        this.totKasir +
        this.totPinjaman +
        this.totBd +
        this.totKnkt +
        this.totModalRamp +
        this.totRamp
      );
    },
    totalHarus() {
      return this.fee.fee + this.fee.pph + this.fee.belum + this.totFeeRamp;
    },
    grandTotal() {
      return this.totalModal - this.totalHarus;
    },
  },
  mounted() {},
  methods: {
    async inisialisasi() {
      await this.getTagihanPabrik();
      await this.getTagihanRamp();
      await this.getBD();
      await this.getPinjaman();
      await this.getFee();
      await this.getKasir();
      await this.getBank();
      await this.getModalRam();
      await this.getFeeram();
      await this.getKnKt();
      await this.getModalTotal();
    },
    async getTagihanPabrik() {
      const uri = `/laporan/tagihan/${this.tanggal}`;
      this.tagihan = await this.loadingApi(uri);
      this.tagihan = this.grupkan(this.tagihan);
      this.totTagihan = this.tagihan.reduce((simpan, x) => {
        return (simpan += parseInt(x.cair));
      }, 0);
      this.dataWa.tagihan = this.tagihan;
    },
    async getTagihanRamp() {
      const uri = `/ramp/tagihan/${this.tanggal}`;
      this.tagihanRamp = await this.loadingApi(uri);
      if (this.tagihanRamp.length > 0) {
        this.tagihanRamp = this.tagihanRamp.reduce(function (chace, item) {
          const indek = chace.findIndex((x) => {
            return x.id == item.id_ramp;
          });
          if (indek >= 0) {
            chace[indek].netto += parseInt(item.netto);
            chace[indek].cair += parseInt(item.cair);
            return chace;
          }
          chace.push({
            id: item.id_ramp,
            nama: item.toke ? item.toke.nama : "",
            netto: parseInt(item.netto),
            cair: parseInt(item.cair),
          });
          return chace;
        }, []);
        this.totRamp = this.tagihanRamp.reduce((x, item) => {
          x += item.cair;
          return x;
        }, 0);
        this.dataWa.tagihanRamp = this.tagihanRamp;
      }
    },
    async getBD() {
      const uri = `/laporan/akun/4/${this.tanggal}`;
      try {
        let data = await axios.get(uri);
        data = data.data;
        this.bd = data.filter((x) => {
          return x.sum != 0;
        });
        this.totBd = this.bd.reduce((awal, item) => {
          return (awal += parseInt(item.sum));
        }, 0);
        this.loading = false;
        this.$noty.success("Berhasil loading operasional");
        this.dataWa.bd = this.bd;
        this.dataWa.totBd = this.totBd;
      } catch (error) {
        this.$noty.error(error.message);
      }
    },
    async getPinjaman() {
      this.loading = true;
      let tabel = [];
      let url = "/pinjaman/total/" + this.tanggal;
      try {
        let data = await axios.get(url);
        tabel = _.values(data.data);
        this.pinjaman = tabel;
        this.totPinjaman = this.pinjaman.reduce((awal, item) => {
          return (awal += parseInt(item.saldo));
        }, 0);
        this.$noty.success("Berhasil loading Pinjaman");
        this.dataWa.pinjaman = this.pinjaman;
      } catch (error) {
        console.log(error.message);
      }
    },
    async getFee() {
      this.loading = true;
      let url = "/tonase/th/" + this.tanggal;
      try {
        let data = await axios.get(url);
        this.fee = data.data;
        this.loading = false;
        this.$noty.success("Berhasil loading fee dan lain2");
        this.dataWa.fee = this.fee;
        this.loading = false;
      } catch (error) {
        console.log(error.message);
      }
    },
    async getKasir() {
      const uri = `/laporan/akun/7/${this.tanggal}`;
      this.kasir = await this.loadingApi(uri);
      this.totKasir = this.kasir.reduce((awal, item) => {
        return (awal += parseInt(item.sum));
      }, 0);
      this.dataWa.kasir = this.kasir;
    },
    async getBank() {
      const data1 = await this.loadingApi(`/laporan/akun/8/${this.tanggal}`);
      const data2 = await this.loadingApi(`/laporan/akun/9/${this.tanggal}`);
      let data = data1.concat(data2);
      this.bank = data;
      this.totBank = this.bank.reduce((awal, item) => {
        return (awal += parseInt(item.sum));
      }, 0);
      this.dataWa.bank = this.bank;
    },
    async getModalRam() {
      this.modalRamp = await this.loadingApi(
        `/laporan/akun/13/${this.tanggal}`
      );
      this.totModalRamp = this.modalRamp.reduce((a, b) => {
        return (a += parseInt(b.sum));
      }, 0);
      this.dataWa.modalRamp = this.modalRamp;
    },
    async getFeeram() {
      const data = await this.loadingApi(`/laporan/akun/14/${this.tanggal}`);
      this.feeRamp = data;
      this.totFeeRamp = this.feeRamp.reduce((a, b) => {
        return (a += parseInt(b.sum * -1));
      }, 0);
      this.dataWa.feeRamp = this.feeRamp;
    },
    async getKnKt() {
      // const jenisknkt = [1, 4, 7, 8, 9, 12, 13, 14];2,3,5,6,10,11
      const data2 = await this.loadingApi(`/laporan/akun/2/${this.tanggal}`);
      const data3 = await this.loadingApi(`/laporan/akun/3/${this.tanggal}`);
      const data5 = await this.loadingApi(`/laporan/akun/5/${this.tanggal}`);
      const data6 = await this.loadingApi(`/laporan/akun/6/${this.tanggal}`);
      const data10 = await this.loadingApi(`/laporan/akun/10/${this.tanggal}`);
      const data11 = await this.loadingApi(`/laporan/akun/11/${this.tanggal}`);
      let knkt = [...data2, ...data3, ...data5, ...data6, ...data10, ...data11];
      console.log(knkt);
      this.data.akun = knkt;
      this.knkt = knkt.reduce((awal, item) => {
        if (awal.length == 0) {
          awal.push({
            id: item.gol.ID,
            nama: item.gol.golongan,
            sum: parseInt(item.sum),
          });
          return awal;
        }
        const i = awal.findIndex((x) => {
          return x.id == item.gol.ID;
        });
        if (i >= 0) {
          awal[i].sum = awal[i].sum + parseInt(item.sum);
          return awal;
        }
        awal.push({
          id: item.gol.ID,
          nama: item.gol.golongan,
          sum: parseInt(item.sum),
        });
        return awal;
      }, []);
      this.totKnkt = this.knkt.reduce((awal, item) => {
        return (awal += parseInt(item.sum));
      }, 0);
      this.dataWa.knkt = this.knkt;
    },
    grupkan(array) {
      console.log(array);
      if (array.length >> 0) {
        let hasil = array.reduce(function (chace, item) {
          const properti = item.idpabrik.id;
          if (chace.some((el) => el.idpabrik === properti)) {
            var terpilih = chace.find(function (x) {
              return x.idpabrik === properti;
            });
            terpilih.tonase += parseInt(item.tonase);
            terpilih.cair += parseInt(item.cair);
            terpilih.data.push(item);
            return [...chace];
          }
          let dt = [item];
          chace.push({
            tonase: parseInt(item.tonase),
            cair: parseInt(item.cair),
            pabrik: item.idpabrik.pabrik,
            idpabrik: item.idpabrik.id,
            data: dt,
          });
          return [...chace];
        }, []);
        hasil.sort(function (a, b) {
          return a.idpabrik - b.idpabrik;
        });
        return hasil;
      }
      return array;
    },
    async loadingApi(uri) {
      try {
        let data = await axios.get(uri);
        data = data.data;
        return data;
      } catch (error) {
        this.$noty.error(error.message);
        return [];
      }
    },
    rinciKnkt(item) {
      this.dataKnKt = this.data.akun.filter((x) => {
        return x.golongan == item.id && x.sum != 0;
      });
      this.listKnkt = true;
    },
    bukaWa() {
      if (this.tanggal == "") {
        this.$noty.success("pilih tanggal dulu");
      } else {
        this.dataWa.totTagihan = this.totTagihan;
        this.dataWa.totBank = this.totBank;
        this.dataWa.totKasir = this.totKasir;
        this.dataWa.totPinjaman = this.totPinjaman;
        this.dataWa.totKnkt = this.totKnkt;
        this.dataWa.totModalRamp = this.totModalRamp;
        this.dataWa.totFeeRamp = this.totFeeRamp;
        this.dataWa.totRamp = this.totRamp;
        this.pemicuWa = true;
        console.log(this.dataWa);
      }
    },
    async getPinjamanMacet() {
      const uri = `/pinjaman/total/macet/${this.tanggalTerpilih[0]}`;
      axios.get(uri).then((r) => {
        this.macet = r.data;
      });
    },
    async getModalTotal() {
      let uri = `/modalu/tanggal/${this.tanggal}`;
      try {
        let data = await axios.get(uri);
        this.modalu = data.data;
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>
