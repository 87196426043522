<template>
  <!-- <v-app>
    <v-app-bar app color="primary" dark></v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app> -->
  <v-app>
    <v-main>
      <div>
        <router-View></router-View>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>
