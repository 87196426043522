var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"dense":"","tile":""}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Tagihan")]),_c('v-spacer'),_c('v-switch',{staticClass:"mr-3",attrs:{"dense":"","label":"Rekap"},model:{value:(_vm.switch1),callback:function ($$v) {_vm.switch1=$$v},expression:"switch1"}}),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){_vm.pemicuPrint = true}}},[_c('v-icon',[_vm._v("mdi-printer")])],1),_c('download-excel',{staticClass:"mx-2",attrs:{"data":_vm.tabel,"fields":_vm.fieldExcell,"worksheet":"My Worksheet","name":_vm.filename}},[_c('v-btn',{attrs:{"icon":"","color":"blue"}},[_c('v-icon',[_vm._v("mdi-table-large")])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.switch1 ? _vm.headersMinimal : _vm.headersFull,"items":_vm.data,"items-per-page":10,"dense":"","mobile-breakpoint":"300","loading":_vm.loading,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        prevIcon: 'mdi-arrow-left-drop-circle-outline',
        nextIcon: 'mdi-arrow-right-drop-circle-outline',
      }},scopedSlots:_vm._u([{key:"item.tanggal",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.tanggal)))])]}},{key:"item.tonase",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("angka")(item.tonase)))])]}},{key:"item.harga",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("rupiah")(item.harga)))])]}},{key:"item.cair",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("rupiah")(item.cair)))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","color":"blue","x-small":""},on:{"click":function($event){_vm.idTon = item.id;
            _vm.tonaseDialog = true;}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}])}),_c('tonaseD',{attrs:{"id":_vm.idTon,"deo":_vm.deo,"toke":_vm.toke,"dialogTon":_vm.tonaseDialog},on:{"tutup":function($event){_vm.tonaseDialog = false},"update":_vm.inisialisasi}}),_c('print',{attrs:{"id":_vm.id,"pemicu":_vm.pemicuPrint,"data":[]},on:{"tutup":function($event){_vm.pemicuPrint = false},"update":function($event){_vm.pemicuPrint = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }