<template>
  <div>
    <v-row
      ><v-col cols="12" md="4">
        <v-card
          ><v-toolbar dark color="primary">
            <v-toolbar-title>Pabrik</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-list dense>
              <v-list-item-group v-model="pabrik" color="primary">
                <v-list-item
                  v-for="item in pabriks"
                  :key="item.id"
                  @click="buka(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list> </v-card-text
        ></v-card> </v-col
      ><v-col cols="12" md="8"
        ><v-card
          ><v-toolbar dark color="primary lighten-2">
            <v-toolbar-title>Print rekapan</v-toolbar-title><v-spacer></v-spacer
            ><v-btn fab small dark color="pink" @click="pemicuTgl = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Tanggal Print</th>
                    <th>Tgl dari</th>
                    <th>Tgl sampai</th>
                    <th>Opsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tabel" :key="item.id">
                    <td>{{ item.tanggal | formatDate }}</td>
                    <td>
                      {{ item.dari | formatDate }}
                    </td>
                    <td>
                      {{ item.sampai | formatDate }}
                    </td>
                    <td>
                      <a @click="print(item)">Print Ulang</a>
                    </td>
                  </tr>
                </tbody>
              </template></v-simple-table
            ></v-card-text
          ></v-card
        ><print
          :pemicu="pemicuPrint"
          :id_pabrik="id_pabrik"
          :tanggal="tanggalPrint"
          @tutup="pemicuPrint = false"
        ></print>
        <rentang
          :pemicu="pemicuTgl"
          @tutup="pemicuTgl = false"
          @simpan="simpan"
        ></rentang></v-col
    ></v-row>
  </div>
</template>
<script>
import axios from "axios";
import _ from "lodash";
import print from "./print.vue";
import rentang from "../../component/popupTgl.vue";
export default {
  components: { print, rentang },
  mounted() {
    this.inisialisasi();
  },
  watch: {},
  computed: {},
  data() {
    return {
      pabriks: [
        { id: 1, text: "PMG", id_ops: 311, id_pabrik: 15 },
        { id: 2, text: "SBPU", id_ops: 312, id_pabrik: 1 },
        { id: 3, text: "MJSL", id_ops: 310, id_pabrik: 19 },
        { id: 4, text: "BBIP", id_ops: 395, id_pabrik: 3 },
      ],
      pabrik: 0,
      id_pabrik: { id: 1, text: "PMG", id_ops: 311, id_pabrik: 15 },
      id_ops: 311,
      tabel: [],
      pemicuPrint: false,
      tanggalPrint: [],
      pemicuTgl: false,
    };
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let uri = "/print/ops/" + this.id_ops;
      axios.get(uri).then((response) => {
        this.tabel = _.values(response.data);
        this.loading = false;
        console.log(this.tabel);
      });
    },
    buka(item) {
      this.id_ops = item.id_ops;
      this.id_pabrik = item;
      this.inisialisasi();
    },
    print(item) {
      this.tanggalPrint = [item.dari, item.sampai];
      this.pemicuPrint = true;
      console.log(this.pemicuPrint);
    },
    simpan(item) {
      this.tanggalPrint = item;
      let data = {
        akun: "0",
        dari: item[0],
        sampai: item[1],
        tanggal: item[1],
        toke: this.id_ops,
        jenis: "2",
        uraian: null,
        akun_fee: "0",
      };
      let uri = "/print";
      axios.post(uri, data).then((response) => {
        if (response.data.status) {
          this.$noty.success("Data berhasil disimpan");
          this.pemicuPrint = true;
          this.inisialisasi();
        } else {
          this.$noty.success(response.data.data[0]);
        }
      });
      console.log(data);
    },
  },
};
</script>
