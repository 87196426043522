<template>
  <v-card dense tile :loading="loading" class="mb-6">
    <v-card-title>Tonase</v-card-title>
    <v-card-text>
      <vue-excel-editor
        v-model="data"
        filter-row
        ref="gridTonase"
        enterToSouth
        no-paging
        new-if-bottom
        :no-mouse-scroll="false"
        free-select
        remember
        :readonly="!edit"
        @update="update"
      >
        <vue-excel-column
          field="pabrik"
          label="Pabrik"
          type="map"
          :change="updatePabrik"
          :options="pabriks"
          width="120px"
        />
        <vue-excel-column
          field="tanggal"
          label="Tanggal"
          :change="updateTanggal"
          type="date"
          width="90px"
        />
        <vue-excel-column
          field="toke"
          label="Toke"
          type="map"
          :options="tokes"
          width="120px"
        />
        <vue-excel-column
          field="supir"
          label="Supir"
          type="string"
          width="140px"
        />
        <vue-excel-column
          field="plat"
          label="Plat"
          type="string"
          width="120px"
        />
        <vue-excel-column
          field="tonase"
          label="Tonase"
          type="number"
          width="70px"
          summary="sum"
          :change="updateTon"
          :to-text="toText"
          :to-value="toVal"
        />
        <vue-excel-column
          field="harga"
          label="Harga"
          type="number"
          width="50px"
          :to-text="toText"
          :to-value="toVal"
        />
        <vue-excel-column
          field="th"
          label="Th"
          type="number"
          width="50px"
          :to-text="toText"
          :to-value="toVal"
        />
        <vue-excel-column
          field="cair"
          label="Cair"
          type="number"
          width="100px"
          summary="sum"
          :to-text="toText"
          :to-value="toVal"
        />
        <vue-excel-column
          field="ops"
          label="Ops"
          type="number"
          width="90px"
          summary="sum"
          :to-text="toText"
          :to-value="toVal"
        />
      </vue-excel-editor>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="save"
        color="blue"
        v-if="edit"
        dark
        class="mx-3"
        :disabled="loading"
        >Simpan</v-btn
      >
      <v-btn
        @click="
          edit = false;
          getData();
        "
        color="red"
        v-if="edit"
        dark
        >Batal</v-btn
      ><v-btn @click="edit = true" color="green" v-else dark>Edit</v-btn
      ><v-spacer></v-spacer
      ><download-excel
        class="mx-2"
        :data="excel"
        :fields="fieldExcell"
        worksheet="My Worksheet"
        :name="'tonase' + nota.ID + '-' + nota.tanggal"
        v-if="edit == false"
      >
        <v-btn dark color="green"
          ><v-icon>mdi-microsoft-excel</v-icon></v-btn
        ></download-excel
      ><v-btn v-if="edit == false" dark color="red" @click="hapus"
        ><v-icon>mdi-delete</v-icon></v-btn
      ></v-card-actions
    >
  </v-card>
</template>
<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";

export default {
  props: ["nota", "pabrik", "deo", "toke", "tonase", "reloader"],
  components: {},
  computed: {},
  watch: {
    reloader() {
      this.inisialisasi();
    },
  },
  mounted() {
    this.inisialisasi();
  },
  data() {
    return {
      loading: false,
      data: [],
      pabrikStd: 1,
      tokeStd: 0,
      tanggalStd: 0,
      harga: [],
      edit: false,
      excel: [],
      fieldExcell: {
        ID: "id",
        Pabrik: "do.pabrik.pabrik",
        Tanggal: "tanggaldo",
        Toke: "toke.nama",
        Supir: "nama",
        Plat: "plat",
        Tonase: {
          field: "tonase",
          callback: (value) => {
            return numeral(parseFloat(value) || 0).format("0,0.0");
          },
        },
        Harga: {
          field: "harga",
          callback: (value) => {
            return numeral(parseFloat(value) || 0).format("0,0.0");
          },
        },
        TH: {
          field: "th",
          callback: (value) => {
            return numeral(parseFloat(value) || 0).format("0,0.0");
          },
        },
        OPS: "ops",
        Kasir: "transaksi.akun.nama",
      },
    };
  },
  methods: {
    inisialisasi() {
      console.log("excell tonase di eksekusi");
      this.loading = true;
      this.tokeStd = this.nota.toke.ID;
      this.tanggalStd = this.nota.tanggal;
      this.getHarga();
    },
    getHarga() {
      let uri = `/harga/60hari/${this.tanggalStd}`;
      axios
        .get(uri)
        .then((res) => {
          this.harga = res.data;
          this.getData();
        })
        .catch((e) => {
          console.log(e.message);
          this.loading = false;
        });
    },
    getData() {
      if (this.tonase.length > 0) {
        this.data = this.tonase.map((x) => {
          let item = {};
          item.pabrik = x.do.pabrik.id;
          item.toke = x.toke.ID;
          item.tanggal = x.tanggaldo;
          item.cair = parseInt(x.tonase) * (parseInt(x.harga) + parseInt(x.th));
          item.supir = x["nama supir"];
          item.th = parseInt(x.th);
          item.tonase = parseInt(x.tonase);
          item.ops = parseInt(x.ops);
          item.plat = x.plat;
          item.harga = parseInt(x.harga);
          item.do = x.do.id;
          item.id = x.id;
          return item;
        });
      } else {
        this.data = [
          {
            pabrik: this.pabrikStd,
            tanggal: this.tanggalStd,
            toke: this.tokeStd,
            supir: "",
            plat: "",
            tonase: 0,
            harga: this.setHarga(this.pabrikStd, this.tanggalStd),
            th: 0,
            cair: 0,
            ops: 0,
            id: 0,
            do: this.setDeo(this.pabrikStd),
          },
        ];
        this.edit = true;
      }
      this.excel = this.tonase.map((x) => {
        x.nama = x["nama supir"];
        return x;
      });
      console.log(this.data);
      this.loading = false;
    },
    setDeo(pabrik) {
      let filterDeo = this.deo.filter((item) => {
        return item.pabrik.id == pabrik;
      });
      if (filterDeo.length > 0) {
        return filterDeo[0].id;
      } else {
        return 0;
      }
    },
    setHarga(pabrik, tanggal) {
      console.log(this.harga);
      let dt = this.harga.filter((x) => {
        return x.pabrik == pabrik;
      });
      let hasil = dt.filter(function (item) {
        return moment(tanggal).isSameOrAfter(item.tanggal);
      });
      console.log(hasil);
      if (hasil.length > 0) {
        return hasil[hasil.length - 1].harga;
      } else {
        return 0;
      }
    },
    tokes() {
      return this.toke;
    },
    pabriks() {
      return this.pabrik;
    },
    toVal(val) {
      return parseInt(val.replace(/[^\d-]/g, ""));
    },
    toText(val) {
      return numeral(parseInt(val)).format("0,0");
    },
    update(record) {
      let a = this.data.findIndex((x) => {
        return x.$id == record[0].$id;
      });
      if (!record[0].newVal && !record[0].oldVal) {
        this.data[a].pabrik = this.pabrikStd;
        this.data[a].tanggal = this.tanggalStd;
        this.data[a].toke = this.tokeStd;
        this.data[a].supir = "";
        this.data[a].plat = "";
        this.data[a].tonase = 0;
        this.data[a].harga = this.setHarga(this.pabrikStd, this.tanggalStd);
        this.data[a].th = 0;
        this.data[a].cair = 0;
        this.data[a].ops = 0;
        this.data[a].id = 0;
        this.data[a].do = this.setDeo(this.pabrikStd);
      } else {
        this.data[a].harga = this.setHarga(
          this.data[a].pabrik,
          this.data[a].tanggal
        );
      }
    },
    updatePabrik(newVal, oldVal, row) {
      this.updateHitungan(newVal, false, row);
    },
    updateTanggal(newVal, oldVal, row) {
      this.updateHitungan(false, newVal, row);
    },
    updateTon(newVal, oldVal, row) {
      let a = this.data.findIndex((x) => {
        return x.$id == row.$id;
      });
      this.data[a].cair = newVal * (this.data[a].harga + this.data[a].th);
    },
    updateHitungan(pabrik, tanggal, row) {
      let a = this.data.findIndex((x) => {
        return x.$id == row.$id;
      });
      const pb = pabrik ? pabrik : this.data[a].pabrik;
      const tgl = tanggal ? tanggal : this.data[a].tanggal;
      this.data[a].harga = this.setHarga(pb, tgl);
      this.data[a].cair =
        this.data[a].tonase * (this.data[a].harga + this.data[a].th);
      this.data[a].do = this.setDeo(pb);
      this.pabrikStd = this.data[this.data.length - 2].pabrik;
    },

    cekData() {
      console.log(this.data);
    },
    save() {
      let uri = `/tonase/excell`;
      this.loading = true;
      console.log("simpan");
      this.data = this.data.filter((x) => {
        return x.tonase;
      });
      let data = {};
      data.data = this.data;
      data.pendukung = this.nota;
      axios
        .post(uri, data)
        .then((res) => {
          console.log(res.data);
          if (res.data.status) {
            this.$noty.success("Data berhasil disimpan");
            this.getData();
            this.edit = false;
            this.$emit("reload");
          }
        })
        .catch((e) => {
          this.$noty.error(e.message).finally((this.loading = false));
        });
      console.log(data);
    },
    hapus() {
      let terpilih = this.$refs.grid.getSelectedRecords();
      if (terpilih.length > 0) {
        let uri = "/tonase/hapus";
        axios
          .post(uri, terpilih)
          .then((res) => {
            console.log(res.data);
            this.$noty.success("data Berhasil dihapus");
            this.$emit("reload");
            terpilih = [];
          })
          .catch((e) => {
            console.log(e.message);
            this.$noty.error(e.message);
          });
      } else {
        console.log("harus dipilih dulu");
      }
    },
  },
};
</script>
