<template>
  <v-dialog
    v-model="dialogD"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card dense class="overflow-auto" :loading="loading"
      ><v-toolbar dense elevation="-2">
        <v-btn icon large @click="tutup()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title v-if="obNota" dense class="text-uppercase">
          <v-chip class="ma-2" color="yellow">
            {{ idNota }}
          </v-chip>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon class="ma-2" @click="print">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <span v-if="!$vuetify.breakpoint.mdAndDown">
          <v-btn
            v-for="(item, index) in items"
            :key="index"
            @click="item.fungsi"
            icon
            ><v-icon>{{ item.icon }}</v-icon></v-btn
          ></span
        >
        Total: {{ total | rupiah }}
        <v-menu offset-y v-if="$vuetify.breakpoint.mdAndDown">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon>
              <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              @click="item.fungsi"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.judul }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-row>
        <v-col cols="12" md="6">
          <v-card class="ma-2" outlined>
            <v-card-title> </v-card-title>
            <v-card-subtitle
              ><v-row
                ><v-col>
                  <div
                    class="text-h5 red--text font-weight-bold text-uppercase"
                  >
                    {{ obNota.toke.nama }}
                  </div>
                  <span class="text-h6 grey--text text-uppercase">{{
                    obNota.keterangan
                  }}</span></v-col
                ><v-spacer></v-spacer
                ><v-btn icon class="ml-2" @click="edit" :disabled="loading">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn></v-row
              ></v-card-subtitle
            ></v-card
          ></v-col
        >

        <v-col cols="12" md="5" class="ma-4">
          <span class="mt-5 text-subtitle-1 blue--text">Metode Bayar :</span
          ><v-chip-group
            v-if="tonAktif"
            active-class="white--text blue"
            column
            v-model="terpilih"
          >
            <v-chip v-for="tag in tags" :key="tag">
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </v-col></v-row
      >
      <div>
        <mobile
          v-if="$vuetify.breakpoint.xsOnly"
          @reload="reload"
          :reloader="reloader"
          :nota="obNota"
          :pinjaman="pinjaman"
          :tonase="tonase"
          :pabrik="pabrik"
          :toke="toke"
          :deo="deo"
        ></mobile>
        <div v-else>
          <v-row no-gutters class="px-md-5" v-if="tonAktif">
            <v-col cols="12" class="my-4">
              <excellTon
                @reload="reload"
                :nota="obNota"
                :tonase="tonase"
                :pabrik="obPabrik"
                :reloader="reloader"
                :toke="obToke"
                :deo="deo"
              ></excellTon>
            </v-col>
            <v-col cols="12">
              <excellPinjaman
                @reload="reload"
                :reloader="reloader"
                :nota="obNota"
                :pinjaman="pinjaman"
                :toke="obToke"
                :deo="deo"
              ></excellPinjaman>
            </v-col>
            <v-col cols="12">
              <ram
                :reloader="reloader"
                :dataAwal="ramp"
                :tanggal="obNota.tanggal"
                :idNota="obNota.ID"
                :idAkun="obNota.id_admin"
                @reload="reload"
              ></ram>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-title> Upload gambar nota </v-card-title>
                <v-card-actions
                  ><input
                    style="display: none"
                    ref="upload"
                    type="file"
                    @change="kasihNama"
                    accept="image/*"
                  />
                  <v-btn @click="$refs.upload.click()" class="mx-2"
                    >Pilih File</v-btn
                  >
                  <!-- <v-btn @click="uploadNota" v-if="gambarNota">Upload</v-btn> -->
                </v-card-actions>
                <div>
                  <img
                    :src="linkGambar"
                    v-if="linkGambar"
                    width="100"
                    height="100"
                    class="ma-2"
                    @click="bukaGambar = true"
                  />
                </div>
                <v-dialog v-model="bukaGambar">
                  <v-card
                    ><div
                      class="d-flex flex-column justify-space-between align-center"
                    >
                      <v-img
                        max-height="700"
                        :src="linkGambar"
                        class="ma-4"
                        contain
                      ></v-img>
                    </div>
                    <v-card-actions
                      ><v-btn @click="bukaGambar = false"
                        >Tutup</v-btn
                      ></v-card-actions
                    ></v-card
                  >
                </v-dialog>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
      <print
        :pemicu="pemicuPrint"
        :transaksi="transaksi"
        @tutup="pemicuPrint = false"
        :id="idNota"
      ></print>
      <angsur
        :pemicu="pemicuAngsur"
        @tutup="pemicuAngsur = false"
        @simpan="
          pemicuAngsur = false;
          reload();
        "
        :id="idToke"
        :nota="obNota"
      ></angsur>
      <nota
        :pemicu="notaDialog"
        :id="idNota"
        @tutup="notaDialog = false"
        @simpan="inisialisasi"
      ></nota>
      <rekeningBank
        :pemicu="pemicuBank"
        @tutup="pemicuBank = false"
        @simpan="
          $emit('reload', idNota);
          isiData();
        "
        :data="dataBank"
      ></rekeningBank>
    </v-card>
  </v-dialog>
</template>
<script>
import excellTon from "./input/newInputTonase.vue";
import excellPinjaman from "./input/excellPinjaman.vue";
import ram from "../transaksiRam/excelRamp.vue";
import print from "../print/nota.vue";
import nota from "./input/notaBaru.vue";
import angsur from "./input/angsur.vue";
import mobile from "./mobile/index.vue";
import rekeningBank from "./input/rekeningBank.vue";
import axios from "axios";
import Compressor from "compressorjs";
export default {
  components: {
    excellTon,
    excellPinjaman,
    print,
    nota,
    angsur,
    mobile,
    ram,
    rekeningBank,
  },
  props: {
    dialog: Boolean,
    idNota: {
      type: Number,
      default: 1,
    },
    cekReload: Boolean,
    transaksi: Object,
    idAkun: String,
    ubah: Boolean,
  },
  watch: {
    terpilih() {
      if (this.metodeBayar == this.idMetode[this.terpilih]) {
        console.log("tidak berubah");
        console.log(this.idMetode[this.terpilih]);
      } else {
        console.log("berubah");
        console.log(this.idMetode[this.terpilih]);
        this.ubahMetode(this.idMetode[this.terpilih]);
      }
    },
    ubah() {
      console.log("ini dirubaaaaaahhhhhhhhhh========");
    },
  },
  data() {
    return {
      selectedItem: 1,
      tab: ["Pabrik", "Pinjaman"],
      loading: false,
      notaDialog: false,
      pinjaman: [],
      akunPindahan: 0,
      tonase: [],
      tags: ["Cash", "Transfer", "Besok"],
      terpilih: 0,
      obNota: {
        toke: 0,
      },
      pemicuPrint: false,
      dataEdit: {},
      resetNota: false,
      total: 0,
      pabrik: [],
      idMetode: ["cash", "transfer", "besok"],
      metodeBayar: "",
      toke: [],
      items: [
        {
          judul: "hapus",
          fungsi: () => {
            this.hapus();
          },
          icon: "mdi-delete",
        },
        {
          judul: "angsur",
          fungsi: () => {
            this.angsur();
          },
          icon: "mdi-handshake",
        },
      ],
      pemicuAngsur: false,
      pemicuPindah: false,
      idToke: 0,
      saldo: {},
      idTab: "Pabrik",
      obPabrik: {},
      obToke: {},
      deo: [],
      tonAktif: false,
      reloader: false,
      tampilan: false,
      PT: 0,
      PS: 0,
      gambarNota: null,
      linkGambar: null,
      bukaGambar: false,
      ramp: [],
      pemicuBank: false,
      dataBank: {},
    };
  },
  computed: {
    dialogD: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("tutup");
      },
    },
    totPinjam() {
      return this.pinjaman.reduce(
        (a, b) => a + (b.pinjam || 0) - (b.angsur || 0),
        0
      );
    },
  },
  mounted() {
    this.inisialisasi();
  },
  methods: {
    async inisialisasi() {
      this.tonAktif = false;
      this.loading = true;
      this.toke = await this.getToke();
      this.obPabrik = this.convertList(await this.getPabrik(), "id", "pabrik");
      this.deo = await this.getDeo();
      this.pabrik = await this.getPabrik();
      await this.isiData();
      this.tonAktif = true;
      this.loading = false;
    },
    getToke() {
      let uri = "/tokeAktif";
      return axios
        .get(uri)
        .then((res) => {
          this.obToke = this.convertList(res.data, "ID", "nama");
          return res.data;
        })
        .catch((e) => {
          console.log(e.message);
          return [];
        });
    },
    kasihNama(item) {
      this.gambarNota = item.target.files[0];
      if (this.gambarNota) {
        this.uploadNota();
      }
    },
    async uploadNota() {
      const uri = `/pencairan/nota/gambar/${this.idNota}`;
      if (!this.gambarNota) {
        return;
      }
      const result = await this.compres();
      console.log(result);
      if (result) {
        console.log(this.gambarNota);
        const data = new FormData();
        data.append("image", result.file, result.file.name);
        console.log(result);
        axios.post(uri, data).then((res) => {
          this.$noty.success("Berhasil di upload");
          this.isiData();
          console.log(res.data);
        });
      }
    },
    async compres() {
      const data = new Compressor(this.gambarNota, {
        quality: 0.6,
        width: 600,
        success(result) {
          return result;
        },
        error(err) {
          console.log(err.message);
          return false;
        },
      });
      return data;
    },
    async getPabrik() {
      let uri = "/pabrik";
      return axios
        .get(uri)
        .then((res) => {
          return res.data;
        })
        .catch((e) => {
          console.log(e.message);
          return [];
        });
    },
    async getDeo() {
      let uri = "/deo";
      return axios
        .get(uri)
        .then((res) => {
          return res.data;
        })
        .catch((e) => {
          console.log(e.message);
          return [];
        });
    },
    async getData() {
      let uri = `/nota/${this.idNota}/full`;
      console.log(uri + "get data");
      return axios
        .get(uri)
        .then((res) => {
          return res.data;
        })
        .catch((e) => {
          console.log(e.message);
          return [];
        });
    },
    async isiData() {
      this.loading = true;
      let dt = await this.getData();
      if (dt.status) {
        console.log(dt);
        this.obNota = dt.nota[0];
        this.total = dt.total;
        this.tonase = dt.tonase;
        this.pinjaman = dt.pinjaman;
        this.ramp = dt.ramp;
        this.PS = dt.saldoPS;
        this.PT = dt.saldoPT;
        this.linkGambar = dt.gambar;
        this.terpilih = this.idMetode.findIndex((x) => {
          return x == this.obNota.caraCair;
        });
        this.metodeBayar = this.obNota.caraCair;
        this.tonAktif = true;
        this.reloader = !this.reloader;
      } else {
        this.$noty.error("error");
      }
      this.loading = false;
      console.log(this.obNota);
    },
    hapus() {
      if (confirm("apa kamu yakin mau menghapus?")) {
        let uri = `/nota/${this.idNota}`;
        console.log(uri);
        axios
          .delete(uri)
          .then((response) => {
            this.$emit("reload");
            this.$emit("tutup");
            console.log(response);
          })
          .catch((e) => {
            this.$noty.error(e.message);
          });
      } else {
        // Do nothing!
        console.log("Thing was not saved to the database.");
      }
    },
    angsur() {
      this.idToke = this.obNota.toke.ID;
      console.log(this.idToke);
      this.pemicuAngsur = true;
      console.log("angsur");
    },
    ubahMetode(item) {
      let data = {};
      data.ID = this.obNota.ID;
      data.caraCair = item;
      data.idAkun = this.idAkun;
      if (data.caraCair == "transfer") {
        this.dataBank = data;
        this.pemicuBank = true;
      } else {
        if (confirm("apa kamu yakin mau ganti metode bayar?")) {
          const uri = `/pencairan/nota/metode`;
          axios.post(uri, data).then((res) => {
            console.log(res);
            this.$emit("reload", this.idNota);
            this.isiData();
          });
        }
      }
    },
    pindahkan_ps() {
      this.idToke = this.obNota.toke.ID;
      this.pemicuPindah = true;
      console.log("pindahkan ps pt");
    },
    pindahkan_ton() {
      console.log("pindahkan tonase");
    },
    tutup() {
      this.$emit("tutup", 0);
      this.resetNota = !this.resetNota;
    },
    reload() {
      this.$emit("reload", this.idNota);
      this.isiData();
    },
    print() {
      this.pemicuPrint = true;
    },
    updateNota(item) {
      console.log("emit dari nota");
      this.obNota.toke.ID = parseInt(item.toke);
      this.obNota.toke.nama = this.toke.find((x) => {
        return x.ID == item.toke;
      }).nama;
      this.obNota.keterangan = item.keterangan;
      this.reload();
    },
    edit() {
      this.notaDialog = true;
    },
    convertList(data, key, value) {
      let hasil = data.reduce(
        (obj, item) => Object.assign(obj, { [item[key]]: item[value] }),
        {}
      );
      return hasil;
    },
  },
};
</script>
