<template>
  <div class="autocomplete">
    <v-text-field
      label="Toke"
      @input="onChange"
      @blur="isiToke"
      v-model="search"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      clearable
      ref="rteks"
      id="r-teks"
    ></v-text-field>
    <v-menu
      v-model="isOpen"
      :close-on-content-click="false"
      max-width="290"
      attach="#r-teks"
    >
      <v-list dense nav>
        <v-list-item
          v-for="(result, i) in results"
          :key="i"
          @click="setResult(result)"
          class="autocomplete-result"
          :class="{ 'is-active': i === arrowCounter }"
        >
          <v-list-item-content>
            <v-list-item-title>{{ result.nama }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- @keydown.enter="onEnter" -->
    <!-- <ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results">
      <li class="loading" v-if="isLoading">Loading results...</li>
      <li
        v-else
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result.nama }}
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    teks: {
      type: String,
      required: false,
      default: "",
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: Number,
  },
  data() {
    return {
      isOpen: false,
      results: [],
      isLoading: false,
      arrowCounter: 0,
      id: this.value,
      sql: "",
      menu1: false,
    };
  },
  watch: {
    items: function (value, oldValue) {
      if (value.length !== oldValue.length) {
        this.results = value;
        this.isLoading = false;
      }
    },
  },
  computed: {
    search: {
      get() {
        if (this.value) {
          return this.items.find((x) => {
            return x.ID == this.value;
          }).nama;
        } else {
          return "";
        }
      },
      set(item) {
        this.sql = item;
      },
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    setResult(result) {
      this.$emit("updateTeks", result.nama);
      this.isOpen = false;
      this.arrowCounter = 0;
    },
    filterResults() {
      if (this.sql) {
        this.results = this.items
          .filter((item) => {
            // return item.nama.toLowerCase().indexOf(this.sql.toLowerCase()) > -1;
            return (
              item.nama.substr(0, this.sql.length).toLocaleLowerCase() ==
              this.sql.toLocaleLowerCase()
            );
          })
          .slice(0, 9);
      }
    },
    onChange() {
      if (this.isAsync) {
        this.isLoading = true;
      } else {
        this.filterResults();
        this.isOpen = true;
      }
      this.id = parseInt(this.results.length > 0 ? this.results[0].ID : 0);
      this.$emit("input", this.id);
      console.log(this.arrowCounter);
      console.log(this.$refs.rteks);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = 0;
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
        this.id = parseInt(
          this.results.length > 0 ? this.results[this.arrowCounter].ID : 0
        );
        this.$emit("input", this.id);
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
        this.id = parseInt(
          this.results.length > 0 ? this.results[this.arrowCounter].ID : 0
        );
        this.$emit("input", this.id);
      }
    },
    onEnter() {
      this.sql = this.results[this.arrowCounter].nama;
      this.isOpen = false;
      this.arrowCounter = 0;
    },
    isiToke() {
      console.log(this.results);
      if (this.results.length > 0) {
        this.$emit(
          "updateTeks",
          this.items.find((x) => {
            return x.ID == this.id;
          }).nama
        );
      } else {
        this.$emit("updateTeks", "");
      }
      this.isOpen = false;
      this.arrowCounter = 0;
    },
  },
};
</script>

<style>
.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
  background-color: white;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
</style>
