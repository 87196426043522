<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="600"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Toke</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Nama Akun"
                required
                v-model="data.nama"
                dense
                ref="toke"
                :rules="[(v) => !!v || 'Harus diisi']"
              ></v-text-field
            ></v-col>

            <v-col cols="12" class="mt-1">
              <v-autocomplete
                v-model="data.golongan"
                auto-select-first
                dense
                item-text="golongan"
                item-value="ID"
                :items="[
                  { ID: 7, golongan: 'KASIR' },
                  { ID: 8, golongan: 'BANK KASIR' },
                  { ID: 9, golongan: 'BANK PABRIK' },
                  { ID: 10, golongan: 'FEE' },
                  { ID: 11, golongan: 'ANGSURAN BANK' },
                ]"
                label="Golongan"
                ref="golongan"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12" class="mt-1">
              <v-autocomplete
                v-model="data.aktif"
                auto-select-first
                dense
                :items="['Aktif', 'Tidak Aktif']"
                label="Aktif"
                ref="aktif"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-btn color="blue" dark type="submit" :disabled="loadingSimpan"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["pemicu", "id"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
      this.kondisikan();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      data: {
        nama: "",
        golongan: 7,
        aktif: "Aktif",
        ID: 0,
      },
      loading: false,
      loadingSimpan: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid && this.loadingSimpan == false) {
        this.loadingSimpan = true;
        let uri = `/akunjurnal/kasir`;
        this.data;
        axios.post(uri, this.data).then((response) => {
          if (response.data.status) {
            this.$noty.success("Data berhasil disimpan");
            this.tutup();
            this.$emit("simpan", response.data.data);
            this.$noty.success(response.data.pesan);
            this.loadingSimpan = false;
          } else {
            this.$noty.success(response.data.pesan);
            this.loadingSimpan = false;
          }
        });
      }
    },
    inisialisasi() {
      if (this.id > 0) {
        let uri = `/akunjurnal/kasir/${this.id}`;
        axios.get(uri).then((response) => {
          this.data = response.data;
          this.data.golongan = parseInt(this.data.golongan);
        });
      } else {
        this.data.ID = 0;
        this.data.nama = "";
        this.data.golongan = 7;
        this.data.aktif = "Aktif";
        console.log(this.data);
      }
    },
    simpan() {},
    kondisikan() {},
  },
};
</script>
