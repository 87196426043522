var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"950","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"white"},[_c('v-card-title',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.pengantar.txPabrik)+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"form",attrs:{"color":"grey"},on:{"submit":function($event){$event.preventDefault();return _vm.simpan()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.ubahTanggal,"clearable":"","label":"Tanggal","readonly":""},on:{"click:clear":function($event){_vm.date = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.pupup),callback:function ($$v) {_vm.pupup=$$v},expression:"pupup"}},[_c('v-date-picker',{on:{"change":function($event){_vm.pupup = false}},model:{value:(_vm.tonase.tanggaldo),callback:function ($$v) {_vm.$set(_vm.tonase, "tanggaldo", $$v)},expression:"tonase.tanggaldo"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('v-text-field',{attrs:{"label":"Supir","rules":_vm.validText},model:{value:(_vm.tonase.supir),callback:function ($$v) {_vm.$set(_vm.tonase, "supir", $$v)},expression:"tonase.supir"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('v-text-field',{attrs:{"label":"Plat","rules":_vm.validText},model:{value:(_vm.tonase.plat),callback:function ($$v) {_vm.$set(_vm.tonase, "plat", $$v)},expression:"tonase.plat"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('v-text-field',{attrs:{"label":"Tonase","required":"","rules":[
                    (v) => v <= 1000000 || 'Terlalu besar',
                    (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                  ],"type":"number"},model:{value:(_vm.tonase.tonase),callback:function ($$v) {_vm.$set(_vm.tonase, "tonase", $$v)},expression:"tonase.tonase"}},[_vm._v(_vm._s(_vm._f("rupiah")(_vm.tonase.tonase)))])],1),_c('v-col',{attrs:{"cols":"6","md":"1","sm":"1"}},[_c('v-text-field',{attrs:{"label":"Th","required":"","rules":[
                    (v) => v <= 1000 || 'Terlalu besar',
                    (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                  ],"type":"number"},model:{value:(_vm.tonase.th),callback:function ($$v) {_vm.$set(_vm.tonase, "th", _vm._n($$v))},expression:"tonase.th"}})],1),_c('v-col',{attrs:{"cols":"6","md":"1","sm":"1"}},[_c('v-text-field',{attrs:{"label":"OPS","required":"","rules":[
                    (v) => v <= 10000000 || 'Terlalu besar',
                    (v) => !isNaN(parseFloat(v)) || 'harus di isi angka',
                  ],"type":"number"},model:{value:(_vm.tonase.ops),callback:function ($$v) {_vm.$set(_vm.tonase, "ops", $$v)},expression:"tonase.ops"}})],1),_c('v-col',{attrs:{"sm":"1","md":"1"}},[_c('v-btn',{attrs:{"color":"green","dark":"","type":"submit","small":"","fab":""}},[_c('v-icon',[_vm._v("mdi-content-save-move")])],1)],1),_c('v-col',{attrs:{"sm":"1","md":"1"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","dark":"","fab":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.barisBaru()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"dif"}},[_c('v-simple-table',{ref:"bodi",attrs:{"dense":"","id":"siji"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{attrs:{"id":"kepala"}},[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("No")]),_c('th',{staticClass:"text-left"},[_vm._v("Tanggal")]),_c('th',{staticClass:"text-left"},[_vm._v("Supir")]),_c('th',{staticClass:"text-left"},[_vm._v("Plat")]),_c('th',{staticClass:"text-left"},[_vm._v("Tonase")]),_c('th',{staticClass:"text-left"},[_vm._v("Harga")]),_c('th',{staticClass:"text-left"},[_vm._v("Cair")]),_c('th',{staticClass:"text-left"},[_vm._v("OPS")])])]),_c('tbody',{attrs:{"id":"badan"}},[_vm._l((_vm.tonaseTonase),function(item){return _c('tr',{key:item.nou,class:_vm.selectedRows.indexOf(item.nou) > -1
                            ? 'primary  white--text font-weight-bold'
                            : '',attrs:{"id":'goto' + item.nou},on:{"click":function($event){return _vm.rowClicked(item)}}},[_c('td',[_vm._v(_vm._s(item.nou))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.tanggaldo))+" ")]),_c('td',[_vm._v(_vm._s(item.supir))]),_c('td',[_vm._v(_vm._s(item.plat))]),_c('td',[_vm._v(_vm._s(item.tonase))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("angkaKoma")((parseFloat(item.harga) + parseFloat(item.th))))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("rupiah")((item.tonase * (parseFloat(item.harga) + parseFloat(item.th)))))+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("rupiah")(item.ops)))])])}),_c('tr',{class:_vm.selectedRows == _vm.tonaseTonase.length + 1
                            ? 'primary  white--text font-weight-bold'
                            : ''},[_c('td',[_vm._v("*")]),_c('td'),_c('td'),_c('td'),_c('td',[_vm._v(_vm._s(_vm._f("angka")(_vm.totalTon)))]),_c('td'),_c('td',[_vm._v(_vm._s(_vm._f("rupiah")(_vm.totalCair)))]),_c('td')])],2)]},proxy:true}])})],1)])],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"blue","dark":"","loading":_vm.loadingBtn,"disabled":_vm.loadingBtn},on:{"click":function($event){return _vm.kirim()}}},[_vm._v("Simpan")]),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.tutup()}}},[_vm._v("Batal")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"ma-1",attrs:{"x-small":""},on:{"click":_vm.sisipkan}},[_vm._v("Sisipkan Tonase")]),_c('v-btn',{staticClass:"ma-1",attrs:{"x-small":""},on:{"click":_vm.sisipkan2}},[_vm._v("Sisipkan Nama")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }