var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-card',[_c('v-card-title',[_c('h3',[_vm._v("List Plat mobil")]),_c('v-btn',{attrs:{"color":"pink","dark":""},on:{"click":_vm.print}},[_vm._v("Print")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.toke,"search":_vm.search,"items-per-page":10,"dense":"","show-select":"","item-key":"id","mobile-breakpoint":"300","footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
              prevIcon: 'mdi-arrow-left-drop-circle-outline',
              nextIcon: 'mdi-arrow-right-drop-circle-outline',
            },"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.supir.supir",fn:function({ item }){return _vm._l((item.supir),function(a){return _c('div',{key:a.id},[_vm._v(" "+_vm._s(a.supir + ": " + a.plat)+" ")])})}},{key:"item.aksi",fn:function({ item }){return [_c('v-btn',{attrs:{"color":"green","dark":"","small":""},on:{"click":function($event){return _vm.tambahPlat(item)}}},[_vm._v("Tambah")]),_c('v-btn',{attrs:{"color":"red","dark":"","small":""},on:{"click":function($event){return _vm.tambahPlat(item)}}},[_vm._v("Hapus")])]}}])})],1),_c('inputSupir',{attrs:{"supirDialog":_vm.dialogSupir,"toke":_vm.tokeTerpilih},on:{"tutup":_vm.tutupSupir,"update":_vm.updateTabel}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }