<template>
	<v-dialog
		transition="dialog-bottom-transition"
		v-model="dialog"
		width="800"
		persistent>
		<v-card dense tile>
			<v-card-title class="text-uppercase">{{
				jenisDialog.input + " " + jenisDialog.jenis
			}}</v-card-title>
			<v-card-text>
				<v-form
					@submit.prevent="simpan()"
					color="grey"
					ref="form"
					v-model="valid">
					<v-row>
						<v-col cols="12" sm="3" md="3">
							<v-autocomplete
								v-model="pinjaman.idtoke"
								autofocus
								:items="toke"
								item-text="nama"
								item-value="ID"
								:filter="cariToke"
								label="Toke"
								ref="toke"
								:rules="[(v) => !!v || 'Item is required']"
								auto-select-first
								@keydown.enter="simpan"
								required></v-autocomplete>
							<!-- <r-toke
                :items="toke"
                v-model="pinjaman.idtoke"
                @updateTeks="updateTeks"
                :teks="teksCari"
                :rules="[(v) => !!v || 'Item is required']"
              ></r-toke> -->
						</v-col>
						<v-col cols="12" md="5" sm="5">
							<v-text-field
								label="Uraian"
								:rules="[
									(v) =>
										v.length <= 50 || 'Terlalu bayak yang di isi, gak boleh',
								]"
								required
								v-model="pinjaman.uraian"></v-text-field>
						</v-col>
						<v-col cols="6" md="2" sm="2">
							<v-text-field
								label="Pinjam"
								required
								type="number"
								:rules="[
									(v) => !isNaN(parseFloat(v)) || 'Item harus angka',
									(v) =>
										(v >= -90000000000 && v <= 90000000000) || 'terlalu besar',
								]"
								v-model="pinjaman.nominal"
								>{{ pinjaman.nominal | rupiah }}</v-text-field
							>
						</v-col>
						<v-col cols="6" sm="1" md="1"
							><v-btn color="green" dark type="submit" small fab
								><v-icon>mdi-content-save-move</v-icon></v-btn
							></v-col
						>
						<v-col cols="1">
							<v-btn color="green" dark fab small @click.prevent="barisBaru()">
								<v-icon>mdi-plus</v-icon></v-btn
							>
						</v-col>
					</v-row>
				</v-form>
				<v-card>
					<div id="dif">
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">No</th>
										<th class="text-left">Toke</th>
										<th class="text-left">Uraian</th>
										<th class="text-left">Nominal</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="item in pinjamans"
										:key="item.nou"
										:class="
											selectedRows.indexOf(item.nou) > -1
												? 'primary  white--text font-weight-bold'
												: ''
										"
										@click="rowClicked(item)">
										<td>{{ item.nou }}</td>
										<td>{{ item.namatoke }}</td>
										<td>{{ item.uraian }}</td>
										<td>{{ item.nominal | rupiah }}</td>
									</tr>
									<tr
										:class="
											selectedRows == pinjamans.length + 1
												? 'primary  white--text font-weight-bold'
												: ''
										">
										<td>*</td>
										<td></td>
										<td colspan="2">Total: {{ total | rupiah }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</div>
				</v-card>
			</v-card-text>
			<v-card-actions>
				<v-btn color="red" dark @click="tutup()" class="ma-2">Batal</v-btn
				><v-btn
					color="blue"
					dark
					@click="kirim()"
					:loading="loadingBtn"
					:disabled="loadingBtn"
					>Simpan</v-btn
				></v-card-actions
			>
		</v-card>
	</v-dialog>
</template>
<style>
	#dif {
		height: 330px;
		overflow: scroll;
	}
</style>
<script>
	import axios from "axios";
	// import rToke from "../../test/autocomplete-r.vue";
	export default {
		props: ["pinjamDialog", "toke", "jenisDialog", "nota"],
		computed: {
			dialog: {
				get() {
					return this.pinjamDialog;
				},
				set() {
					this.$emit("tutup");
				},
			},
		},
		components: {},
		watch: {
			dialog() {
				this.kosongkan();
				this.pinjaman.nou = 1;
				this.pinjamans = [];
				console.log(this.nota);
				console.log("cap rizki");
			},
		},
		mounted() {
			if (this.nota) {
				this.kosongkan();
				console.log(this.toke);
			}
		},
		data() {
			return {
				pinjaman: {
					ID: 0,
					namatoke: "",
					idtoke: 1,
					uraian: "",
					pinjam: 0,
					angsur: 0,
					tanggal: "",
					jenis: "ps",
					id_gabung: 500,
					id_akun: 3,
					id_cair: 477,
					id_gabungcb: 0,
					saldo: 0,
					ok: 0,
					id_masukan: 0,
					jenis_masukan: "normal",
					label: 0,
					hitung: 0,
					nominal: 0,
					nou: 1,
				},
				headers: [
					{ text: "No", value: "nou" },
					{
						text: "Toke",
						align: "start",
						filterable: true,
						value: "namatoke",
					},
					{ text: "Uraian", value: "uraian" },
					{ text: "Nominal", value: "nominal" },
				],
				search: "",
				pinjamans: [],
				selectedRows: [],
				pasEnter: 0,
				valid: true,
				validasiNominal: (v) => {
					if (!v.trim()) return true;
					if (!isNaN(parseFloat(v))) return true;
					return "Harus di isi Angka";
				},
				loadingBtn: false,
				itemHeight: 35,
				teksCari: "",
				total: 0,
			};
		},
		methods: {
			tutup() {
				this.$emit("tutup");
				this.loadingBtn = false;
			},
			scrollToItem() {
				let dip = document.getElementById("dif");
				let a =
					(parseInt(this.selectedRows - 2) * parseInt(dip.scrollHeight)) /
					parseInt(this.pinjamans.length);
				dip.scrollTop = a;
			},
			scrollToBottom() {
				let dip = document.getElementById("dif");
				dip.scrollTop = 10000;
			},
			simpan() {
				console.log(this.pinjaman.idtoke);
				this.$refs.form.validate();
				if (this.valid) {
					this.kondisikanPS();
					let copyan = Object.assign({}, this.pinjaman);
					const index = this.pinjamans.findIndex((e) => e.nou === copyan.nou);
					if (index === -1) {
						this.pinjamans.push(copyan);
						this.pinjaman.nou += 1;
						this.selectedRows = [this.pinjaman.nou];
						this.kosongkan();
						this.scrollToBottom();
					} else {
						this.pinjamans[index] = copyan;
						this.pinjaman.nou += 1;
						this.selectedRows = [this.pinjaman.nou];
						if (this.pinjaman.nou > this.pinjamans.length) {
							this.kosongkan();
						} else {
							this.isiObPinjaman(this.pinjaman.nou);
						}
						this.scrollToItem();
					}
					this.sumTotal();
				}
			},
			sumTotal() {
				this.total = this.pinjamans.reduce((simpan, item) => {
					return (simpan += item.pinjam - item.angsur);
				}, 0);
			},
			isiObPinjaman(nou) {
				let hasil = this.pinjamans.filter(function (item) {
					return item.nou == nou;
				});
				if (hasil.length > 0) {
					this.pinjaman = Object.assign({}, hasil[0]);
					// this.teksCari = this.strToke(this.pinjaman.idtoke);
					this.teksCari = "";
				} else {
					this.kosongkan();
				}
			},
			kondisikanPS() {
				if (this.jenisDialog.jenis == "PS") {
					this.pinjaman.jenis = "PS";
				} else {
					this.pinjaman.jenis = "PT";
				}
				this.pinjaman.nominal = parseInt(this.pinjaman.nominal);
				if (this.jenisDialog.input == "pinjam") {
					this.pinjaman.pinjam = this.pinjaman.nominal;
					this.pinjaman.angsur = 0;
					this.pinjaman.hitung = this.pinjaman.nominal;
				} else {
					this.pinjaman.pinjam = 0;
					this.pinjaman.angsur = this.pinjaman.nominal;
					this.pinjaman.hitung = this.pinjaman.nominal * -1;
				}
				this.pinjaman.namatoke = this.strToke(this.pinjaman.idtoke);
			},
			strToke(id) {
				const hasil = this.toke.findIndex((e) => e.ID === id);
				return this.toke[hasil].nama;
			},
			kosongkan() {
				if (this.nota) {
					this.pinjaman.id_akun = this.nota.id_admin;
					this.pinjaman.id_cair = this.nota.id_cair;
					this.pinjaman.id_gabung = this.nota ? this.nota.ID : 0;
					this.pinjaman.tanggal = this.nota.tanggal;
					this.pinjaman.idtoke = parseInt(
						this.nota.toke ? this.nota.toke.ID : 0
					);
					// this.pinjaman.namatoke = "";
					this.teksCari = "";
					this.pinjaman.namatoke = this.nota.toke ? this.nota.toke.nama : "";
					this.pinjaman.uraian = "";
					this.pinjaman.nominal = 0;
					// this.teksCari = this.toke.find((x) => {
					//   return x.ID == this.nota.toke.ID;
					// }).nama;
					// const elem = document.querySelector("input-4067");
					console.log(`teksCari: ${this.teksCari}`);
					console.log(document.activeElement);
				}
			},
			barisBaru() {
				this.kosongkan();
				this.pinjaman.nou = this.pinjamans.length + 1;
				this.selectedRows = [this.pinjaman.nou];
				// this.teksCari = this.toke.find((x) => {
				//   return x.ID == this.nota.toke.ID;
				// }).nama;
				this.teksCari = "";
				this.$refs.toke.focus();
				this.scrollToBottom();
				console.log(`teksCari: ${this.teksCari}`);
			},
			rowClicked(item) {
				this.selectedRows = [item.nou];
				this.isiObPinjaman(item.nou);
			},
			kirim() {
				console.log("proses simpan");
				console.log(this.pinjamans);
				this.loadingBtn = true;
				let uri = "/pinjaman/insertbanyak";
				let pinjamans = this.pinjamans;
				axios
					.post(uri, pinjamans)
					.then((response) => {
						if (response.data) {
							this.$noty.success("Data berhasil disimpan");
							this.dialog = false;
							this.$emit("reload");
							this.loadingBtn = false;
						}
					})
					.catch((error) => {
						this.$noty.error(
							"Terjadi Erorr, entah kenapa bisa error. silahkan coba lagi atau batalkan"
						);
						console.log(error);
						this.loadingBtn = false;
					});
			},
			cariToke(item, queryTex) {
				return (
					item.nama.substr(0, queryTex.length).toLocaleLowerCase() ==
					queryTex.toLocaleLowerCase()
				);
			},
			updateTeks(item) {
				this.teksCari = item;
			},
		},
	};
</script>
