<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="500"
    persistent
  >
    <v-card dense>
      <v-card-title>Bayar tagihan</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
              class="mx-5 my-4"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="back">
            <a @click="kembali" class="text-bold"
              ><v-icon>mdi-arrow-left</v-icon> {{ folderTerpilih }}</a
            >
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="data"
              :search="search"
              :items-per-page="10"
              :show-select="seleksi"
              dense
              v-model="terpilih"
              item-key="ID"
              mobile-breakpoint="300"
            >
              <template v-slot:item.nominal="{ item }">
                <span>{{ item.nominal | rupiah }}</span>
              </template>
              <template v-slot:item.uraianA="{ item }">
                <a @click="bukaIsi(item)">{{ item.keterangan }}</a>
              </template>
            </v-data-table>
          </v-col>
          <v-btn
            color="blue"
            dark
            @click="simpan"
            :disabled="tombolOff || kosong"
            >Bayar</v-btn
          ><v-spacer></v-spacer>
          <v-btn color="red" dark @click="tutup()">Batal</v-btn>
        </v-row>
      </v-card-text>
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="pemicuNama"
        width="400"
        persistent
      >
        <v-card dense tile>
          <v-card-title>Bayar Tagihan</v-card-title>
          <v-card-text>
            <v-form
              @submit.prevent="simpanNota()"
              color="grey"
              ref="form"
              v-model="valid"
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nama Tagihan"
                    required
                    v-model="dataNota.keterangan"
                    autofocus
                  >
                  </v-text-field>
                </v-col>
                <v-btn color="blue" dark type="submit" :disabled="tombolOff"
                  >Simpan</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn color="red" dark @click="tutupNota()">Batal</v-btn>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: {
    pemicu: Boolean,
    id: Number,
    idNota: Number,
    folder: Number,
  },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    kosong() {
      return !this.terpilih.length;
    },
  },
  watch: {
    pemicu() {
      if (this.pemicu) {
        this.inisialisasi();
      }
    },
  },
  data() {
    return {
      valid: false,
      tombolOff: false,
      data: [],
      dataMentah: [],
      search: "",
      headerF: [
        {
          text: "Uraian",
          align: "start",
          filterable: true,
          value: "uraianA",
        },
        { text: "Bayar", value: "nominal" },
      ],
      headerA: [
        {
          text: "Uraian",
          align: "start",
          filterable: true,
          value: "keterangan",
        },
        { text: "Bayar", value: "nominal" },
      ],
      headers: [],
      terpilih: [],
      seleksi: false,
      back: false,
      folderTerpilih: "",
      dataNota: { keterangan: "" },
      transaksi: {},
      pemicuNama: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    inisialisasi() {
      this.terpilih = [];
      let uri = `/transfer/id_cair/${this.id}`;
      this.headers = this.headerF;
      this.seleksi = false;
      this.terpilih = [];
      this.back = false;
      axios
        .get(uri)
        .then((response) => {
          console.log(response.data);
          this.dataMentah = response.data;
          this.grupkan();
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    bukaIsi(item) {
      let id = item.id_gabung;
      this.headers = this.headerA;
      this.folderTerpilih = item.keterangan;
      this.data = this.dataMentah
        .filter((x) => {
          return x.id_gabung == id;
        })
        .map((x) => {
          x.nominal = x.hitung * -1;
          x.keterangan = x.uraian;
          return x;
        });
      this.seleksi = true;
      this.back = true;
    },
    kembali() {
      this.headers = this.headerF;
      this.folderTerpilih = "";
      this.seleksi = false;
      this.back = false;
      this.grupkan();
      this.terpilih = [];
    },
    grupkan() {
      this.data = this.dataMentah.reduce((before, item) => {
        let cur = before.findIndex((x) => {
          return x.id_gabung == item.id_gabung;
        });
        if (cur >= 0) {
          before[cur].nominal += parseFloat(item.hitung) * -1;
        } else {
          item.keterangan = item.nota.keterangan;
          item.nominal = parseFloat(item.hitung) * -1;
          before.push(item);
        }
        return before;
      }, []);
    },
    simpan() {
      if (this.idNota) {
        this.kirim();
      } else {
        let uri = `/transaksi/${this.id}`;
        axios.get(uri).then((res) => {
          this.transaksi = res.data[0];
          this.dataNota.keterangan = "";
          this.dataNota.toke = 0;
          this.dataNota.tanggal = this.transaksi.tanggal;
          this.dataNota.id_admin = this.transaksi.akun.id;
          this.dataNota.id_cair = this.transaksi.id;
          this.dataNota.id_nota = this.folder;
          this.dataNota.jenis = 4;
          this.pemicuNama = true;
        });
      }
    },
    kirim() {
      console.log("data dikirim");
      let data = {};
      data.idTransfer = this.terpilih.map((x) => {
        return x.tf.id;
      });
      data.data = this.terpilih;
      let uri = `/transfer/bayar/${this.idNota}`;
      axios
        .put(uri, data)
        .then((res) => {
          console.log(res);
          this.$emit("simpan");
          this.tutup();
        })
        .catch((e) => {
          console.log(e.message);
        });
      console.log(data);
    },
    simpanNota() {
      if (this.valid) {
        this.tombolOff = true;
        let uri = "/nota";
        axios
          .post(uri, this.dataNota)
          .then((response) => {
            if (response.status == 200) {
              let nota = response.data.data.ID;
              let data = {};
              data.idTransfer = this.terpilih.map((x) => {
                return x.tf.id;
              });
              data.data = this.terpilih;
              let uri = `/transfer/bayar/${nota}`;
              axios
                .put(uri, data)
                .then((res) => {
                  console.log(res);
                  this.$noty.success("berhasil di simpan");
                  this.$emit("simpan");
                  this.tutup();
                  this.tombolOff = false;
                })
                .catch((e) => {
                  console.log(e.message);
                });
            } else {
              this.$noty.error(response.data.pesan);
              this.tombolOff = false;
            }
          })
          .catch((error) => {
            this.$noty.error(error.Error);
            console.log(error);
            this.tombolOff = false;
          });
      }
    },
    tutupNota() {
      this.pemicuNama = false;
    },
    kondisikan() {},
  },
};
</script>
