<template>
	<v-dialog
		transition="dialog-bottom-transition"
		v-model="dialog"
		width="400"
		persistent>
		<v-card dense tile :loading="loading">
			<v-card-title
				>Pinjaman <v-spacer></v-spacer
				><v-btn @click="tutup" icon color="red" large
					><v-icon>mdi-close</v-icon></v-btn
				></v-card-title
			>
			<v-card-text>
				<v-form
					@submit.prevent="kirim()"
					color="grey"
					ref="form"
					v-model="valid">
					<v-row
						><v-col cols="12">
							<v-autocomplete
								v-model="data.jenis"
								:items="['PS', 'PT']"
								label="Jenis"
								ref="pinjam"
								dense
								:rules="[(v) => !!v || 'Harus diisi']">
							</v-autocomplete
						></v-col>
						<v-col cols="12">
							<v-autocomplete
								v-model="data.toke"
								:items="tokes"
								item-text="nama"
								item-value="ID"
								auto-select-first
								label="akun"
								autofocus
								dense
								ref="toke"
								:rules="[(v) => !!v || 'Harus diisi']">
							</v-autocomplete
						></v-col>
						<v-col cols="12">
							<v-text-field label="Uraian" required v-model="data.uraian" dense>
							</v-text-field>
						</v-col>
						<v-col cols="12"></v-col>
						<v-col cols="12">
							<v-text-field
								label="Pinjam"
								required
								v-model="data.pinjam"
								dense
								type="number">
							</v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field label="angsur" required v-model="data.angsur" dense>
							</v-text-field>
						</v-col>
						<v-btn color="blue" dark type="submit" :disabled="tombolOff"
							>Simpan</v-btn
						>
						<v-spacer></v-spacer>
						<v-btn color="orange" dark @click="keTagihan()" v-show="id"
							>ke Tagihan</v-btn
						>
					</v-row>
				</v-form>
			</v-card-text>
			<v-card-actions> </v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
	import axios from "axios";
	export default {
		props: ["id", "nota", "pemicu"],
		computed: {
			dialog: {
				get() {
					return this.pemicu;
				},
				set() {
					this.$emit("tutup");
				},
			},
		},
		watch: {
			pemicu() {
				this.kondisikan();
			},
		},
		data() {
			return {
				valid: false,
				tombolOff: false,
				tokes: [],
				data: {},
				loading: false,
			};
		},
		methods: {
			tutup() {
				this.$emit("tutup");
			},
			kirim() {
				if (this.valid) {
					this.tombolOff = true;
					this.isiData();
					if (this.id) {
						let uri = `/pinjaman/${this.id}`;
						axios
							.put(uri, this.data)
							.then((response) => {
								if (response.data.status) {
									this.$noty.success(response.data.messages);
									this.$emit("simpan", response.data.awal);
									this.$emit("tutup");
									this.tombolOff = false;
								}
							})
							.catch((error) => {
								this.$noty.error(error.message);
								this.tombolOff = false;
							});
					} else {
						let uri = "/pinjaman";
						console.log(uri);
						console.log(this.data);
						axios
							.post(uri, this.data)
							.then((response) => {
								if (response.data.status) {
									this.$emit("simpan", response.data.data);
									this.$emit("tutup");
									this.$noty.success(response.data.pesan);
									console.log(response.data.data);
									console.log(response.data.pesan);
								} else {
									this.$noty.error(response.data.pesan);
								}
								this.tutup();
								this.tombolOff = false;
							})
							.catch((error) => {
								this.$noty.error(error.message);
								this.tombolOff = false;
							});
					}
				}
			},
			kondisikan() {
				this.loading = true;
				if (this.id) {
					let uri = "/pinjaman/" + this.id;
					axios.get(uri).then((response) => {
						this.data = response.data;
						this.data.toke = parseInt(this.data.toke);
						console.log(this.data);
					});
				} else {
					this.data.ID = 0;
					this.data.toke = 0;
					this.data.uraian = "";
					this.data.pinjam = 0;
					this.data.angsur = 0;
					this.data.tanggal = this.nota.tanggal;
					this.data.jenis = "PS";
					this.data.id_gabung = this.nota.ID;
					this.data.id_akun = this.nota.id_admin;
					this.data.id_cair = this.nota.id_cair;
					this.data.id_gabungcb = 0;
					this.data.saldo = 0;
					this.data.ok = 0;
					this.data.id_masukan = 0;
					this.data.jenis_masukan = "normal";
					this.data.label = 0;
					this.data.hitung = 0;
				}
				this.getToke();
			},
			getToke() {
				let uri = "/tokeAktif";
				axios.get(uri).then((res) => {
					this.tokes = res.data;
					this.loading = false;
				});
			},
			isiData() {
				this.data.pinjam = parseInt(this.data.pinjam) || 0;
				this.data.angsur = parseInt(this.data.angsur) || 0;
				this.data.hitung = this.data.pinjam - this.data.angsur;
			},
			keTagihan() {
				console.log("ke tagihan");
				let data = {};
				if (this.data.transfer) {
					if (this.data.transfer.id_bayar == 0) {
						data.aksi = "biasa";
					} else {
						data.aksi = "tidak";
					}
				} else {
					data.aksi = "tagihan";
				}
				data.ID = this.data.ID;
				data = [data];
				let kirim = { toke: 0, data: data };
				console.log(kirim);
				let uri = "/pinjaman/opssupir";
				axios
					.post(uri, kirim)
					.then((res) => {
						console.log(res.data);
						this.$emit("simpan");
						this.$noty.success("data Berhasil di Pindah");
					})
					.catch((e) => {
						console.log(e.message);
						this.$noty.error(e.message);
					})
					.finally(this.tutup());
			},
		},
	};
</script>
