<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile :loading="loading">
      <v-card-title
        >Rekening<v-spacer></v-spacer
        ><v-btn @click="tutup" icon color="red" large
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-card-title
      >
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field label="nama" required v-model="data.nama" dense>
              </v-text-field>
            </v-col>
            <v-col cols="12"
              ><v-text-field
                label="no rekening"
                required
                v-model="data.rek"
                dense
              >
              </v-text-field
            ></v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="data.bank"
                :items="akun"
                item-text="nama"
                item-value="ID"
                auto-select-first
                label="akun"
                autofocus
                dense
                ref="toke"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete>
            </v-col>

            <v-btn color="blue" dark type="submit" :disabled="tombolOff"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["id", "pemicu"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.kondisikan();
    },
  },
  data() {
    return {
      valid: false,
      tombolOff: false,
      data: {},
      loading: false,
      akun: [],
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid) {
        this.tombolOff = true;
        this.isiData();
        if (this.id) {
          let uri = `/rekening/${this.id}`;
          console.log(uri);
          console.log(this.data);
          axios
            .put(uri, this.data)
            .then((response) => {
              if (response.data.status) {
                this.$noty.success(response.data.messages);
                this.$emit("update", response.data.awal);
                this.$emit("tutup");
                this.tombolOff = false;
              }
            })
            .catch((error) => {
              this.$noty.error(error.message);
              this.tombolOff = false;
            });
        } else {
          let uri = "/rekening";
          console.log(uri);
          console.log(this.data);
          axios
            .post(uri, this.data)
            .then((response) => {
              if (response.data.status) {
                this.$emit("update", response.data.data);
                this.$emit("tutup");
                this.$noty.success(response.data.pesan);
              } else {
                this.$noty.error(response.data.pesan);
              }
              this.tutup();
              this.tombolOff = false;
            })
            .catch((error) => {
              this.$noty.error(error.message);
              this.tombolOff = false;
            });
        }
      }
    },
    kondisikan() {
      this.loading = true;
      axios.get("/akunjurnal/kasir").then((res) => {
        this.akun = res.data;
        if (this.id) {
          let uri = "/rekening/" + this.id;
          console.log(uri);
          axios.get(uri).then((response) => {
            this.data = response.data;
            this.data.bank = parseInt(this.data.bank);
            console.log(this.data);
            this.loading = false;
          });
        } else {
          this.data.id = 0;
          this.data.nama = "";
          this.data.rekening = "";
          this.data.bank = 0;
          this.loading = false;
        }
      });
    },
    isiData() {},
  },
};
</script>
