<template>
	<v-dialog
		v-model="dialogD"
		fullscreen
		persistent
		hide-overlay
		transition="dialog-bottom-transition">
		<v-card dense class="overflow-auto" :loading="loading"
			><v-toolbar dense elevation="-2">
				<v-btn icon large @click="tutup()">
					<v-icon>mdi-arrow-left</v-icon>
				</v-btn>
				<v-toolbar-title v-if="obNota.toke" dense class="text-uppercase">
					<v-chip class="ma-2" color="yellow">
						{{ idNota }}
					</v-chip>
					{{ obNota.keterangan }} |
					<span class="subtitle-2">{{ obNota.toke.nama }}</span>
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon class="ml-2" @click="edit" :disabled="loading">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
				<v-btn icon class="ma-2" @click="print">
					<v-icon>mdi-printer</v-icon>
				</v-btn>
				Total: {{ total | rupiah }}
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon>
							<v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item
							v-for="(item, index) in items"
							:key="index"
							@click="item.fungsi">
							<v-list-item-icon>
								<v-icon v-text="item.icon"></v-icon>
							</v-list-item-icon>
							<v-list-item-title>{{ item.judul }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<!-- <template v-slot:extension>
				<v-tabs align-with-title>
					<v-tabs-slider color="blue"></v-tabs-slider>
					<v-tab v-for="item in tab" :key="item" @click="idTab = item">
						{{ item }}
					</v-tab>
				</v-tabs>
			</template> -->
			</v-toolbar>

			<v-row no-gutters class="px-md-5" v-if="tampilan">
				<v-col cols="12" class="my-4">
					<isiton
						v-show="tonase"
						:tonase="tonase"
						:toke="toke"
						:pabrik="obPabrik"
						:Aloading="loading"
						:deo="deo"
						@reload="reload"
						:nota="obNota"
						:reset="resetNota"></isiton>
				</v-col>
				<v-col cols="12">
					<isips
						v-show="pinjaman"
						:pinjaman="pinjaman"
						:Aloading="loading"
						:toke="toke"
						@reload="reload"
						:total="pinjaman.length"
						:nota="obNota"
						:reset="resetNota"
						:saldo="saldo"></isips>
				</v-col>
			</v-row>
			<v-row no-gutters class="px-md-5" v-else>
				<v-col cols="12" class="my-4">
					<excellTon
						v-if="tonAktif"
						@reload="reload"
						:nota="obNota"
						:tonase="tonase"
						:pabrik="obPabrik"
						:reloader="reloader"
						:toke="obToke"
						:deo="deo"></excellTon>
				</v-col>
				<v-col cols="12">
					<excellPinjaman
						v-if="tonAktif"
						@reload="reload"
						:reloader="reloader"
						:nota="obNota"
						:pinjaman="pinjaman"
						:pabrik="obPabrik"
						:toke="obToke"
						:deo="deo"></excellPinjaman>
				</v-col>
			</v-row>
			<print
				:pemicu="pemicuPrint"
				:transaksi="transaksi"
				@tutup="pemicuPrint = false"
				:id="idNota"></print>
			<angsur
				:pemicu="pemicuAngsur"
				@tutup="pemicuAngsur = false"
				@simpan="
					pemicuAngsur = false;
					reload();
				"
				:id="idToke"
				:nota="obNota"></angsur>
			<nota
				:toke="toke"
				:notaDialog="notaDialog"
				:transaksi="transaksi"
				@tutup="notaDialog = false"
				@bukaNota="updateNota"
				:isEdit="true"
				:dataNota="dataEdit"></nota>
		</v-card>
	</v-dialog>
</template>
<script>
	import isiton from "./isiTonNota.vue";
	import excellTon from "./input/newInputTonase.vue";
	import excellPinjaman from "./input/excellPinjaman.vue";
	import isips from "./isiPSNota.vue";
	import print from "../print/nota.vue";
	import nota from "./input/notaBaru.vue";
	import angsur from "./input/angsur.vue";
	import axios from "axios";
	export default {
		components: {
			excellTon,
			excellPinjaman,
			isiton,
			isips,
			print,
			nota,
			angsur,
		},
		props: {
			dialog: Boolean,
			idNota: {
				type: Number,
				default: 1,
			},
			cekReload: Boolean,
			transaksi: Object,
		},
		watch: {},
		data() {
			return {
				selectedItem: 1,
				tab: ["Pabrik", "Pinjaman"],
				loading: false,
				notaDialog: false,
				pinjaman: [],
				tonase: [],
				obNota: {
					toke: 0,
				},
				pemicuPrint: false,
				dataEdit: {},
				resetNota: false,
				total: 0,
				toke: [],
				items: [
					{
						judul: "hapus",
						fungsi: () => {
							this.hapus();
						},
						icon: "mdi-delete",
					},
					{
						judul: "angsur",
						fungsi: () => {
							this.angsur();
						},
						icon: "mdi-handshake",
					},
					{
						judul: "ganti tampilan",
						fungsi: () => {
							this.gantiTampilan();
						},
						icon: "ganti",
					},
				],
				pemicuAngsur: false,
				pemicuPindah: false,
				idToke: 0,
				saldo: {},
				idTab: "Pabrik",
				obPabrik: {},
				obToke: {},
				deo: [],
				tonAktif: false,
				reloader: false,
				tampilan: false,
			};
		},
		computed: {
			dialogD: {
				get() {
					return this.dialog;
				},
				set() {
					this.$emit("tutup");
				},
			},
			totPinjam() {
				return this.pinjaman.reduce(
					(a, b) => a + (b.pinjam || 0) - (b.angsur || 0),
					0
				);
			},
		},
		mounted() {
			this.inisialisasi();
		},
		methods: {
			inisialisasi() {
				this.tonAktif = false;
				let uri = "/tokeAktif";
				axios
					.get(uri)
					.then((res) => {
						this.toke = res.data;
						this.obToke = this.convertList(res.data, "ID", "nama");
						this.getPabrik();
					})
					.catch((e) => {
						console.log(e.message);
					});
			},
			getPabrik() {
				let uri = "/pabrik";
				axios
					.get(uri)
					.then((res) => {
						this.obPabrik = this.convertList(res.data, "id", "pabrik");
						this.getDeo();
					})
					.catch((e) => {
						console.log(e.message);
					});
			},
			getDeo() {
				let uri = "/deo";
				axios
					.get(uri)
					.then((res) => {
						this.deo = res.data;
						this.getData();
					})
					.catch((e) => {
						console.log(e.message);
					});
			},
			getData() {
				this.loading = true;
				let uri = `/nota/${this.idNota}/full`;
				console.log(uri + "get data");
				axios
					.get(uri)
					.then((res) => {
						console.log(res.data);
						this.obNota = res.data.nota[0];
						this.total = res.data.total;
						this.tonase = res.data.tonase;
						this.pinjaman = res.data.pinjaman;

						this.getSaldo();
					})
					.catch((e) => {
						console.log(e.message);
						this.loading = false;
					});
				console.log(this.tonase);
			},
			getSaldo() {
				let uri = `/pinjaman/saldo/${this.obNota.toke.ID}`;
				axios.get(uri).then((r) => {
					this.saldo = r.data;
					this.reloader = !this.reloader;
					this.tonAktif = true;
					this.loading = false;
				});
			},
			hapus() {
				if (confirm("apa kamu yakin mau menghapus?")) {
					let uri = `/nota/${this.idNota}`;
					console.log(uri);
					axios
						.delete(uri)
						.then((response) => {
							this.$emit("reload");
							this.$emit("tutup");
							console.log(response);
						})
						.catch((e) => {
							this.$noty.error(e.message);
						});
				} else {
					// Do nothing!
					console.log("Thing was not saved to the database.");
				}
			},
			angsur() {
				this.idToke = this.obNota.toke.ID;
				console.log(this.idToke);
				this.pemicuAngsur = true;
				console.log("angsur");
			},

			pindahkan_ps() {
				this.idToke = this.obNota.toke.ID;
				this.pemicuPindah = true;
				console.log("pindahkan ps pt");
			},
			pindahkan_ton() {
				console.log("pindahkan tonase");
			},
			tutup() {
				this.$emit("tutup", 0);
				this.resetNota = !this.resetNota;
			},
			gantiTampilan() {
				this.tampilan = !this.tampilan;
			},
			reload() {
				this.$emit("reload", this.idNota);
				this.getData();
			},
			print() {
				this.pemicuPrint = true;
			},
			updateNota(item) {
				console.log("emit dari nota");
				this.obNota.toke.ID = parseInt(item.toke);
				this.obNota.toke.nama = this.toke.find((x) => {
					return x.ID == item.toke;
				}).nama;
				this.obNota.keterangan = item.keterangan;
				this.reload();
			},
			edit() {
				this.dataEdit = {
					ID: this.obNota.ID,
					id_admin: this.obNota.id_admin,
					id_cair: this.obNota.id_cair,
					keterangan: this.obNota.keterangan,
					toke: this.obNota.toke.ID,
				};
				this.notaDialog = true;
			},
			convertList(data, key, value) {
				let hasil = data.reduce(
					(obj, item) => Object.assign(obj, { [item[key]]: item[value] }),
					{}
				);
				return hasil;
			},
		},
	};
</script>
