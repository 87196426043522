<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="900"
    persistent
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>Tagihan</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="dialog.value = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :headers="switch1 ? headersMinimal : headersFull"
            :items="tabel"
            :items-per-page="10"
            dense
            mobile-breakpoint="300"
            :loading="loading"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-page-first',
              lastIcon: 'mdi-page-last',
              prevIcon: 'mdi-arrow-left-drop-circle-outline',
              nextIcon: 'mdi-arrow-right-drop-circle-outline',
            }"
          >
            <template v-slot:item.tanggal="{ item }">
              <span>{{ item.tanggal | formatDate }}</span>
            </template>
            <template v-slot:item.tonase="{ item }">
              <span>{{ item.tonase | angka }}</span>
            </template>
            <template v-slot:item.harga="{ item }">
              <span>{{ item.harga | rupiah }}</span>
            </template>
            <template v-slot:item.cair="{ item }">
              <span>{{ item.cair | rupiah }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                class="mx-2"
                icon
                color="blue"
                x-small
                @click="
                  idTon = item.id;
                  tonaseDialog = true;
                "
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <template slot="body.append">
              <tr>
                <th>Totals</th>
                <th></th>
                <th>{{ switch1 ? totTon : 0 | angka }}</th>
                <th>{{ switch1 ? totCair : 0 | rupiah }}</th>
                <th>{{ !switch1 ? totTon : 0 | angka }}</th>
                <th></th>
                <th>{{ !switch1 ? totCair : 0 | rupiah }}</th>
              </tr>
            </template>
          </v-data-table>
          <v-switch
            v-model="switch1"
            dense
            label="Rekap"
            class="ml-8"
          ></v-switch>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
// import axios from "axios";
// import _ from "lodash";
export default {
  components: {},
  props: {
    pemicu: Boolean,
    data: Array,
  },

  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    total() {
      return 0;
    },
  },
  data() {
    return {
      tabel: [],
      headersFull: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggal",
        },
        { text: "pabrik", value: "idpabrik.pabrik" },
        { text: "nama", value: "nama supir" },
        { text: "plat", value: "plat" },
        { text: "tonase", value: "tonase" },
        { text: "harga", value: "harga" },
        { text: "cair", value: "cair" },
        { text: "Actions", value: "actions" },
      ],
      headersMinimal: [
        {
          text: "Tanggal",
          align: "start",
          filterable: true,
          value: "tanggal",
        },
        { text: "pabrik", value: "pabrik" },
        { text: "tonase", value: "tonase" },
        { text: "cair", value: "cair" },
      ],
      loading: false,
      switch1: false,
      tonaseDialog: false,
      totTon: 0,
      totCair: 0,
    };
  },
  watch: {
    switch1() {
      if (this.switch1) {
        this.rekap();
      } else {
        this.reset();
      }
    },
    pemicu() {
      this.reset();
    },
  },
  methods: {
    tutup() {},
    rekap() {
      this.tabel = this.data.reduce((simp, item) => {
        if (
          !simp.find((x) => {
            return x.tanggal === item.tanggal;
          })
        ) {
          let hs = {
            tanggal: item.tanggal,
            pabrik: item.idpabrik.pabrik,
            tonase: parseFloat(item.tonase),
            cair: parseFloat(item.cair),
          };
          simp.push(hs);
          return simp;
        }
        let i = simp.find((x) => {
          return x.tanggal === item.tanggal;
        });
        i.tonase += parseFloat(item.tonase);
        i.cair += parseFloat(item.cair);
        return simp;
      }, []);
      this.totals();
    },
    reset() {
      this.tabel = this.data;
      this.totals();
    },
    totals() {
      this.totTon = this.data.reduce((simp, item) => {
        simp += parseFloat(item.tonase);
        return simp;
      }, 0);
      this.totCair = this.data.reduce((simp, item) => {
        simp += parseFloat(item.cair);
        return simp;
      }, 0);
    },
  },
};
</script>
