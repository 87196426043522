<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="400"
    persistent
  >
    <v-card dense tile :loading="loading">
      <v-card-title
        >Pinjaman <v-spacer></v-spacer
        ><v-btn @click="tutup" icon color="red" large
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-card-title
      >
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row
            ><v-col cols="12">
              <v-autocomplete
                v-model="data.jenis"
                :items="['PS', 'PT']"
                label="Jenis"
                ref="pinjam"
                dense
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="data.toke"
                :items="tokes"
                item-text="nama"
                item-value="ID"
                auto-select-first
                label="akun"
                autofocus
                dense
                ref="toke"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12">
              <v-text-field label="Uraian" required v-model="data.uraian" dense>
              </v-text-field>
            </v-col>
            <v-col cols="12"></v-col>
            <v-col cols="12">
              <v-text-field
                label="Pinjam"
                required
                v-model="data.pinjam"
                dense
                type="number"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Bayar" required v-model="data.angsur" dense>
              </v-text-field>
            </v-col>
            <v-btn color="blue" dark type="submit" :disabled="tombolOff"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="orange" dark @click="keTagihan()" v-show="id"
              >ke Tagihan</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
export default {
  props: ["id", "tanggal", "idAkun", "pemicu", "tanpaNota", "idNota"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.kondisikan();
    },
  },
  data() {
    return {
      valid: false,
      tombolOff: false,
      tokes: [],
      data: {},
      loading: false,
      antri: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid && this.antri == false) {
        this.antri = true;
        this.tombolOff = true;
        console.log(this.data);
        this.tanpaNota
          ? (this.data.tanpaNota = true)
          : (this.data.tanpaNota = false);
        let uri = `/pencairan/pinjaman`;
        console.log(uri);
        console.log(this.data);
        axios
          .post(uri, this.data)
          .then((response) => {
            if (response.data.status) {
              this.$noty.success(response.data.messages);
              this.$emit("simpan", response.data.awal);
              this.$emit("tutup");
              this.antri = false;
              this.tombolOff = false;
            }
          })
          .catch((error) => {
            this.$noty.error(error.message);
            this.tombolOff = false;
            this.antri = false;
          });
      }
    },
    kondisikan() {
      this.loading = true;
      console.log(this.id);
      if (this.id) {
        if (this.tanpaNota) {
          let uri = `/pinjaman/${this.id}`;
          axios.get(uri).then((response) => {
            this.data = response.data;
            this.data.toke = parseInt(this.data.toke);
            console.log(response.data);
            console.log(this.data);
          });
        } else {
          let uri = `/nota/${this.id}/full`;
          axios.get(uri).then((response) => {
            this.data = response.data.pinjaman[0];
            this.data.toke = parseInt(this.data.toke.ID);
            console.log(response.data);
            console.log(this.data);
          });
        }
      } else {
        this.data.ID = 0;
        this.data.toke = 0;
        this.data.uraian = "";
        this.data.pinjam = 0;
        this.data.angsur = 0;
        this.data.tanggal = this.tanggal;
        this.data.id_gabung = Array.isArray(this.idNota)
          ? this.idNota[0]
          : this.idNota;
        this.data.jenis = "PS";
        this.data.id_akun = this.idAkun;
      }
      this.getToke();
    },
    getToke() {
      let uri = "/tokeAktif";
      axios.get(uri).then((res) => {
        this.tokes = res.data;
        this.loading = false;
      });
    },
    keTagihan() {
      console.log("ke tagihan");
      let data = {};
      if (this.data.transfer) {
        if (this.data.transfer.id_bayar == 0) {
          data.aksi = "biasa";
        } else {
          data.aksi = "tidak";
        }
      } else {
        data.aksi = "tagihan";
      }
      data.ID = this.data.ID;
      data = [data];
      let kirim = { toke: 0, data: data };
      console.log(kirim);
      let uri = "/pinjaman/opssupir";
      axios
        .post(uri, kirim)
        .then((res) => {
          console.log(res.data);
          this.$emit("simpan");
          this.$noty.success("data Berhasil di Pindah");
        })
        .catch((e) => {
          console.log(e.message);
          this.$noty.error(e.message);
        })
        .finally(this.tutup());
    },
  },
};
</script>
