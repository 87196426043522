<template>
  <v-col cols="12">
    <v-row class="my-3">
      <v-btn class="mx-3" fab color="red" dark @click="baru"
        ><v-icon>mdi-plus</v-icon></v-btn
      ><v-spacer></v-spacer>
      <v-text-field
        class="mx-6"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dense
      ></v-text-field>
      <v-switch
        v-model="novalid"
        label="tidak valid"
        color="red darken-3"
        value="novalid"
        hide-details
        class="ma-2"
      ></v-switch
    ></v-row>
    <v-data-table
      :headers="headers"
      :items="dt"
      :items-per-page="1000"
      :search="search"
      show-select
      v-model="terpilih"
      dense
      item-key="id"
      mobile-breakpoint="300"
      :loading="loading"
      hide-default-footer
    >
      <template v-slot:item.masuk="{ item }">
        <span :class="item.valid == 0 ? 'red--text' : ''">{{
          item.masuk | angka
        }}</span>
      </template>
      <template v-slot:item.tanggal="{ item }">
        <span :class="item.valid == 0 ? 'red--text' : ''">{{
          item.tanggal | formatDate
        }}</span>
      </template>
      <template v-slot:item.keluar="{ item }">
        <span :class="item.valid == 0 ? 'red--text' : ''">{{
          item.keluar | angka
        }}</span>
      </template>
      <template v-slot:item.saldo="{ item }">
        <span>{{ item.saldo | angka }}</span>
      </template>
      <template v-slot:item.opsi="{ item }">
        <span
          ><v-btn icon @click="edit(item.id)" class="mx-1"
            ><v-icon>mdi-pencil</v-icon></v-btn
          ><v-btn icon @click="hapus(item.id)" class="mx-1"
            ><v-icon>mdi-delete</v-icon></v-btn
          ></span
        >
      </template>
      <template v-slot:item.valid="{ item }">
        <v-icon v-if="item.valid == 0">mdi-close</v-icon
        ><v-icon v-else>mdi-check</v-icon>
      </template>
    </v-data-table>
    <rekkor
      :pemicu="pemicuRekkor"
      @tutup="pemicuRekkor = false"
      @update="$emit('update')"
      :id="idRekkor"
      :rekening="id"
      :tanggal="tanggal"
    ></rekkor>
  </v-col>
</template>
<script>
import axios from "axios";
import moment from "moment";
import rekkor from "./rekkor.vue";
export default {
  components: { rekkor },
  props: ["data", "toke", "id"],
  computed: {
    dt() {
      if (this.novalid) {
        return this.data.filter((x) => x.valid == 0);
      } else {
        return this.data;
      }
    },
  },
  data() {
    return {
      tanggal: moment().format("YYYY-MM-DD"),
      loading: false,
      akun: {},
      pemicuRekkor: false,
      search: "",
      terpilih: [],
      idRekkor: 0,
      pemicuFilter: false,
      pemicuExcel: false,
      reloader: false,
      headers: [
        { text: "Tanggal", value: "tanggal" },
        { text: "Uraian", value: "uraian" },
        { text: "Masuk", value: "masuk" },
        { text: "Keluar", value: "keluar" },
        { text: "saldo", value: "saldo" },
        { text: "v", value: "valid" },
        { text: "opsi", value: "opsi" },
      ],
      fieldExcell: {
        ID: "id",
        Jenis: "jenis",
        Tanggal: "tanggal",
        Akun: "toke.nama",
        uraian: "uraian",
        Masuk: "masuk",
        Keluar: "keluar",
        Saldo: "saldo",
      },
      saldo: 0,
      novalid: false,
    };
  },
  mounted() {
    this.inisialisasi();
  },
  watch: {
    tanggal() {
      this.inisialisasi();
    },
  },
  methods: {
    inisialisasi() {},
    getToke() {},
    home() {
      this.$router.push("/rekening");
    },
    baru() {
      this.idRekkor = 0;
      this.pemicuRekkor = true;
    },
    edit(id) {
      this.idRekkor = id;
      this.pemicuRekkor = true;
    },
    hapus(id) {
      let uri = `/rekeningkoran/${id}`;
      axios.delete(uri).then((res) => {
        console.log(res.data);
        this.$noty.success("berhasil di hapus");
        this.inisialisasi();
        this.$emit("update");
      });
    },
    updateFilter(item) {
      this.data = item;
    },
    refresh() {},
  },
};
</script>
