<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="600"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Akun Baru</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Nama"
                required
                v-model="dataTransaksi.nama"
                ref="nama"
                :rules="[(v) => !!v || 'Harus diisi']"
              ></v-text-field
            ></v-col>
            <v-col cols="12">
              <v-text-field
                label="Nama lama"
                required
                v-model="dataTransaksi.nama_lama"
                ref="namaL"
                :rules="[(v) => !!v || 'Harus diisi']"
              ></v-text-field
            ></v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="dataTransaksi.aktif"
                auto-select-first
                :items="['Aktif', 'Nonaktif']"
                label="Aktif"
                ref="aktif"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-btn color="blue" dark type="submit" :disabled="loadingSimpan"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import rizki from "./../../../mixins/rizki.js";
export default {
  mixins: [rizki],
  props: ["pemicu", "id", "jenis"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
      this.kondisikan();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      dataTransaksi: {
        nama: "",
        jenis: "",
        nama_lama: "",
        aktif: "Aktif",
      },
      loading: false,
      loadingSimpan: false,
      pembanding: {},
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid && this.loadingSimpan == false) {
        this.loadingSimpan = true;
        console.log(this.dataTransaksi);
        this.simpan();
        if (this.id > 0) {
          let uri = `/akun/${this.id}`;
          axios
            .put(uri, this.r_data_update(this.dataTransaksi, this.pembanding))
            .then((response) => {
              if (response.data.status) {
                this.$noty.success("Data berhasil disimpan");
                this.tutup();
                this.$emit("lanjutkan", response.data.data);
                console.log(response.data.data);
                this.loadingSimpan = false;
              } else {
                this.$noty.success(response.data.data[0]);
              }
            })
            .catch((error) => {
              console.log(error);
              this.loadingSimpan = false;
            });
        } else {
          console.log(this.dataTransaksi);
          let uri = "/akun";
          axios
            .post(uri, this.dataTransaksi)
            .then((response) => {
              if (response.data.status) {
                this.$noty.success("Data berhasil disimpan");
                this.tutup();
                this.$emit("lanjutkan", response.data.data);
                console.log(response.data.data);
                this.loadingSimpan = false;
              } else {
                this.$noty.success(response.data.data[0]);
              }
            })
            .catch((error) => {
              console.log(error);
              this.loadingSimpan = false;
            });
        }
      }
    },
    inisialisasi() {
      if (this.id > 0) {
        let uri = `/akun/${this.id}`;
        axios.get(uri).then((response) => {
          this.dataTransaksi = response.data;
          this.pembanding = Object.assign({}, this.dataTransaksi);
        });
      } else {
        this.dataTransaksi.nama = "";
        this.dataTransaksi.jenis = this.jenis;
        this.dataTransaksi.nama_lama = "";
        this.dataTransaksi.aktif = "Aktif";
      }
    },
    simpan() {},
    kondisikan() {},
  },
};
</script>
