<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog.value = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Print</v-toolbar-title><v-spacer></v-spacer
            ><v-btn dark color="pink" @click="print"
              ><v-icon>mdi-printer</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text> </v-card-text>
          <v-row class="lebar" id="printTagihan">
            <v-col>
              <v-card :loading="loading">
                <v-card-title>
                  <h4>Tagihan pembayaran pabrik</h4>
                </v-card-title>
                <table class="tabel">
                  <thead>
                    <tr class="judul">
                      <th>No</th>
                      <th>Pabrik</th>
                      <th>Tanggal</th>
                      <th>Nama</th>
                      <th>Plat</th>
                      <th>Tonase</th>
                      <th>Harga</th>
                      <th>Cair</th>
                    </tr>
                  </thead>
                  <tbody v-for="items in tabel" :key="items.pabrik">
                    <tr>
                      <td colspan="8" class="judul">{{ items.pabrik }}</td>
                    </tr>
                    <tr
                      v-for="(item, index) in items.data"
                      :key="item.id"
                      class="isiTabel"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.idpabrik.pabrik }}</td>
                      <td>{{ item.tanggal | formatDate }}</td>
                      <td>{{ item["nama supir"] }}</td>
                      <td>{{ item.plat }}</td>
                      <td>{{ item.tonase | angka }}</td>
                      <td>{{ item.harga | rupiah }}</td>
                      <td>{{ (item.tonase * item.harga) | rupiah }}</td>
                    </tr>
                    <tr class="saldo">
                      <td colspan="5">Total</td>
                      <td>{{ items.sumTonase | angka }}</td>
                      <td></td>
                      <td>{{ items.sumCair | rupiah }}</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="saldo">
                      <td colspan="5">Grand Total</td>
                      <td>{{ totalTon | angka }}</td>
                      <td></td>
                      <td>{{ totalCair | rupiah }}</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="saldo">
                      <td colspan="5">PPH</td>
                      <td></td>
                      <td></td>
                      <td>{{ pph | rupiah }}</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr class="saldo">
                      <td colspan="5">Dibayar</td>
                      <td></td>
                      <td></td>
                      <td>{{ (totalCair - pph) | rupiah }}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<style type="text/css" media="print">
@import "@/assets/css/tagihanpabrik.css";
</style>

<script>
import moment from "moment";
import axios from "axios";
import _ from "lodash";
export default {
  components: {},
  props: ["pemicu", "id", "data"],
  data() {
    return {
      tabel: [],
      loading: false,
      totalTon: 0,
      totalCair: 0,
      pph: 0,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  mounted() {},
  watch: {
    pemicu() {
      this.tabel = [];
      this.inisialisasi();
    },
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      console.log(this.data);
      if (this.data.length > 0) {
        let hasil = [];
        this.data.forEach((x) => {
          x.idpabrik = x.do.pabrik;
          x.tanggal = x.tanggaldo;
          hasil.push(x);
        });
        this.tabel = hasil;
        this.tabel = this.tabel.sort((a, b) => {
          return new Date(a.tanggal) - new Date(b.tanggal);
        });
        console.log("ini datanya ada");
        console.log(this.tabel);
        this.pph = parseInt(this.totPPH());
        this.grup();
        this.loading = false;
      } else {
        let url = "/tonase/cairbank/" + this.id;
        axios.get(url).then((response) => {
          this.tabel = response.data;
          console.log("ini datanya kosong");
          console.log(this.tabel);
          this.pph = this.totPPH();
          this.grup();
          this.loading = false;
        });
      }
    },
    totPPH() {
      console.log("start hitung pph");
      const a = this.tabel.reduce((simp, item) => {
        if (item.do.pempph == "1") {
          simp +=
            (parseFloat(item.tonase) *
              parseFloat(item.harga) *
              parseFloat(item.do.pph)) /
            100;
        }
        return simp;
      }, 0);
      return a;
    },
    grup() {
      this.tabel = _.chain(this.tabel)
        .groupBy("idpabrik.pabrik")
        .map((value, key) => ({
          pabrik: key,
          data: value,
          sumTonase: this.sumPer(value, "tonase"),
          sumCair: parseInt(this.sumCair(value)),
        }))
        .value();
      console.log("grup di jalankan");
      console.log(this.tabel);
      this.totalTon = this.sumPer(this.tabel, "sumTonase");
      this.totalCair = this.sumPer(this.tabel, "sumCair");
    },
    sumPer(item, prop) {
      return item.reduce(function (a, b) {
        return a + (parseInt(b[prop]) || 0);
      }, 0);
    },
    sumCair(item) {
      return item.reduce(function (a, b) {
        a += (parseFloat(b.tonase) || 0) * (parseFloat(b.harga) || 0);
        return a;
      }, 0);
    },
    periode() {
      return moment(this.thn + "/" + this.bln, "YYYY/M").format("MMMM YYYY");
    },
    goPrint() {
      window.print();
    },
    print() {
      this.$htmlToPaper("printTagihan", {
        styles: ["/aplikasi/riz/app.css", "/aplikasi/riz/tagihanpabrik.css"],
      });
    },
  },
};
</script>
