<template>
  <v-card dense class="overflow-auto" outlined>
    <v-row
      ><v-col cols="12"
        ><v-row class="px-4 mt-4 mx-2 mb-2"
          ><span class="text-button">Tonase</span><v-spacer></v-spacer
          ><v-btn
            class="mx-4 mt-n-2"
            fab
            color="blue"
            dark
            @click="
              idTon = 0;
              pemicuTon = true;
            "
            ><v-icon>mdi-plus</v-icon></v-btn
          ></v-row
        ></v-col
      >

      <v-col cols="12" v-if="totMbl() > 0"
        ><v-card
          v-for="(item, id) in tonase"
          :key="item.id"
          @click="
            idTon = item.id;
            pemicuTon = true;
          "
          ><v-card-text
            ><v-row class="ma-1"
              ><span class="mx-2 text-title">{{ id + 1 }}</span>
              <v-chip class="mx-2" color="success" outlined>
                <v-icon left> mdi-factory </v-icon>
                {{ item.do ? item.do.pabrik.pabrik : "" }} </v-chip
              ><span>{{ item["nama supir"] }}</span
              ><v-spacer></v-spacer
              ><span
                >{{ parseInt(item.tonase) | formatNumber
                }}<span class="font-weight-bold"
                  >({{ cair(item) | rupiah }})</span
                ></span
              ></v-row
            ></v-card-text
          ></v-card
        ></v-col
      ><v-col cols="12" v-if="totMbl() > 0"
        ><v-card
          ><v-card-text
            ><v-row class="ma-1"
              ><span class="mx-2 text-title">{{ totMbl() }} Mobil </span>

              <v-spacer></v-spacer
              ><span class="mx-2 text-title"
                >{{ totTon().tonase | formatNumber }} </span
              >(<span class="font-weight-bold">{{
                totTon().cair | rupiah
              }}</span
              >)</v-row
            ></v-card-text
          ></v-card
        ></v-col
      ></v-row
    ><v-divider></v-divider>
    <v-row
      ><v-col cols="12"
        ><v-row class="px-4 mt-4 mx-2 mb-2"
          ><span class="text-button">Pinjaman</span><v-spacer></v-spacer
          ><v-btn
            class="mx-4"
            fab
            color="blue"
            dark
            @click="
              idPinjaman = 0;
              pemicuPinjaman = true;
            "
            ><v-icon>mdi-plus</v-icon></v-btn
          ></v-row
        ></v-col
      >
      <v-col cols="12" v-if="pinjaman.length > 0"
        ><v-card
          v-for="(item, id) in pinjaman"
          :key="item.id"
          @click="
            idPinjaman = item.ID;
            pemicuPinjaman = true;
          "
          ><v-card-text
            ><v-row class="ma-1"
              ><span class="mx-2 text-title">{{ id + 1 }}</span>
              <v-chip class="mx-2" color="success" outlined>
                <v-icon left> mdi-account </v-icon>
                {{ item.toke ? item.toke.nama : "" }} </v-chip
              ><span>{{ item.uraian }}</span
              ><v-spacer></v-spacer
              ><span>{{
                (parseInt(item.pinjam) - parseInt(item.angsur)) | rupiah
              }}</span></v-row
            ></v-card-text
          ></v-card
        ></v-col
      ><v-col cols="12" v-if="pinjaman.length > 0"
        ><v-card
          ><v-card-text
            ><v-row class="ma-1">
              <v-spacer></v-spacer
              ><span class="mx-2 text-title">{{ totPinjaman() | rupiah }}</span>
            </v-row></v-card-text
          ></v-card
        ></v-col
      ></v-row
    ><tonase
      :pemicu="pemicuTon"
      @tutup="pemicuTon = false"
      :id="idTon"
      :nota="nota"
      :pabriks="pabrik"
      :deo="deo"
      :toke="toke"
      @reload="reload"
    ></tonase>
    <pinjaman
      :id="idPinjaman"
      :pemicu="pemicuPinjaman"
      :tanggal="nota.tanggal"
      :idAkun="nota.id_admin"
      :idNota="nota.ID"
      :tanpaNota="true"
      @tutup="pemicuPinjaman = false"
      @simpan="reload"
    ></pinjaman>
  </v-card>
</template>
<script>
// import axios from "axios";
import tonase from "./inputTon.vue";
import pinjaman from "../input/bayar/pinjamanMini.vue";
export default {
  components: { tonase, pinjaman },
  props: ["reloader", "nota", "pinjaman", "tonase", "pabrik", "toke", "deo"],
  watch: {
    tonase() {
      console.log(this.tonase);
    },
  },
  data() {
    return { pemicuTon: false, pemicuPinjaman: false, idTon: 0, idPinjaman: 0 };
  },
  computed: {},
  mounted() {
    this.inisialisasi();
  },
  methods: {
    async inisialisasi() {},
    cair(item) {
      return parseInt(item.tonase) * (parseInt(item.harga) + parseInt(item.th));
    },
    totMbl() {
      return this.tonase.length;
    },
    totTon() {
      return this.tonase.reduce(
        (awal, x) => {
          awal.tonase += parseInt(x.tonase);
          awal.cair += this.cair(x);
          return awal;
        },
        { tonase: 0, cair: 0 }
      );
    },
    totPinjaman() {
      return this.pinjaman.reduce((awal, x) => {
        awal += parseInt(x.pinjam) - parseInt(x.angsur);
        return awal;
      }, 0);
    },
    reload() {
      this.$emit("reload");
    },
  },
};
</script>
