<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="500"
    persistent
  >
    <v-card dense tile>
      <v-card-title>
        <h3>Tabel Tonase</h3>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="tokePilihan"
              :items="toke"
              item-text="nama"
              item-value="id"
              dense
              chips
              small-chips
              label="Pilih toke untuk filter"
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="akunPilihan"
              :items="akun"
              item-text="nama"
              item-value="id"
              dense
              chips
              small-chips
              label="Pilih kasir untuk filter"
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="pabrikPilihan"
              :items="pabrik"
              item-text="nama"
              item-value="id"
              dense
              chips
              small-chips
              label="Pilih pabrik untuk filter"
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="thPilihan"
              :items="th"
              outlined
              dense
              chips
              small-chips
              label="Pilih TH untuk filter"
              multiple
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="blue" dark @click="simpan">Simpan</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red" dark @click="tutup()">Batal</v-btn></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["pemicu", "tonase"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
      this.kondisikan();
      console.log("terbuka");
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      loading: false,
      tokePilihan: [],
      akunPilihan: [],
      pabrikPilihan: [],
      thPilihan: [],
      toke: [],
      akun: [],
      pabrik: [],
      tonUpdate: [],
      th: [],
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {},
    inisialisasi() {
      this.tonUpdate = this.tonase;
      this.toke = this.tabelFilter1("toke", "ID", "nama");
      this.akun = this.tabelFilter2("transaksi", "akun", "id", "nama");
      this.pabrik = this.tabelFilter2("do", "pabrik", "id", "pabrik");
      this.th = this.filterTh();
    },
    filterToke() {},
    tabelFilter1(kolom, idnya, nama) {
      if (this.tonUpdate.length >> 0) {
        var c = this.tonUpdate.reduce(function (chace, item) {
          const properti = item[kolom][idnya];
          const kolomA = item[kolom][nama];
          if (chace.some((el) => el.id === properti)) {
            return [...chace];
          }
          chace.push({ id: properti, nama: kolomA });
          return [...chace];
        }, []);
        return c;
      }
    },
    tabelFilter2(kolom, subKolom, idnya, nama) {
      if (this.tonUpdate.length >> 0) {
        var c = this.tonUpdate.reduce(function (chace, item) {
          const properti = item[kolom][subKolom][idnya];
          const kolomA = item[kolom][subKolom][nama];
          if (chace.some((el) => el.id === properti)) {
            return [...chace];
          }
          chace.push({ id: properti, nama: kolomA });
          return [...chace];
        }, []);
        return c;
      }
    },
    filterTh() {
      if (this.tonUpdate.length >> 0) {
        var c = this.tonUpdate.reduce(function (chace, item) {
          const properti = parseInt(item.th);
          if (chace.indexOf(properti) !== -1) {
            return [...chace];
          }
          chace.push(properti);
          return [...chace];
        }, []);
        return c;
      }
    },
    filter() {
      if (this.tonase.length >> 0) {
        this.tonUpdate = this.tonase;
        const toke = this.tokePilihan;
        const akun = this.akunPilihan;
        const pabrik = this.pabrikPilihan;
        const th = this.thPilihan;
        if (toke.length >> 0) {
          console.log("toke difilter");
          this.tonUpdate = this.tonUpdate.filter(function (x) {
            return toke.indexOf(x.toke.ID) !== -1;
          });
        }
        if (akun.length >> 0) {
          console.log("akun di filter");
          this.tonUpdate = this.tonUpdate.filter(function (x) {
            return akun.indexOf(x["transaksi"]["akun"]["id"]) !== -1;
          });
        }
        if (pabrik.length >> 0) {
          this.tonUpdate = this.tonUpdate.filter(function (x) {
            return pabrik.indexOf(x["do"]["pabrik"]["id"]) !== -1;
          });
        }
        if (th.length >> 0) {
          this.tonUpdate = this.tonUpdate.filter(function (x) {
            return th.indexOf(parseInt(x["th"])) !== -1;
          });
        }
      }
    },
    simpan() {
      this.filter();
      this.$emit("simpan", this.tonUpdate);
      this.$emit("tutup");
    },
    kondisikan() {},
  },
};
</script>
