<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="600"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Invoice</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="No Invoice"
                required
                v-model="data['no inv']"
                ref="nomor"
                :rules="[(v) => !!v || 'Harus diisi']"
              ></v-text-field
            ></v-col>
            <v-col cols="12" class="mt-1">
              <v-menu
                v-model="pupup"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="ubahTanggal"
                    clearable
                    label="Tanggal kirim"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data['tanggal kirim']"
                  @change="pupup = false"
                ></v-date-picker> </v-menu
            ></v-col>
            <v-col cols="12" class="mt-1">
              <v-menu
                v-model="pupup2"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="ubahTanggal2"
                    clearable
                    label="Tanggal invoice"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date2 = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data['tanggal inv']"
                  @change="pupup2 = false"
                ></v-date-picker> </v-menu
            ></v-col>
            <v-col cols="12" class="mt-1">
              <v-autocomplete
                v-model="data.pabrik"
                auto-select-first
                item-text="pabrik"
                item-value="id"
                :items="pabrik"
                label="Pabrik"
                ref="pabrik"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-btn color="blue" dark type="submit" :disabled="loadingSimpan"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import rizki from "./../../../mixins/rizki.js";
import moment from "moment";
export default {
  mixins: [rizki],
  props: ["pemicu", "buku", "bln", "id"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    ubahTanggal() {
      return this.data
        ? moment(this.data["tanggal kirim"]).format("DD/MM/YYYY")
        : "";
    },
    ubahTanggal2() {
      return this.data
        ? moment(this.data["tanggal inv"]).format("DD/MM/YYYY")
        : "";
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
      this.kondisikan();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      data: {
        "no inv": "",
        "tanggal kirim": "",
        "tanggal inv": "",
        pabrik: 0,
        buku: 0,
        tonase: 0,
        dpp: 0,
        ppn: 0,
        pph: 0,
        dari_tanggal: "",
        sampai_tanggal: "",
        link_inv: null,
        link_faktur: null,
        "PPN FAKTA": 0,
      },
      pupup: false,
      pupup2: false,
      loading: false,
      loadingSimpan: false,
      pembanding: {},
      pabrik: [],
      kelasBuku: [
        { id: 2, buku: "SMJ" },
        { id: 3, buku: "AKD" },
        { id: 4, buku: "JSAJ" },
      ],
      bulanRomawi: [
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
        "X",
        "XI",
        "XII",
      ],
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid && this.loadingSimpan == false) {
        this.loadingSimpan = true;
        this.simpan();
        if (this.id > 0) {
          console.log(this.r_data_update(this.data, this.pembanding));
          let uri = `/invoice/${this.id}`;
          axios
            .put(uri, this.r_data_update(this.data, this.pembanding))
            .then((response) => {
              if (response.data.status) {
                this.$noty.success("Data berhasil disimpan");
                this.tutup();
                this.$emit("lanjutkan", response.data.data);
                this.loadingSimpan = false;
              } else {
                this.$noty.success(response.data.data[0]);
              }
            });
        } else {
          console.log(this.data);
          let uri = "/invoice";
          axios.post(uri, this.data).then((response) => {
            if (response.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.tutup();
              this.$emit("lanjutkan", response.data.data);
              this.loadingSimpan = false;
            } else {
              this.$noty.success(response.data.data[0]);
            }
          });
        }
      }
    },
    inisialisasi() {
      let uri = "/pabrikgrup";
      this.r_coba();
      axios.get(uri).then((response) => {
        this.pabrik = response.data;
        console.log(this.pabrik);
      });
      if (this.id > 0) {
        let uri = `/invoice/${this.id}`;
        axios.get(uri).then((response) => {
          this.data = response.data;
          console.log(this.data);
          this.data.pabrik = parseInt(this.data.pabrik.id);
          this.pembanding = Object.assign({}, this.data);
        });
      } else {
        let tglB = moment(this.bln + "/1")
          .add(1, "months")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        let idBuku = this.buku;
        let noInv =
          `001/${
            this.kelasBuku.find((x) => {
              return x.id == idBuku;
            }).buku
          }/` +
          this.bulanRomawi[parseInt(this.bln.split("/")[1]) - 1] +
          "/" +
          this.bln.split("/")[0];
        let tglA = moment(this.bln + "/1").format("YYYY-MM-DD");
        this.data["no inv"] = noInv;
        this.data["tanggal kirim"] = moment().format("YYYY-MM-DD");
        this.data["tanggal inv"] = tglB;
        this.data.pabrik = 0;
        this.data.buku = this.buku;
        this.data.tonase = 0;
        this.data.dpp = 0;
        this.data.ppn = 0;
        this.data.pph = 0;
        this.data.dari_tanggal = tglA;
        this.data.sampai_tanggal = tglB;
        this.data.link_inv = null;
        this.data.link_faktur = null;
        this.data["PPN FAKTA"] = 0;
        console.log(this.data);
      }
    },
    simpan() {},
    kondisikan() {},
  },
};
</script>
