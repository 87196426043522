<template>
	<v-row dense no-gutters class="elevation-2 rounded-lg my-5">
		<v-col cols="12">
			<v-toolbar dense flat color="grey lighten-4" class="elevation-3">
				<span>Pinjaman/ pengeluaran</span
				><v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
					dense
					class="mx-4"></v-text-field
				><v-spacer></v-spacer>
				<span class="mx-2">{{ total() | rupiah }}</span>
			</v-toolbar>
		</v-col>
		<v-col cols="12" class="my-auto grey lighten-1">
			<v-btn
				color="blue"
				dark
				class="ma-1"
				x-small
				v-for="(item, i) in menu"
				:disabled="Aloading"
				:key="i"
				@click.stop="diklik(item)"
				><v-icon dark left v-text="item.icon"></v-icon>{{ item.title }}
			</v-btn>
			<span
				><a
					class="mx-2"
					@click="
						idToke = nota.toke.ID;
						pemicuAngsur = true;
					"
					>PS: {{ saldo.ps | rupiah }}</a
				><span class="mx-2" @click="pemicuAngsur = true"
					>PT: {{ saldo.pt | rupiah }}</span
				></span
			>
			<v-btn
				color="green"
				class="ma-1"
				x-small
				dark
				@click="gantiKolom"
				:disabled="adaYangTerpilih"
				:loading="loadingKolom"
				>Pindah Kolom</v-btn
			>
			<v-btn
				color="green"
				class="ma-1"
				x-small
				dark
				@click="gantiJenis"
				:disabled="adaYangTerpilih"
				:loading="loadingJenis"
				>Ganti Jenis Pin</v-btn
			>
			<v-btn
				color="green"
				class="ma-1"
				x-small
				dark
				@click="pemicuToke = true"
				:disabled="adaYangTerpilih"
				:loading="loadingGantiToke"
				>Ganti Toke</v-btn
			>
			<v-btn
				color="orange"
				class="ma-1"
				x-small
				dark
				@click="pemicuPindah = true"
				:disabled="adaYangTerpilih"
				:loading="loadingGantiToke"
				>Pindah Nota</v-btn
			>
			<v-btn
				color="pink"
				class="ma-1"
				x-small
				dark
				@click="cekTagihan"
				:disabled="adaYangTerpilih"
				:loading="loadingGantiToke"
				>Tagihan</v-btn
			>
			<v-btn
				color="orange"
				class="ma-1"
				x-small
				dark
				@click="pemicuPigas = true"
				:loading="loadingGantiToke"
				>Pigas</v-btn
			>
		</v-col>
		<v-col cols="12" class="mt-3 elevation-5">
			<v-data-table
				:headers="headers"
				:items="pinjaman"
				:search="search"
				:items-per-page="100"
				show-select
				dense
				v-model="terpilih"
				item-key="ID"
				mobile-breakpoint="300"
				group-by="jenis"
				hide-default-footer
				v-show="pinjaman.length > 0">
				<template v-slot:group.header="{ items, isOpen, toggle }">
					<th colspan="11" class="blue lighten-4">
						<v-icon @click="toggle"
							>{{ isOpen ? "mdi-minus" : "mdi-plus" }}
						</v-icon>
						{{ items[0].jenis }}
					</th>
				</template>
				<template v-slot:item.pinjam="{ item }">
					<span>{{ item.pinjam | rupiah }}</span>
				</template>
				<template v-slot:item.angsur="{ item }">
					<span>{{ item.angsur | rupiah }}</span>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-btn
						class="mx-2"
						icon
						x-small
						color="green"
						@click.stop="edit(item)">
						<v-icon> mdi-pencil </v-icon>
					</v-btn>
					<v-btn
						class="mx-2"
						fab
						icon
						x-small
						color="red"
						:nota="nota"
						@click.stop="hapus(item)"
						:loading="loadingDelet"
						:disabled="loadingDelet">
						<v-icon> mdi-trash-can </v-icon>
					</v-btn>
				</template></v-data-table
			>
			<pinjamanedit
				:dialogPinjaman="dialogPinjaman"
				:id="idNya"
				:toke="toke"
				@tutup="tutup"
				@update="reload"></pinjamanedit>
			<ganti-toke
				:pemicu="pemicuToke"
				@tutup="pamicuToke = false"
				:toke="toke"
				@aksi="gantiToke"></ganti-toke>
			<pigas
				:pemicu="pemicuPigas"
				@tutup="pemicuPigas = false"
				:idNota="nota ? nota.ID : 0"
				@simpan="reload"></pigas>
			<pinjamD
				:pinjamDialog="pinjamDialog"
				@tutup="tutupPinjamD"
				:toke="toke"
				:jenisDialog="jenisDialog"
				:nota="nota"
				@reload="reload"></pinjamD>
			<pindah
				:pemicu="pemicuPindah"
				@tutup="pemicuPindah = false"
				@simpan="
					pemicuPindah = false;
					reload();
				"
				:id="nota ? nota.id_cair : 0"
				:paket="terpilih"
				:tonase="false"></pindah>
			<angsuran
				:pemicu="pemicuAngsur"
				@tutup="pemicuAngsur = false"
				@simpan="
					pemicuAngsur = false;
					reload();
				"
				:id="idToke"
				:nota="nota"></angsuran>
		</v-col>
	</v-row>
</template>

<script>
	import axios from "axios";
	import pinjamanedit from "./input/pinjamanEdit.vue";
	import pinjamD from "./input/pinjam.vue";
	import gantiToke from "../Tonase/gantiToke.vue";
	import pindah from "./pindah/listNota.vue";
	import angsuran from "./input/angsur.vue";
	import pigas from "./input/bayar/bayarPigas.vue";
	export default {
		components: {
			pinjamanedit,
			pinjamD,
			gantiToke,
			pindah,
			angsuran,
			pigas,
		},
		computed: {
			adaYangTerpilih() {
				if (this.terpilih.length > 0) {
					return false;
				} else {
					return true;
				}
			},
		},
		watch: {
			reset() {
				this.terpilih = [];
			},
		},
		props: ["pinjaman", "nota", "toke", "reset", "Aloading", "saldo"],
		data() {
			return {
				menu: [
					{ id: 2, title: "Pinjam PS", icon: "mdi-account" },
					{ id: 3, title: "Pinjam PT", icon: "mdi-account" },
					{ id: 4, title: "Bayar PS", icon: "mdi-note" },
					{ id: 5, title: "Bayar PT", icon: "mdi-note" },
				],
				headers: [
					{
						text: "Toke",
						align: "start",
						filterable: true,
						value: "toke.nama",
					},
					{ text: "Uraian", value: "uraian" },
					{ text: "Pinjam", value: "pinjam" },
					{ text: "Bayar", value: "angsur" },
					{ text: "Kategori", value: "jenis" },
					{ text: "Actions", value: "actions", sortable: false },
				],
				search: "",
				terpilih: [],
				dialogPinjaman: false,
				idNya: 0,
				loadingKolom: false,
				loadingJenis: false,
				loadingDelet: false,
				pinjamDialog: false,
				loadingGantiToke: false,
				pemicuToke: false,
				jenisDialog: {},
				pemicuPindah: false,
				id_cair: 0,
				pemicuAngsur: false,
				idToke: 0,
				pemicuPigas: false,
			};
		},
		methods: {
			edit(item) {
				this.idNya = item.ID;
				this.dialogPinjaman = true;
			},
			tutup() {
				this.dialogPinjaman = false;
			},
			reload() {
				this.dialogPinjaman = false;
				this.$emit("reload");
			},
			gantiKolom() {
				this.loadingKolom = true;
				let tabel = [];
				this.terpilih.forEach((item) => {
					let pinj = parseInt(item.pinjam) || 0;
					let angs = parseInt(item.angsur) || 0;
					let hsl = angs - pinj;
					tabel.push({
						ID: item.ID,
						pinjam: angs,
						angsur: pinj,
						hasil: hsl,
					});
				});
				let uri = "/pinjaman/gantikolom/1";
				axios
					.put(uri, tabel)
					.then((response) => {
						if (response.data) {
							this.$noty.success("data Berhasil di Update");
							this.loadingKolom = false;
							this.$emit("reload");
							(tabel = []), (this.terpilih = []);
						}
					})
					.catch((error) => {
						this.$noty.error(error.response.data.errors[0]);
					});
			},
			gantiJenis() {
				this.loadingJenis = true;
				let tabel = [];
				this.terpilih.forEach((item) => {
					let jns = "PS";
					if (item.jenis == "PS") {
						jns = "PT";
					} else {
						jns = "PS";
					}
					tabel.push({
						ID: item.ID,
						jenis: jns,
					});
				});
				let uri = "/pinjaman/gantijenis/1";
				axios
					.put(uri, tabel)
					.then((response) => {
						if (response.data) {
							this.$noty.success("data Berhasil di Update");
							this.loadingJenis = false;
							this.$emit("reload");
							(tabel = []), (this.terpilih = []);
						}
					})
					.catch((error) => {
						this.$noty.error(error.response.data.errors[0]);
					});
			},
			gantiToke(item) {
				this.loadingToke = true;
				this.pemicuToke = false;
				let tabel = this.terpilih.map((item) => {
					return item.ID;
				});
				let uri = `/pinjaman/gantitoke/${item.ID}`;
				axios
					.put(uri, tabel)
					.then((response) => {
						if (response.data.status) {
							this.$noty.success("data Berhasil di Update");
							this.loadingToke = false;
							this.$emit("reload");
							tabel = [];
							this.terpilih = [];
						} else {
							this.$noty.error("terjadi kesalahan");
						}
					})
					.catch((error) => {
						this.$noty.error(error.response.data.errors[0]);
					});
			},
			pindahkan_ps() {
				this.idToke = this.nota.toke.ID;
				this.pemicuPindah = true;
				console.log("pindahkan ps pt");
			},
			hapus(item) {
				this.loadingDelet = true;
				let uri = "/pinjaman/" + item.ID;
				axios.delete(uri).then((response) => {
					this.$emit("reload");
					console.log(response);
					this.loadingDelet = false;
				});
			},
			tutupPinjamD() {
				this.pinjamDialog = false;
			},
			total() {
				return this.pinjaman.reduce(
					(a, b) => a + (b.pinjam || 0) - (b.angsur || 0),
					0
				);
			},
			diklik(item) {
				switch (item.id) {
					case 1:
						console.log("email");
						break;
					case 2:
						this.jenisDialog.jenis = "PS";
						this.jenisDialog.input = "pinjam";
						this.pinjamDialog = true;
						break;
					case 3:
						this.jenisDialog.jenis = "PT";
						this.jenisDialog.input = "pinjam";
						this.pinjamDialog = true;
						break;
					case 4:
						this.jenisDialog.jenis = "PS";
						this.jenisDialog.input = "bayar";
						this.pinjamDialog = true;
						break;
					case 5:
						this.jenisDialog.jenis = "PT";
						this.jenisDialog.input = "bayar";
						this.pinjamDialog = true;
						break;
				}
			},
			cekTagihan() {
				let cek = this.terpilih.map((x) => {
					if (x.transfer) {
						if (x.transfer.id_bayar == 0) {
							x.aksi = "biasa";
						} else {
							x.aksi = "tidak";
						}
					} else {
						x.aksi = "tagihan";
					}
					return { ID: x.ID, aksi: x.aksi };
				});
				let data = { toke: this.nota.toke.ID, data: cek };
				let uri = "/pinjaman/opssupir";
				axios
					.post(uri, data)
					.then((res) => {
						console.log(res.data);
						this.$noty.success("data Berhasil di Pindah");
						this.reload();
						this.terpilih = [];
					})
					.catch((e) => {
						console.log(e.message);
						this.$noty.error(e.message);
					});
			},
		},
	};
</script>
