<template>
  <div>
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="dialog"
      width="600"
      persistent
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Log Book Printer</v-toolbar-title
            ><v-spacer></v-spacer
            ><v-btn icon dark @click="dialog.value = false" color="red">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Tanggal Print</th>
                    <th>Tgl dari</th>
                    <th>Tgl sampai</th>
                    <th>Opsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tabel" :key="item.id">
                    <td>{{ item.tanggal | formatDate }}</td>
                    <td>
                      {{ item.dari | formatDate }}
                    </td>
                    <td>
                      {{ item.sampai | formatDate }}
                    </td>
                    <td>
                      <a @click="print(item)">Print Ulang</a>
                    </td>
                  </tr>
                </tbody>
              </template></v-simple-table
            >
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <printt
      @tutup="pemicuPrint = false"
      :pemicu="pemicuPrint"
      :tanggalPrint="tanggalPrint"
      :admin="id"
    ></printt>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import printt from "./portrait.vue";
export default {
  components: { printt },
  props: {
    pemicu: Boolean,
    id: Object,
  },

  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  data() {
    return {
      tabel: [],
      loading: false,
      pemicuPrint: false,
      tanggalPrint: [],
    };
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  methods: {
    inisialisasi() {
      this.loading = true;
      let uri = "/print/akun/" + this.id.akun.id;
      axios.get(uri).then((response) => {
        this.tabel = _.values(response.data);
        this.loading = false;
        console.log(this.tabel);
      });
    },
    tutup(){},
    print(x) {
      console.log(x);
      this.tanggalPrint = [x.dari, x.sampai];
      this.pemicuPrint = true;
    },
  },
};
</script>
