<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    width="600"
    persistent
  >
    <v-card dense tile>
      <v-card-title>Pabrik</v-card-title>
      <v-card-text>
        <v-form
          @submit.prevent="kirim()"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="12" md="7">
              <v-text-field
                label="Nama Pabrik"
                required
                v-model="data.pabrik"
                ref="pabrik"
                :rules="[(v) => !!v || 'Harus diisi']"
              ></v-text-field
            ></v-col>
            <v-col cols="5">
              <v-autocomplete
                v-model="data.grup_pabrik"
                auto-select-first
                :items="grup"
                item-text="pabrik"
                item-value="id"
                label="Grup Pabrik"
                ref="grup"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="data.id_ops"
                auto-select-first
                :items="toke"
                item-text="nama"
                item-value="ID"
                label="Akun OPS"
                ref="grup"
                :rules="[(v) => !!v || 'Harus diisi']"
              >
              </v-autocomplete
            ></v-col>
            <v-btn color="blue" dark type="submit" :disabled="loadingSimpan"
              >Simpan</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="red" dark @click="tutup()">Batal</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import rizki from "./../../../mixins/rizki.js";
export default {
  mixins: [rizki],
  props: ["pemicu", "id"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    pemicu() {
      this.inisialisasi();
    },
  },
  mounted() {},
  data() {
    return {
      valid: false,
      data: {
        pabrik: "",
        grup_pabrik: 0,
        id_ops: 0,
      },
      loadingSimpan: false,
      grup: [],
      toke: [],
      pembanding: {},
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    kirim() {
      if (this.valid && this.loadingSimpan == false) {
        this.loadingSimpan = true;
        if (this.id > 0) {
          let uri = `/pabrik/${this.id}`;
          axios
            .put(uri, this.r_data_update(this.data, this.pembanding))
            .then((response) => {
              if (response.data.status) {
                this.$noty.success("Data berhasil disimpan");
                this.tutup();
                this.$emit("lanjutkan", response.data.data);
                console.log(response.data.data);
                this.loadingSimpan = false;
              } else {
                this.$noty.success(response.data.data[0]);
              }
            });
        } else {
          console.log(this.data);
          let uri = "/pabrik";
          axios.post(uri, this.data).then((response) => {
            if (response.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.tutup();
              this.$emit("lanjutkan", response.data.data);
              console.log(response.data.data);
              this.loadingSimpan = false;
            } else {
              this.$noty.success(response.data.data[0]);
            }
          });
        }
      }
    },
    inisialisasi() {
      axios.get("/pabrikgrup").then((response) => {
        this.grup = response.data;
        axios.get("/toke").then((res) => {
          this.toke = res.data;
        });
      });
      if (this.id > 0) {
        let uri = `/pabrik/${this.id}`;
        axios.get(uri).then((response) => {
          this.data = response.data;
          console.log(this.data);
          this.data.grup_pabrik = parseInt(this.data.grup_pabrik);
          this.data.id_ops = parseInt(this.data.id_ops);
          this.pembanding = Object.assign({}, this.data);
        });
      } else {
        this.data.pabrik = "";
        this.data.grup_pabrik = 0;
        this.data.id_ops = 0;
      }
    },
  },
};
</script>
