var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"900","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"dense":"","tile":""}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Rekap Transaksi "+_vm._s(_vm._f("formatDate")(_vm.arrTanggal[0]))+" - "+_vm._s(_vm._f("formatDate")(_vm.arrTanggal[1])))]),_c('v-spacer'),_c('download-excel',{staticClass:"mx-2",attrs:{"data":_vm.tabel,"fields":_vm.fieldExcell,"worksheet":"data","name":_vm.filename}},[_c('v-btn',{attrs:{"icon":"","color":"blue"}},[_c('v-icon',[_vm._v("mdi-table-large")])],1)],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',[_c('v-btn-toggle',{attrs:{"dense":""},model:{value:(_vm.toggle_none),callback:function ($$v) {_vm.toggle_none=$$v},expression:"toggle_none"}},_vm._l((_vm.namaPabrik),function(a){return _c('v-btn',{key:a.id,attrs:{"x-small":""},on:{"click":function($event){return _vm.filter(a)}}},[_vm._v(" "+_vm._s(a)+" ")])}),1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tabel,"items-per-page":5,"dense":"","mobile-breakpoint":"300","loading":_vm.loading,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-page-first',
          lastIcon: 'mdi-page-last',
          prevIcon: 'mdi-arrow-left-drop-circle-outline',
          nextIcon: 'mdi-arrow-right-drop-circle-outline',
        }},scopedSlots:_vm._u([{key:"item.tanggal",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.tanggal)))])]}},{key:"item.tanggalawal",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.daritanggal))+"-"+_vm._s(_vm._f("formatDate")(item.sampaitanggal)))])]}},{key:"item.cair",fn:function({ item }){return [_c('span',{class:_vm.classEror(item)},[_vm._v(_vm._s(_vm._f("angka")(item.cair)))])]}},{key:"item.pembanding",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("angka")(item.banding)))])]}},{key:"item.selisih",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("angka")((parseInt(item.cair) - parseInt(item.banding)))))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","color":"blue","x-small":""},on:{"click":function($event){return _vm.buka(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","color":"blue","x-small":""},on:{"click":function($event){return _vm.bukaSatu(item)}}},[_c('v-icon',[_vm._v(" mdi-list-status ")])],1)]}}])})],1),_c('v-card-actions'),_c('tambah',{attrs:{"transaksi":_vm.transaksi,"pemicu":_vm.dialogTambah,"idcair":_vm.idcair,"akun":_vm.akun,"tanggal":_vm.arrTanggal[0]},on:{"tutup":_vm.tutup,"insert":_vm.insert,"update":_vm.update}}),_c('satusatu',{attrs:{"idTanggal":_vm.idcair,"pemicu":_vm.pemicuSatu},on:{"tutup":function($event){_vm.pemicuSatu = false},"update":_vm.update}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }