<template>
  <v-col cols="12">
    <v-card dense tile :loading="loading" class="mb-6">
      <v-card-title>Rekening koran <v-spacer></v-spacer></v-card-title>
      <v-card-text>
        <vue-excel-editor
          v-model="data"
          filter-row
          ref="grid"
          enterToSouth
          no-paging
          new-if-bottom
          :no-mouse-scroll="false"
          free-select
          :readonly="!edit"
          @update="update"
        >
          <vue-excel-column
            field="tanggal"
            label="Tanggal"
            type="date"
            width="90px"
          />
          <vue-excel-column
            field="uraian"
            label="Uraian"
            type="string"
            width="250px"
          />
          <vue-excel-column
            field="masuk"
            label="masuk"
            type="number"
            width="150px"
            summary="sum"
            :to-text="toText"
            :to-value="toVal"
          />
          <vue-excel-column
            field="keluar"
            label="keluar"
            type="number"
            width="150px"
            summary="sum"
            :to-text="toText"
            :to-value="toVal"
          />
          <vue-excel-column
            field="saldo"
            label="saldo"
            type="number"
            width="150px"
            :to-text="toText"
            :to-value="toVal"
          />
        </vue-excel-editor>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="save"
          color="blue"
          v-if="edit"
          dark
          class="mx-3"
          :disabled="loading"
          >Simpan</v-btn
        >
        <v-btn
          @click="
            edit = false;
            getData();
          "
          color="red"
          v-if="edit"
          dark
          >Batal</v-btn
        ><v-btn @click="edit = true" color="green" v-else dark>Edit</v-btn>
        <v-spacer></v-spacer
        ><v-btn v-if="edit == false" dark color="red" @click="hapus"
          ><v-icon>mdi-delete</v-icon></v-btn
        ></v-card-actions
      >
    </v-card>
  </v-col>
</template>
<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
// import moment from "moment";

export default {
  props: [
    "tanggal",
    "pabrik",
    "pinjaman",
    "pemicu",
    "toke",
    "rekening",
    "reloader",
  ],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    reloader() {
      this.inisialisasi();
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      pabrikStd: 1,
      tokeStd: 1,
      tanggalStd: 0,
      harga: [],
      edit: false,
    };
  },
  mounted() {
    this.inisialisasi();
  },
  methods: {
    async inisialisasi() {
      console.log("excell pinjaman di eksekusi");
      this.loading = true;
      let uri = `/setting/get/tokeStandar`;
      this.tokeStd = await axios
        .get(uri)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return 1;
        });
      this.getData();
    },
    tutup() {
      this.$emit("tutup");
    },
    async getData() {
      if ((await this.pinjaman.length) > 0) {
        this.data = this.pinjaman.map((x) => {
          let item = {};
          item.jenis = x.jenis;
          item.akun = x.akun;
          item.tanggal = x.tanggal;
          item.uraian = x.uraian;
          item.masuk = parseInt(x.masuk);
          item.keluar = parseInt(x.keluar);
          item.saldo = parseInt(x.saldo);
          item.id = x.id;
          return item;
        });
        this.edit = false;
      } else {
        this.data = [
          {
            jenis: "PINJAMAN",
            akun: this.tokeStd,
            tanggal: moment().format("YYYY-MM-DD"),
            uraian: "",
            masuk: 0,
            keluar: 0,
            id: 0,
          },
        ];
        this.edit = true;
      }
      console.log(this.data);
      this.loading = false;
    },
    tokes() {
      return this.toke;
    },
    toVal(val) {
      return parseInt(val.replace(/[^\d-]/g, ""));
    },
    toText(val) {
      return numeral(parseInt(val)).format("0,0");
    },
    update(record) {
      let a = this.data.findIndex((x) => {
        return x.$id == record[0].$id;
      });
      if (!record[0].newVal && !record[0].oldVal) {
        this.data[a].jenis = "PINJAMAN";
        this.data[a].akun = this.tokeStd;
        this.data[a].tanggal = moment().format("YYYY-MM-DD");
        this.data[a].uraian = "";
        this.data[a].masuk = 0;
        this.data[a].keluar = 0;
        this.data[a].id = 0;
      }
    },
    cekData() {
      console.log(this.data);
    },
    save() {
      if (this.loading == false) {
        let uri = `/rekeningkoran/bank`;
        this.loading = true;
        console.log(uri);
        this.data = this.data.filter((x) => {
          return !(x.masuk == 0 && x.keluar == 0);
        });
        let data = {};
        data.data = this.data;
        data.rekening = this.rekening;
        console.log(data);
        axios
          .post(uri, data)
          .then((res) => {
            console.log(res.data);
            if (res.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.getData();
              this.edit = false;
              this.$emit("reload");
              console.log(res.data);
            }
          })
          .catch((e) => {
            this.$noty.error(e.message);
          })
          .finally((this.loading = false));
      }
    },
    hapus() {
      let terpilih = this.$refs.grid.getSelectedRecords().map((x) => x.id);
      if (terpilih.length > 0) {
        // console.log(terpilih);
        let uri = "/rekeningkoran/hapus";
        axios
          .post(uri, terpilih)
          .then((res) => {
            console.log(res.data);
            this.$noty.success("data Berhasil dihapus");
            this.$emit("reload");
            terpilih = [];
          })
          .catch((e) => {
            console.log(e.message);
            this.$noty.error(e.message);
          });
      } else {
        console.log("harus dipilih dulu");
      }
    },
  },
};
</script>
