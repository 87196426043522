<template>
  <v-card dense tile :loading="loading" class="mb-6">
    <v-card-title>Timbangan Ramp <v-spacer></v-spacer></v-card-title>
    <v-card-text>
      <vue-excel-editor
        v-model="data"
        filter-row
        ref="grid"
        enterToSouth
        no-paging
        new-if-bottom
        :no-mouse-scroll="false"
        free-select
        :readonly="!edit"
        @update="update"
      >
        <vue-excel-column
          field="tanggalDo"
          label="Tanggal"
          type="date"
          width="90px"
        />
        <vue-excel-column
          field="nama"
          label="nama"
          type="string"
          width="150px"
        />
        <vue-excel-column
          field="bruto"
          label="bruto"
          type="number"
          width="80px"
          summary="sum"
          :to-text="toText"
          :to-value="toVal"
        />
        <vue-excel-column
          field="tara"
          label="tara"
          type="number"
          width="80px"
          summary="sum"
          :to-text="toText"
          :to-value="toVal"
        />
        <vue-excel-column
          field="tbs"
          label="tbs"
          type="number"
          width="80px"
          summary="sum"
          :to-text="toText"
          :to-value="toVal"
        />

        <vue-excel-column
          field="potongan"
          label="potongan"
          type="number"
          width="50px"
          summary="sum"
          :to-text="toText"
          :to-value="toVal"
        />
        <vue-excel-column
          field="netto"
          label="netto"
          type="number"
          :change="updateHitungan"
          width="80px"
          summary="sum"
          :to-text="toText"
          :to-value="toVal"
        />
        <vue-excel-column
          field="harga"
          label="harga"
          type="number"
          :change="updateHitungan"
          width="80px"
          summary="sum"
          :to-text="toText"
          :to-value="toVal"
        />
        <vue-excel-column
          field="cair"
          label="cair"
          type="number"
          width="150px"
          summary="sum"
          :to-text="toText"
          :to-value="toVal"
        />
      </vue-excel-editor>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="save"
        color="blue"
        v-if="edit"
        dark
        class="mx-3"
        :disabled="loading"
        >Simpan</v-btn
      >
      <v-btn
        @click="
          edit = false;
          getData();
        "
        color="red"
        v-if="edit"
        dark
        >Batal</v-btn
      ><v-btn @click="edit = true" color="green" v-else dark>Edit</v-btn>
      <v-spacer></v-spacer
      ><v-btn v-if="edit == false" dark color="red" @click="hapus"
        ><v-icon>mdi-delete</v-icon></v-btn
      ></v-card-actions
    >
  </v-card>
</template>
<script>
import axios from "axios";
import numeral from "numeral";
// import moment from "moment";

export default {
  props: ["tanggal", "idAkun", "dataAwal", "reloader", "idNota"],
  components: {},
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
  },
  watch: {
    reloader() {
      this.inisialisasi();
    },
  },
  mounted() {
    this.inisialisasi();
  },
  data() {
    return {
      loading: false,
      data: [],
      pabrikStd: 1,
      tokeStd: 1,
      tanggalStd: 0,
      harga: [],
      edit: false,
    };
  },
  methods: {
    tutup() {
      this.$emit("tutup");
    },
    inisialisasi() {
      if (this.dataAwal.length > 0) {
        this.data = this.dataAwal.map((x) => {
          let item = {};
          item.id = x.id;
          item.tanggalDo = x.tanggalDo;
          item.nama = x.nama;
          item.bruto = parseInt(x.bruto);
          item.tara = parseInt(x.tara);
          item.tbs = parseInt(x.tbs);
          item.potongan = parseInt(x.potongan);
          item.netto = parseInt(x.netto);
          item.harga = parseInt(x.harga);
          item.cair = item.netto * item.harga;
          return item;
        });
      } else {
        this.data = [
          {
            id: 0,
            tanggalDo: this.tanggal,
            nama: "",
            bruto: 0,
            tara: 0,
            tbs: 0,
            potongan: 0,
            netto: 0,
            harga: 0,
            cair: 0,
          },
        ];
        this.edit = true;
      }
      console.log(this.data);
      this.loading = false;
    },
    tokes() {
      return this.toke;
    },
    toVal(val) {
      return parseInt(val.replace(/[^\d-]/g, ""));
    },
    toText(val) {
      return numeral(parseInt(val)).format("0,0");
    },
    update(record) {
      let a = this.data.findIndex((x) => {
        return x.$id == record[0].$id;
      });
      if (!record[0].newVal && !record[0].oldVal) {
        this.data[a].id = 0;
        this.data[a].tanggalDo = this.tanggal;
        this.data[a].nama = "";
        this.data[a].bruto = 0;
        this.data[a].tara = 0;
        this.data[a].tbs = 0;
        this.data[a].potongan = 0;
        this.data[a].netto = 0;
        this.data[a].harga = 0;
        this.data[a].cair = 0;
      } else {
        this.data[a].cair = this.data[a].harga * this.data[a].netto;
      }
    },
    cekData() {
      console.log(this.data);
    },
    save() {
      if (this.loading == false) {
        let uri = `/ramp/excel`;
        this.loading = true;
        console.log(uri);
        this.data = this.data.filter((x) => {
          return !(x.netto == 0);
        });
        let data = {};
        data.data = this.data;
        data.idAkun = this.idAkun;
        data.idNota = this.idNota;
        data.tanggal = this.tanggal;
        console.log(data);
        axios
          .post(uri, data)
          .then((res) => {
            console.log(res.data);
            if (res.data.status) {
              this.$noty.success("Data berhasil disimpan");
              this.edit = false;
              this.$emit("reload");
              console.log(res.data);
            }
          })
          .catch((e) => {
            this.$noty.error(e.message);
          })
          .finally(() => (this.loading = false));
      }
    },
    updateHitungan(newVal, oldVal, row) {
      console.log(newVal + oldVal);
      let a = this.data.findIndex((x) => {
        return x.$id == row.$id;
      });
      this.data[a].cair = this.data[a].netto * this.data[a].harga;
    },
    hapus() {
      let terpilih = this.$refs.grid.getSelectedRecords();
      if (terpilih.length > 0) {
        let uri = "/ramp/hapus";
        const data = terpilih.map((x) => x.id);
        console.log(uri);
        console.log(data);
        axios
          .post(uri, data)
          .then((res) => {
            console.log(res.data);
            this.$noty.success("data Berhasil dihapus");
            this.$emit("reload");
            terpilih = [];
          })
          .catch((e) => {
            console.log(e.message);
            this.$noty.error(e.message);
          });
      } else {
        console.log("harus dipilih dulu");
      }
    },
  },
};
</script>
