<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    persistent
    width="550"
  >
    <v-card :loading="loading">
      <v-toolbar dense color="blue" dark>
        <v-toolbar-title>Tonase Edit</v-toolbar-title>
        <v-spacer></v-spacer>
        <span v-if="tonase.transaksi"
          >{{ tonase.transaksi.akun.nama }} /
          {{ tonase.transaksi.tanggal | formatDate }}</span
        >
      </v-toolbar>
      <v-card-text>
        <v-form
          @submit.prevent="updateData"
          color="grey"
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="4">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs"
                      clearable
                      label="Tanggal"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="date = null"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tonase.tanggaldo"
                    @change="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  v-model="tonase.toke"
                  :items="toke"
                  item-text="nama"
                  item-value="ID"
                  label="Toke"
                  dense
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  :items="deo"
                  label="Do"
                  item-text="do"
                  item-value="id"
                  v-model="tonase.do"
                  required
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Nama Supir"
                  required
                  v-model="tonase.supir"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Plat"
                  required
                  v-model="tonase.plat"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Tonase"
                  required
                  v-model="tonase.tonase"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="th"
                  required
                  v-model="tonase.th"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <p class="subtitle-1 pa-3">
                  X {{ hargaHitung ? hargaHitung.harga : 0 }}
                </p>
              </v-col>
              <v-col cols="12" md="4">
                <p class="subtitle-1 pa-3">
                  {{
                    ((hargaHitung ? hargaHitung.harga : 0) * tonase.tonase)
                      | rupiah
                  }}
                </p>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="OPS"
                  required
                  v-model="tonase.ops"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="updateData"
                  type="submit"
                  :disabled="tombolOff"
                  class="ma-2"
                >
                  Simpan
                </v-btn>
                <v-btn text @click="tutup"> Batal </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
  data() {
    return {
      menu1: false,
      date: "",
      tonase: [],
      harga: [],
      loadingTon: false,
      loadingHarga: false,
      valid: false,
      tombolOff: false,
      loading: false,
      deo: [],
      toke: [],
    };
  },
  props: ["id", "dialogTon"],
  computed: {
    computedDateFormattedMomentjs() {
      return this.tonase
        ? moment(this.tonase.tanggaldo).format("DD/MM/YYYY")
        : "";
    },
    hargaHitung() {
      return this.cariHarga();
    },
    dialog: {
      get() {
        return this.dialogTon;
      },
      set() {
        this.$emit("tutup");
        this.modalModal = [];
      },
    },
  },
  watch: {
    id() {
      this.inisialisasi();
    },
  },
  mounted() {
    this.inisialisasi();
  },
  methods: {
    tutup() {
      this.$emit("tutup", 0);
    },
    inisialisasi() {
      if (this.id) {
        this.loading = true;
        axios.get("/tonase/" + this.id).then((response) => {
          this.tonase = response.data[0];
          this.tonase.do = parseInt(this.tonase.do);
          this.tonase.toke = parseInt(this.tonase.toke);
          console.log(this.tonase);
          this.getDeo();
          this.getToke();
          this.getHarga();
          this.loading = false;
        });
      }
    },
    getToke() {
      let uri = "/tokeAktif";
      axios.get(uri).then((res) => {
        this.toke = res.data;
      });
    },
    getDeo() {
      let uri = "/deo";
      axios.get(uri).then((res) => {
        this.deo = res.data;
      });
    },
    getHarga() {
      if (this.id) {
        axios.get("/harga/60hari/" + this.tonase.tanggaldo).then((response) => {
          this.harga = response.data;
          this.loadingHarga = true;
        });
      }
    },
    updateData() {
      if (this.valid) {
        this.tombolOff = true;
        let tabel = this.tonase;
        let toke2 = this.toke;
        tabel.txtoke = toke2.find((x) => x.ID == tabel.toke).nama;
        tabel.pabrik = this.deo.find((x) => x.id == tabel.do).pabrik.pabrik;
        this.tonase.harga = this.cariHarga().harga;
        this.tonase.id_harga = this.cariHarga().id;
        let uri = `/tonase/${this.tonase.id}`;
        axios
          .put(uri, this.tonase)
          .then((response) => {
            if (response.data.status) {
              this.$noty.success(response.data.messages);
              this.$emit("update", tabel);
              this.tombolOff = false;
            }
          })
          .catch((error) => {
            this.$noty.error(error.response.data.errors[0]);
            this.tombolOff = false;
          });
      }
    },
    cariHarga() {
      if (this.loadingHarga) {
        let deo = this.tonase.do;
        let tgl = this.tonase.tanggaldo;
        let pabrik = this.deo.find(function (item) {
          return item.id == deo;
        }).pabrik.id;
        let hasil = this.harga.filter(function (item) {
          return (
            moment(tgl).isSameOrAfter(item.tanggal) && pabrik == item.pabrik
          );
        });
        if (hasil.length > 0) {
          return hasil[hasil.length - 1];
        } else {
          return 0;
        }
      }
    },
    simpan() {},
  },
};
</script>
